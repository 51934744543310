import React from 'react';
import { Helmet } from 'react-helmet';
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import AuthApi from '../../../assets/js/utils/Auth'
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import GridItem from "../../components/Grid/GridItem";
import { Link as RouerLink } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
import Button from "../../components/CustomButtons/Button";
import DeleteUserModal from "../../components/User/DeleteUserModal";
import CancelDeleteRequestModal from "../../components/User/CancelDeleteRequestModal";
// import Countdown from 'react-countdown';
import moment from 'moment';

import { createBrowserHistory } from 'history'
const history = createBrowserHistory();

const Privacy = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            showError: false,
            errorMessage: "",
            saving: false,
            deleteFileModal: false,
            deleteRequestModal: false
        };

        this.deleteUser = this.deleteUser.bind(this);
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }    
    }
    deleteUser(){
        this.setState({deleteUserModal: true});
    }
    onModalClose(deleteUserModal = true){
        this.setState({
            deleteUserModal: deleteUserModal,
        });
    }
    onModalSuccess(){
        const { user } = this.store.getState();
        let values = {};
        const source = axios.CancelToken.source();
        Api.deleteUser(user.id, values, source).then((data) => {
            this.setState({
                deleteUserModal: false,
                saving: false
            });
            this.logout();
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                saving: false
            });
        });

        this.setState({
            saving: true
        });
    }
    logout(){
        history.push('/');
        AuthApi.logout(this);
        this.store.dispatch({type: 'LOGOUT', state: {notifications: null}});
    }
    deleteRequestModal(status = true){
        this.setState({deleteRequestModal: status});
    }
    onDeleteRequestModalSuccess(){
        const { user } = this.store.getState();
        let values = {};
        const source = axios.CancelToken.source();
        Api.restoreDeletedUser(user.id, values, source).then((data) => {
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
            this.store.dispatch({
                type: "LOGIN",
                state: {
                    user: data.user
                }
            });
            this.setState({
                deleteRequestModal: false,
                saving: false
            });
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                saving: false
            });
        });

        this.setState({
            saving: true
        });
    }
    renderScheduled(){
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return null;
        }
        if(!user.hasOwnProperty("scheduled_deletion") || !user.scheduled_deletion 
        || !user.hasOwnProperty("scheduled_deletion_at") || !user.scheduled_deletion_at || user.scheduled_deletion_at.length <= 0){
            return null;
        }
        const { classes } = this.props;
        const deletionDate = moment(user.scheduled_deletion_at);
        const currentDate = moment(new Date());
        const duration = moment.duration(deletionDate.diff(currentDate));
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        return (
            <>
                <p className={classes.scheduledDeletion}>
                    Your account is in the process of being deleted in{" "}
                    {
                        days > 0 ?
                            days > 1 ? (days+" days") : (days+" day")
                        :
                            hours > 0 ?
                                hours > 1 ? (hours+" hours") : (hours+" hour")
                            :
                                minutes > 1 ? (minutes+" minutes") : (minutes+" minute")
                    }
                    {/* <Countdown className={classes.countdown} date={new Date(user.scheduled_deletion_at)} /> */}
                </p>
                <Button className={"user-manage"} color="custom" onClick={() => this.deleteRequestModal()}>Cancel Deletion Request</Button>
            </>
        )

    }
    render() {
        const { deleteUserModal, deleteRequestModal, saving } = this.state;
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.accountMainContainer}>
                    <div className={classes.content}>
                        <GridItem>
                            <div className={classes.privacyContainer}>
                                <h3 className={classes.title}>Privacy settings</h3>
                                <div className="privacy-section">
                                    <h4>Delete Account</h4>
                                    <p>You may request permanent deletion of your account at any time, provided you do not have active subscriptions or organizations. Please consider the following common problems and their solutions before continuing:</p>
                                    <ul>
                                        <li><RouerLink to={"/user/profile"} >Need to update Personal Account Details?</RouerLink></li>
                                        <li><RouerLink to={"/user/account"} >Want a different plan?</RouerLink></li>
                                        <li><RouerLink to={"/user/profile"} >Getting too many emails?</RouerLink></li>
                                    </ul>
                                    <div className="delete-account">
                                        {
                                            (!user.hasOwnProperty("scheduled_deletion") || !user.scheduled_deletion 
                                            || !user.hasOwnProperty("scheduled_deletion_at") || !user.scheduled_deletion_at || user.scheduled_deletion_at.length <= 0) ?
                                                <>
                                                    <p>Account you are requesting to be deleted: {user.email}</p>
                                                    <Button className={"user-manage"} color="custom" onClick={() => this.deleteUser()}>Continue</Button>
                                                </>
                                            :
                                            this.renderScheduled()
                                        }
                                    </div>
                                </div>
                            </div>
                        </GridItem>
                    </div>
                    <DeleteUserModal open={deleteUserModal} saving={saving} onClose={() => this.onModalClose(false)} onSuccess={() => this.onModalSuccess()} />
                    <CancelDeleteRequestModal
                        open={deleteRequestModal}
                        saving={saving}
                        onClose={() => this.deleteRequestModal(false)}
                        onSuccess={() => this.onDeleteRequestModalSuccess()}
                    />
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Privacy.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(Privacy);
