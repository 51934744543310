import React from 'react';
import axios from "axios";
// import Cookie from '../../../assets/js/utils/Cookie';
import Api from "../../../assets/js/utils/Api";
import { Helmet } from 'react-helmet';
import Auth from "../../../assets/js/utils/Auth";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import GridItem from "../../components/Grid/GridItem";
import AddLoginVideoModal from "../../components/User/AddLoginVideoModal";
import EditIcon from "@material-ui/icons/EditOutlined";
import IconButton from "@material-ui/core/IconButton";
import DeleteLoginVideoModal from "../../components/User/DeleteLoginVideoModal";

import MaterialIcon from "@mdi/react";
import { mdiDeleteOutline } from '@mdi/js';

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
import { helper } from '../../../assets/js/utils/Element.js';

const LoginVideos = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            allAccountTypes: this.getAccountTypes(true),
            limit: 200,
            response: [],
            cancelToken: null,
            loading: false,
            videos: [],
            checked: [],
            errorMessage: null,
            addVideoModal: false,
            editMode: false,
            loginVideoDetails: this.defaultLoginVideoDetails(),
            success: false,
            deleteModal: false,
            deletingVideo: false,
            checkedAll: false,
            videoId: null,
        };
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(user.hasOwnProperty('user_type') && user.user_type !== "business"){
            let redirectUrl = helper.getHomePageUrl(user);
            if(user.level_id === 5){
                redirectUrl = "/user/shared";
            }
            this.history.push(redirectUrl);
        }
        if(authorized){
            this.loadLoginVideos();
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    defaultLoginVideoDetails(){
        return {
            type: "",
            title: "",
            video_url: "",
            additionalVideos: [
                {
                    title: "",
                    video_url: ""
                }
            ]
        }
    }
    getAccountTypes(all = false){
        let types = this.getTypes();
        
        let accountTypes = [];
        types.map(type => {
            if(type.id === '-1'){
                return null;
            }
            if(all === true){
                accountTypes.push(type);
            }else{
                if(!this.state.typesArray.includes(type.id)){
                    accountTypes.push(type);
                }
            }
            return null;
        });

        if(all === true){
            return accountTypes;
        }
        
        let stateObj = {};
        stateObj['accountTypes'] = accountTypes;

        this.setState(stateObj)
    }
    getTypes(){
        return [
            {
                id: 2,
                label: "Standard",
            },
            {
                id: 3,
                label: "Pro",
            },
        ]
    }
    prepareTypesArray(){
        let types = [];
        this.state.response.map(data => {
            types.push(data.type);

            return null
        });

        this.setState({
            typesArray: types
        }, () => {
            this.getAccountTypes();
        });
    }
    onAddVideoModalSuccess(){
        this.loadLoginVideos();
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    onAddVideoModal(status){
        if(status === false){
            this.loadLoginVideos();
            return;
        }
        this.setState({
            addVideoModal: status,
            loginVideoDetails: this.defaultLoginVideoDetails()
        });
    }
    onEditModal(status = true, video){
        if(!status){
            this.loadLoginVideos();
            return;
        }
        this.setState({
            addVideoModal: status,
            loginVideoDetails: {
                id: video.id,
                type: video.type,
                title: video.title,
                video_url: video.video_url,
                additionalVideos: video.hasOwnProperty("additional_videos") ? video.additional_videos : []
            },
            editMode: status
        });
    }
    loadLoginVideos(){
        const source = axios.CancelToken.source();

        let requestData = {};
        Api.getLoginVideos(requestData, source).then(data => {
            this.setState({
                response: data.response,
                loading: false,
            });

            this.prepareTypesArray();
        }).catch(err => {

        });

        this.setState({
            loading: !this.state.loading, 
            showError: false, 
            cancelToken: source,
            addVideoModal: false,
            editMode: false,
            deleteModal: false,
            deletingVideo: false,
            checked: [],
            checkedAll: false
        });
    }
    onToggleAllVideos(e){
        if(this.state.loading){
            return;
        }

        const checkedVideos = [];
        if(e.target.checked){
            const { response } = this.state;

            response.map((video, key) => {
                checkedVideos.push(video.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedVideos
        });
    }
    getColumns(){
        let columns = [
            {
                Header: "Type",
                accessor: "type",
                headerClassName: "hd_type",
                className: "hd_type td_type left",
            },
            {
                Header: "Title",
                accessor: "title",
                headerClassName: "hd_title",
                className: "hd_title td_title left",
            },
            {
                Header: "Video Url",
                accessor: "video_url",
                headerClassName: "hd_video_url",
                className: "hd_video_url td_video_url left",
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false,
                headerClassName: "hd_actions left",
                className: "hd_actions td_actions left",
            }
        ];
        
        return columns;
    }
    handleToggle(video) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(video.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(video.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked,
        });
    }
    onDeleteModal(status = false, videoId = null){
        const { checked } = this.state;
        if(checked.length <= 0 && videoId === null && status === true){
            return;
        }
        this.setState({
            deleteModal: status,
            videoId: videoId
        });
    }
    onDeleteModalSuccess(){
        const { videoId, checked } = this.state;
        let videos = [videoId];
        if(videoId === null){
            videos = checked;
        }

        const source = axios.CancelToken.source();
        this.setState({
            deletingVideo: true,
            cancelToken: source,
        });
        const requestData = {
            loginVideos: videos
        }

        Api.deleteLoginVideo(requestData, source).then(data => {
            this.loadLoginVideos();
        }).catch(err => {
            console.log(err);
        });
    }
    getType(type){
        let typeName = "";
        this.state.allAccountTypes.map((item) => {
            if(item.id === type){
                typeName = item.label;
            }
            return null;
        });

        return typeName;
    }
    getTableData(){
        const { response } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(video => {
            if(video.type !== 2 && video.type !== 3){
                return null;
            }
            let videoArray = {
                id: video.id,
                type: this.getType(video.type),
                title: video.title,
                video_url: video.video_url,
                actions: (
                    <>
                        <IconButton
                            color="inherit"
                            onClick={() => this.onEditModal(true, video)}
                            title="Edit"
                        >
                            <EditIcon />
                        </IconButton>
                        {
                            video.owner_id !== 0 ?
                                <IconButton 
                                    color="inherit" 
                                    onClick={() => this.onDeleteModal(true, video.id)}
                                    title="Delete"
                                >
                                    <MaterialIcon path={mdiDeleteOutline} className="MuiSvgIcon-root" />
                                </IconButton>
                            :
                                <></>
                        }
                    </>
                )
            };
            tableData.push(videoArray);
            return null;
        });
        return tableData;
    }
    getVideosContent(response){
        const { classes } = this.props;
        const { loading, accountTypes } = this.state; 
        const { user } = this.store.getState();

        let content = (
            <div className={classes.content}>
                <GridItem>
                    <div className={classes.controls}>
                        <h4>Login Videos</h4>
                        <div className={classes.controlButtons+" "+(user.theme === 'standard' ? '': 'light-theme-buttons')}>
                            {
                                accountTypes && accountTypes.length > 0 ?
                                    <Button color="transparent" onClick={() => this.onAddVideoModal(true)}>
                                        <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.7858 3.125C11.7858 2.69531 11.4322 2.34375 11.0001 2.34375C10.5679 2.34375 10.2143 2.69531 10.2143 3.125V11.7188H1.57148C1.13934 11.7188 0.785767 12.0703 0.785767 12.5C0.785767 12.9297 1.13934 13.2812 1.57148 13.2812H10.2143V21.875C10.2143 22.3047 10.5679 22.6562 11.0001 22.6562C11.4322 22.6562 11.7858 22.3047 11.7858 21.875V13.2812H20.4286C20.8608 13.2812 21.2143 12.9297 21.2143 12.5C21.2143 12.0703 20.8608 11.7188 20.4286 11.7188H11.7858V3.125Z"/>
                                        </svg>
                                        Add video
                                    </Button>
                                :
                                    <></>
                            }
                        </div>
                    </div>
                    <div className={classes.usersContainer}>
                        <div className={classes.users}>
                            {
                                loading ?
                                    <LoaderComponent color="custom" align="center" />
                                :
                                    <ReactTable
                                        columns={this.getColumns()}
                                        data={this.getTableData()}
                                        pageSizeOptions={[500]}
                                        defaultPageSize={50000000000}
                                        showPaginationTop={false}
                                        minRows={0}
                                        showPaginationBottom={false}
                                        className={"-striped -highlight "+classes.filesTable+" loginVideosTable"}
                                        getNoDataProps={() => this.getNoDataProps()}
                                    />
                            }
                        </div>
                    </div>
                </GridItem>
            </div>
        );
        
        return (
            content 
        );
    }
    render() {
        const { classes } = this.props;
        const { response, addVideoModal, editMode, loginVideoDetails, deleteModal, deletingVideo, checked, 
            allAccountTypes, accountTypes } = this.state;
        
        const { authorized, user } = this.store.getState();
        if(!authorized || user.level_id === 5){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }   
        
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    {
                        this.getVideosContent(response)
                    }
                    {
                        addVideoModal ?
                            <AddLoginVideoModal 
                                store={this.store} 
                                allAccountTypes={allAccountTypes} 
                                accountTypes={accountTypes} 
                                open={addVideoModal} 
                                editMode={editMode} 
                                loginVideoDetails={loginVideoDetails} 
                                onClose={() => this.onAddVideoModal(false)} 
                                onSuccess={() => this.onAddVideoModalSuccess()} 
                            />
                        :
                            <></>
                    }
                    {
                        deleteModal ?
                            <DeleteLoginVideoModal 
                                open={deleteModal} 
                                saving={deletingVideo} 
                                onClose={() => this.onDeleteModal(false)} 
                                onSuccess={() => this.onDeleteModalSuccess()}
                                confirmationMessage="Are you sure you want to delete?"
                                checked={checked}
                            />
                        :
                        <></>
                    }
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

LoginVideos.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(LoginVideos);
