import { 
    dangerColor, 
    successColor 
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
const addLoginVideoModelStyle = theme => ({
    ...modalStyle(theme),
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "50px",
        paddingTop: "65px",
        paddingRight: "0px",
        paddingLeft: "0px",
        "&.success": {
            paddingTop: "25px",
            paddingBottom: "20px",
            "& p": {
                marginBottom: "0px"
            }
        },
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& h4": {
            textAlign: "center",
            marginTop: "0px",
            marginBottom: '15px',
        },
        "& li": {
            opacity: 1,
            color: theme.customize.whiteBlackColor,
        },
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    addMultiUserForm: {
        "& .multi-user-form-content": {
            fontSize: "14px",
            textAlign: "left",
            "& a": {
                display: "block",
                textDecoration: "none",
                color: theme.customize.linksColor
            },
            "& >p": {
                marginBottom: "0px",
            },
            "& .sample-files": {
                padding: theme.spacing(2,0),
                "& a": {
                    marginTop: theme.spacing(3)
                },
                "& button": {
                    textTransform: "none",
                    padding: "0px",
                    marginTop: theme.spacing(3),
                    marginBottom: "0px",
                    fontSize: "14px",
                    fontWeight:"300"
                }
            },
            "& h6": {
                textTransform: "none",
            },
            "& ul": {
                paddingLeft: theme.spacing(2),
                "& li+li": {
                    marginTop: "5px"
                },
                "& p": {
                    marginBottom: "0px"
                }
            }
        }
    },
    fileField: {
        "& >.MuiSvgIcon-root": {
            right: "45px",
            bottom: "11px"
        }
    },
    cardIcons: {
        top: "6px",
        position: "relative"
    },
    dangerColor: {
        color: dangerColor[0]
    },
    successColor: {
        color: successColor[0]
    },
    addUserContainer: {
        "& .MuiFormLabel-root": {
            top: "10px"
        }
    },
    additionalVideosList: {
        marginTop: "20px",
        "& p": {
            fontWeight: "bold",
            marginBottom: "0px"
        },
        "& .additional-video": {
            "& .MuiFormControl-root": {
                "&:first-child": {
                    marginBottom: "0px"
                }
            }
        },
        "& .clone-input-buttons": {
            display: "flex",
            justifyContent: "space-between",
        }
    }
});

export default addLoginVideoModelStyle;
