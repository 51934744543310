import {
    blackColor, dangerColor, grayColor, whiteColor, hexToRgb
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const newLinkModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalTitleDesc: {
        color: theme.customize.whiteBlackColor,
        opacity: "0.8",
        marginBottom: "0px"
    },
    modalTitleMultiDesc: {
        marginTop: "34px",
        marginBottom: "-14px",
        "& p+p": {
            marginTop: "15px"
        }
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "71px",
        paddingTop: "51px",
        paddingRight: "0px",
        paddingLeft: "0px",
        "&.success-body": {
            paddingBottom: "30px",
        },
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& h4": {
            textAlign: "center",
            marginTop: "0px",
            marginBottom: '15px',
        },
        "& .viewPasswordIcon": {
            cursor: "pointer",
            marginRight: "10px",
            "& svg": {
                color: "#556DC2BF",
                fill: "#556DC2BF",
            }
        },
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    errorMessage: {
        color: dangerColor[0]
    },
    downloadLink: {
        wordBreak: "break-all",
        textAlign: "left",
        "&>p": {
            fontWeight: "700",
            marginBottom: "15px",
            lineHeight: "normal",
            color: theme.customize.whiteBlackColor
        },
        "& .share-icon-lists": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            listStyleType: "none",
            padding: '0px',
            marginBottom: theme.spacing(3),
            "& li": {
                width: "66px",
                height: "66px",
                borderRadius: '100%',
                "& a": {
                    cursor: "pointer"
                },
                "& img": {
                    maxWidth: '66px',
                    width: "100%",
                    display: 'block'
                },
                [theme.breakpoints.down("xs")]: {
                    width: "40px",
                    height: "40px", 
                    "& svg": {
                        width: "40px",
                        height: "40px"
                    },
                    "& img": {
                        maxWidth: "40px"
                    }
                }
            },
            "& .sendlinx-icon": {
                backgroundColor: "#888787",  
                "& img": {
                    padding: "5px"
                }
            },
        },
        "& .download-link-section": {
            backgroundColor: "#D9D9D94D",
            display: 'flex',
            borderRadius: '5px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '7px 6px',
            "& p": {
                fontSize: '16px',
                margin: '0px',
                flex: '1',
                color: theme.customize.whiteBlackColor,
                opacity: "0.8"
            },
            "& button": {
                margin: "0px",
                textTransform: 'none',
                padding: '0.3rem 1.0rem',
                fontSize: "16px",
                borderRadius: "5px",
                backgroundColor: blackColor,
                "& span": {
                    color: whiteColor,
                    opacity: "1"
                }
            },
            '& .icon': {                
                paddingRight: '4px',
                display: 'flex',
                paddingLeft: '4px',
                marginRight: '5px',
                "& svg": {
                    color: theme.customize.whiteBlackColor,
                    fill: theme.customize.whiteBlackColor,
                    opacity: "0.5"
                }
            }
        }
    },
    dNone: {
        display: 'none'
    },
    modalCloseButton: {
        "&, &:hover": {
            color: grayColor[0]
        },
        "&:hover": {
            opacity: "1"
        },
        cursor: "pointer",
        padding: "0.5rem",
        margin: "-1rem -1rem -1rem auto",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5",
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    uploadFilesModal: {
        position: "fixed",
        right: "60px",
        bottom: "60px",
        backgroundColor: theme.customize.blackWhiteColor,
        zIndex: 10000000000,
        borderRadius: "10px",
        maxWidth: "320px",
        width: "100%",
        columnGap: "20px",
        alignItems: "center",
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        overflow: "hidden",
        padding: "10px 0px 5px",
        "& $modalHeader": {
            padding: "0px 12px 14px",
            borderBottom: "1px solid #D8D9E0",
            "& $modalCloseButton": {
                right: "12px"
            }
        },
        "& $modalTitle": {
            fontSize: "16px",
            lineHeight: "normal",
        },
        "& $modalTitleDesc": {
            fontSize: "12px",
            textAlign: "left",
            lineHeight: "normal",
            marginTop: "5px",
            marginBottom: "5px",
        },
        "& .success-title-section": {
            display: "flex",
            alignItems: "center",
            columnGap: "3px",
            "& h4": {
                fontSize: "16px",
            },
            "& p": {
                marginBottom: "0px",
                fontSize: "12px",
                color: "rgba("+hexToRgb(theme.customize.whiteBlackColor)+", 0.8)",
                lineHeight: "normal",
                marginTop: "1px"
            }
        },
        "& $downloadLink": {
            wordBreak: "break-all",
            textAlign: "left",
            "&>p": {
                fontWeight: "700",
                marginBottom: "8px",
            },
            "& .share-icon-lists": {
                display: "flex",
                justifyContent: "center",
                columnGap: "10px",
                alignItems: "center",
                listStyleType: "none",
                padding: '0px',
                marginBottom: theme.spacing(3),
                "& li": {
                    width: "40px",
                    height: "40px",
                    borderRadius: '100%',
                    "& a": {
                        cursor: "pointer"
                    },
                    "& img": {
                        maxWidth: '40px',
                        width: "100%",
                        display: 'block'
                    },
                    "& svg": {
                        width: "40px",
                        height: "40px"
                    }
                },
                "& .sendlinx-icon": {
                    backgroundColor: "#888787",  
                    "& img": {
                        padding: "5px"
                    }
                },
            },
            "& .download-link-section": {
                backgroundColor: "#D9D9D94D",
                display: 'flex',
                borderRadius: '5px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: '7px 6px',
                "& p": {
                    fontSize: '16px',
                    margin: '0px',
                    flex: '1',
                    color: theme.customize.whiteBlackColor,
                    opacity: "0.8",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
                "& button": {
                    margin: "0px",
                    textTransform: 'none',
                    padding: '0.3rem 1.0rem',
                    fontSize: "16px",
                    borderRadius: "5px",
                    backgroundColor: blackColor,
                    "& span": {
                        color: whiteColor,
                        opacity: "1"
                    }
                },
                '& .icon': {                
                    paddingRight: '4px',
                    display: 'flex',
                    paddingLeft: '4px',
                    marginRight: '5px',
                    "& svg": {
                        color: theme.customize.whiteBlackColor,
                        fill: theme.customize.whiteBlackColor,
                        opacity: "0.5"
                    }
                }
            }
        },
    },
    uploaderCompleteContent: {
        padding: "12px"
    },
    uploadingSuccessFooter: {
        display: "flex",
        justifyContent: "flex-end !important",
        MsFlexPack: "flex-end !important",
        WebkitBoxPack: "flex-end !important",
        columnGap: "9px",
        padding: "6px 12px 0px",
        borderTop: "1px solid #D8D9E0",
    },
});
  
export default newLinkModalStyle;
  