import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import axios from "axios";
import Api from "../../assets/js/utils/Api";
import MaterialIcon from "@mdi/react";
import { mdiLinkVariant, mdiClose } from '@mdi/js';
import Button from "../components/CustomButtons/Button";
import LoaderComponent from "../components/Loader";
import withStyles from "@material-ui/core/styles/withStyles";
import externalLinkPageStyle from "../../assets/jss/material-kit-pro-react/views/externalLinkPageStyle.jsx";
import { Link as RouterLink } from 'react-router-dom';
import CustomInput from "../components/CustomInput/CustomInput.jsx";

const ExternalLink = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.state = {
            response: null,
            id: this.props.match.params.id,
            loading: true,
            cancelToken: null,
            error: false,
            errorMessage: null,
            password: "",
            passwordIncorrect: false,
            validation: {
                password: "",
                isValid: false,
            },
        };
        this.cancelToken = null;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        this.loadPreview();
    }
    componentDidUpdate(prevProps, prevState){
        const { response } = this.state;

        if(response !== null && response.hasOwnProperty('hide_link') && response.hide_link === 0){
            window.location.href = response.url;
        }
    }
    loadPreview(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source
        });
        const { id } = this.state;
        Api.getExternalLink(id, source).then(data => {
            that.setState({
                response: data,
                loading: false,
                error: false,
            });
        }).catch(err => {
            if(err.message !== "Request Cancelled"){
                this.setState({
                    loading: false,
                    error: true,
                    errorMessage: err.message
                });
            }
        });
    }
    handleChange(e, name){
        let state = {};
        let value = e.target.value;
        state[name] = value;
        this.setState(state);
    }
    validateForm(){
        let validation = {
            password: 'success',
            isValid: true
        };

        const values = this.state;
        
        if(values.password.length <= 0){
            validation.password = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, loading: true});
        
        const { id, password} = this.state;

        let requestData = {
            password: password
        };
        Api.previewLinkPassword(id, requestData, source).then(data => {
            if(data.passwordIncorrect){
                that.setState({
                    passwordIncorrect: true,
                    loading: false
                })
                return;
            }
            
            that.setState({
                response: data,
                loading: false,
                error: false,
                passwordIncorrect: false
            });
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    loading: false,
                    error: true,
                    errorMessage: err.message
                });
            }  
        });
    }
    render() {
        const { classes } = this.props;
        const { response, loading, error, errorMessage, validation, passwordIncorrect } = this.state;
        const showIframe = (error === false && response !== null && response.hide_link === 1);

        return (
            <>
                <div className={classes.externalLinkPageContent+" "+(showIframe ? classes.showIframe : "")}>
                    <div className={"externalLinkTopColumns"}>
                        {
                            error === false && response !== null && response.hasPassword === true ?
                                <div className={classes.transferForm+" password-form"}>
                                    <div className={classes.transferContent}>
                                        <RouterLink to={'/'} className={"closeIcon"}>
                                            <MaterialIcon path={mdiClose} className="MuiSvgIcon-root" />
                                        </RouterLink>
                                    </div>
                                    <div className={classes.externalLinkHeading+" externalLink-heading"}>
                                        <h2>Please enter password to access file(s)</h2>       
                                    </div>
                                    <form onSubmit={this.handleSubmit} className={"form"}>
                                        {
                                            passwordIncorrect === true ?
                                                <div className={"passwordCheck-notValid-customizable"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                                                    <span className="checkPasswordText-lowerletter">Password is Incorrect</span>
                                                </div>
                                            :
                                            <></>
                                        }
                                        <CustomInput
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.customFormControlClasses
                                            }}
                                            success={validation.password === "success"}
                                            error={validation.password === "error" || passwordIncorrect}
                                            id="input-password"
                                            labelText="Password"
                                            passwordInput={true}
                                            inputProps={{
                                                onChange: (e) => this.handleChange(e, 'password'),
                                                name: "password",
                                                value: this.state.password,
                                                type: "text",
                                                autoComplete: 'off',
                                            }}
                                        />
                                        <div className={classes.formFooter}>
                                            <Button color="custom" className={"form-button"} type="submit" disabled={loading ? true: false}>
                                                {
                                                    loading ?
                                                        <LoaderComponent color="white" />
                                                    :
                                                        "Submit"
                                                }
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            : showIframe ?
                                <div className={classes.iframeContent}>
                                    <iframe title="iframe" src={response.url}></iframe>
                                </div>
                            :
                                <div className={classes.transferForm}>
                                    <div className={classes.transferContent}>
                                        <RouterLink to={'/'} className={"closeIcon"}>
                                            Close
                                            <MaterialIcon path={mdiClose} className="MuiSvgIcon-root" />
                                        </RouterLink>
                                        <div className={classes.externalLinkHeading+" externalLink-heading "+(error?classes.errorHeading:"")}>
                                            <div className="arrowIcon">
                                                <MaterialIcon path={mdiLinkVariant} className="MuiSvgIcon-root" />
                                            </div>
                                            {
                                                error === true ?
                                                    <p className={classes.processingWarning}>{errorMessage}</p>
                                                :
                                                    <p>Click on below button for visit link</p>
                                            }
                                        </div>
                                    </div>
                                    {
                                        error === false ?
                                            <div className={classes.transferFooter}>
                                                {
                                                    response !== null ?
                                                        <a href={response.url} className="transfer-button" rel="noopener noreferrer" target="_blank">
                                                            Visit Link
                                                        </a>
                                                    :
                                                        loading ?
                                                            <Button color="custom" disabled className="transfer-button" type="button">
                                                                <LoaderComponent color="white" />
                                                            </Button>
                                                        :
                                                            <></>
                                                }
                                            </div>
                                        :
                                        <></>
                                    }
                                </div>
                        }
                    </div>
                </div>
                <Helmet 
                    htmlAttributes={{
                        title: process.env.REACT_APP_TITLE
                    }}
                />
            </>
        )
    }
}

ExternalLink.propTypes = {
    classes: PropTypes.object
};

export default withStyles(externalLinkPageStyle)(ExternalLink);
