import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactTable from "react-table";

import linkTableViewStyle from "../../../assets/jss/linkTableViewStyle";

class SummarySubView extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        
        this.state = {
            row: this.props.row,
        }
    }
    componentWillReceiveProps(props){
        this.setState({
            row: props.row,
        });
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getTableData(row){
        if(row === null){
            return [];
        }
        let tableData = [
            {
                type: row.original.type,
                account: row.original.account,
                server: row.original.server,
                sender_email_orginal: row.original.sender_email_orginal,
                sender_email: row.original.sender_email,
                subject: row.original.subject,
                last_downloaded: row.original.last_downloaded,
                orignalPath: row.original.orignalPath,
                path: row.original.path,
                title: row.original.title
            }
        ];

        return tableData;
    }
    render(){
        const { classes } = this.props;
        const { row } = this.state;

        return (
            <div>
                <ReactTable
                    columns={[
                        {
                            Header: "Type",
                            accessor: "type",
                            sortable: false,
                            filterable: false,
                            headerClassName: "hd_type",
                            className: "hd_type td_type",
                        },
                        {
                            Header: "Account",
                            accessor: "account",
                            headerClassName: "hd_account",
                            className: "hd_account td_account",
                        },
                        {
                            Header: "Server",
                            accessor: "server",
                            headerClassName: "hd_server",
                            className: "hd_server td_server",
                        },
                        {
                            Header: "Sender",
                            accessor: "sender_email_orignal",
                            headerClassName: "hd_sender hd_show_head",
                            className: "hd_sender td_sender td_hide",
                            resizable: false
                        },
                        {
                            Header: "Sender",
                            accessor: "sender_email",
                            headerClassName: "hd_sender hd_hide",
                            className: "hd_sender td_sender",
                        },
                        {
                            Header: "Subject",
                            accessor: "subject",
                            headerClassName: "hd_message",
                            className: "hd_message td_message",
                        },
                        {
                            Header: "Last Downloaded",
                            accessor: "last_downloaded",
                            headerClassName: "hd_last_download",
                            className: "hd_last_download td_last_download",
                            sortMethod: (a, b) => {
                                var a1 = new Date(a).getTime();
                                var b1 = new Date(b).getTime();
                              if(a1<b1)
                                return 1;
                              else if(a1>b1)
                                return -1;
                              else
                                return 0;
                            }
                        },
                        {
                            Header: "Path",
                            accessor: "orignalPath",
                            headerClassName: "hd_path hd_show_head",
                            className: "hd_path td_path td_hide",
                            resizable: false
                        },
                        {
                            Header: "Path",
                            accessor: "path",
                            headerClassName: "hd_path hd_hide",
                            className: "hd_path td_path",
                        },
                    ]}
                    data={this.getTableData(row)}
                    pageSizeOptions={[500]}
                    showPaginationTop={false}
                    minRows={0}
                    showPaginationBottom={false}
                    className={"-striped -highlight "+classes.filesTable+" "+classes.subfilesTable}
                    getNoDataProps={() => this.getNoDataProps()}
                />
            </div>
        )
    }
}

SummarySubView.propTypes = {
    classes: PropTypes.object
};

export default (withStyles(linkTableViewStyle)(SummarySubView));