import {
    whiteColor,
    grayColor,
    sidebar,
    tableHeadColumns,
    controls,
    separator,
    content
} from "../material-kit-pro-react.jsx";

import userHomePageStyle from "./userHomePageStyle.jsx";
const linksPageStyle = theme => ({
    ...userHomePageStyle(theme),
    sidebar: {
        ...sidebar(theme)
    },
    main: {
        marginTop: "0px"
    },
    container: {
        width: "100%",
        zIndex: "2",
        top: "62px",
        position: "relative",
        paddingTop: "20px",
        height: "calc(100vh - 62px)",
        "& .MuiTabs-root": {
            marginTop: "0px",
            "&+div": {
                marginTop: "0px"
            }
        },
        "& .search-input": {
            paddingTop: "0px !important"
        }
    },
    content: {
        ...content(theme),
        paddingBottom: "50px",
    },
    controls: {
        ...controls(theme)
    },
    separator: {
        ...separator(theme)
    },
    filesContainer: {
        maxWidth: "99%",
        width: "100%",
        overflow: "auto",
        minHeight: "470px",
        maxHeight: "470px",
    },
    filesTable: {
        ...tableHeadColumns(theme),
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
            color: theme.customize.color,
        },
        "& .rt-tbody": {
            overflow: "unset",
            minHeight: "390px",
            "& .rt-tr-group": {
                flexGrow: "0"
            }
        },
        "& .rt-tbody .rt-td": {
            padding: "0px 5px",
            margin: "5px 0px",
            "& svg": {
                fill: theme.customize.tableRowActionsIconsColor,
                color: theme.customize.tableRowActionsIconsColor
            },
            "& button": {
                padding: "0px",
                height: "17px",
                width: "30px",
                minWidth: "0px",
                margin: "0px"
            }
        },
        "& .hd_check": {
            width: "30px !important",
            textAlign: "center !important",
        },
        "& .td_check": {
            "& .MuiButtonBase-root": {
                padding: "0px"
            },
            "& svg": {
                fill: whiteColor+" !important"
            }
        },
        "& .hd_account": {
            width: "10% !important",
            textAlign: "center !important",
        },
        "& .hd_server": {
            width: "5% !important",
            textAlign: "center !important",
        },
        "& .hd_sender": {
            width: "10% !important",
            textAlign: "left !important",
        },
        "& .hd_recipients": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_message": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_created": {
            width: "10% !important",
            textAlign: "left !important",
        },
        "& .hd_attachments": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_expire": {
            width: "12% !important",
            textAlign: "left !important",
        },
        "& .hd_action": {
            width: "7% !important",
            textAlign: "center !important",
        },
        "& .td_action": {
            width: "7% !important",
            textAlign: "center !important",
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            color: grayColor[17],
            opacity: "1 !important"
        },
        "& .rt-td": {
            fontSize: "12px",
            "&.td_attachments": {
                wordBreak: "break-all",
                "& a+a": {
                    "&:before": {
                        content: "','",
                        display: "inline-block",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }
                },
                "& button": {
                    width: "auto !important",
                    height: "auto !important",
                    textTransform: "none",
                    "&:hover": {
                        textDecoration: "underline",
                    }
                }
            }
        },
        "& .rt-tr": {
            cursor: "pointer"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "10px"
        }
    },
    loadMore: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    filterGridItem: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        "& h4": {
            marginRight: "40px",
        },
        [theme.breakpoints.down("xs")]: {
            "& h4": {
                marginRight: "10px",
            },
        }
    },
    filterGridItemSm: {
        [theme.breakpoints.down("sm")]: {
            display: "block"
        }
    },
    linkFilters: {
        listStyleType: "none",
        padding: "0px",
        margin: "10px 0px",
        "& li": {
            display: "inline-block",
            cursor: "pointer",
            "&+li": {
                marginLeft: "20px",
                [theme.breakpoints.down("xs")]: {
                    marginLeft: "15px",
                }
            },
            "& img": {
                marginRight: "5px",
                width: "18px"
            },
            "&.active": {
                "& span": {
                    paddingBottom: "2px",
                    borderBottom: "3px solid "+theme.customize.iconsColor
                }
            }
        }
    },
    viewType: {
        margin: "10px 0px",
    },
    fileActions: {
        textAlign: "right",
        padding: "5px 20px",
        marginTop: "10px",
        backgroundColor: theme.customize.opacityColor,
        border: theme.customize.border,
        "& svg": {
            fill: theme.customize.iconsColor,
            color: theme.customize.iconsColor,
            width: "1em",
            display: "inline-block",
            verticalAlign: "middle",
            fontSize: "1.5rem",
            marginRight: "5px",
            marginTop: "-2px",
        },
        "& li": {
            "& button": {
                textTransform: "none",
                margin: "0px",
                padding: "6px 8px 4px 5px",            
                fontSize: "inherit",
                "&:hover": {
                    background: theme.customize.sidebarMenuHoverColor,
                }
            }
        },
        "& li + li": {
            "&:before": {
                content: "'|'",
                display: "inline-block",
                marginRight: "20px"
            },
            [theme.breakpoints.down("xs")]: {
                marginLeft: "10px",
                "&:before": {
                    marginRight: "10px"
                },
            }
        },
        [theme.breakpoints.down("xs")]: {
            "& li": {
                fontSize: "14px"
            },
            "& svg": {
                fontSize: "1.2rem",
                marginRight: "3px",
            }
        }
    },
    fileActionEnabled: {
        color: theme.customize.iconsColor
    },
    loading: {
        display: "none"
    }
});

export default linksPageStyle;
