import headerLinksStyle from "./material-kit-pro-react/components/headerLinksStyle.jsx";
const sidebarStyle = theme => ({
    ...headerLinksStyle(theme),
    brand: {
        margin: "18px 0px 40px",
        display: "block",
        "& img": {
            maxWidth: "156px",
            maxHeight: "50px",
            display: "block",
            margin: "0px auto",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    container: {
        background: theme.customize.sidebarBackgroundColor,
        width: "220px",
        position: "fixed",
        height: "100vh",
        overflowY: "auto",
        padding: "0px 8px",
        [theme.breakpoints.down("sm")]: {
            border: "none",
            width: "100%",
            height: "auto",
            overflow: "hidden",
            padding: "6px 15px 5px",
        },
        "& .MuiList-root": {
            marginTop: "0px",
            overflow: "hidden",
            marginLeft: "0px",
            display: "block",
            "& .dropdown-submenu": {
                paddingLeft: "15px",
                color: theme.customize.borderColor,
            },
        }
    },
    listItem: {
        ...headerLinksStyle(theme).listItem,
        float: "none",
        width: "100%",
        border: "none !important",
        "& a": {
            color: theme.customize.sidebarMenuLinkColor,
            "& button": {
                fontWeight: theme.customize.sidebarMenuWeight,
                width: '100%',
                justifyContent: 'flex-start',
                borderRadius: "5px",
            },
            "&:hover": {
                color: theme.customize.sidebarMenuActiveLinkColor,
                textDecoration: "none",
            }
        },
        "& ul": {
            overflow: "auto",
            marginTop: "0px",
            marginLeft: "10px",
            paddingLeft: "30px",
            "& a": {
                color: theme.customize.sidebarMenuLinkColor,
                "& button": {
                    fontWeight: "normal",
                    paddingLeft: "25px",
                },
            },
        },
        "&:not(:last-child)": {
            "&:after": {
                display: "none"
            }
        },
        "& button": {
            margin: "0px",
            padding: "9px 12px",
            textTransform: "none",
            overflow: "visible",
            display: 'block',
            width: '100%',
            textAlign: 'left',
            borderRadius: "5px",
            "&:hover": {
                backgroundColor: theme.customize.sidebarMenuHoverColor,
                "& .label": {
                    color: theme.customize.sidebarMenuActiveLinkColor,
                },
                "& svg": {
                    color: theme.customize.sidebarMenuActiveIconsColor,
                    fill: theme.customize.sidebarMenuActiveIconsColor,
                },
            }
        }
    },
    toggleMenu: {
        textAlign: "left",
        "& svg": {
            color: theme.customize.color,
            fill: theme.customize.color
        },
        "& button": {
            [theme.breakpoints.down("sm")]: {
                paddingLeft: '0px',
            }
        }
    },
    collapse: {
        display: "none",
        "&.active": {
            display: "block",
            height: 'calc(100vh - 60px)',
            overflowY: "auto"
        }
    },
    navLink: {
        "& a:hover": {
            textDecoration: "none",
        },
        "& svg": {
            marginLeft: "0px",
            marginTop: "-2px",
            fill: theme.customize.sidebarMenuIconsColor,
            color: theme.customize.sidebarMenuIconsColor,
            width: "20px",
            height: "20px",
            marginRight: "14px",
        },
        "& img": {
            width: "16px",
            marginRight: "5px",
            marginLeft: "-23px",
            "&.just-icon": {
                maxWidth: "80px",
                width: "100%",
                marginLeft: "0px",
            }
        },
        "&.active": {
            "& .label": {
                color: theme.customize.sidebarMenuActiveLinkColor,
            },
            "& svg": {
                color: theme.customize.sidebarMenuActiveIconsColor,
                fill: theme.customize.sidebarMenuActiveIconsColor,
            },
            backgroundColor: theme.customize.sidebarMenuHoverColor,
        },
    },
    textMenu: {
        color: theme.customize.sidebarMenuLinkColor+" !important",
        fontWeight: theme.customize.sidebarMenuWeight,
        "&:hover": {
            color: theme.customize.sidebarMenuLinkColor,
        }
    },
    navButtonLink: {
        color: theme.customize.sidebarMenuLinkColor+" !important",
        fontWeight: theme.customize.sidebarMenuWeight,
        "&:hover": {
            backgroundColor: theme.customize.sidebarMenuHoverColor,
        }
    },
    multiListItem: {
        "& .MuiButton-label": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        "& .toggle-menu-icon":{
            display: "flex",
            flex: "1",
            justifyContent: "flex-end",
            position: "absolute",
            top: "0px",
            right: "0px",
            padding: "13px",
            cursor: "pointer",
            zIndex: "1",
            "& svg": {
                width: "10px",
                height: "10px",
                marginRight: "0px",
                fill: theme.customize.sidebarMenuLinkColor,
                color: theme.customize.sidebarMenuLinkColor,
            }
        }
    },
    subMenuList: {
        "&::before": {
            content: '""',
            width: '1px',
            height: '100%',
            position: 'absolute',
            display: 'block',
            backgroundColor: '#556DC217',
            left: '21px',
        }
    }
});

export default sidebarStyle;
