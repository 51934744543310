import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
const newUpdateModalStyle = theme => ({
    ...modalStyle(theme),
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "950px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "50px",
        paddingTop: "50px",
        paddingRight: "0px",
        paddingLeft: "0px",
        textAlign: "center",
        "& .sd-video-iframe": {
            "& iframe": {
                minHeight: "350px",
                maxWidth: "600px",
                width: "100%",
                [theme.breakpoints.down("xs")]: {
                    minHeight: "280px"
                }
            },
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "center !important",
        MsFlexPack: "center !important",
        WebkitBoxPack: "center !important",
        padding: "0px",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important"
    },
});

export default newUpdateModalStyle;
