import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import axios from "axios";
import Api from "../../assets/js/utils/Api";
import { Link as RouterLink } from "react-router-dom";

import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardFooter from "../components/Card/CardFooter.jsx";
import Button from "../components/CustomButtons/Button.jsx";
import ProcessingModal from "../components/User/ProcessingModal";
import ManageStorageModal from "../components/User/ManageStorageModal";

import withStyles from "@material-ui/core/styles/withStyles";
import plansPageStyle from "../../assets/jss/plansPageStyle.jsx";
import { helper } from '../../assets/js/utils/Element.js';

const Plans = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();

        this.state = {
            id: null,
            loadingModal: false,
            loading: false,
            successMessage: "Your subscription has been updated.",
            user: user,
            failed: false,
            storageModal: false,
            plan: null
        };
    }
    renderPlanButton(plan){
        const { classes } = this.props;
        const { user, authorized } = this.store.getState();
        let button = (
            <Button color="custom" onClick={() => this.onModalStatus(true, plan)}>
                {
                    authorized && user.account_type === 5 ?
                        "Upgrade"
                    :
                    "Change"
                }
            </Button>
        );
        if(plan === 1){
            button = (
                <Button color="white" disabled>
                    Disabled
                </Button>
            );
        }
        if(plan === user.account_type){
            button = (
                <Button color="white" disabled>
                    Active
                </Button>
            )
        }
        return (
            <div>
                {button}
                {
                    plan === user.account_type ?
                        <Button color="custom" className={classes.increaseStorage} onClick={() => this.onStorageModal(true, plan)}>
                            Manage Storage
                        </Button>
                    :
                        null
                }
            </div>
        );
    }
    onStorageModal(status = false, plan = null){
        this.setState({storageModal: status, plan: plan});
    }
    changeSubscription(id){
        const { classes } = this.props;
        const source = axios.CancelToken.source();
        const requestData = {
            accountType: id
        };
        Api.changeSubscription(requestData, source).then(data => {
            if(data.hasOwnProperty("checkout_url")){
                window.location.href = data.checkout_url;
                return;
            }
            this.setState({loading: false, user: data.user});
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
            
        }).catch(err => {
            this.setState({
                loading: false,
                failed: true,
                successMessage: <span className={classes.danger}>Your subscription failed.</span>
            });
        });
        this.setState({
            loading: true,
            loadingModal: true
        });
    }
    onModalStatus(status = false, plan = null){
        const { user } = this.store.getState();
        this.setState({
            loadingModal: status,
            loading: status,
            id: plan
        });

        if(!status && this.state.failed){
            return;
        }
        if(!status){
            this.history.push(helper.getHomePageUrl(user));
            this.store.dispatch({
                type: "UPDATE_STATE",
                state: {
                    user: this.state.user
                }
            }); 
        }else{
            this.changeSubscription(plan);
        }
    }
    renderContent() {
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        const { loadingModal, loading, successMessage, storageModal, plan } = this.state;
        return (
            <div>
                <div className={classes.panelContent}>
                    <div className={classes.plansContent}>
                        <div>
                        <GridContainer>
                            <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            className={
                                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                            }
                            >
                            <h2 className={classes.title}>Pick the best plan for you</h2>
                            </GridItem>
                        </GridContainer>
                        <GridContainer className={classes.cardGridContainer}>
                            <GridItem xs={12} sm={6} md={4} lg={4} className={classes.cardGridItem}>
                                <Card pricing color="white" className={classes.card}>
                                    <CardBody pricing>
                                        <h6 className={classes.cardCategory}>Standard</h6>
                                        <h1 className={classes.cardTitle}>
                                            <small>$</small> 6.95
                                        </h1>
                                        <ul>
                                            <li>
                                                Set Expiry
                                            </li>
                                            <li>
                                                Link Management
                                            </li>
                                            <li>
                                                ZIP & Password Protect
                                            </li>
                                            <li>
                                                File Management
                                            </li>
                                            <li>
                                                Multiple Sendlinx Accounts
                                            </li>
                                            <li>
                                                -
                                            </li>
                                            <li>
                                                -
                                            </li>
                                            <li>
                                                -
                                            </li>
                                            <li>
                                                -
                                            </li>
                                            <li>
                                                -
                                            </li>
                                        </ul>
                                    </CardBody>
                                    <CardFooter pricing className={classes.justifyContentCenter}>
                                        {
                                            authorized ?
                                                this.renderPlanButton(2)
                                            :
                                            <RouterLink to={"/signup?id=2"}>
                                                <Button color="white">
                                                    Signup
                                                </Button>                
                                            </RouterLink>
                                        }
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} lg={4} className={classes.cardGridItem}>
                                <Card pricing color="white" className={classes.card}>
                                    <CardBody pricing>
                                        <h6 className={classes.cardCategory}>Pro</h6>
                                        <h1 className={classes.cardTitle}>
                                            <small>$</small> 9.95
                                        </h1>
                                        <ul>
                                            <li>
                                                Set Expiry
                                            </li>
                                            <li>
                                                Link Management
                                            </li>
                                            <li>
                                                ZIP & Password Protect
                                            </li>
                                            <li>
                                                File Management
                                            </li>
                                            <li>
                                                Multiple Sendlinx Accounts
                                            </li>
                                            <li>
                                                Multiple FTP Accounts
                                            </li>
                                            <li>
                                                Auto Detect Large files
                                            </li>
                                            <li>
                                                Desktop Application Software*
                                            </li>
                                            <li>
                                                -
                                            </li>
                                            <li>
                                                -
                                            </li>
                                        </ul>
                                    </CardBody>
                                    <CardFooter pricing className={classes.justifyContentCenter}>
                                        {
                                            authorized ?
                                                this.renderPlanButton(3)
                                            :
                                            <RouterLink to={"/signup?id=3"}>
                                                <Button color="success">
                                                    Signup
                                                </Button>                
                                            </RouterLink>
                                        }
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} lg={4} className={classes.cardGridItem}>
                                <Card pricing color="white" className={classes.card}>
                                    <CardBody pricing>
                                        <h6 className={classes.cardCategory}>Business</h6>
                                        <h1 className={classes.cardTitle}>
                                            <small>$</small> 39.95
                                        </h1>
                                        <ul>
                                            <li>
                                                Set Expiry
                                            </li>
                                            <li>
                                                Link Management
                                            </li>
                                            <li>
                                                ZIP & Password Protect
                                            </li>
                                            <li>
                                                File Management
                                            </li>
                                            <li>
                                                Multiple Sendlinx Accounts
                                            </li>
                                            <li>
                                                Multiple FTP Accounts
                                            </li>
                                            <li>
                                                Auto Detect Large files
                                            </li>
                                            <li>
                                                Desktop Application Software*
                                            </li>
                                            <li>
                                                Outlook Addin Software*
                                            </li>
                                            <li>
                                                Up to 5 Users
                                            </li>
                                        </ul>
                                    </CardBody>
                                    <CardFooter pricing className={classes.justifyContentCenter}>
                                        {
                                            authorized ?
                                                this.renderPlanButton(4)
                                            :
                                            <RouterLink to={"/signup?id=4"}>
                                                <Button color="success">
                                                    Signup
                                                </Button>                
                                            </RouterLink>
                                        }
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>
                        </div>
                    </div>
                </div>
                {
                    loadingModal ?
                        <ProcessingModal 
                            open={loadingModal} 
                            saving={loading} 
                            onClose={() => this.onModalStatus()} 
                            onSuccess={() => this.onModalStatus()}
                            loadingMessage="Please while we prepare your subscription."
                            successMessage={successMessage}
                            store={this.store}
                        />
                    :
                    <></>
                }
                {
                    storageModal ?
                        <ManageStorageModal 
                            open={storageModal} 
                            onClose={() => this.onStorageModal()} 
                            onSuccess={() => this.onStorageModal()}
                            plan={plan}
                            store={this.store}
                        />
                    :
                    <></>
                }
            </div>
        )
    }
    render(){
        const { classes } = this.props;
        const { user } = this.store.getState();
        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.accountMainContainer}>
                    <div className={classes.content+" "+(user.account_type === -1 ? classes.upgradePlan : "")}>
                        {this.renderContent()}
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Plans.propTypes = {
    classes: PropTypes.object
};

export default withStyles(plansPageStyle)(Plans);
