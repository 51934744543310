import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader";
import CustomInput from "../CustomInput/CustomInput.jsx";
import Close from "@material-ui/icons/Close";

import renameFileModalStyle from "../../../assets/jss/user/renameFileModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const RenameFileModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            newFileName: this.props.file.name
        }
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    render() {
        const { classes, saving, file, errorMessage } = this.props;
        const { newFileName } = this.state;
        //console.log(file);
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="renameFileModal-slide-title"
                aria-describedby="renameFileModal-slide-description"
            >
                <DialogTitle
                    id="renameFileModal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        {
                            errorMessage !== null ?
                                <></>
                            :
                            saving ?
                                <h4 className={classes.modalTitle}>Please wait while we rename {file.type}.</h4> 
                            :
                            <h4 className={classes.modalTitle}>Rename {file.type}</h4> 
                        }
                </DialogTitle>
                <DialogContent
                    id="renameFileModal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            errorMessage !== null ?
                                <p className={classes.errorMessage}>{errorMessage}</p>
                            : saving ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            <>
                                <CustomInput
                                    labelText= {"New "+(file.type)+" Name"}
                                    success={(newFileName.length > 3)}
                                    error={(newFileName.length < 3)}
                                    id="input-newFileName"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e, 'newFileName'),
                                        name: "newFileName",
                                        value: newFileName,
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input m-zero"
                                    }}
                                />
                                <p className='input-note'>Old {file.type} name: <span>{file.name}</span></p>
                            </>
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    {
                        errorMessage !== null ?
                            <Button color="custom" onClick={() => this.props.onClose()}>Cancel</Button>
                        : saving === false ?
                            <>
                                <Button color="custom" onClick={() => this.props.onSuccess(newFileName)} disabled={(newFileName.length < 3)}>Rename {file.type}</Button>
                                <Button color="white" onClick={() => this.props.onClose()}>Cancel</Button>
                            </>
                        :
                            <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

RenameFileModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    saving: false,
    errorMessage: null
}
RenameFileModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    saving: PropTypes.bool,
    errorMessage: PropTypes.string,
}
export default withStyles(renameFileModalStyle)(RenameFileModal);