import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import DeleteFileVersionModal from "./DeleteFileVersionModal"
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import { helper } from '../../../assets/js/utils/Element';
import Danger from "../../components/Typography/Danger";
import GridItem from "../../components/Grid/GridItem";
import LoaderComponent from "../../components/Loader";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FileVersionTypeModal from "./FileVersionTypeModal";

import MaterialIcon from "@mdi/react";
import { mdiCloudDownload, mdiTrashCanOutline, mdiCheckboxMarked, mdiCheckboxBlankOutline, mdiUpdate } from '@mdi/js';
import AddIcon from "@material-ui/icons/Add";
import MoreVert from "@material-ui/icons/MoreVert";
import infinityIcon from '../../../assets/img/infinity.png';

import fileVersionModalStyle from "../../../assets/jss/user/fileVersionModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const FileVersionModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.hiddenFile = React.createRef();

        this.state = {
            uploading: false,
            totalSize: 0,
            totalSent: 0,
            singleSent: 0,
            totalFiles: 1,
            showError: false,
            errorMessage: "",
            chunkSize: (1048576 * 15), //15 MB by default
            file: null,
            resumable: false,
            success: false,
            startTime: null,
            response: null,
            loading: false,
            fileMenuOpen: null,
            anchorEl: null,
            deleteVersionModal: false,
            deletedFile: null,
            cancelToken: null,
            fileTypeModal: false
        };

        this.onUploadNewVersion = this.onUploadNewVersion.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
        this.cancelUpload = this.cancelUpload.bind(this);
    }
    componentDidMount(){
        this.loadVersions();
    }
    loadVersions(){
        const uploadedFile = this.props.file;
        const requestData = {
            fileId: uploadedFile.id
        };
        const source = axios.CancelToken.source();
        this.setState({
            cancelToken: source,
            loading: true,
            showError: false,
            errorMessage: ""
        });
        Api.getFileVersions(requestData, source).then(data => {
            this.setState({
                response: data,
                loading: false,
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                let state = {
                    loading: false,
                    showError: true,
                    errorMessage: err.message
                };
                if(err.message === "Network Error"){
                    state['resumable'] = true;
                }
                this.setState(state);
            }
        });
    }
    onUploadNewVersion(e){
        const files = e.target.files;
        this.setState({
            file: files[0]
        }, () => {
            const { file } = this.state;
            const uploadedFile = this.props.file;
            const extension = file.name.split('.').pop();
            if(extension !== uploadedFile.extension){
                this.setState({fileTypeModal: true});
                return;
            }
            this.uploadFiles();
        });
    }
    onFileTypeCancel(){
        this.setState({fileTypeModal: false});
    }
    onSelectFile = e => {
        this.hiddenFile.current.click(e);
    }
    getTotalSize(files = null){
        const { file } = this.state;
        if(file === null){
            return 0;
        }
        return file.size;
    }
    isMultiPartSupported(file){
        if(file === null){
            return false;
        }
        const chunks = this.getTotalChunks(file);
        if(chunks < 2){
            return false;
        }
        const slice = file.slice || file.mozSlice || file.webkitSlice;
        if(typeof(slice) === "function"){
            return true;
        }
        return false;
    }
    getPartContent(file){
        let { chunkSize } = this.state;
        const slice = file.slice || file.mozSlice || file.webkitSlice;
        let end = this.chunksSent + chunkSize;
        if ((file.size - end) < 0) {
            end = file.size;
        }
        
        const blob = slice.call(file, this.chunksSent, end);
        // this.chunksSent = this.chunksSent + chunkSize;
        return blob;
    }
    getTotalChunks(file){
        const { chunkSize } = this.state;
        var chunks = Math.ceil(file.size/chunkSize,chunkSize);
        return chunks;
    }
    formatEstimatedTime(expectedTime){
        if(expectedTime <= 2){
            return "Few Seconds";
        }
        if(expectedTime < 60){
            expectedTime = Math.trunc(expectedTime);
            return expectedTime+" Seconds";
        }
        let minutes = expectedTime/60;
        if(minutes < 60){
            const seconds = Math.round(expectedTime%60);
            minutes = Math.trunc(minutes);
            return (minutes+" Minutes"+(seconds ? (" "+seconds+" Seconds") : ""));
        }
        expectedTime = expectedTime/60;
        let hours = expectedTime/60;
        minutes = Math.round(expectedTime%60);
        hours = Math.trunc(hours);
        return (hours+" Hours"+(minutes ? (" "+minutes+" Minutes") : ""));
    }
    getEstimatedTimeLeft(){
        const { startTime, totalSize, totalSent, singleSent } = this.state;
        const currentTime = new Date();
        const timeElapsed = (currentTime - startTime)/1000;
        const totalUploaded = totalSent + singleSent;
        if(totalUploaded <= 0){
            return null;
        }
        let uploadRate = (totalUploaded/timeElapsed);
        const expectedTime = (totalSize - totalUploaded)/uploadRate;
        const timeEstimate = this.formatEstimatedTime(expectedTime);
        let unit = "Byte";
        if(uploadRate >= 1024){
            uploadRate = uploadRate/1024;
            unit = "KB";
        }
        if(uploadRate >= 1024){
            uploadRate = uploadRate/1024;
            unit = "MB";
        }
        uploadRate = Math.round(uploadRate);
        return timeEstimate+" ("+uploadRate+" "+unit+"/sec)";
    }
    uploadFiles(){
        const { file } = this.state;
        if(file === null){
            return;
        }

        const uploadedFile = this.props.file;
        const requestData = {
            "fileId": uploadedFile.id,
            "file": {
                "name": file.name,
                "size": file.size,
                "type": file.type
            }
        };
        
        const that = this;
        const source = axios.CancelToken.source();
        that.setState({
            cancelToken: source,
            uploading: true,
            showError: false,
            errorMessage: "",
            fileTypeModal: false
        });
        Api.createVersion(requestData, source).then(data => {
            if(this.isMultiPartSupported(file)){
                try{
                    this.partNumber = 0;
                    this.chunksSent = 0;
                    this.uploadMultiPart(file, source);
                    this.partNumber = this.partNumber + 1;
                }catch(e){
                    console.log(e);
                }
            }else{
                Api.uploadMedia(file, data, source, this.onUploadProgress).then(data => {
                    const totalSent = that.state.totalSent + file.size;
                    that.setState({
                        totalSent: totalSent,
                        uploading: false,
                        success: true
                    }, () => this.loadVersions());
                }).catch(err => {
                    if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                        this.setState({
                            showError: true,
                            errorMessage: err.message
                        });
                    }
                });
            }
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    onUploadProgress(e){
        this.setState({
            singleSent: e.loaded
        });
    }
    uploadMultiPart(file, source){
        const blob = this.getPartContent(file);
        const uploadedFile = this.props.file;
        const requestData = {
            partNumber: (this.partNumber + 1),
            chunkSize: blob.size,
            id: uploadedFile.id
        };
        this.setState({
            cancelToken: source,
            showError: false,
            errorMessage: "",
            resumable: false,
            uploading: true
        });
        
        const that = this;
        var chunks = this.getTotalChunks(file);
        const { chunkSize } = this.state;
        Api.getPartUrl(file, blob, requestData, source, this.onUploadProgress, true).then(data => {
            this.chunksSent = this.chunksSent + chunkSize;
            if(this.partNumber < chunks){
                source = axios.CancelToken.source();
                that.setState({
                    totalSent: that.state.totalSent + blob.size,
                    singleSent: 0,
                    cancelToken: source,
                    showError: false,
                    errorMessage: ""
                });
                this.uploadMultiPart(file, source);
                this.partNumber = this.partNumber + 1;
            }else{
                const source = axios.CancelToken.source();
                that.setState({
                    totalSent: that.state.totalSent + blob.size,
                    singleSent: 0,
                    cancelToken: source,
                    showError: false,
                    errorMessage: ""
                });
                that.finalizeMultiPart(file, source);
            }
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                let state = {
                    showError: true,
                    errorMessage: err.message
                };
                if(err.message === "Network Error"){
                    state['resumable'] = true;
                }
                this.setState(state);
            }
        });
    }
    finalizeMultiPart(file, source){
        const uploadedFile = this.props.file;
        const requestData = {
            chunks: this.getTotalChunks(file),
            id: uploadedFile.id
        };
        Api.finalizeMultiPart(requestData, source, true).then(data => {
            const totalSent = this.state.totalSent + file.size;
            this.setState({
                totalSent: totalSent,
                uploading: false,
                success: true
            }, () => this.loadVersions());
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    onFileMenu(e, id){
        this.setState({fileMenuOpen: id, anchorEl: e.currentTarget});
    }
    onFileMenuClose(){
        this.setState({fileMenuOpen: null, anchorEl: null});
    }
    onFileDownload(file){
        this.setState({fileMenuOpen: null, anchorEl: null});
        window.location.href = file.download_link;
    }
    onFileKeepForever(file, key){
        const source = axios.CancelToken.source();
        const { response } = this.state;
        file.keep_forever = file.keep_forever ? 0 : 1;
        response[key] = file;
        this.setState({
            response: response,
            fileMenuOpen: null,
            anchorEl: null,
            cancelToken: source
        });

        const requestData = {id: file.id};
        Api.keepFileVersion(requestData, source).then(data => {
            //Silent
        }).catch(err => {
            //Silent
        });
    }
    onFileSetCurrent(file, key){
        const source = axios.CancelToken.source();
        const { response } = this.state;
        file.keep_forever = file.keep_forever ? 0 : 1;
        response[key] = file;
        this.setState({
            response: response,
            fileMenuOpen: null,
            anchorEl: null,
            cancelToken: source,
            loading: true
        });

        const requestData = {id: file.id};
        Api.setCurrentFileVersion(requestData, source).then(data => {
            this.loadVersions();
        }).catch(err => {
            //Silent
        });
    }
    onFileDelete(file){
        this.setState({
            deletedFile: file,
            deleteVersionModal: true,
            fileMenuOpen: null,
            anchorEl: null
        });
    }
    onDeleteCancel(){
        this.setState({
            deletedFile: null,
            deleteVersionModal: false,
        });
    }
    onDeleteSuccess(){
        const { deletedFile, response } = this.state;
        const requestData = {id: deletedFile.id};
        const source = axios.CancelToken.source();
        Api.deleteFileVersion(requestData, source).then(data => {
            //Silent
        }).catch(err => {
            //Silent
        });

        let newResponse = [];
        response.map((file) => {
            if(deletedFile.id !== file.id){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            deletedFile: null,
            deleteVersionModal: false,
            cancelToken: source,
            response: newResponse
        });
    }
    renderFileMenuItems(file, key){
        let menuItems = [];
        menuItems.push(
            <MenuItem key="download" onClick={() => this.onFileDownload(file)}>
                <MaterialIcon path={mdiCloudDownload} className="MuiSvgIcon-root" />
                Download
            </MenuItem>
        )
        if(!file.current){
            menuItems.push(
                <MenuItem key="keepforever" onClick={() => this.onFileKeepForever(file, key)}>
                    <MaterialIcon path={(file.keep_forever ? mdiCheckboxMarked : mdiCheckboxBlankOutline)} className="MuiSvgIcon-root" />
                    Keep Forever
                </MenuItem>
            )
            menuItems.push(
                <MenuItem key="setcurrent" onClick={() => this.onFileSetCurrent(file, key)}>
                    <MaterialIcon path={mdiUpdate} className="MuiSvgIcon-root" />
                    Set Current Version
                </MenuItem>
            )
        }
        if(file.allow_delete){
            menuItems.push(
                <MenuItem key="delete" onClick={() => this.onFileDelete(file)}>
                    <MaterialIcon path={mdiTrashCanOutline} className="MuiSvgIcon-root" />
                    Delete
                </MenuItem>
            )
        }
        return menuItems;
    }
    renderFile(file, key){
        const { fileMenuOpen, anchorEl } = this.state;
        const { classes } = this.props;
        
        return (
            <li key={file.id}>
                <div>
                    <div className={classes.fileHeader}>
                        <span className={classes.fileVersion}>
                            {
                                file.current ?
                                    "Current Version: "+file.version+","
                                :
                                "Version "+file.version+":"
                            }
                        </span>
                        <span className={classes.fileName}>{file.name}</span>
                    </div>
                    <div className={classes.fileFooter}>
                        <span className={classes.fileDate}>{file.date}</span>
                        <span className={classes.fileOwner}>{file.owner}</span>
                    </div>
                </div>
                <div>
                    {
                        file.keep_forever ?
                            <img src={infinityIcon} alt="Keep Forever" className={classes.infinityIcon} />
                        :
                            <></>
                    }
                    <Button color="custom" round justIcon onClick={(e) => this.onFileMenu(e, file.id)}>
                        <MoreVert />
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={fileMenuOpen === file.id}
                        onClose={() => this.onFileMenuClose()}
                        elevation={0}
                        className={classes.fileOptionsMenu}
                    >
                        {this.renderFileMenuItems(file, key)}
                    </Menu>
                </div>
            </li>
        )
    }
    renderList(){
        const { loading, response } = this.state;
        const { classes } = this.props;
        
        return (
            <div className={classes.files}>
                {
                    loading ?
                        <LoaderComponent color="custom" align="center" />
                    :
                        <>
                            <input type="file" onChange={this.onUploadNewVersion} className={classes.inputFile} ref={this.hiddenFile} />
                            <Button color="custom" onClick={this.onSelectFile}>
                                <AddIcon />
                                Upload New Version
                            </Button>
                            <ul className={classes.filesList}>
                                {
                                    response !== null ?
                                        response.map((file, key) => {
                                            return this.renderFile(file, key)
                                        })
                                    :
                                    <></>
                                }
                            </ul>
                        </>
                }
            </div>
        )
    }
    cancelUpload(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
        this.setState({
            uploading: false
        });
    }
    renderUploading(){
        const { totalSize, totalSent, singleSent, showError, errorMessage, resumable  } = this.state;
        const { classes } = this.props;
        
        const totalUploaded = totalSent + singleSent;
        let progress = 0;
        let progressOffset = 502.655;
        if(totalSize > 0){
            progress = (totalUploaded/totalSize)*100;
            if(progress > 0){
                progressOffset = ((100 - progress)/100)*progressOffset;
            }
        }
        const roundedValue = Math.round(progress);
        const estimateTimeLeft = this.getEstimatedTimeLeft();
        return (
            <div className={classes.uploadContainer}>
                <div className={classes.uploadingProgress}>
                    <div className={classes.uploadingContent}>
                        <div className="transfer_loader">
                            <svg height="170" width="170" shapeRendering="geometricPrecision" viewBox="0 0 170 170">
                                <circle className="loader__background" r="80" cx="85" cy="85" fill="transparent"style={{
                                    strokeDasharray: 502.655,
                                    strokeDashoffset: 0,
                                    strokeWidth: 10,
                                    stroke: "rgb(232, 235, 237)"
                                }}></circle>
                                <circle className="loader_foreground" r="80" cx="85" cy="85" fill="transparent" style={{
                                    strokeDasharray: 502.655,
                                    strokeDashoffset: progressOffset,
                                    strokeWidth: 10,
                                    stroke: "rgb(64, 159, 255)"
                                }} ></circle>
                            </svg>
                            <span>{`${roundedValue}%`}</span>
                        </div>
                        {
                            showError ?
                                <GridItem>
                                    <Danger>{errorMessage}</Danger>
                                </GridItem>
                            :
                            <>
                            <h2>Transferring</h2>
                            <p className="orangeColor">Uploading file</p>
                            <p>{helper.getFormatedSize(totalUploaded)} of {helper.getFormatedSize(totalSize)} uploaded</p>
                            {
                                estimateTimeLeft !== null ?
                                    <p>Estimated Time Left: {estimateTimeLeft}</p>
                                :
                                <p>Estimated Time Left: Calculating</p>
                            }
                            </>
                        }
                        
                    </div>
                    <div className={classes.transferFooter+" "+classes.uploadingProgressFooter}>
                        {
                            resumable ?
                                <Button color="custom" className="transfer-button" type="button" onClick={this.resumeUpload} >Resume</Button>
                            :
                                <Button color="custom" className="transfer-button" type="button" onClick={this.cancelUpload} >Cancel</Button>
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
    render(){
        const { classes, file } = this.props;
        const { uploading, deleteVersionModal, deletedFile, fileTypeModal } = this.state;

        return(
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="fileVersionModal-slide-title"
                aria-describedby="fileVersionModal-slide-description"
            >
                <DialogTitle
                    id="fileVersionModal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            {
                                uploading === false ?
                                    <Close className={classes.modalClose} />
                                :
                                    <></>
                            }
                        </Button>
                        {
                            uploading ===  false ?
                                <>
                                    <h4 className={classes.modalTitle}>Manage Versions</h4>
                                    <p className={classes.modalTitleDesc}>Older versions of '{file.name}' may be deleted after 30 days or after 100 versions are stored. To avoid deletion, click on three dots and select <b>Keep Forever</b>. Versions are displayed in the order they were uploaded.</p>
                                </>
                            :
                                <></>
                        }
                </DialogTitle>
                <DialogContent
                    id="fileVersionModal-slide-description"
                    className={classes.modalBody+' '+(uploading ? 'uploading' : '')}
                >
                        {
                            uploading ?
                                this.renderUploading()
                            :
                                this.renderList()
                        }
                </DialogContent>
                {
                    deleteVersionModal ?
                        <DeleteFileVersionModal
                            open={deleteVersionModal}
                            file={deletedFile}
                            onClose={() => this.onDeleteCancel()}
                            onSuccess={() => this.onDeleteSuccess()}
                        />
                    :
                    <></>
                }
                {
                    fileTypeModal ?
                        <FileVersionTypeModal
                            open={fileTypeModal}
                            uploadedFile={file}
                            file={this.state.file}
                            onClose={() => this.onFileTypeCancel()}
                            onSuccess={() => this.uploadFiles()}
                        />
                    :
                    <></>
                }
            </Dialog>
        )
    }
};

FileVersionModal.defaultProps = {
    open: false,
    path: "",
    files: [],
    sharedFileId: null,
    ownerNotification: '',
    groupNotification: '',
    sharedWith: [],
    isShared: false
};
FileVersionModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    path: PropTypes.string,
    files: PropTypes.array,
    ownerNotification: PropTypes.string,
    groupNotification: PropTypes.string,
    sharedWith: PropTypes.array,
    isShared: PropTypes.bool
};
export default withStyles(fileVersionModalStyle)(FileVersionModal);
