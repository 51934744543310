import {
    whiteColor,
    content
} from "../material-kit-pro-react.jsx";

const userSendPageStyle = theme => ({
    main: {
        marginTop: "0px"
    },
    container: {
        width: "100%",
        zIndex: "2",
        top: "62px",
        position: "relative",
        paddingTop: "20px",
        height: "calc(100vh - 62px)"
    },
    sidebar: {
        width: "190px",
        float: "left",
        overflow: "hidden",
        color: whiteColor,
        borderRight: "1px solid "+theme.customize.borderColor,
        marginRight: "10px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            float: "none"
        }
    },
    content: {
        ...content(theme),
        "& .transferForm": {
            top: "0px !important"
        },
        "& .transferFormUploading": {
            top: "1em !important"
        },
        "& .transferFormSuccess": {
            top: "1em !important"
        }
    },
});

export default userSendPageStyle;
