import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../CustomInput/CustomInput.jsx";

// core components
import GridContainer from "../../Grid/GridContainer.jsx";
import GridItem from "../../Grid/GridItem.jsx";

const style = {
    files: {
        listStyleType: "none",
        padding: "0px",
        marginBottom: "10px",
        "& li": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5px 10px",
            "&:hover": {
                backgroundColor: "#eee"
            },
            "& svg": {
                cursor: "pointer"
            },
            "&+li": {
                marginTop: "10px"
            }
        }
    },
    recipientDetail: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        textAlign: "left",
        "& svg": {
            marginRight: "10px"
        }
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 15px",
        textAlign: "center",
        fontSize: "14px"
    },
    addRecipient: {
        textAlign: "left",
    },
    cancelButton: {
        marginLeft: "10px"
    }
};

class Email extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            subject: "Please sign these documents",
            message: "",
            validation: {
                subject: "",
                message: "",
            }
        };
    }
    sendState() {
        return this.state;
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    validateForm(){
        let validation = {
            subject: "success",
            message: "success",
            isValid: true
        };
        const { subject } = this.state;
        if(subject.length <= 0){
            validation.subject = "error";
            validation.isValid = false;
        }
        return validation.isValid;
    }
    render() {
        const { classes } = this.props;
        const { validation, subject, message } = this.state;
        return (
            <GridContainer justifyContent="center">
                <GridItem xs={12} sm={12}>
                    <h4 className={classes.infoText}>Add message</h4>
                    <div>
                        <CustomInput
                            success={validation.subject === "success"}
                            error={validation.subject === "error"}
                            id="input-subject"
                            labelText="Subject"
                            inputProps={{
                                required: true,
                                onChange: (e) => this.handleChange(e,'subject'),
                                name: "subject",
                                value: subject,
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <CustomInput
                            success={validation.message === "success"}
                            error={validation.message === "error"}
                            id="input-message"
                            labelText="Message"
                            inputProps={{
                                required: true,
                                onChange: (e) => this.handleChange(e,'message'),
                                name: "message",
                                value: message,
                                multiline: true
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </div>
                </GridItem>
            </GridContainer>
        );
    }
}

Email.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(Email);
