import { 
    dangerColor, 
    successColor,
} from "../material-kit-pro-react.jsx";

import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
const accountAllowanceModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important",
        marginTop: "0px"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "65px",
        paddingBottom: "50px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& .viewPasswordIcon": {
            cursor: "pointer",
            marginRight: "10px",
            "& svg": {
                color: "#556DC2BF",
                fill: "#556DC2BF",
            }
        },
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    transferFooter: {
        borderTop: "0px",
        "& svg": {
            transform: "none !important",
            marginTop: "0px !important"
        },
        "& button": {
            marginBottom: "0px"
        }
    },
    datePicker: {
        marginBottom: "17px"
    },
    selectFormControl: {
        margin: "0px !Important"
    },
    customSelectFormControl: {
        textAlign: "left",
        marginBottom: "17px !important"
    },
    customFormControlClasses: {
        textAlign: "left",
        marginBottom: "10px",
        paddingTop: "15px",
        "& label + .MuiInput-formControl": {
            marginTop: "0px"
        }
    },
    fileField: {
        "& >.MuiSvgIcon-root": {
            right: "45px",
            bottom: "11px"
        }
    },
    cardIcons: {
        top: "6px",
        position: "relative"
    },
    dangerColor: {
        color: dangerColor[0]
    },
    successColor: {
        color: successColor[0]
    },
    addUserContainer: {
        marginTop: 0,
        "& .date-fields": {
            display: 'flex',
            justifyContent: "space-between",
        },
        "& .MuiFormControlLabel-root": {
            width: "100%",
            marginBottom: "10px",
            marginLeft: "0px",
        },
        "& .MuiFormControl-fullWidth": {
            marginBottom: "5px"
        }
    },
    alignLeft: {
        textAlign: "left"
    },
    tagsInput: {
        textAlign: "left",
        marginBottom: "15px",
        width: "100%",
        padding: "5px",
        borderRadius: "5px",
        border: "1px solid "+theme.customize.borderColorModal,
        "& .react-tagsinput-tag": {
            marginRight: "0px",
            "&:hover": {
                paddingRight: "8px",
                "& a": {
                    paddingRight: "0px",
                }
            },
            "& a": {
                position: "static",
                opacity: "1",
                marginLeft: "4px",
                "&::before": {
                    color: theme.customize.iconsColor,
                }
            }
        },
        "& .users-icon": {
            float: "left",
            "& img": {
                maxWidth: "20px",
                width: "100%"
            },
            "& svg": {
                width: "20px",
                display: "block",
                color: theme.customize.iconsColor,
                fill: theme.customize.iconsColor
            }
        },
        "& .users-lists": {
            paddingLeft: "20px"
        },
        "& span": {
            color: theme.customize.whiteBlackColor,
            textTransform: "none",
            backgroundColor: "transparent !Important",
            "&+span": {
                borderLeft: "1px solid "+theme.customize.whiteBlackColor,
            },
            borderRadius: "0px",
            lineHeight: "normal",
            paddingTop: "0px",
            paddingBottom: "0px",
            "& a": {
                top: "1px"
            }
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            float: "none"
        },
    },
    textarea: {
        minHeight: "200px",
        width: "100%",
        resize: "vertical",
        padding: "10px",
        backgroundColor: "transparent",
        color: theme.customize.whiteBlackColor
    }
});

export default accountAllowanceModalStyle;
