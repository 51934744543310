import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import LoaderComponent from '../../components/Loader'
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import CustomFileInput from "../../components/CustomFileInput/CustomFileInput";
import AttachFile from "@material-ui/icons/AttachFile";
import { helper } from '../../../assets/js/utils/Element';
import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

const AdManagement = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            businessAds: null,
            editInfo: false,
            savingInfo: false,
            showError: false,
            errorMessage: "",
            success: false,
            uploading: false,
            bannerBlobs: {
                header_banner_photo: "",
                bottom_banner_photo: "",
                left_banner_photo: "",
                right_banner_photo: ""
            }
        }

        this.editUserInfo = this.editUserInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveUserInfo = this.saveUserInfo.bind(this);
        this.updateUser = this.updateUser.bind(this);
        
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }        
        if(authorized){
            if(user.hasOwnProperty('user_type') && user.user_type !== "business"){
                let redirectUrl = helper.getHomePageUrl(user);
                if(user.level_id === 5){
                    redirectUrl = "/user/shared";
                }
                this.history.push(redirectUrl);
            }

            this.loadBusinessAds();                
        }
    }
    loadBusinessAds(){
        const { user } = this.store.getState();
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loading: true
        });

        let requestData = {
            id: user.id
        }
        Api.getBusinessAds(requestData, source).then(data => {
            that.setState({
                businessAds: data,
                loading: false,
                error: false,
                bannerBlobs: {
                    header_banner_photo: data.header_banner_photo,
                    bottom_banner_photo: data.bottom_banner_photo,
                    left_banner_photo: data.left_banner_photo,
                    right_banner_photo: data.right_banner_photo
                }
            });
        }).catch(err => {
            console.log(err);
            if(err.message !== "Request Cancelled"){
                this.setState({
                    loading: false,
                    error: true,
                    errorMessage: err.message
                });
            }
        });
    }
    editUserInfo(editInfo = true){
        this.setState({editInfo: editInfo});
    }
    saveUserInfo(){
        this.setState({
            editInfo: false, 
            savingInfo: true,
            showError: false
        });
        this.updateUser();
    }
    updateUser(){
        const { user } = this.store.getState();
        const { businessAds } = this.state;
        
        var requestData = new FormData();
        requestData.append('headerBannerPhoto', businessAds.header_banner_photo);
        requestData.append('headerBannerUrl', businessAds.header_banner_url);
        requestData.append('bottomBannerPhoto', businessAds.bottom_banner_photo);
        requestData.append('bottomBannerUrl', businessAds.bottom_banner_url);
        requestData.append('leftBannerPhoto', businessAds.left_banner_photo);
        requestData.append('leftBannerUrl', businessAds.left_banner_url);
        requestData.append('rightBannerPhoto', businessAds.right_banner_photo);
        requestData.append('rightBannerUrl', businessAds.right_banner_url);
        
        const source = axios.CancelToken.source();
        Api.updateBusinessAds(user.id, requestData, source).then((data) => {
            this.setState({
                savingInfo: false, 
                businessAds: data
            });
            
        }).catch(err => {
            this.setState({
                savingInfo: false, 
                showError: true, 
                errorMessage: err.message
            });
        });
    }
    handleChange(event, name) {
        this.setState({
            businessAds: {
                ...this.state.businessAds, 
                [name]: event.target.value
            }
        });
    }
    handleFileChange(e, name){
        this.setState({
            businessAds: {
                ...this.state.businessAds, 
                [name]: e.target.files[0]
            },
            bannerBlobs: {
                ...this.state.bannerBlobs,
                [name]: URL.createObjectURL(e.target.files[0])
            }
        });
    }
    removeBusinessAds(e, name){
        const source = axios.CancelToken.source();
        this.setState({
            businessAds: {
                ...this.state.businessAds, 
                [name]: ''
            },
            bannerBlobs: {
                ...this.state.bannerBlobs,
                [name]: ''
            },
            cancelToken: source,
            showError: false,
        });
        let requestData = {
            name: name
        };
        const that = this;
        Api.removeBusinessAds(requestData, source).then(data => {
            that.setState({
                success: true,
                uploading: false,
                businessAds: data
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    uploading: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"form-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"form-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    render() {
        const { classes } = this.props;
        const { editInfo, businessAds, savingInfo, showError, loading, bannerBlobs } = this.state;

        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }

        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.profileContainer+" "+classes.businessAdsContainer}>
                    <div className={classes.content}>
                        <GridItem className={classes.main} xs={12} sm={12} md={8} lg={6}>
                            <h3 className={classes.title}>Ad Management</h3>
                            {
                                businessAds ?
                                    <>
                                        <div>

                                            {
                                                editInfo === false && savingInfo === false ?
                                                    <div className={"editButton"}>
                                                        <Button color="custom" onClick={() => this.editUserInfo()}>Edit</Button>
                                                    </div>
                                                :
                                                savingInfo ? <LoaderComponent color="custom" align="left" /> : <></>
                                            }                    
                                            {
                                                editInfo === true ?
                                                    <div className={"saveButtons"}>
                                                        <Button color="white" onClick={() => this.editUserInfo(false)}>
                                                            Cancel
                                                        </Button>
                                                        <Button color="custom" type="button" onClick={() => this.saveUserInfo()}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                :
                                                    <></>
                                            }
                                        </div>
                                        <div className={"userInfo adManagement "+(editInfo === false?classes.viewInfo:'')}>
                                            {
                                                showError ?
                                                    this.renderErrorMessages()
                                                :
                                                <></>
                                            }
                                            <div className={(bannerBlobs.header_banner_photo ? "photo-section" : "")}>
                                                {
                                                    bannerBlobs.header_banner_photo ?
                                                        <img src={bannerBlobs.header_banner_photo} alt="banner" />
                                                    :
                                                        <></>
                                                }
                                                <CustomFileInput
                                                    id="input-headerBannerPhoto"                            
                                                    onChange={(e) => this.handleFileChange(e,'header_banner_photo')}
                                                    endButton={{
                                                        buttonProps: {
                                                            round: true,
                                                            color: "custom",
                                                            justIcon: true,
                                                            fileButton: true,
                                                        },
                                                        icon: <AttachFile />
                                                    }}
                                                    inputProps={{
                                                        value: businessAds.header_banner_photo,
                                                        placeholder: "Header Banner Photo",
                                                        disabled: !editInfo,
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                                <div className="photo-section-bottom">
                                                    <p className="note">Banner size should be 728x90</p>
                                                    {
                                                        businessAds.header_banner_photo ?
                                                            <div className={"removePhoto"}>
                                                                <Button color="custom" size={"sm"} onClick={(e) => this.removeBusinessAds(e, 'header_banner_photo')}>Remove Photo</Button>
                                                            </div>
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                            <CustomInput
                                                id="outlined-headerBannerUrl"
                                                labelText="Header Banner Url"
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e, 'header_banner_url'),
                                                    name: "headerBannerUrl",
                                                    value: businessAds.header_banner_url ? businessAds.header_banner_url : "",
                                                    disabled: !editInfo,
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: "custom-input body"
                                                }}
                                            />
                                            <div className={(bannerBlobs.bottom_banner_photo ? "photo-section" : "")}>
                                                {
                                                    bannerBlobs.bottom_banner_photo ?
                                                        <img src={bannerBlobs.bottom_banner_photo} alt="banner" />
                                                    :
                                                        <></>
                                                }
                                                <CustomFileInput
                                                    id="input-bottomBannerPhoto"                            
                                                    onChange={(e) => this.handleFileChange(e,'bottom_banner_photo')}
                                                    endButton={{
                                                        buttonProps: {
                                                            round: true,
                                                            color: "custom",
                                                            justIcon: true,
                                                            fileButton: true,
                                                        },
                                                        icon: <AttachFile />
                                                    }}
                                                    inputProps={{
                                                        value: businessAds.bottom_banner_photo,
                                                        placeholder: "Bottom Banner Photo",
                                                        disabled: !editInfo,
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                                <div className="photo-section-bottom">
                                                    <p className="note">Banner size should be 728x90</p>
                                                    {
                                                        businessAds.bottom_banner_photo ?
                                                            <div className={"removePhoto"}>
                                                                <Button color="custom" size={"sm"} onClick={(e) => this.removeBusinessAds(e, 'bottom_banner_photo')}>Remove Photo</Button>
                                                            </div>
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                            <CustomInput
                                                id="input-bottomBannerUrl"
                                                labelText="Bottom Banner Url"
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,'bottom_banner_url'),
                                                    name: "bottomBannerUrl",
                                                    value: businessAds.bottom_banner_url ? businessAds.bottom_banner_url : "",
                                                    disabled: !editInfo
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: "custom-input body"
                                                }}
                                            />
                                            <div className={(bannerBlobs.left_banner_photo ? "photo-section" : "")}>
                                                {
                                                    bannerBlobs.left_banner_photo ?
                                                        <img src={bannerBlobs.left_banner_photo} alt="banner" />
                                                    :
                                                        <></>
                                                }
                                                <CustomFileInput
                                                    id="input-leftBannerPhoto"                            
                                                    onChange={(e) => this.handleFileChange(e,'left_banner_photo')}
                                                    endButton={{
                                                        buttonProps: {
                                                            round: true,
                                                            color: "custom",
                                                            justIcon: true,
                                                            fileButton: true,
                                                        },
                                                        icon: <AttachFile />
                                                    }}
                                                    inputProps={{
                                                        value: businessAds.left_banner_photo,
                                                        placeholder: "Left Vertical Banner Photo",
                                                        disabled: !editInfo,
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                                <div className="photo-section-bottom">
                                                    <p className="note">Banner size should be 160x600</p>
                                                    {
                                                        businessAds.left_banner_photo ?
                                                            <div className={"removePhoto"}>
                                                                <Button color="custom" size={"sm"} onClick={(e) => this.removeBusinessAds(e, 'left_banner_photo')}>Remove Photo</Button>
                                                            </div>
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                            <CustomInput
                                                id="input-leftBannerUrl"
                                                labelText="Left Vertical Banner Url"
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,'left_banner_url'),
                                                    name: "leftBannerUrl",
                                                    value: businessAds.left_banner_url ? businessAds.left_banner_url : "",
                                                    disabled: !editInfo
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: "custom-input body"
                                                }}
                                            />
                                            <div className={(bannerBlobs.right_banner_photo ? "photo-section" : "")}>
                                                {   
                                                    bannerBlobs.right_banner_photo ?
                                                        <img src={bannerBlobs.right_banner_photo} alt="banner" />
                                                    :
                                                        <></>
                                                }
                                                <CustomFileInput
                                                    id="input-rightBannerPhoto"                            
                                                    onChange={(e) => this.handleFileChange(e,'right_banner_photo')}
                                                    endButton={{
                                                        buttonProps: {
                                                            round: true,
                                                            color: "custom",
                                                            justIcon: true,
                                                            fileButton: true,
                                                        },
                                                        icon: <AttachFile />
                                                    }}
                                                    inputProps={{
                                                        value: businessAds.right_banner_photo,
                                                        placeholder: "Right Vertical Banner Photo",
                                                        disabled: !editInfo,
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                                <div className="photo-section-bottom">
                                                    <p className="note">Banner size should be 160x600</p>
                                                    {
                                                        businessAds.right_banner_photo ?
                                                            <div className={"removePhoto"}>
                                                                <Button color="custom" size={"sm"} onClick={(e) => this.removeBusinessAds(e, 'right_banner_photo')}>Remove Photo</Button>
                                                            </div>
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                            <CustomInput
                                                id="input-rightBannerUrl"
                                                labelText="Right Vertical Banner Url"
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,'right_banner_url'),
                                                    name: "rightBannerUrl",
                                                    value: businessAds.right_banner_url ? businessAds.right_banner_url : "",
                                                    disabled: !editInfo
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: "custom-input body"
                                                }}
                                            />
                                        </div>
                                        <div>
                                            {
                                                editInfo === true ?
                                                    <div className={"saveButtons saveButtonsBottom"}>
                                                        <Button color="white" onClick={() => this.editUserInfo(false)}>
                                                            Cancel
                                                        </Button>
                                                        <Button color="custom" type="button" onClick={() => this.saveUserInfo()}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                :
                                                    editInfo === false && savingInfo === false ?
                                                        <div className={"editButton editButtonBottom"}>
                                                            <Button color="custom" onClick={() => this.editUserInfo()}>Edit</Button>
                                                        </div>
                                                    : savingInfo ? 
                                                        <LoaderComponent color="custom" align="left" /> 
                                                    : 
                                                        <></>
                                            }
                                        </div>
                                    </>
                                : loading === true ? 
                                    <LoaderComponent color="custom" align="left" />
                                :
                                    <></>
                            }
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

AdManagement.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(AdManagement);