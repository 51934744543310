import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomSelect from "../CustomInput/CustomSelect";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader";

import changeExpiryDateModalStyle from "../../../assets/jss/user/changeExpiryDateModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ChangeVersionControlModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            versionControl: this.props.versionControl
        };

        this.handleVersionControlChange = this.handleVersionControlChange.bind(this);

        this.versionControlOptions = [
            {
                value: "Not Set",
                key: "0"
            },
            {
                value: "Date/Time",
                key: "datetime"
            },
            {
                value: "Alphanumeric",
                key: "alphanumeric"
            }
        ];
    }
    handleVersionControlChange(e, name){
        this.setState({
            [name]: e.target.value
        });
    }
    render() {
        const { classes, saving, confirmationMessage } = this.props;
        const { versionControl } = this.state;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        {
                            saving ?
                                <></>
                            :
                            <h4 className={classes.modalTitle+" "+classes.textCenter}>{confirmationMessage}</h4> 
                        }
                </DialogTitle>
                <DialogContent
                    id="confirmationModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            saving ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            <>
                                <CustomSelect 
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input m-zero"
                                    }}
                                    labelText="Version Control"
                                    selectProps={{
                                        onChange: (e) => this.handleVersionControlChange(e, 'versionControl'),
                                        value: versionControl
                                    }}
                                    inputProps={{
                                        name: "versionControl",
                                    }}
                                    color="custom"
                                    items={this.versionControlOptions}
                                    itemLabel="value"
                                    itemValue="key"
                                />
                            </>
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        saving ?
                            <></>
                        :
                            <Button color="custom" className={classes.changeButton} onClick={() => this.props.onSuccess(versionControl)}>
                                Change
                            </Button>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

ChangeVersionControlModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false
}
ChangeVersionControlModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool
}
export default withStyles(changeExpiryDateModalStyle)(ChangeVersionControlModal);