import {
    primaryColor,
    grayColor,
    defaultFont,
    dangerColor,
    successColor,
} from "../../material-kit-pro-react.jsx";

const dateTimePickerStyle = (theme) => ({
    formControl: {
        margin: "0px 0px 17px 0px",
        paddingTop: "27px",
        position: "relative",
        "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: grayColor[19]
        },
        "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
            color: grayColor[13]
        },
        "& .MuiFormLabel-root.Mui-focused": {
            color: primaryColor[0]
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: primaryColor[0]
        },
        "& .MuiInput-underline, & .MuiInput-underline:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottomColor: primaryColor[0]
        },
        "& .MuiFormLabel-root": {
            ...defaultFont,
            color: grayColor[12] + " !important",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "1.42857",
            letterSpacing: "unset",
        },
        "& input": {
            color: grayColor[13],
            height: "unset",
            "&,&::placeholder": {
                fontSize: "14px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.42857",
                opacity: "1"
            },
            "&::placeholder": {
                color: grayColor[12]
            }
        },
        "&.custom-input": {
            padding: "0px",
            marginBottom: "0px",
            marginTop: "23px",
            "&.m-zero": {
                marginTop: "0px"
            },
            "&.body": {
                "& .MuiInputLabel-root": {
                    "&.MuiInputLabel-shrink": {
                        backgroundColor: theme.customize.backgroundColor,
                    },
                }
            },
            "& .MuiInput-root": {
                marginTop: "0px",
                backgroundColor: "transparent",
                borderRadius: "5px",
                border: "1px solid #B9BBC6",
                "&:before": {
                    border: "none"
                },
                "&:after": {
                    border: "none"
                }
            },
            "& .MuiInput-input": {
                paddingLeft: "13px",
                paddingTop: "17px",
                paddingBottom: "14px",
                color: theme.customize.whiteBlackColor,
                borderRadius: "5px",
            },
            "& .MuiInputLabel-root": {
                top: "-8px",
                left: "13px",
                zIndex: "1",
                cursor: "text",
                "&.MuiInputLabel-shrink": {
                    transform: "translate(0, 2px) scale(0.75)",
                    backgroundColor: theme.customize.modalBackground,
                    padding: "0px 5px",
                },
                "&.MuiInputLabel-animated": {
                    transition: "color 300ms cubic-bezier(0.0, 0.25, 0.75, 1) 0ms,transform 300ms cubic-bezier(0.0, 0.25, 0.75, 1) 0ms"
                },
                "&.Mui-focused": {
                    color: primaryColor[0]+" !important",
                    "&+.MuiInput-root": {
                        borderColor: primaryColor[0]+" !important"
                    }
                },
                "&$labelRootSuccess": {
                    "&+.MuiInput-root": {
                        borderColor: successColor[0]+" !important"
                    }
                },
                "&$labelRootError": {
                    "&+.MuiInput-root": {
                        borderColor: dangerColor[0]+" !important"
                    }
                },
            },
            "& .MuiFormControl-root": {
                marginBottom: "0px"
            },
            "& .input-icon": {
                bottom: "13px",
                right: "10px",
            }
        }
    },
    modalRoot: {
        "& .MuiPickersToolbar-toolbar": {
            backgroundColor: primaryColor[0]
        },
        "& .MuiPickerDTTabs-tabs": {
            backgroundColor: primaryColor[0],
            "& .MuiTabs-indicator": {
                backgroundColor: primaryColor[4],
            }
        },
        "& .MuiPickersDay-daySelected": {
            backgroundColor: primaryColor[0],
        },
        "& .MuiPickersClock-pin": {
            backgroundColor: primaryColor[0],
        },
        "& .MuiPickersClockPointer-noPoint": {
            backgroundColor: primaryColor[0],
        },
        "& .MuiPickersClockPointer-thumb": {
            borderColor: primaryColor[0],
        },
        "& .MuiPickersClockPointer-pointer": {
            backgroundColor: primaryColor[0],
        },
        "& .MuiButton-text": {
            color: primaryColor[0],
        }
    },
    buttonDisabled: {
        display: "none"
    }
});

export default dateTimePickerStyle;
