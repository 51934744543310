import {
    dangerColor,
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
import userHomePageStyle from "./userHomePageStyle.jsx";
const storageModalStyle = theme => ({
    ...userHomePageStyle(theme),
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "25px",
        paddingBottom: "0px",
        "& .react-swipeable-view-container": {
            "& > div > div": {
                padding: "0px !important",
            }
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
        paddingTop: "20px"
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    errorMessage: {
        color: dangerColor[0]
    },
    numberInput: {
        
    },
    filesTable: {
        ...userHomePageStyle(theme).filesTable,
        "& .td_actions": {
            "& button": {
                padding: "0px",
                margin: "0px",
            }
        },
        "& .rt-tbody": {
            minHeight: "auto"
        }
    }
});
  
export default storageModalStyle;
  