import React from 'react';
import axios from "axios";
import Api from "../../../../assets/js/utils/Api";
import PropTypes from "prop-types";
import { helper } from "../../../../assets/js/utils/Element";
import Button from "../../../components/CustomButtons/Button";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import ReactTable from "react-table";
import LoaderComponent from "../../../components/Loader";
import RefreshIcon from "@material-ui/icons/Refresh";
import DisconnectAccountModal from "../../../components/User/DisconnectAccountModal";

import withStyles from "@material-ui/core/styles/withStyles";
import userAccountsPageStyle from "../../../../assets/jss/user/userAccountsPageStyle.jsx";

const OtherAccounts = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            totalItemCount: 0,
            response: null,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            page: 1,
            disconnectAccountModal: false,
            disconnectAccountDetail: {},
        }; 
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(authorized){
            this.loadAccounts();
        }
    }
    loadAccounts(viewMore = false, tab = 0){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 200,
            other: 1
        };
        Api.getAccounts(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            cancelToken: source,
            page: page,
        });
    }
    onDisconnectAccountModal(status = true, item){
        if(status === false){
            this.setState({
                disconnectAccountModal: status,
            })
            return;
        }
        this.setState({
            disconnectAccountModal: status,
            disconnectAccountDetail: {
                userId: item.id,
            }
        })
    }
    onDisconnectAccount(){
        this.onDisconnectAccountModal(false);
        this.loadAccounts();
    }
    getTableData(){
        const { response } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(item => {
            let newItem = Object.assign({}, item);
            newItem['name'] = item.account_name;
            newItem['usage'] = this.getUserUsage(item);
            newItem['actions'] = (
                <Button color="white" simple size="lg" onClick={() => this.onDisconnectAccountModal(true, item)}>Disconnect</Button>
            )
            tableData.push(newItem);
            return null;
        });
        return tableData;
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getUserUsage(user){
        let usage = "";
        if(user.hasOwnProperty("usage")){
            const usagePercentage = (user.usage / user.quota)*100;
            usage += usagePercentage.toFixed(2)+"%";
        }
        if(user.hasOwnProperty("usage")){
            usage += " | "+helper.getFormatedSize(user.quota);
        }

        return usage;
    }
    render(){
        const { loading, limit, loadingMore, page, totalPages, disconnectAccountModal, disconnectAccountDetail } = this.state;
        const { classes } = this.props;
        const tableData = this.getTableData();   

        return(
            <>
                <h4>
                    Other(s) Added You
                    <Button color="custom" justIcon onClick={() => this.loadAccounts()}>
                        <RefreshIcon className={classes.icons} />
                    </Button>
                </h4>
                <div className="accounts-content">
                    <GridContainer>
                        <GridItem>
                            <div className={classes.accounts}>
                                {
                                    loading ?
                                        <LoaderComponent color="custom" align="center" />
                                    :
                                        <ReactTable
                                            columns={[
                                                {
                                                    Header: "Computer Name",
                                                    accessor: "computer",
                                                    headerClassName: "hd_computer",
                                                    className: "hd_computer td_computer",
                                                },
                                                {
                                                    Header: "IP",
                                                    accessor: "lastupdated_ip",
                                                    headerClassName: "hd_ip",
                                                    className: "hd_ip td_ip",
                                                },
                                                {
                                                    Header: "Application",
                                                    accessor: "application",
                                                    headerClassName: "hd_application",
                                                    className: "hd_application td_application",
                                                },
                                                {
                                                    Header: "Account Name",
                                                    accessor: "name",
                                                    headerClassName: "hd_account_name",
                                                    className: "hd_account_name td_account_name",
                                                },
                                                {
                                                    Header: "First Name",
                                                    accessor: "first_name",
                                                    headerClassName: "hd_first_name",
                                                    className: "hd_first_name td_first_name",
                                                },
                                                {
                                                    Header: "First Name",
                                                    accessor: "last_name",
                                                    headerClassName: "hd_last_name",
                                                    className: "hd_last_name td_last_name",
                                                },
                                                {
                                                    Header: "",
                                                    accessor: "actions",
                                                    headerClassName: "hd_actions",
                                                    className: "hd_actions td_actions",
                                                    sortable: false,
                                                    filterable: false,
                                                },
                                            ]}
                                            data={tableData}
                                            pageSizeOptions={[500]}
                                            defaultPageSize={limit}
                                            showPaginationTop={false}
                                            minRows={0}
                                            showPaginationBottom={false}
                                            className={"-striped -highlight "+classes.filesTable}
                                            getNoDataProps={() => this.getNoDataProps()}
                                        />
                                }
                                
                            </div>
                            <div className={classes.loadMore}>
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="custom" id="contentViewMore" onClick={(e) => this.loadAccounts(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                {
                    disconnectAccountModal ?
                        <DisconnectAccountModal open={disconnectAccountModal} disconnectAccountDetail={disconnectAccountDetail} onClose={() => this.onDisconnectAccountModal(false)} onSuccess={() => this.onDisconnectAccount()} />
                    :
                        <></>
                }
            </>
        )
    }
}

OtherAccounts.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userAccountsPageStyle)(OtherAccounts);
