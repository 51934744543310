import { grayColor, whiteColor } from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
const firstTimeLoginModalStyle = theme => ({
    ...modalStyle(theme),
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "950px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "50px",
        paddingTop: "50px",
        paddingRight: "0px",
        paddingLeft: "0px",
        textAlign: "center",
        "& .second-grid": {
            marginTop: "25px",
        },
        "& .popup-section-listings": {
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            columnGap: "20px",
            rowGap: "20px",
        },
        "& .popup-listing-content": {
            textAlign: "center",
            maxWidth: "150px",
            width: "100%",
            "&.align-left": {
                textAlign: "left",
            }
        },
        "& .sd-post-image-bg": {
            position: "relative",
            "& .play-icon": {
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                cursor: "pointer",
                "& svg": {
                    fill: theme.customize.iconsColor,
                    width: "60px",
                    height: "60px",
                }
            },
            "& iframe": {
                width: "100%",
                height: "250px",
                display: "block"
            },
            "& img": {
                display: "inline-block",
                width: "100%",
            }
        },
        "& .sd-post-margin-content": {
            padding: "10px 0px",
            maxWidth: "100%",
            margin: "0px auto 0 auto",
            position: "relative",
            "& h2": {
                marginBottom: "0px",
                marginTop: "0px",
                fontSize: "14px",
                lineHeight: "normal",
                fontWeight: "400",
                color: "#515151",
                "& a": {
                    color: theme.customize.whiteBlackColor,
                    cursor: "pointer"
                }
            },
            "& .sd-post-short-content": {
                marginBottom: "15px",
                fontSize: "14px",
            },
            "& .sd-readmorebtn": {
                transition: "all 0.5s ease-out 0s",
                textDecoration: "none !important",
                color: grayColor[4],
                border: "2px solid "+grayColor[4],
                padding: "8px 15px",
                fontSize: "13px",
                display: "inline-block",
                boxSizing: "border-box",
                lineHeight: "normal !important",
                "&:hover": {
                    background: grayColor[4],
                    color: whiteColor,
                }
            }
        },
        "& .sd-video-iframe": {
            marginBottom: "60px",
            "&.m-zero": {
                marginBottom: "0px",
            },
            "& iframe": {
                minHeight: "350px",
                maxWidth: "600px",
                width: "100%",
                [theme.breakpoints.down("xs")]: {
                    minHeight: "280px"
                }
            },
            "& .sd-video-info": {
                "& p.sd-video-description": {
                    fontSize: "16px"
                }
            }
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "center !important",
        MsFlexPack: "center !important",
        WebkitBoxPack: "center !important",
        padding: "0px",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important"
    },
});

export default firstTimeLoginModalStyle;
