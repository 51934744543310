import modalStyle from "../material-kit-pro-react/modalStyle.jsx";

const signModalStyle = theme => ({
    ...modalStyle(theme),
    modalRoot: {
        overflow: "auto",
        display: "block"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 0px 30px",
    },
    modalHeader: {
        borderBottom: "none",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        minHeight: "0px"
    },
    modalTitle: {
        margin: "0",
        lineHeight: "1.5",
        textAlign: "center"
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "16px",
        right: "16px",
        margin: "0px",
        padding: "0px",
        zIndex: "1",
        "& svg": {
            marginRight: "0px"
        }
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        padding: "0px !important",
        position: "relative",
        overflow: "visible",
        textAlign: "center",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& .successMessage": {
            marginTop: "20px",
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "center !important",
        MsFlexPack: "center !important",
        WebkitBoxPack: "center !important",
        padding: "0px",
    },
    wizard: {
        "& .wizard_card": {
            marginTop: "0px",
            marginBottom: "0px",
            boxShadow: "none",
            background: "transparent",
        },
        "& .wizard_footer": {
            marginBottom: "11px"
        }
    },
    savingWizard: {
        display: "none"
    }
});

export default signModalStyle;
