import React from 'react'
import moment from 'moment';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MaterialIcon from "@mdi/react";
import { mdiPencil  } from '@mdi/js';

import linkDetailModalStyle from "../../../assets/jss/user/linkDetailModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const LinkDetailModal = class extends React.Component {
    getType(link){
        let label = "Direct Upload";
        if(link.upload_type.length <= 0){
            link.upload_type = link.type;
        }
        switch(link.upload_type){
            case 'web':
                label = 'Web';
                break;
            case 'my_docs':
                label = 'My Docs';
                break;
            case 'direct_upload':
                label = 'Direct Upload';
                break;
            case 'outlook':
                label = 'Outlook';
                break;
            case 'desktop':
                label = 'Desktop';
                break;
            case 'mobile':
                label = 'Mobile';
                break;
            // case 'share':
            //     label = 'Shared';
            //     break;
            // case 'manager_upload':
            //     label = 'File Manager Upload';
            //     break;
            default:
                if(link.path.indexOf("Web")){
                    label = "Web";
                }else if(link.path.indexOf("Outlook")){
                    label = "Outlook";
                }else if(link.path.indexOf("My Docs")){
                    label = "Desktop";
                }
                break;
        }
        return label;
    }
    getFormatedExpiry(expire_at){
        if(!expire_at || expire_at.length <= 0){
            return "No Expiry Date";
        }
        return moment(expire_at).format("MM/DD/YYYY h:mm A");
    }
    getFormatedDownloadDate(date){
        if(!date || date.length <= 0){
            return "";
        }
        return moment(date).format("MM/DD/YYYY h:mm A");
    }
    getData(linkDetail){
        let link = linkDetail;

        let fileArray = {
            type: this.getType(link),
            account: link.account,
            server: "Sendlinx",
            sender_email: (
                <div title={ link.sender_email }>
                    { link.sender_email }
                </div>
            ),
            recipients: (
                link.title !== '' && link.title ?
                    link.title
                :
                    link.recipients.join(",")
            ),
            message: (
                <div dangerouslySetInnerHTML={{__html: link.message}}></div>
            ),
            subject: link.subject,
            created_at: link.created_at,
            expire_at: (
                <div className={(link.status === 'expire' ? 'expire-link' : 'active-link')}>
                    {this.getFormatedExpiry(link.expire_at)}
                    <Button justIcon color="transparent" onClick={() => this.props.onChangeExpiryDate(true, link.id, link.expire_at)}>
                        <MaterialIcon path={mdiPencil} className="MuiSvgIcon-root" />
                    </Button>
                </div>
            ),
            last_downloaded: this.getFormatedDownloadDate(link.download_date),
            status: (
                link.state === 'uploaded' || link.state === 'processing' ?
                    <div className='orange'>
                        Processing ...
                    </div>
                :
                    <div>
                        Ready
                    </div>
            ),
            path: (
                <div title={ link.path }>
                    { link.path }
                </div>
            ),
            title: link.title,
            link_password: (
                <div>
                    { link.link_password }
                    <Button justIcon color="transparent" onClick={() => this.props.onChangeLinkPassword(true, link.id, link.link_password)} title={"Link Password"}>
                        <MaterialIcon path={mdiPencil} className="MuiSvgIcon-root" />
                    </Button>
                </div>
            )
        };
            
        return fileArray;
    }
    render() {
        const { classes, linkDetail  } = this.props;
        console.log(linkDetail);
        let link = this.getData(linkDetail);

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="attachments-modal-slide-title"
                aria-describedby="attachments-modal-slide-description"
            >
                <DialogTitle
                    id="attachments-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle+" "+classes.textCenter}>{this.props.title}</h4> 
                </DialogTitle>
                <DialogContent id="link-detail-modal-slide-description" className={classes.modalBody}>
                    {
                        linkDetail ?
                            <ul>
                                <li><span>Type</span>{link.type}</li>
                                <li><span>Account</span>{link.account}</li>
                                <li><span>Server</span>{link.server}</li>
                                <li><span>Sender</span>{link.sender_email}</li>
                                <li><span>Destination</span>{link.recipients}</li>
                                <li><span>Subject</span>{link.subject}</li>
                                <li><span>Date</span>{link.created_at}</li>
                                <li><span>Link Password</span>{link.link_password}</li>
                                <li><span>Expiry Date</span>{link.expire_at}</li>
                                <li><span>Last Downloaded</span>{link.last_downloaded}</li>
                                <li><span>Path</span>{link.path}</li>
                                <li><span>Status</span>{link.status}</li>
                            </ul>
                        :
                            <></>
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button color="custom" onClick={() => this.props.onClose()}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

LinkDetailModal.defaultProps = {
    open: false,
    title: "Link Detail"
}
LinkDetailModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string
}
export default withStyles(linkDetailModalStyle)(LinkDetailModal);