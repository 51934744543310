import React from 'react';
import axios from "axios";
import Cookie from '../../../assets/js/utils/Cookie';
import PropTypes from "prop-types";
import Config from "../../../../Config";
import { createBrowserHistory } from 'history';
import { Redirect } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import Api from "../../../assets/js/utils/Api";
import { helper } from "../../../assets/js/utils/Element";
import LoaderComponent from '../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import Warning from "../../components/Typography/Warning";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";

import loginPageStyle from "../../../assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";
const history = createBrowserHistory();

const Login = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.handleTwoFaSubmit = this.handleTwoFaSubmit.bind(this);

        this.state = {
            email: "",
            password: "",
            passwordConfirm: "",
            code: "",
            cell: "",
            country_id: "",
            loading: false,
            validation: {
                email: '',
                password: '',
                passwordConfirm: '',
                code: '',
                cell: '',
                isValid: false
            },
            showError: false,
            errorMessage: "",
            forgotPassword: this.props.forgot,
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            requestCode: false,
            response: null,
            twoFaVerification: false,
            confirmCell: false,
            countries: []
        }
    }
    handleChange(e, name){
        let state = {};
        let value = e.target.value;
        if(name === "email"){
            value = value.toLowerCase();
        }
        let limit = 64;
        if(name === "email"){
            limit = 128;
        }
        state[name] = value.substr(0, limit);
        this.setState(state);
    }
    componentDidMount(){
        this.loadCountries();
    }
    componentDidUpdate(prevProps, prevState){
        const { password, passwordConfirm } = this.state;
        let validatePassword = false;
        let validatePasswordConfirm = false;
        if(prevState.password !== password){
            validatePassword = true;            
        }
        if(prevState.passwordConfirm !== passwordConfirm){
            validatePasswordConfirm = true;            
        }
        if(this.state.requestCode && (validatePassword || validatePasswordConfirm)){
            this.validateResetForm(validatePassword, validatePasswordConfirm);
        }
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            that.setState({
                countries: data.response,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    handleSubmit(e){
        e.preventDefault();
        if(this.state.forgotPassword === true && this.state.requestCode === true){
            this.handleResetPassword();
            return;
        }
        if(this.state.forgotPassword === true){
            this.handleForgotSubmit();
            return;
        }
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        const source = axios.CancelToken.source();
        const { email, password } = this.state;
        const requestData = {
            email: email,
            password: password,
            application: "Web"
        }
        Api.login(requestData, source).then(data => {
            if(data.require2FaVerification){
                this.setState({
                    twoFaVerification: true,
                    confirmCell: !data.user.cell_verified,
                    cell: data.user.cell,
                    country_id: data.user.country_id,
                    response: data,
                    loading: false,
                    showError: false,
                    cancelToken: null,
                    errorMessage: ""
                });
            }else{
                this.handleLoginSuccess(data);
            }
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    handleLoginSuccess(data){
        Cookie.write("oauth_token", data.oauth_token);
        Cookie.write("oauth_secret", data.oauth_secret);
        Cookie.write("expires_at", data.expires_at);
        localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
        let redirectUrl = helper.getHomePageUrl(data.user);
        if(data.user.level_id === 5){
            redirectUrl = "/user/shared";
        }
        const returnUrl = decodeURIComponent(helper.getParam("return"));
        if(returnUrl && returnUrl !== "null" && returnUrl.length > 0){
            redirectUrl = returnUrl;
        }
        if(data.user.account_type === -1){
            redirectUrl = "/user/plans";
        }
        this.history.push(redirectUrl);
        this.store.dispatch({
            type: "LOGIN",
            state: {
                authorized: true,
                user: data.user
            }
        });
    }
    handleForgotSubmit(){
        const isValid = this.validateForgotForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        const { email } = this.state;
        const requestData = {
            email: email,
        };
        this.setState({loading: true, cancelToken: source, showError: false});
        Api.forgot(requestData, source).then(data => {
            this.setState({requestCode: true, validated: false, loading: false});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
    }
    handleResetPassword(){
        const isValid = this.validateResetForm(true,true);
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        const { email, code, password } = this.state;
        const requestData = {
            email: email,
            code: code,
            password: password
        };
        this.setState({loading: true, cancelToken: source, showError: false});
        Api.reset(requestData, source).then(data => {
            Cookie.write("oauth_token", data.oauth_token);
            Cookie.write("oauth_secret", data.oauth_secret);
            Cookie.write("expires_at", data.expires_at);
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
            this.history.push("/dashboard");
            this.store.dispatch({
                type: "LOGIN",
                state: {
                    authorized: true,
                    user: data.user
                }
            }); 
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
    }
    validateForm(){
        let validation = {
            email: 'success',
            password: 'success',
            isValid: true
        };
        var emailRegex = Config.getEmailRegex();
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        if(this.state.password.length <= 0){
            validation.password = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validateForgotForm(){
        let validation = {
            email: 'success',
            password: '',
            passwordConfirm: '',
            code: '',
            isValid: true
        };
        var emailRegex = Config.getEmailRegex()
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validateResetForm(validatePassword = false, validatePasswordConfirm = false){
        let validation = {
            email: 'success',
            password: (validatePassword ? 'success': this.state.validation.password),
            passwordConfirm: (validatePasswordConfirm ? 'success': this.state.validation.passwordConfirm),
            code: '',
            isValid: true
        };
        if(this.state.code.length <= 3){
            validation.code = "error";
            validation.isValid = false;
        }

        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(this.state.password.length <= 0 || check === false){
            if(validatePassword){
                validation.password = "error";
            }
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            if(validatePasswordConfirm){
                validation.passwordConfirm = "error";
            }
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    handleForgotPassword(){
        this.setState({forgotPassword: true});
        history.push("/auth/forgot");
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    handleTwoFaSubmit(e){
        e.preventDefault();
        const { code, response, password, cell, confirmCell } = this.state;
        if(confirmCell && cell.length > 0){
            this.confirmCell();
            return;
        }
        if(code.length < 4){
            this.setState({
                validation: {
                    ...this.state.validation,
                    code: "error"
                }
            });
            return;
        }
        const requestData = {
            code: code,
            email: response.user.email,
            password: password
        };
        const source = axios.CancelToken.source();
        Api.verifyCode(requestData, source).then((data) => {
            this.handleLoginSuccess(data);
        }).catch(err => {
            this.setState({
                loading: false, 
                showError: true, 
                cancelToken: null,
                errorMessage: err.message
            });
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    confirmCell(){
        const { response, password, cell, country_id } = this.state;
        const requestData = {
            cell: cell,
            email: response.user.email,
            password: password,
            country_id: country_id
        };
        const source = axios.CancelToken.source();
        Api.confirmCell(requestData, source).then((data) => {
            this.setState({
                loading: false,
                confirmCell: false,
                showError: false, 
                cancelToken: null,
            });
        }).catch(err => {
            this.setState({
                loading: false, 
                showError: true, 
                cancelToken: null,
                errorMessage: err.message
            });
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    resendCode(){
        const { response } = this.state;
        if(response === null){
            return;
        }
        const requestData = {
            email: response.user.email
        };
        const source = axios.CancelToken.source();
        Api.resendSmsCode(requestData, source).then((data) => {
            this.setState({
                loading: false, 
                showError: false, 
                cancelToken: null,
                errorMessage: null
            });
        }).catch(err => {
            this.setState({
                loading: false, 
                showError: true, 
                cancelToken: null,
                errorMessage: err.message
            });
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    handleCellChange(e, name) {
        const { cell } = this.state;
        let state = {};
        const value = e.target.value;
        state[name] = value;
        const regex = /^\d+$/;
        if((value.length > 0 && !regex.test(value)) || value.length > 16){
            state[name] = cell;
        }
        if(state[name].length > 10){
            state['validation'] = {
                ...this.state.validation,
                cell: "success"
            };
        }else{
            state['validation'] = {
                ...this.state.validation,
                cell: "error"
            };
        }
        this.setState(state);
    }
    onCountryChange(e){
        const country = e.target.value;
        this.setState({
            country_id: country,
            validation: {
                ...this.state.validation,
                country_id: "success"
            }
        });
    }
    renderTwoFaVerification(){
        const { classes } = this.props;
        const { validation, loading, showError, cell, confirmCell, country_id, countries } = this.state;
        return (
            <div className={classes.container}>
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={12} md={5}>
                        <Card className={classes.loginCard}>
                            <form className={classes.form} onSubmit={this.handleTwoFaSubmit} noValidate>
                                <h2 className={classes.cardTitle}>Two Factor Verification</h2>
                                <CardBody signup>
                                    {
                                        showError ?
                                            this.renderErrorMessages()
                                        :
                                        <></>
                                    }
                                    {
                                        confirmCell === false ?
                                            <>
                                                <Warning>
                                                    <p>We have sent a code by sms. Enter it below to confirm your login.</p>
                                                </Warning>
                                                <CustomInput
                                                    success={validation.code === "success"}
                                                    error={validation.code === "error"}
                                                    id="input-code"
                                                    labelText="Verification Code"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'code'),
                                                        name: "code",
                                                        type: "text",
                                                        className: classes.passwordControl
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                                <p className={classes.anchor} onClick={() => this.resendCode()}>Resend Code?</p>
                                            </>
                                        :
                                        <>
                                            <Warning>
                                                <p>Please confirm your cell number before continuing.</p>
                                            </Warning>
                                            <CustomSelect
                                                success={validation.country_id === "success"}
                                                error={validation.country_id === "error"}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                labelText="Country"
                                                selectProps={{
                                                    onChange: (e) => this.onCountryChange(e),
                                                    value: country_id
                                                }}
                                                inputProps={{
                                                    name: "country_id",
                                                    id: "input-countryId",
                                                    className: classes.alignLeft,
                                                }}
                                                items={countries}
                                                itemLabel="name"
                                                itemValue="id"
                                            />
                                            <CustomInput
                                                success={validation.cell === "success"}
                                                error={validation.cell === "error"}
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                id="input-cell"
                                                labelText="Cell"
                                                inputProps={{
                                                    onChange: (e) => this.handleCellChange(e, 'cell'),
                                                    name: "cell",
                                                    value: cell,
                                                }}
                                            />
                                        </>
                                    }
                                    
                                </CardBody>
                                <div className={classes.textCenter}>
                                    {
                                        loading ?
                                            <LoaderComponent />
                                        :
                                            <Button round color="primary" type="submit">
                                                {
                                                    confirmCell ?
                                                        "Confirm"
                                                    :
                                                    "Verify"
                                                }
                                            </Button>
                                    }
                                </div>
                            </form>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
    render() {
        const { classes } = this.props;
        const { validation, loading, showError, forgotPassword, requestCode, email, password, passwordConfirm } = this.state;
        const { requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength, twoFaVerification } = this.state;
        const { authorized } = this.store.getState();
        const showPasswordErrors = (validation.password === "success" || validation.password === "error");
        const showPasswordConfirmErrors = (validation.passwordConfirm === "success" || validation.passwordConfirm === "error");
        const requirePasswordConfirm = (passwordConfirm.length > 0 && password === passwordConfirm);
        if(authorized){
            return <Redirect to='/' />
        }
        if(twoFaVerification){
            return this.renderTwoFaVerification();
        }
        return (
            <div>
                <div className={classes.container}>
                    <GridContainer justifyContent="center">
                        <GridItem xs={12} sm={12} md={5}>
                            <Card className={classes.loginCard}>
                                <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
                                    <h2 className={classes.cardTitle}>
                                        {
                                            forgotPassword ?
                                                "Forgot Password"
                                            :
                                            "Log in"
                                        }
                                    </h2>
                                    <CardBody signup>
                                        {
                                            showError ?
                                                this.renderErrorMessages()
                                            :
                                            <></>
                                        }
                                        {
                                            requestCode === false ?
                                                <CustomInput
                                                    id="email"
                                                    success={validation.email === "success"}
                                                    error={validation.email === "error"}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    labelText="Email"
                                                    inputProps={{
                                                        type: "email",
                                                        onChange: (e) => this.handleChange(e, 'email'),
                                                        name: "email",
                                                        value: email,
                                                    }}
                                                />
                                            :
                                            <>
                                                <Warning>
                                                    <p>We have sent a code by email. Enter it below to confirm your account.</p>
                                                </Warning>
                                                <CustomInput
                                                    success={validation.code === "success"}
                                                    error={validation.code === "error"}
                                                    id="input-code"
                                                    labelText="Verification Code"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'code'),
                                                        name: "code",
                                                        type: "text"
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                                <CustomInput
                                                    success={validation.password === "success"}
                                                    error={validation.password === "error"}
                                                    id="input-password"
                                                    labelText="New Password"
                                                    passwordInput={true}
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'password'),
                                                        name: "password",
                                                        type: "text",
                                                        value: password,
                                                        autoComplete: 'off',
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                                {
                                                    showPasswordErrors ?
                                                        <GridItem>
                                                            <div>
                                                                <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                                                </div>
                                                                <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                                                </div>
                                                                <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-symbols">Password must contain a number</span>
                                                                </div>
                                                                <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                                </div>
                                                                <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                                </div>
                                                            </div>
                                                        </GridItem>
                                                    :
                                                    <></>
                                                }
                                                <CustomInput
                                                    success={validation.passwordConfirm === "success"}
                                                    error={validation.passwordConfirm === "error"}
                                                    id="input-passwordConfirm"
                                                    labelText="New Password Again"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'passwordConfirm'),
                                                        name: "passwordConfirm",
                                                        type: "password",
                                                        value: passwordConfirm
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                                {
                                                    showPasswordConfirmErrors ?
                                                        <GridItem>
                                                            <div>
                                                                <div className={(requirePasswordConfirm?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requirePasswordConfirm? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-lowerletter">Passwords do not match</span>
                                                                </div>
                                                            </div>
                                                        </GridItem>
                                                    :
                                                    null
                                                }
                                            </>
                                        }
                                        
                                        {
                                            forgotPassword === false ?
                                                <>
                                                <CustomInput
                                                    id="password"
                                                    success={validation.password === "success"}
                                                    error={validation.password === "error"}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.passwordControl
                                                    }}
                                                    labelText={"Password"}
                                                    inputProps={{
                                                        type: "password",
                                                        onChange: (e) => this.handleChange(e, 'password'),
                                                        name: "password",
                                                        value: password,
                                                    }}
                                                />
                                                <GridItem className={classes.forgotGridItem}>
                                                    <p className={classes.anchor} onClick={this.handleForgotPassword}>Forgot?</p>
                                                    <p>
                                                        No Account? <Link href="https://sendlinx.com/plans/">Create one!</Link>
                                                    </p>
                                                </GridItem>
                                                
                                                </>
                                            :
                                            <></>
                                        }
                                    </CardBody>
                                    <div className={classes.textCenter}>
                                        {
                                            loading ?
                                                <LoaderComponent />
                                            :
                                            forgotPassword === false ?
                                                <Button round color="primary" type="submit">Login</Button>
                                            :
                                                requestCode === true ?
                                                    <Button round color="primary" type="submit">Reset Password</Button>
                                                :
                                                    <Button round color="primary" type="submit">Help me!</Button>
                                            
                                        }                                        
                                    </div>
                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                <BackgroundSlider store={this.store} />
            </div>
        )
    }
}

Login.defaultProps = {
    forgot: false
}
Login.propTypes = {
    forgot: PropTypes.bool
}
export default withStyles(loginPageStyle)(Login);