import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import { Close, Edit } from "@material-ui/icons";

import noteModalStyle from "../../../assets/jss/user/noteModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const FileNoteModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            file: this.props.file
        }
    }
    getHoverText(){
        const { file } = this.state;

        return (file.note_modified_by+" on "+file.note_modified_date);
    }
    render() {
        const { classes, canEdit } = this.props;
        const { file } = this.state;
        
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="noteFileModal-slide-title"
                aria-describedby="noteFileModal-slide-description"
            >
                <DialogTitle
                    id="noteFileModal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Note</h4> 
                </DialogTitle>
                <DialogContent id="noteFileModal-slide-description" className={classes.modalBody}>
                    <p>{file.note}</p>
                    {
                        canEdit ?
                            <>
                                <Button
                                    simple
                                    className={classes.modalCloseButton+" "+classes.modalEditButtonCustom}
                                    key="edit"
                                    aria-label="Edit"
                                    onClick={() => this.props.onEdit()}
                                >
                                    <Edit className={classes.modalClose} />
                                </Button>
                                <Tooltip title={this.getHoverText()} placement="right">
                                    <Button simple size="md" className='last-modified'>Last modified by...</Button>
                                </Tooltip>
                            </>
                        :
                            <></>
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button color="custom" onClick={() => this.props.onClose()}>Close</Button>        
                </DialogActions>
            </Dialog>
        )
    }
}

FileNoteModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    canEdit: true
}
FileNoteModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    canEdit: PropTypes.bool
}
export default withStyles(noteModalStyle)(FileNoteModal);