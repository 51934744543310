import React from "react";
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import { helper } from "../../../assets/js/utils/Element";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import LoaderComponent from "../../components/Loader";
import Close from "@material-ui/icons/Close";
import MaterialIcon from "@mdi/react";
import { mdiFileCheckOutline, mdiCheck, mdiUndoVariant } from '@mdi/js';
import restoreFileModalStyle from "../../../assets/jss/user/restoreFileModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const RestoreFileModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        
        this.state = {
            duplicatesFound: false,
            duplicates: null,
            duplicateAction: null,
            selectivePendingAction: null,
            selectiveUploadedAction: null,
            keepPendingFiles: [],
            keepUploadedFiles: [],
            files: this.props.restoreFiles,
            showError: false,
            errorMessage: "",
            loading: false,
            restoreLinks: null
        };
    }
    getFile(file, key){
        return (
            <li key={key}>
                <p>{file.name} ({file.type})</p>
            </li>
        );
    }
    getFileData(file){
        return {
            id: file.id,
            name: file.name,
            type: file.type,
        };
    }
    onSuccess(){
        const { duplicateAction, files, keepPendingFiles, duplicates, keepUploadedFiles, restoreLinks } = this.state;
        let filteredFiles = [];
        let filesToDelete = [];
        files.map((file) => {
            filteredFiles.push(this.getFileData(file));
            return null;
        });
        const requestData = {
            checkDuplicates: 1
        };
        if(duplicateAction !== null){
            requestData['duplicateAction'] = duplicateAction;
            let duplicatesArray = [];
            duplicates.map((file) => {
                duplicatesArray.push(file.pending.id);
                return null;
            });
            
            // replace skip selective
            if(duplicateAction === "skip"){
                filteredFiles = [];
                files.map((file) => {
                    filesToDelete.push(file.id);
                    if(duplicatesArray.includes(file.id)){
                        return null;
                    }
                    filteredFiles.push(this.getFileData(file));
                    return null;
                });
            }else if(duplicateAction === "selective"){
                filteredFiles = [];
                files.map((file) => {
                    if(!duplicatesArray.includes(file.id)){
                        return null;
                    }
                    if(keepPendingFiles.includes(file.id) && keepUploadedFiles.includes(file.id)){
                        const data = this.getFileData(file);
                        data['duplicate'] = 1;
                        filteredFiles.push(data);
                        return null;
                    }else if(keepPendingFiles.includes(file.id)){
                        filteredFiles.push(this.getFileData(file));
                    }
                    if(!keepPendingFiles.includes(file.id) && keepUploadedFiles.includes(file.id)){
                        filesToDelete.push(file.id);
                    }
                    return null;
                });
            }
            requestData['filesToDelete'] = filesToDelete;
            requestData['files'] = filteredFiles;
        }
        requestData['files'] = filteredFiles;
        if(filteredFiles.length <= 0 && duplicateAction === "selective"){
            this.props.onClose();
            return;
        }
        
        requestData['restoreLinks'] = restoreLinks;
        const source = axios.CancelToken.source();
        Api.restoreFilesPermanently(requestData, source).then(data => {
            //Handle Success;
            this.props.onSuccess();
        }).catch(err => {
            if(err.hasOwnProperty("duplicates") && err.duplicates.length > 0){
                this.setState({
                    showError: true,
                    duplicatesFound: true,
                    duplicates: err.duplicates,
                    errorMessage: err.message,
                    duplicateAction: null,
                    selectivePendingAction: null,
                    selectiveUploadedAction: null,
                    keepPendingFiles: [],
                    keepUploadedFiles: [],
                    loading: false
                });
                return;
            }
            this.setState({
                showError: true,
                errorMessage: err.message,
                files: this.state.userFiles,
                loading: false
            });
        });

        this.setState({loading: true});
    }
    onSelectionCancel(){
        this.setState({
            duplicateAction: null,
        });
    }
    onSelectionConfirm(){
        const { keepPendingFiles, keepUploadedFiles, duplicates } = this.state;
        let isValid = true;
        duplicates.map((file) => {
            if(!keepPendingFiles.includes(file.pending.id) && !keepUploadedFiles.includes(file.uploaded.id)){
                isValid = false;
            }
            return null;
        });

        if(!isValid){
            return;
        }

        this.onSuccess();
    }
    onDuplicateFileAction(action){
        if(action === "replace" || action === "skip"){
            this.setState({duplicateAction: action, duplicatesFound: false}, () => {
                this.onSuccess();
            });
            return;
        }

        this.setState({
            duplicateAction: action,
        });
    }
    getFormatedDateTime(date){
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        let hours = date.getHours();
        const mins = date.getMinutes();
        var a = ( hours >= 12 ) ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const dateTime = year+"-"+month+"-"+day+" "+hours+":"+mins+" "+a;
        return dateTime;
    }
    handleSelectiveAction(value, name){
        let { duplicates, selectivePendingAction, selectiveUploadedAction } = this.state;
        let state = {};
        state[name] = value;
        if(value === "overwrite"){
            state['keepPendingFiles'] = [];
            if(selectivePendingAction === value){
                state[name] = null;
            }else{
                duplicates.map((file) => {
                    state['keepPendingFiles'].push(file.pending.id);
                    return null;
                });
            }
        }else{
            state['keepUploadedFiles'] = [];
            if(selectiveUploadedAction === value){
                state[name] = null;
            }else{
                duplicates.map((file) => {
                    state['keepUploadedFiles'].push(file.uploaded.id);
                    return null;
                });
            }
        }
        this.setState(state);
    }
    handleFileAction(type, file){
        const { keepPendingFiles, keepUploadedFiles, duplicates } = this.state;
        const pendingIndex = keepPendingFiles.indexOf(file.pending.id);
        const uploadedIndex = keepUploadedFiles.indexOf(file.uploaded.id);
        let state = {};
        if(type === "pending"){
            if (pendingIndex === -1) {
                keepPendingFiles.push(file.pending.id);
            } else {
                keepPendingFiles.splice(pendingIndex, 1);
                state['selectivePendingAction'] = null;
            }
            if(keepPendingFiles.length === duplicates.length){
                state['selectivePendingAction'] = "overwrite";
            }
        }else{
            if (uploadedIndex === -1) {
                keepUploadedFiles.push(file.uploaded.id);
            } else {
                keepUploadedFiles.splice(uploadedIndex, 1);
                state['selectiveUploadedAction'] = null;
            }
            if(keepUploadedFiles.length === duplicates.length){
                state['selectiveUploadedAction'] = "skip";
            }
        }
        this.setState({
            ...state,
            keepPendingFiles: keepPendingFiles,
            keepUploadedFiles: keepUploadedFiles
        });
    }
    renderDuplicates(){
        const { duplicates, path, files, duplicateAction, selectivePendingAction, keepPendingFiles, keepUploadedFiles, selectiveUploadedAction } = this.state;
        const { classes } = this.props;
        let pathArray = [];
        let folderName = null;
        if(typeof(path) === "string" && path.trim().length){
            pathArray = path.split("/");
            folderName = pathArray[pathArray.length - 1];
        }
        const date = new Date();
        const dateTime = this.getFormatedDateTime(date);

        if(duplicates !== null && duplicateAction === "selective"){
            return (
                <div className={classes.duplicateSelection}>
                    <p>File(s) with that name already exist(s). Please select which one(s) you would like to keep.</p>
                    <p>If you select both versions, the copied file will have number added to its name.</p>
                    <div className={classes.selectiveCheckboxes}>
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={(e) => this.handleSelectiveAction("overwrite", "selectivePendingAction")}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                    checked={selectivePendingAction === "overwrite"}
                                />
                            }
                            label={"File(s) in trash"}
                        />
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={(e) => this.handleSelectiveAction("skip", "selectiveUploadedAction")}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                    checked={selectiveUploadedAction === "skip"}
                                />
                            }
                            label={"File(s) already uploaded"}
                        />
                    </div>
                    <ul>
                        {
                            duplicates.map((file, key) => {
                                return (
                                    <li key={key}>
                                        <div className={classes.duplicateFileName}>{file.pending.name}</div>
                                        <div className={classes.duplicateFile}>
                                            <FormControlLabel
                                                classes={{label: classes.label}}
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={(e) => this.handleFileAction("pending", file)}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                                        checked={keepPendingFiles.includes(file.pending.id)}
                                                    />
                                                }
                                                label={(
                                                    <div>
                                                        {dateTime}
                                                        <div>{helper.getFormatedSize(file.pending.size)}</div>
                                                    </div>
                                                )}
                                            />
                                            <FormControlLabel
                                                classes={{label: classes.label}}
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={(e) => this.handleFileAction("uploaded", file)}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                                        checked={keepUploadedFiles.includes(file.uploaded.id)}
                                                    />
                                                }
                                                label={(
                                                    <div>
                                                        {this.getFormatedDateTime(new Date(file.uploaded.updated_at))}
                                                        <div>{helper.getFormatedSize(file.uploaded.size)}</div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <div className={classes.selectionButtons}>
                        <Button color="custom" onClick={() => this.onSelectionConfirm()}>Continue</Button>
                        <Button color="white" onClick={() => this.onSelectionCancel()}>Cancel</Button>
                    </div>
                </div>
            )
        }
        return (
            <div className={classes.duplicates}>
                <h4>
                    Restoring {files.length} files
                    {
                        folderName !== null ?
                            " to "+folderName
                        :
                        <></>
                    }
                </h4>
                <p>The destination has {files.length} file(s) or folder(s) with the same names</p>
                <ul>
                    <li className="active" onClick={() => this.onDuplicateFileAction("replace")}>
                        <MaterialIcon path={mdiCheck} className={classes.checkIcon+" MuiSvgIcon-root"} />
                        Replace the files in the destination
                    </li>
                    <li onClick={() => this.onDuplicateFileAction("skip")}>
                        <MaterialIcon path={mdiUndoVariant} className={classes.skipIcon+" MuiSvgIcon-root"} />
                        Skip these files
                    </li>
                    <li onClick={() => this.onDuplicateFileAction("selective")}>
                        <MaterialIcon path={mdiFileCheckOutline} className="MuiSvgIcon-root" />
                        Let me decide for each file
                    </li>
                </ul>
            </div>
        )
    }
    handleRadio(e, name, value){
        let state = {};
        state[name] = value;
        this.setState(state);
    }
    render() {
        const { classes } = this.props;
        const { files, duplicatesFound, duplicates, duplicateAction, loading, restoreLinks } = this.state;

        return (
        <Dialog
            classes={{
            root: classes.modalRoot,
            paper: classes.modal+" "+ ((duplicatesFound && duplicates !== null && duplicateAction === "selective") ? classes.modalDuplicates : "")
            }}
            open={this.props.open}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="restoreFile-slide-title"
            aria-describedby="restoreFile-slide-description"
        >
            <DialogTitle
                id="attachments-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
                <Button
                    simple
                    className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.props.onClose()}
                >
                    {" "}
                    <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>
                    {
                        duplicatesFound && duplicates !== null ?
                            duplicateAction === "selective" ?
                                duplicates.length+" Total File Conflict(s)"
                            :
                                "Replace or Skip Files"
                        :
                            "Are you sure you want to restore?"
                    }
                </h4> 
            </DialogTitle>
            <DialogContent
                id="restoreFile-slide-description"
                className={classes.modalBody}
            >
                {
                    duplicatesFound && duplicates !== null ?
                        this.renderDuplicates()
                    :
                        files && files.length > 0 ?
                            <div className="items-lists">
                                <p className="lists-heading">Items</p>
                                <ul>
                                    {
                                        files.map((file, key) => {
                                            return this.getFile(file, key)
                                        })
                                    }
                                </ul>
                            </div>
                        :
                            <></>
                }
                <div className={classes.checkboxSection}>
                    <p>Do you want to restore associated links as well?</p>
                    <FormControlLabel
                        classes={{label: classes.label}}
                        control={
                            <Radio                                                
                                checked={restoreLinks === true ? true : false}
                                onChange={(e) => this.handleRadio(e,"restoreLinks", true)}
                                value={true}
                                name="restoreLinks"
                                icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                            />
                        }
                        label={"Yes"}
                    />
                    <FormControlLabel
                        classes={{label: classes.label}}
                        control={
                            <Radio                                                
                                checked={restoreLinks === false ? true : false}
                                onChange={(e) => this.handleRadio(e,"restoreLinks", false)}
                                value={false}
                                name="restoreLinks"
                                icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                            />
                        }
                        label={"No"}
                    />
                </div>
            </DialogContent>
            <DialogActions
            className={classes.modalFooter + " " + classes.selectionButtons}
            >
                {
                    loading ?
                        <LoaderComponent color="custom" align="center" />
                    :
                        duplicatesFound && duplicates !== null ?
                            null
                        :
                            <>
                                <Button color="custom" disabled={restoreLinks === null ? true : false} onClick={() => this.onSuccess()} >Restore</Button>
                                <Button color="white" onClick={() => this.props.onClose()}>Cancel</Button>
                            </>
                }
            </DialogActions>        
        </Dialog>
        );
    }
};

RestoreFileModal.defaultProps = {
  open: false,
  deletingUser: false,
  deleteAll: false
};
RestoreFileModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  deleteAll: PropTypes.bool
};
export default withStyles(restoreFileModalStyle)(RestoreFileModal);
