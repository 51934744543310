import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import CustomTabs from "../CustomTabs/CustomTabs.jsx";
import { helper } from "../../../assets/js/utils/Element";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import detailSectionStyle from "../../../assets/jss/user/detailSectionStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DetailsSection = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            activeTab: 0
        }
    }
    getSharedUsersTitle(sharedWith){
        let usersTitle = "";
        sharedWith.map((user, i)  => {
            if(!user.isOwner){
                usersTitle += user.title;
                if (i + 1 < sharedWith.length) {
                    usersTitle +=" and ";
                }
            }

            return null;
        });

        return usersTitle;
    }
    getHoverText(user){
        let privacyText = "view";
        if(user.privacy === 'write'){
            privacyText = "edit";
        }

        let hoverText = user.title+" can "+privacyText;
        if(user.isOwner){
            hoverText = user.title+" is the owner";
        }

        return hoverText;
    }
    getSharedUsersPhoto(user, key){
        let userFirstLetter = user.title.charAt(0);
        return (
            <>
                <span className="user-icon" key={key}>
                    <Tooltip title={this.getHoverText(user)} placement="top-start">
                        {
                            user.icon_photo_url ?
                                <img src={user.icon_photo_url} className="profile-picture" alt={userFirstLetter} />
                            :
                                <p>{userFirstLetter}</p>
                        }
                    </Tooltip>
                </span>
                {
                    user.isOwner ?
                        <span className="border" key={key+"_owner"}></span>
                    :
                        <></>
                }
            </>
        );
    }
    getDetailsContent(){
        const { classes, file, modificationAllowed } = this.props;
        return( 
            <>
                <div className='thumbnail-icon'>
                    {
                        file.type === "folder" ? 
                            <Button justIcon color="transparent" className={classes.fileIcon}>
                                {
                                    file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0 ?
                                        helper.getFolderIcon(true)
                                    :
                                        helper.getFolderIcon()
                                }
                            </Button>
                        :
                            helper.getFileIcon(file, classes, modificationAllowed, true)
                    }
                </div>
                <div className='file-share-detail'>
                    {
                        file.type === "folder" && file.sharedWith.length > 0 ? 
                            <div>
                                <p className='heading'>Who has access</p>
                                <div className='shared-users-icon'>
                                    {
                                        file.sharedWith.map((user, key) => {
                                            return this.getSharedUsersPhoto(user, key)
                                        })
                                    }
                                </div>
                                <p className='owned-by'>Owned by you. Shared with {this.getSharedUsersTitle(file.sharedWith)}</p>
                            </div>
                        :
                            <></>
                    }
                </div>
                <div className='file-detail'>
                    <h4>File details</h4>
                    <p className='file-detail-heading'>Type</p>
                    <p>{file.type}</p>
                    <p className='file-detail-heading'>Size</p>
                    <p>{file.size}</p>
                    <p className='file-detail-heading'>Storage used</p>
                    <p>{file.size}</p>
                    <p className='file-detail-heading'>Location</p>
                    <p>{file.folder_path}</p>
                    <p className='file-detail-heading'>Owner</p>
                    <p>{file.owner}</p>
                    <p className='file-detail-heading'>Modified</p>
                    <p>{file.modified_date}</p>
                    <p className='file-detail-heading'>Created</p>
                    <p>{file.date}</p>
                </div>
            </>
        )
    }
    onTabChange(tab){
        this.setState({
            activeTab: tab
        });
    }
    render() {
        const { classes, file } = this.props;
        const { activeTab } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="deleteFile-slide-title"
                aria-describedby="deleteFile-slide-description"
                className={classes.dialog}
            >
                <div classes={classes.detailsSection}>
                    <DialogTitle
                        disableTypography
                        className={classes.detailsHeader}
                        >
                            <div className='fileName'>{file.name}</div>
                            <Button
                                simple
                                className={classes.detailsCloseButton}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.onClose()}
                            >
                                {" "}
                                <Close className={classes.modalClose} />
                            </Button>
                    </DialogTitle>
                    <DialogContent
                        className={classes.detailBody}
                    >
                        <div className={classes.customTabs}>
                            <CustomTabs
                                tabs={[
                                    { 
                                        tabName: "Details", 
                                        tabContent: (
                                            this.getDetailsContent()
                                        )
                                    }
                                ]}
                                activeTab={activeTab}
                                headerColor={"primary"}
                                onChange={(tab) => this.onTabChange(tab)}
                            />
                        </div>
                    </DialogContent>
                </div>
            </Dialog>
        )
    }
}

DetailsSection.defaultProps = {
    open: false,
}
DetailsSection.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(detailSectionStyle)(DetailsSection);