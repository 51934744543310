import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import AdvancedSearchModal from "./AdvancedSearchModal";

import searchInputStyle from "../../../assets/jss/searchInputStyle";

class SearchInput extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        const { searchFormData } = this.props;

        this.state = {
            searchFormData: searchFormData,
            advancedSearchModal: false
        };
    }
    componentWillReceiveProps(props){
        this.setState({
            searchFormData: props.searchFormData,
        });
    }
    handleChange(e, name){
        this.setState({
            searchFormData: {
                ...this.state.searchFormData,
                search: e.target.value
            }
        });
    }
    onAdvancedSearchModal(status){
        this.setState({
            advancedSearchModal: status,
        });
    }
    onClearSearch(){
        this.onAdvancedSearchModal(false);
        this.props.clearSearch();
    }
    onAdvancedSearchModalSuccess(searchData){
        console.log(searchData);
        this.onAdvancedSearchModal(false);
        this.props.onSuccess(searchData);
    }
    handleSubmit(){
        this.props.onSuccess(this.state.searchFormData);
    }
    onEnterSubmit(e){
        e.preventDefault();
        if(e.nativeEvent.keyCode === 13){
            this.props.onSuccess(this.state.searchFormData);
        }
    }
    render(){
        const { classes, inputProps } = this.props;
        const { searchFormData, advancedSearchModal } = this.state;
        return (
            <div className={classes.searchInput}>
                <CustomInput
                    id="input-search"
                    inputProps={{
                        onChange: (e) => this.handleChange(e,'search'),
                        onKeyUp: (e) => this.onEnterSubmit(e),
                        value: searchFormData.search,
                        name: "search",
                        type: "text",
                        className: searchFormData.search !== '' ? 'input-filled' : '',
                        startAdornment: (
                            <InputAdornment 
                                className={classes.inputAdornment+' search-icon'} 
                                position='start'
                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.0015 8.12579C15.0015 7.22287 14.8236 6.32878 14.4781 5.49459C14.1326 4.66039 13.6261 3.90242 12.9876 3.26396C12.3492 2.62549 11.5912 2.11904 10.757 1.7735C9.9228 1.42797 9.02872 1.25012 8.12579 1.25012C7.22287 1.25012 6.32878 1.42797 5.49459 1.7735C4.66039 2.11904 3.90242 2.62549 3.26396 3.26396C2.62549 3.90242 2.11904 4.66039 1.7735 5.49459C1.42797 6.32878 1.25012 7.22287 1.25012 8.12579C1.25012 9.02872 1.42797 9.9228 1.7735 10.757C2.11904 11.5912 2.62549 12.3492 3.26396 12.9876C3.90242 13.6261 4.66039 14.1326 5.49459 14.4781C6.32878 14.8236 7.22287 15.0015 8.12579 15.0015C9.02872 15.0015 9.9228 14.8236 10.757 14.4781C11.5912 14.1326 12.3492 13.6261 12.9876 12.9876C13.6261 12.3492 14.1326 11.5912 14.4781 10.757C14.8236 9.9228 15.0015 9.02872 15.0015 8.12579ZM13.4115 14.2983C11.9934 15.5171 10.1455 16.2516 8.12579 16.2516C3.63707 16.2516 0 12.6145 0 8.12579C0 3.63707 3.63707 0 8.12579 0C12.6145 0 16.2516 3.63707 16.2516 8.12579C16.2516 10.1455 15.5171 11.9934 14.2983 13.4115L19.8183 18.9354C20.0606 19.1777 20.0606 19.5761 19.8183 19.8183C19.5761 20.0606 19.1777 20.0606 18.9354 19.8183L13.4115 14.2983Z"/>
                                </svg>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment 
                                className={classes.inputAdornment} 
                                position='end'
                            >
                                <span className='advanceSearchIcon' onClick={() => this.onAdvancedSearchModal(true) }>
                                    <svg width="16" height="18" viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_816_12)">
                                    <path d="M0 2.8125C0 2.50312 0.253125 2.25 0.5625 2.25H15.1875C15.4969 2.25 15.75 2.50312 15.75 2.8125C15.75 3.12187 15.4969 3.375 15.1875 3.375H0.5625C0.253125 3.375 0 3.12187 0 2.8125ZM2.25 8.4375C2.25 8.12813 2.50312 7.875 2.8125 7.875H12.9375C13.2469 7.875 13.5 8.12813 13.5 8.4375C13.5 8.74687 13.2469 9 12.9375 9H2.8125C2.50312 9 2.25 8.74687 2.25 8.4375ZM10.125 14.0625C10.125 14.3719 9.87187 14.625 9.5625 14.625H6.1875C5.87813 14.625 5.625 14.3719 5.625 14.0625C5.625 13.7531 5.87813 13.5 6.1875 13.5H9.5625C9.87187 13.5 10.125 13.7531 10.125 14.0625Z"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_816_12">
                                    <rect width="15.75" height="18"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                </span>
                                {
                                    searchFormData.search !== '' ?
                                        <span className='clear-icon' onClick={() => this.onClearSearch()}>
                                            <svg width="14" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.4082 14.4527C11.6262 14.6707 11.9848 14.6707 12.2027 14.4527C12.4207 14.2348 12.4207 13.8762 12.2027 13.6582L7.54453 9L12.2027 4.3418C12.4207 4.12383 12.4207 3.76523 12.2027 3.54727C11.9848 3.3293 11.6262 3.3293 11.4082 3.54727L6.75 8.20547L2.09531 3.54727C1.87734 3.3293 1.51875 3.3293 1.30078 3.54727C1.08281 3.76523 1.08281 4.12383 1.30078 4.3418L5.95547 9L1.29727 13.6582C1.0793 13.8762 1.0793 14.2348 1.29727 14.4527C1.51523 14.6707 1.87383 14.6707 2.0918 14.4527L6.75 9.79453L11.4082 14.4527Z"/>
                                            </svg>
                                        </span>
                                    :
                                        <></>
                                }
                            </InputAdornment>
                        ),
                        ...inputProps
                    }}
                    color="custom"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses+" search-input"
                    }}
                />
                {/* <IconButton
                    color="inherit"
                    aria-label="search"
                    onClick={() => this.handleSubmit()}
                    className={classes.searchIcon}
                    title="Advanced Search"
                    disabled={searchFormData.search ? false : true}
                >
                    <SearchIcon />
                </IconButton> */}
                {
                    advancedSearchModal === true ?
                        <AdvancedSearchModal store={this.store} searchFormData={this.props.searchFormData} open={advancedSearchModal} onClose={() => this.onAdvancedSearchModal(false)} onSuccess={(searchData) => this.onAdvancedSearchModalSuccess(searchData)} clearSearch={() => this.onClearSearch()} history={this.history}  />
                    :
                        <></>
                }
            </div>
        )
    }
}

SearchInput.defaultProps = {
    align: "center",
    color: "primary",
    inputProps: {}
}
SearchInput.propTypes = {
    classes: PropTypes.object,
    inputProps: PropTypes.object
};

export default (withStyles(searchInputStyle)(SearchInput));