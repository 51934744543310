import { grayColor } from "./material-kit-pro-react";

const termsPageStyle = theme => ({
    panelContent: {
        paddingLeft: "5em",
        paddingRight: "5em",
        paddingTop: "5.5em",
        paddingBottom: "4em",
        [theme.breakpoints.down("md")]: {
            paddingLeft: "3em",
            paddingRight: "3em"
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "2em",
            paddingRight: "2em",
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1em",
            paddingRight: "1em"
        },
        "& h1": {
            fontSize: "2.5em",
            marginTop: "0px",
            marginBottom: "0.25em",
            fontWeight: "500",
            [theme.breakpoints.down("sm")]: {
                fontSize: "2em",
            },
        },
        "& p": {
            fontSize: "1em",
            lineHeight: "1.3125em",
            marginTop: "0",
            marginBottom: "1em",
            display: "inline-block",
            verticalAlign: "top",
        },
        "& ul, & ol": {
            fontSize: "0.875em",
            lineHeight: "1.5714285714em",
            margin: "0 0 2em",
            padding: "0 2.8571428571em 0 2em",
            "& li": {
                paddingLeft: "0.5714285714em",
                paddingRight: "0.5714285714em",
            },
            [theme.breakpoints.down("sm")]: {
                paddingRight: "0px",
                "& li": {
                    paddingRight: "0px"
                }
            },
        },
        "& .panel-subtitle": {
            marginBottom: "1.875em",
            display: "block",
            fontSize: "0.875em",
            "& >span:not(:last-child)::after": {
                content: '"\\00B7"',
                display: "inline-block",
                padding: "0 0.625em",
            }
        },
        "& h3": {
            fontSize: "1.375em",
            lineHeight: "1.2727272727em",
            marginBottom: "0.3636363636em",
            [theme.breakpoints.down("sm")]: {
                fontSize: "1em",
            },
        },
        "& a": {
            color: grayColor[1],
            textDecoration: "underline"
        },
    },
    legalTermsService: {
        paddingLeft: "1em",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "0.5em",
        },
        "& p": {
            width: "calc(100% - 2em)",
        },
        "& ol": {
            counterReset: "counter",
            fontSize: "1.375em",
            lineHeight: "1.2727272727em",
            margin: "0 0 0.3636363636em",
            padding: "0",
            "&:nth-of-type(1)": {
                counterReset: "counter 0",
            },
            "& ul": {
                listStyleType: "disc",
                paddingLeft: "3.75em !important",
            },
            "& h3": {
                display: "inline-block",
            },
            "&>li": {
                listStyle: "none",
                margin: "0.45em 0",
                padding: "0 !important",
                position: "relative",
                "&::before": {
                    content: 'counter(counter) ". "',
                    counterIncrement: "counter",
                },
                "& ol>li": {
                    fontSize: "0.5333333333em",
                    "& p": {
                        paddingLeft: "1.25em",
                        [theme.breakpoints.down("sm")]: {
                            paddingLeft: "0.5em",
                        },
                    },
                    "&::before": {
                        content: 'counters(counter, ".") " "',
                        counterIncrement: "counter",
                        fontSize: "0.875em",
                        position: "relative",
                        top: "-0.6428571429em",
                    }
                },
            },
        },
    },
    textLeft: {
        textAlign: "left"
    },
});

export default termsPageStyle;
