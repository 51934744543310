import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "../CustomInput/CustomInput.jsx";
import LoaderComponent from "../../components/Loader";
import deleteFileModalStyle from "../../../assets/jss/user/deleteFileModalStyle";
import Close from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DeleteFileModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            deleteText: ''
        }
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    getFile(file, key){
        return (
            <li key={key}>
                <p>{file.name} ({file.type})</p>
            </li>
        );
    }
  render() {
    const { classes, deletedFiles, confirm, loading, errorMessage } = this.props;
    const { deleteText } = this.state;
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="deleteFile-slide-title"
        aria-describedby="deleteFile-slide-description"
      >
        <DialogTitle
            id="attachments-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            >
                <Button
                    simple
                    className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.props.onClose()}
                >
                    {" "}
                    <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>
                    {
                        deletedFiles && deletedFiles.length > 1 ?
                            <>Delete {deletedFiles.length} Items?</>
                        :
                            <>Delete Item?</>
                    }
                </h4> 
        </DialogTitle>
        <DialogContent
          id="deleteFile-slide-description"
          className={classes.modalBody}
        >
            { 
                this.props.deleteNote ?
                    <>
                        <p className={"delete-note"}>Are you sure you want to delete?</p>
                        <p className={classes.danger}>{errorMessage}</p>
                    </>
                :
                    <></>
            }
            {
                deletedFiles && deletedFiles.length > 0 ?
                    <div className="items-lists">
                        <p className="lists-heading">Items</p>
                        <ul>
                            {
                                deletedFiles.map((file, key) => {
                                    return this.getFile(file, key)
                                })
                            }
                        </ul>
                    </div>
                :
                    <></>
            }
            { 
                this.props.deleteNote ?
                    <p className={"delete-note delete-note-1"}>Please note that all associated link(s) will also be deleted.</p>
                :
                    <></>
            }
            {
                confirm && deletedFiles.length > 5 ?
                    <CustomInput
                        id="input-deleteText"
                        labelText="Type delete for confirm"
                        inputProps={{
                            onChange: (e) => this.handleChange(e,'deleteText'),
                            value: deleteText,
                            name: "deleteText",
                            type: "text",
                        }}
                        color="custom"
                        formControlProps={{
                            fullWidth: true,
                            className: "custom-input"
                        }}
                    />
                :
                    <></>
            }
        </DialogContent>
        <DialogActions
          className={classes.modalFooter}
        >
          <Button color="custom" disabled={(loading || (confirm && deletedFiles.length > 5 && deleteText.toLowerCase() !== 'delete' ? true : false)) } onClick={() => this.props.onSuccess()}>
            {
                loading ?
                    <LoaderComponent size={17} color="white" align="center" saving={true} />
                :
                    "Delete"
            }
          </Button>
          <Button color="white" disabled={loading} onClick={() => this.props.onClose()}>Cancel</Button>
        </DialogActions>        
      </Dialog>
    );
  }
};

DeleteFileModal.defaultProps = {
  open: false,
  deletingUser: false,
  deleteAll: false,
  confirm: false,
  loading: false,
  errorMessage: null
};
DeleteFileModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  deleteAll: PropTypes.bool,
  confirm: PropTypes.bool,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string
};
export default withStyles(deleteFileModalStyle)(DeleteFileModal);
