import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader";
import CustomInput from "../CustomInput/CustomInput.jsx";

import confirmationModalStyle from "../../../assets/jss/user/confirmationModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const TwoFaVerificationModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            code: '',
            isValid: true
        }
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        if(state[name].length > 3){
            state['isValid'] = true;
        }
        if(state[name].length > 6){
            state[name] = this.state[name];
        }
        this.setState(state);
    }
    onSuccess(){
        const { code } = this.state;
        if(code.length <= 3){
            this.setState({isValid: false});
            return;
        }
        this.props.onSuccess(code);
    }
    render() {
        const { classes, saving, errorMessage, success } = this.props;
        const { code, isValid } = this.state;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        {
                            saving || success ?
                                <></>
                            :
                            <h4 className={classes.modalTitle+" "+classes.textCenter}>Please enter verification code you received on your cell number.</h4> 
                        }
                </DialogTitle>
                <DialogContent
                    id="confirmationModal-modal-slide-description"
                    className={classes.modalBody}
                    
                >
                    {
                        success ?
                            <p className={classes.success}>You have successfully enable two factor authentication.</p>
                        :
                        saving ?
                            <LoaderComponent color="custom" align="center" />
                        :
                        <>
                            <p className={classes.danger}>{errorMessage}</p>
                            <CustomInput
                                id="input-code"
                                labelText="Type verification code"
                                error={!isValid}
                                inputProps={{
                                    onChange: (e) => this.handleChange(e,'code'),
                                    value: code,
                                    name: "code",
                                    type: "number",
                                }}
                                color="custom"
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.customFormControlClasses
                                }}
                            />
                            <p className={classes.anchor} onClick={() => this.props.onResendCode()}>Resend Code?</p>
                        </>
                    }
                </DialogContent>
                {
                    success ?
                        <DialogActions className={classes.modalFooter +" " +classes.justifyCenter}>
                            <Button color="custom" onClick={() => this.props.onClose()}>
                                Close
                            </Button>
                        </DialogActions>
                    :
                    saving === false ?
                        <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                            <Button color="white" onClick={() => this.props.onClose()}>
                                Cancel
                            </Button>
                            <Button color="custom" className={classes.rightButton} onClick={() => this.onSuccess(code)} >
                                Verify
                            </Button>
                        </DialogActions>
                    :
                    <></>
                }
            </Dialog>
        )
    }
}

TwoFaVerificationModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false
}
TwoFaVerificationModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool,
    onResendCode: PropTypes.func
}
export default withStyles(confirmationModalStyle)(TwoFaVerificationModal);