import {
    dangerColor,
    tableHeadColumns,
} from "../material-kit-pro-react.jsx";
import userHomePageStyle from "./userHomePageStyle.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
const activitiesModalStyle = theme => ({
    ...userHomePageStyle(theme),
    ...modalStyle(theme),
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "1050px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "25px",
        paddingBottom: "20px",
        textAlign: "left",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    errorMessage: {
        color: dangerColor[0]
    },
    csvFileIcon: {
        fontSize: "12px",
    },
    capitalize: {
        textTransform: "capitalize"
    },
    usersTable: {
        ...tableHeadColumns(theme),
        ...userHomePageStyle(theme).filesTable,
        background: 'transparent',
        "& .rt-tbody .rt-td": {
            padding: "0px 5px",
            margin: "5px 0px",
            display: "flex",
            alignItems: "center",
            "&.td_name": {
                margin: "0px",
                padding: "0px",
                "& .react-contextmenu-wrapper": {
                    padding: "5px 5px",
                    margin: "0px",
                }
            }
        },
        "& .rt-tbody": {
            overflow: "unset",
            minHeight: "auto !important",
            marginBottom: "10px",
            "& .rt-tr-group": {
                flexGrow: "0",
            },
            "& .rt-tr": {
                padding: "3px 0px",
            },
            "& .rt-td": {
                textAlign: "left !important",
            },
            "& .td_action p": {
                textOverflow: "ellipsis",
                width: "100%",
                overflow: "hidden",
                whiteSpace: 'nowrap',
                display: 'block !important',
                fontSize: '12px',
                marginBottom: '0px',
            },
            "& .td_name p": {
                textOverflow: "ellipsis",
                width: "100%",
                overflow: "hidden",
                whiteSpace: 'nowrap',
                display: 'block !important',
                fontSize: '12px',
                marginBottom: '0px',
            }
        },
        "& .hd_date_time": {
            width: '5% !important',
        },
        "& .hd_action": {
            width: "30% !important",
            "& button": {
                width: "auto !important",
            }
        },
        "& .hd_name": {
            width: "10% !Important"
        },
        "& .rt-resizer": {
            display: "none !important"
        },
        "& .rt-resizable-header-content:after": {
            display: "none !important"
        }
    },
    UserRecentActivitySection: {
        paddingLeft: '0px',
        paddingTop: '10px',
        "&>p": {
            color: '#659DFA',
            marginBottom: '0px',
            marginLeft: '5px',
        },
        "& .csv-file-icon": {
            textAlign: "right",
            padding: "10px 0px",
            "& img": {
                maxWidth: "24px"
            }
        }
    },
});
  
export default activitiesModalStyle;
  