import React from 'react';
import { Redirect } from 'react-router-dom';
import Cookie from '../../../assets/js/utils/Cookie';
import axios from "axios";
import { helper } from '../../../assets/js/utils/Element';
import Config from "../../../../Config";
import Api from "../../../assets/js/utils/Api";
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import LoaderComponent from '../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
// import InfoArea from "../../components/InfoArea/InfoArea.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Warning from "../../components/Typography/Warning.jsx";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import DatePicker from "../../components/CustomInput/DatePicker";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Link from '@material-ui/core/Link';
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// @material-ui/icons
// import Timeline from "@material-ui/icons/Timeline";
// import Code from "@material-ui/icons/Code";
// import Group from "@material-ui/icons/Group";
import Email from "@material-ui/icons/Email";
import AuthIcon from "@material-ui/icons/Lock";
import Icon from "@material-ui/core/Icon";
import EditIcon from "@material-ui/icons/Edit";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import CalenderIcon from "@material-ui/icons/DateRange";
import RecaptchaV2 from "../../components/Google/RecaptchaV2";
import Danger from "../../components/Typography/Danger";
import Success from "../../components/Typography/Success";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import signupPageStyle from "../../../assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";

const Signup = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resendCode = this.resendCode.bind(this);
        this.confirmSignUp = this.confirmSignUp.bind(this);
        this.applyPromoCode = this.applyPromoCode.bind(this);

        let havePlanId = true;
        let planId = helper.getParam("id");
        if(planId === null || planId === "null"){
            havePlanId = false;
            planId = "";
        }

        let selectAccountType = true;
        let userAccountType = 'personal';
        if(planId === '4'){
            userAccountType = 'business';
            this.history.push("/signup/business");
        }else if(havePlanId === true){
            selectAccountType = false;
        }

        let signupCode = null;
        let inviteCode = null;
        let email = "";
        if(this.props.match.params && this.props.match.params.signupCode){
            signupCode = this.props.match.params.signupCode;
            if(this.props.match.params.email){
                email = this.props.match.params.email;
            }
            if(this.props.match.params.planId){
                planId = this.props.match.params.planId;
                selectAccountType = false
            }
        }
        if(this.props.match && this.props.match.match && this.props.match.match.params && this.props.match.match.params.inviteCode){
            inviteCode = this.props.match.match.params.inviteCode;
        }

        this.state = {
            firstName: '',
            lastName: '',
            email: email,
            password: "",
            passwordConfirm: "",
            accountName: "",
            phone: "",
            cell: "",
            address1: "",
            address2: "",
            address3: "",
            accountType: planId,
            code: "",
            countryId: "",
            stateId: "",
            cityId: "",
            dob: "",
            timezone: "",
            promoCode: "",
            loading: false,
            terms: true,
            promotions: true,
            validation: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                passwordConfirm: '',
                accountName: '',
                code: '',
                countryId: '',
                stateId: '',
                timezone: '',
                businessName: '',
                phone: '',
                accountType: '',
                promoCode: '',
                isValid: false,
                isValidate: false,
                validateForm: false
            },
            recaptchaToken: "",
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            requestCode: false,
            showError: false,
            errorMessage: "",
            user: null,
            advancedFields: false,
            countries: [],
            states: [],
            cities: [],
            loadingCountries: false,
            loadingStates: false,
            loadingCities: false,
            showRecaptcha: true,
            userAccountType:  userAccountType,
            selectAccountType: selectAccountType,
            businessName: '',
            isPromoCodeValid: null,
            validatingPromoCode: false,
            signupCode: signupCode,
            inviteCode: inviteCode,
            viewPassword: false,
            viewConfirmPassword: false,
            loadingAccountTypes: false,
            accountTypes: []
        };

        this.timezoneOptions = Api.getTimezoneOptions();
    }
    componentDidMount(){
        this.loadSignupControls();
        this.loadCountries();
        
        if(this.state.accountType !== ""){
            this.canSignup();
        }
    }
    componentWillUnmount(){
        if (this.cancelToken) {
            this.cancelToken.cancel('Request Cancelled')
        }
        if(this.cancelControlsToken){
            this.cancelControlsToken.cancel('Request Cancelled')
        }
    }
    loadSignupControls() {
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelControlsToken = source;

        this.setState({
            loadingAccountTypes: true,
        });
    
        const requestData = {
            limit: 200,
        };
        
        Api.getSignupControls(requestData, source).then(data => {
            that.prepareTypesArray(data.response);
        }).catch(err => {
            console.log(err);
        });
    }
    prepareTypesArray(signupControls){
        let plans = Api.getPlans();

        const filteredAccountTypes = plans.filter(plan =>
            signupControls.some(control => control.account_type === plan.id)
        );

        this.setState({
            loadingAccountTypes: false,
            accountTypes: filteredAccountTypes,
        })
    }
    canSignup(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
        });

        let requestData = {
            accountType: this.state.accountType,
            signupCode: this.state.signupCode,
            email: this.state.email,
            inviteCode: this.state.inviteCode
        }
        Api.canSignup(requestData, source).then(data => {
            if(data.canSignup === false){
                this.setState({
                    loading: false, 
                });

                window.location.href = data.path;
                return;
            }

            that.setState({
                loading: false,
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message,
                    advancedFields: false
                });
            }
        });

        this.setState({loading: false, showError: false, cancelToken: source});
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        let limit = 64;
        if(name === "email"){
            limit = 128;
        }
        if(typeof(state[name]) === "string"){
            state[name] = state[name].substr(0, limit);
        }
        if(name === "email"){
            state[name] = state[name].toLowerCase();
        }
        if(name === "accountType"){
            state['promoCode'] = '';
            state['isPromoCodeValid'] = '';
            state['validation'] = {
                ...this.state.validation,
                promoCode: ""
            };
        }
        this.setState(state,() => {
            if(name === 'password' || name === 'passwordConfirm'){
                this.validatePassword();
            }
        });
    }
    onPromoCodeChange(e){
        const code = e.target.value;
        this.setState({
            promoCode: code,
            validation: {
                ...this.state.validation,
                promoCode: ""
            },
            isPromoCodeValid: null
        });
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateAdvForm();
        if(!isValid || this.state.loading){
            return;
        }
        let values = this.state;
        const { recaptchaToken, promoCode } = this.state;
        const requestData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            accountName: values.accountName,
            cell: values.cell,
            phone: values.phone,
            address1: values.address1,
            address2: values.address2,
            address3: values.address3,
            accountType: values.accountType,
            countryId: values.countryId,
            stateId: values.stateId,
            cityId: values.cityId,
            dob: values.dob,
            timezone: values.timezone,
            recaptchaToken: recaptchaToken,
            terms: values.terms,
            promotions: values.promotions,
            userType: values.userAccountType,
            promoCode: promoCode,
            signupControl: 1
        };
        if(values.signupCode){
            requestData['signupCode'] = values.signupCode;
        }
        if(values.inviteCode){
            requestData['inviteCode'] = values.inviteCode;
        }
        const source = axios.CancelToken.source();
        this.setState({loading: true, showError: false, cancelToken: source});
        
        Api.signUp(requestData, source).then(data => {
            if(data.canSignup === false){
                this.setState({
                    loading: false, 
                });

                window.location.href = data.path;
                return;
            }

            this.setState({
                loading: false, 
                requestCode: true,
                user: data.user
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message,
                    advancedFields: false
                });
            }
        });
    }
    validatePassword(){
        if(this.state.validation.validateForm){
            this.validateForm();
            return;
        }
        let validation = {
            password: "success",
            passwordConfirm: "success",
            isValid: true
        };

        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
                showPasswordErrors: true,
                requireLowerletter: requireLowerletter,
                requireUpperletter: requireUpperletter,
                requireNumber: requireNumber,
                requireSymbol: requireSymbol,
                requireLength: requireLength
            });
        });

        if(this.state.password.length <= 0 || check === false){
            validation.password = "error";
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            validation.passwordConfirm = "error";
            validation.isValid = false;
        }
        validation.isValidate = true;

        this.setState({
            validation: validation
        });
    }
    validateForm(){
        const { userAccountType } = this.state;
        let validation = {
            firstName: "success",
            lastName: "success",
            email: "success",
            password: "success",
            passwordConfirm: "success",
            timezone: "success",
            isValid: true
        };

        if(userAccountType === "personal"){
            if(this.state.firstName.trim().length <= 0){
                validation.firstName = "error";
                validation.isValid = false;
            }
            if(this.state.lastName.trim().length <= 0){
                validation.lastName = "error";
                validation.isValid = false;
            }
        }else{
            validation['firstName'] = "";
            validation['lastName'] = "";
        }
        var emailRegex = Config.getEmailRegex();
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        
        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(this.state.password.length <= 0 || check === false){
            validation.password = "error";
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            validation.passwordConfirm = "error";
            validation.isValid = false;
        }
        if(this.state.timezone.length <= 2){
            validation.timezone = "error";
            validation.isValid = false;
        }
        validation.isValidate = true;
        validation.validateForm = true;

        this.setState({validation: validation});
        return validation.isValid;
    }
    validateAdvForm(){
        let validation = this.state.validation;
        validation.countryId = 'success';
        validation.stateId = 'success';
        validation.accountName = "success";
        validation.accountType = "success";
        validation.promoCode = "success";
        validation.isValid = true;
        const { accountName, countryId, stateId, accountType, promoCode, recaptchaToken, isPromoCodeValid, signupCode, terms } = this.state;
        if(accountName.trim().length <= 0){
            validation.accountName = "error";
            validation.isValid = false;
        }
        if(countryId.length <= 2){
            validation.countryId = "error";
            validation.isValid = false;
        }
        if(this.state.states.length > 0 && stateId.length <= 0){
            validation.stateId = "error";
            validation.isValid = false;
        }
        if(accountType.length <= 0){
            validation.accountType = "error";
            validation.isValid = false;
        }

        if(recaptchaToken.length <= 0){
            validation.isValid = false;
        }
        if(signupCode === null && accountType === 1 && (promoCode.length <= 0 || !isPromoCodeValid)){
            validation.promoCode = "error";
            validation.isValid = false;
        }
        if(!terms){
            validation.isValid = false;
        }
        validation.isValidate = true;

        this.setState({validation: validation});
        return validation.isValid;
    }
    resendCode(event){
        event.preventDefault();
        event.stopPropagation();
        const requestData = {
            email: this.state.email,
        };
        const source = axios.CancelToken.source();
        Api.resendCode(requestData, source).then(data => {
            this.setState({
                loading: false, 
            });
        }).catch(err => {
            
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    confirmSignUp(event){
        event.preventDefault();
        event.stopPropagation();
        if (this.state.code.length <= 0) {
            this.setState({
                ...this.state,
                validation: {
                    ...this.state.validation,
                    code: "error"
                }
            });
            return;
        }
        const source = axios.CancelToken.source();
        const code = this.state.code;
        this.setState({loading: true, showError: false, cancelToken: source});
        const requestData = {
            code: code,
            email: this.state.email,
            cancelToken: source
        };
        Api.confirmSignUp(requestData, source).then(data => {
            Cookie.write("oauth_token", data.oauth_token);
            Cookie.write("oauth_secret", data.oauth_secret);
            Cookie.write("expires_at", data.expires_at);
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
            if(data.hasOwnProperty("checkout_url")){
                window.location.href = data.checkout_url;
                return;
            }
            this.history.push(helper.getHomePageUrl(data.user));
            this.store.dispatch({
                type: "LOGIN",
                state: {
                    authorized: true,
                    user: data.user
                }
            }); 
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });;
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    showAdvancedFields(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({advancedFields: true});
    }
    hideAdvancedFields(){
        this.setState({advancedFields: false});
    }
    showFields(selectType = false){
        const { userAccountType, inviteCode } = this.state;
        if(userAccountType === "business"){
            let url = "/signup/business";
            if(inviteCode !== null){
                url = "/signup/business/"+inviteCode;
            }
            this.history.push(url);
            return;
        }
        this.setState({advancedFields: false, selectAccountType: selectType});
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            that.setState({
                countries: data.response,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onCountryChange(e){
        const country = e.target.value;
        this.loadStates(country);
    }
    loadStates(country){
        const { countryId } = this.state;
        if(countryId === country){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingStates: true,
            countryId: country,
            states: [],
            stateId: "",
            cities: [],
            cityId: ""
        });
        Api.getStates(country, source).then(data => {
            that.setState({
                states: data.response,
                loadingStates: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onStateChange(e){
        const state = e.target.value;
        this.loadCities(state);
    }
    loadCities(state){
        const { stateId } = this.state;
        if(stateId === state){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        const { countryId } = this.state;
        that.setState({
            cancelToken: source,
            loadingCities: true,
            stateId: state,
            cities: [],
            cityId: ""
        });
        Api.getCities(countryId, state, source).then(data => {
            that.setState({
                cities: data.response,
                loadingCities: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onRecaptcha(token){
        this.setState({
            showRecaptcha: false,
            recaptchaToken: token
        });
    }
    handleUserAccountType(name, value){
        this.setState({
            [name]: value
        });
    }
    renderCountries(){
        const { classes } = this.props;
        const { validation } = this.state;
        const { countries, states, cities, loadingCountries, loadingStates, loadingCities } = this.state;
        return (
            <>
                {
                    countries.length > 0 ?
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="Country"
                            success={validation.countryId === "success"}
                            error={validation.countryId === "error"}
                            selectProps={{
                                onChange: (e) => this.onCountryChange(e),
                                value: this.state.countryId
                            }}
                            inputProps={{
                                name: "countryId",
                                id: "input-countryId",
                                className: classes.alignLeft
                            }}
                            items={countries}
                            itemLabel="name"
                            itemValue="id"
                        />
                    :
                        loadingCountries ?
                            <LoaderComponent align="left" />
                        :
                        <></>
                }
                {
                    states.length > 0 ?
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="State / Province"
                            success={validation.stateId === "success"}
                            error={validation.stateId === "error"}
                            selectProps={{
                                onChange: (e) => this.onStateChange(e),
                                value: this.state.stateId
                            }}
                            inputProps={{
                                name: "stateId",
                                id: "input-stateId",
                                className: classes.alignLeft
                            }}
                            items={states}
                            itemLabel="name"
                            itemValue="id"
                        />
                    :
                        loadingStates ?
                            <LoaderComponent align="left" />
                        :
                        <></>
                }
                {
                    cities.length > 0 ?
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="City"
                            selectProps={{
                                onChange: (e) => this.handleChange(e, "cityId"),
                                value: this.state.cityId
                            }}
                            inputProps={{
                                name: "cityId",
                                id: "input-cityId",
                                className: classes.alignLeft
                            }}
                            items={cities}
                            itemLabel="name"
                            itemValue="id"
                        />
                    :
                        loadingCities ?
                            <LoaderComponent align="left" />
                        :
                        <></>
                }
            </>
        )
    }
    applyPromoCode(){
        const source = axios.CancelToken.source();
        const { promoCode } = this.state;
        this.setState({
            cancelToken: source,
            validatingPromoCode: (promoCode.length > 0 ? true : false),
            isPromoCodeValid: (promoCode.length > 0 ? null : false)
        });
        if(promoCode.length <= 0){
            return;
        }
        const requestData = {
            code: promoCode
        };
        Api.checkPromoCode(requestData, source).then(() => {
            this.setState({
                cancelToken: null,
                validatingPromoCode: false,
                isPromoCodeValid: true,
            });
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    cancelToken: null,
                    validatingPromoCode: false,
                    isPromoCodeValid: false,
                });
            }
        });
    }
    onViewPassword(name = 'viewPassword'){
        let state = {};
        if(name  === 'viewPassword'){
            state['viewPassword'] = !this.state.viewPassword;
        }else if(name === 'viewConfirmPassword'){
            state['viewConfirmPassword'] = !this.state.viewConfirmPassword;
        }
        this.setState(state);
    }
    renderPersonalSignup(){
        const { classes } = this.props;
        const { validation, advancedFields } = this.state;
        const { requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength, password, passwordConfirm, 
            accountName, showRecaptcha, validatingPromoCode, isPromoCodeValid, viewPassword, viewConfirmPassword } = this.state;
        const showPasswordErrors = (validation.password === "success" || validation.password === "error");
        const showPasswordConfirmErrors = (validation.passwordConfirm === "success" || validation.passwordConfirm === "error");
        const requirePasswordConfirm = (passwordConfirm.length > 0 && password === passwordConfirm);
        if(!advancedFields){
            return (
                <>
                    <CustomInput
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                        }}
                        success={validation.firstName === "success"}
                        error={validation.firstName === "error"}
                        id="input-firstName"
                        inputProps={{
                            onChange: (e) => this.handleChange(e, 'firstName'),
                            name: "firstName",
                            value: this.state.firstName,
                            startAdornment: (
                                <InputAdornment position="start" className={classes.inputAdornment} >
                                    <EditIcon className={classes.inputAdornmentIcon}/>
                                </InputAdornment>
                            ),
                            placeholder:"First Name"
                        }}
                    />
                    <CustomInput
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                        }}
                        success={validation.lastName === "success"}
                        error={validation.lastName === "error"}
                        id="input-lastName"
                        inputProps={{
                            onChange: (e) => this.handleChange(e, 'lastName'),
                            name: "lastName",
                            value: this.state.lastName,
                            startAdornment: (
                                <InputAdornment position="start" className={classes.inputAdornment} >
                                    <EditIcon className={classes.inputAdornmentIcon}/>
                                </InputAdornment>
                            ),
                            placeholder:"Last Name"
                        }}
                    />
                    <CustomInput
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                        }}
                        success={validation.email === "success"}
                        error={validation.email === "error"}
                        id="input-email"
                        inputProps={{
                            onChange: (e) => this.handleChange(e, 'email'),
                            name: "email",
                            value: this.state.email,
                            startAdornment: (
                                <InputAdornment position="start" className={classes.inputAdornment} >
                                    <Email className={classes.inputAdornmentIcon}/>
                                </InputAdornment>
                            ),
                            placeholder:"Email",
                            type: "email"
                        }}
                    />
                    <p className={classes.verificationNote}>A verification code will be sent to this email to complete registration.</p>
                    <CustomInput
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                        }}
                        success={validation.password === "success"}
                        error={validation.password === "error"}
                        id="input-password" 
                        passwordInput={viewPassword ? false : true}                                       
                        inputProps={{
                            onChange: (e) => this.handleChange(e,'password'),
                            name: "password",
                            value: password,
                            type: "text",
                            autoComplete: 'off',
                            startAdornment: (
                                <InputAdornment position="start" className={classes.inputAdornment}>
                                    <Icon className={classes.inputAdornmentIcon}>lock_outline </Icon>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment 
                                    position="end" 
                                    className={classes.inputAdornment+' viewPasswordIcon'} 
                                    onClick={() => this.onViewPassword('viewPassword') }
                                >
                                    {
                                        password ?
                                            viewPassword ?
                                                <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                            :
                                                <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                        :
                                            <></>
                                    }
                                </InputAdornment>
                            ),
                            placeholder:"Password"
                        }}
                    />
                    {
                        showPasswordErrors || password.length > 0 ?
                            <GridItem>
                                <div>
                                    <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                        <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                        <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                    </div>
                                    <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                        <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                        <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                    </div>
                                    <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                        <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                        <span className="checkPasswordText-symbols">Password must contain a number</span>
                                    </div>
                                    <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                        <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                        <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                    </div>
                                    <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                        <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                        <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                    </div>
                                </div>
                            </GridItem>
                        :
                        <></>
                    }
                    <CustomInput
                        success={validation.passwordConfirm === "success"}
                        error={validation.passwordConfirm === "error"}
                        id="input-signupPasswordConfirm"
                        passwordInput={viewConfirmPassword ? false : true}
                        inputProps={{
                            required: true,
                            onChange: (e) => this.handleChange(e,'passwordConfirm'),
                            name: "passwordConfirm",
                            type: "text",
                            value: passwordConfirm,
                            placeholder:"Type Password Again",
                            autoComplete: 'off',
                            startAdornment: (
                                <InputAdornment position="start" className={classes.inputAdornment}>
                                    <Icon className={classes.inputAdornmentIcon}>lock_outline </Icon>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment 
                                    position="end" 
                                    className={classes.inputAdornment+' viewPasswordIcon'} 
                                    onClick={() => this.onViewPassword('viewConfirmPassword') }
                                >
                                    {
                                        passwordConfirm ?
                                            viewConfirmPassword ?
                                                <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                            :
                                                <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                        :
                                            <></>
                                    }
                                </InputAdornment>
                            ),
                        }}                                    
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                        }}
                    />
                    {
                        showPasswordConfirmErrors ?
                            <GridItem>
                                <div>
                                    <div className={(requirePasswordConfirm?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                        <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requirePasswordConfirm? '\u2713' : '\u2716' }</span>
                                        <span className="checkPasswordText-lowerletter">Password do not match</span>
                                    </div>
                                </div>
                            </GridItem>
                        :
                        null
                    }
                    <CustomSelect 
                        formControlProps={{
                            fullWidth: true
                        }}
                        labelText="Timezone"
                        selectProps={{
                            onChange: (e) => this.handleChange(e,"timezone"),
                            value: this.state.timezone
                        }}
                        inputProps={{
                            name: "timezone",
                            id: "input-timezone",
                            className: classes.alignLeft
                        }}
                        success={validation.timezone === "success"}
                        error={validation.timezone === "error"}
                        items={this.timezoneOptions}
                        itemLabel="value"
                        itemValue="key"
                    />
                </>
            )
        }

        return (
            <>
                <CustomInput
                    success={validation.accountName === "success"}
                    error={validation.accountName === "error"}
                    id="input-accountName"
                    inputProps={{
                        required: true,
                        onChange: (e) => this.handleChange(e,'accountName'),
                        name: "accountName",
                        value: accountName,
                        placeholder:"Friendly Account Name",
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                    }}                                    
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                />
                <DatePicker
                    labelText={null}
                    id="input-dob"
                    value={this.state.dob}
                    disableFuture={true}
                    openTo="year"
                    views={["year", "month", "date"]}
                    onChange={(date) => this.handleDateChange(date, 'dob')}
                    inputProps={{
                        placeholder:"Date of Birth",
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <CalenderIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                    }}
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                />
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    id="input-phone"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'phone'),
                        name: "phone",
                        value: this.state.phone,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Phone"
                    }}
                />
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    id="input-cell"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'cell'),
                        name: "cell",
                        value: this.state.cell,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Cell"
                    }}
                />
                
                {this.renderCountries()}
                
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    id="input-address1"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'address1'),
                        name: "address1",
                        value: this.state.address1,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Address1"
                    }}
                />
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    id="input-address2"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'address2'),
                        name: "address2",
                        value: this.state.address2,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Address2"
                    }}
                />
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    id="input-address3"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'address3'),
                        name: "address3",
                        value: this.state.address3,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Address3"
                    }}
                />
                <CustomSelect 
                    formControlProps={{
                        fullWidth: true
                    }}
                    success={validation.accountType === "success"}
                    error={validation.accountType === "error"}
                    labelText="Account Type"
                    selectProps={{
                        onChange: (e) => this.handleChange(e,"accountType"),
                        value: this.state.accountType
                    }}
                    inputProps={{
                        name: "accountType",
                        id: "input-accountType",
                        className: classes.alignLeft
                    }}
                    items={this.state.accountTypes}
                    itemLabel="label"
                    itemValue="id"
                />
                {
                    this.state.accountType === 1 && this.state.signupCode === null ?
                        <>
                            <CustomInput
                                success={validation.promoCode === "success"}
                                error={validation.promoCode === "error"}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.customFormControlClasses
                                }}
                                id="input-promoCode"
                                inputProps={{
                                    onChange: (e) => this.onPromoCodeChange(e, 'promoCode'),
                                    name: "promoCode",
                                    value: this.state.promoCode,
                                    startAdornment: (
                                        <InputAdornment position="start" className={classes.inputAdornment} >
                                            <EditIcon className={classes.inputAdornmentIcon}/>
                                        </InputAdornment>
                                    ),
                                    placeholder:"Promo Code"
                                }}
                            />
                            <div>
                                {
                                    validatingPromoCode ?
                                        <LoaderComponent align="right" />
                                    :
                                    <div className={classes.alignRight}>
                                        {
                                            isPromoCodeValid === true ?
                                                <Success>This code is valid</Success>
                                            :
                                                isPromoCodeValid === false ?
                                                    <Danger>This code is invalid</Danger>
                                                :
                                                <></>
                                        }
                                        <Button color="primary" round onClick={this.applyPromoCode} className={classes.applyButton}>
                                            Apply
                                        </Button>
                                    </div>
                                }
                            </div>
                        </>
                    :
                    null
                }
                
                <FormControlLabel
                    classes={{label: classes.label}}
                    control={
                        <Checkbox                                                
                            tabIndex={-1}
                            onClick={(e) => this.handleCheckbox(e, "promotions")}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{checked: classes.checked, root: classes.checkRoot}}
                            checked={this.state.promotions}
                        />
                    }
                    label={"I agree to recieve instructional and promotion emails"}
                />
                <FormControlLabel
                    classes={{label: classes.label+" "+(this.state.terms ? '' : classes.termsError)}}
                    control={
                        <Checkbox                                                
                            tabIndex={-1}
                            onClick={(e) => this.handleCheckbox(e, "terms")}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{checked: classes.checked, root: classes.checkRoot}}
                            checked={(this.state.terms)}
                        />
                    }
                    label={
                        <span>I agree to Sendlinx's <Link href="https://sendlinx.com/terms-of-use/" target="_blank">Term of Use</Link> & <Link href="https://sendlinx.com/privacy-policy/" target="_blank">Privacy Policy</Link></span>
                    }
                />
                <div className={classes.recaptcha}>
                    <RecaptchaV2 onChange={(response) => this.onRecaptcha(response)} show={showRecaptcha} />
                </div>
            </>
        )
    }
    render() {
        const { classes } = this.props;
        const { validation, loading, requestCode, showError, advancedFields } = this.state;
        const { userAccountType, selectAccountType } = this.state;
        const { authorized } = this.store.getState();
        if(authorized){
            return <Redirect to='/' />
        }
        return (
            <div>
                <div className={classes.container}>
                    <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={10} md={6}>
                        <Card className={classes.cardSignup+(validation.isValidate ? ' validate' : '')}>
                        <h2 className={classes.cardTitle}>
                            Sign up & Start Sending
                        </h2>
                        <CardBody>
                            <GridContainer justifyContent="center">
                                <GridItem xs={12} sm={12} md={12}>
                                    {
                                        requestCode ?
                                            <div>
                                                <GridItem>
                                                    <Warning>
                                                        We have sent a code by email. Please check your spam or junk folder if you could not receive email in Inbox. Enter it below to confirm your account.
                                                    </Warning>
                                                </GridItem>
                                                <GridContainer>
                                                    <GridItem>
                                                        {
                                                            showError ?
                                                                this.renderErrorMessages()
                                                            :
                                                            <></>
                                                        }
                                                        <form onSubmit={this.confirmSignUp} noValidate>
                                                            <GridItem>                             
                                                                <CustomInput
                                                                    success={validation.code === "success"}
                                                                    error={validation.code === "error"}
                                                                    id="input-code"
                                                                    inputProps={{
                                                                        required: true,
                                                                        onChange: (e) => this.handleChange(e,'code'),
                                                                        name: "code",
                                                                        type: "text",
                                                                        startAdornment: (
                                                                            <InputAdornment position="start" className={classes.inputAdornment} >
                                                                                <VerifiedUser className={classes.inputAdornmentIcon}/>
                                                                            </InputAdornment>
                                                                        ),
                                                                        placeholder:"Verification Code"
                                                                    }}                                    
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                />
                                                            </GridItem>                              
                                                            <GridItem>
                                                                {
                                                                    loading ?
                                                                        <LoaderComponent />
                                                                    :
                                                                        <>
                                                                        <Button type="submit" color="primary" round>
                                                                            <AuthIcon className={classes.icons} /> Confirm Account
                                                                        </Button>
                                                                        <p className="redirect-customizable text-center mt-2"><span>Didn't receive a code?</span>&nbsp;<span className={classes.anchor} onClick={(e) => this.resendCode(e)}>Resend it</span></p>
                                                                        </>
                                                                }
                                                                
                                                            </GridItem>
                                                        </form>
                                                    </GridItem>
                                                </GridContainer>                        
                                            </div>
                                        :
                                        <>
                                            {
                                                showError && selectAccountType === false ?
                                                    this.renderErrorMessages()
                                                :
                                                <></>
                                            }
                                            <form className={classes.form}>
                                                {
                                                    selectAccountType ?
                                                        <div className={classes.selectAccountType}>
                                                            <FormControlLabel
                                                                classes={{label: classes.label}}
                                                                control={
                                                                    <Radio
                                                                        checked={(userAccountType === "personal")}
                                                                        onChange={(e) => this.handleUserAccountType("userAccountType", "personal")}
                                                                        value={"personal"}
                                                                        name="userAccountType"
                                                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                                        classes={{
                                                                            checked: classes.radio,
                                                                            root: classes.radioRoot
                                                                        }}
                                                                    />
                                                                }
                                                                label={(
                                                                    <>
                                                                        Personal Account
                                                                        <p>Send and receive file easily and securely with your friends and family.</p>
                                                                    </>
                                                                )}
                                                            />
                                                            <FormControlLabel
                                                                classes={{label: classes.label}}
                                                                control={
                                                                    <Radio
                                                                        checked={(userAccountType === "business")}
                                                                        onChange={(e) => this.handleUserAccountType("userAccountType", "business")}
                                                                        value={"business"}
                                                                        name="userAccountType"
                                                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                                        classes={{
                                                                            checked: classes.radio,
                                                                            root: classes.radioRoot
                                                                        }}
                                                                    />
                                                                }
                                                                label={(
                                                                    <>
                                                                        Business Account
                                                                        <p>Share files with your team mates and customers easily and securely.</p>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    :
                                                        this.renderPersonalSignup()
                                                }
                                                <div className={classes.textCenter+" "+(selectAccountType ? '' : classes.alignButtons)}>
                                                    {
                                                        loading ?
                                                            <LoaderComponent />
                                                        :
                                                            selectAccountType ?
                                                                <Button round color="primary" onClick={() => this.showFields()}>Next</Button>
                                                            :
                                                                advancedFields ?
                                                                    <>
                                                                        <Button round color="primary" onClick={() => this.hideAdvancedFields()}>Back</Button>
                                                                        <Button round color="primary" onClick={this.handleSubmit}>Signup</Button>
                                                                    </>
                                                                :
                                                                        <>
                                                                            <Button round color="primary" onClick={() => this.showFields(true)}>Back</Button>
                                                                            <Button round color="primary" onClick={() => this.showAdvancedFields()}>Next</Button>
                                                                        </>
                                                    }                                        
                                                </div>
                                            </form>
                                        </>
                                    }
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        </Card>
                    </GridItem>
                    </GridContainer>
                </div>
                <BackgroundSlider store={this.store} />
            </div>
        )
    }
}

export default withStyles(signupPageStyle)(Signup);