import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import privacyPageStyle from "../../assets/jss/privacyPageStyle.jsx";

const Privacy = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            
        };
    }  
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.panelContent+" "+classes.textLeft}>
                    <div className={classes.leagalContent}>
                        <h1>Privacy Policy</h1>
                        <h3>What is this Privacy Policy for?</h3>
                        <p>We take all reasonable care to prevent any unauthorized access to your personal information. "Personal information" in this Privacy Policy means information which identifies, or is capable of identifying, you as an individual.</p>
                        <p>By using our website, you indicate your agreement to this Privacy Policy. If you register for our services, we will ask you to accept this Privacy Policy.</p>
                        <p>This Privacy Policy covers the provision of our services and our website only. It does not cover any third party sites which you may access from our website or by using our services.</p>
                        <h3>When do we collect personal information from you?</h3>
                        <p>We do not collect any personal information from you when you browse our web site, unless you sign up for information or send us an inquiry. If you register as a customer of any of our services, we will ask you for personal information such as your name, address, telephone numbers and email address. We may also ask you for other information.</p>
                        <p>If you subscribe to one of our services, your credit or debit card information will be taken by one of our partner companies. We do not store your credit or debit card details.</p>
                        <h3>What do we use your personal information for?</h3>
                        <p>If you are a registered customer we may use your personal information to:</p>
                        <ul>
                            <li>Confirm our acceptance of your registration</li>
                            <li>Communicate with you about our services</li>
                            <li>Check that you are a registered customer if you call our help desk</li>
                            <li>Collect payment from you</li>
                            <li>Let you know by email about any changes to our services and web site</li>
                            <li>Market our products and services to you</li>
                            <li>Improve our understanding of the type of customers we are attracting to our services and how they use the services.</li>
                            <li>We will not pass your personal data to third parties except as necessary to perform our services to you or as required by the processes of the law.</li>
                        </ul>
                        <h3>Do we share your personal information with anyone else?</h3>
                        <p>We sometimes need to share your personal information with the partner companies which we use to provide some of our services. We do not allow these companies to use your personal information for any purposes beyond providing our services to you.</p>
                        <p>If we receive a Court Order requiring us to reveal your identity, we will do so.</p>
                        <p>If there is a change in the ownership of Cyber Stream or any of its assets, we may have to disclose personal information to the new owner.</p>
                        <h3>Other information we collect</h3>
                        <p>We also collect anonymous information from visitors to our website and registered customers to help us improve our website and services. This anonymous information tells us, for instance, how many visitors there were to our website, when they visited, for how long and which areas of our website they went to. This information helps us to assess which parts of our website are popular or need improving.</p>
                        <p>We may share this data in an aggregated form with our partners but only in ways that do not identify any individuals.</p>
                        <h3>How can I inspect and change the personal information Sendlix holds about me?</h3>
                        <p>If you want to know what personal information Sendlinx holds about you and you are a Sendlinx customer, please write to us at the following address: 1235 Fairview St #381, Burlington, ON L7S 2K9. Please enclose a cheque for the administration fee of $25.00 made payable to Sendlinx Inc.</p>
                        <p>If the information we hold about you is inaccurate, please let us know and we will make the necessary amendments and confirm that thesehave been made.</p>
                        <h3>How do we protect your personal information?</h3>
                        <p>We use secure servers to store the information you give us when you register. Any information you send us is encrypted using 'Secure Socket Layer' (SSL) technology. SSL is an industry standard and is one of the best ways to ensure internet messages are not intercepted.</p>
                        <h3>Changes to our Privacy Policy</h3>
                        <p>We may change our Privacy Policy from time to time. If you are a registered customer, we will notify you of the changed policy.</p>
                        <p>E-mail: <a href="mailto:support@sendlinx.com">support@sendlinx.com</a></p>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Privacy.propTypes = {
    classes: PropTypes.object
};

export default withStyles(privacyPageStyle)(Privacy);
