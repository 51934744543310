import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader";
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Close from "@material-ui/icons/Close";
import copyFilesModalStyle from "../../../assets/jss/user/copyFilesModalStyle.jsx";
import { helper } from "../../../assets/js/utils/Element.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const MoveFilesModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            newPath: this.props.path,
            folders: null,
            loading: false
        }
    }
    componentDidMount(){
        this.loadFolders()
    }
    loadFolders(){
        const source = axios.CancelToken.source();

        let requestData = {
            page: 1,
            limit: 200,
            path: '/My Docs',
            nested_files: 1,
            includeSub: true
        };
        this.setState({
            loading: true
        })
        
        Api.getFolders(requestData, source).then(data => {
            this.getFolders(data);
        }).catch(err => {
            this.setState({ loading: false })
        }); 
    }
    onNodeSelect(e, value){
        this.setState({
            newPath: value
        });
    }
    getNodeName(nodeName){
        const {  classes } = this.props;
        return (
            <div className={classes.nodeLabel}>
                <Button justIcon color="transparent" className={classes.fileIcon}>
                    { helper.getFolderIcon() }
                </Button>
                {nodeName}
            </div>
        )
    }
    renderTree(nodes){
        return(
            <TreeItem key={nodes.id} nodeId={nodes.id} label={this.getNodeName(nodes.name)}>
                {Array.isArray(nodes.children) ? nodes.children.map((node) => this.renderTree(node)) : null}
            </TreeItem>
        )
    }
    getFolderItems(data){
        let array = [];
        data.map((folder) => {
            if(folder.type === 'file'){
                return null;
            }
            let obj = {}
            obj.id = folder.path;
            obj.name = folder.name;
            array.push(obj);

            obj['children'] = this.getFolderItems(folder.files);

            return null
        });
        return array;
    }
    getFoldersObj(data){
        let obj = {
            'id': '/',
            'name': 'root'
        }
        
        obj['children'] = this.getFolderItems(data);

        return obj;
    }
    getFolders(data){
        if(!data.response || data.response.length === 0){
            return;
        }
        const folders = this.getFoldersObj(data.response);

        this.setState({
            folders: folders,
            loading: false
        });
    }
    getDefaultExpanded(){
        var pathArray = this.props.path.split("/");
        pathArray = pathArray.map(i => '/' + i);

        let pathStringArray = [];
        let previousPath = "";
        pathArray.map((path, key) => {
            if(key <= 1){
                pathStringArray[key] = path;
            }else{
                pathStringArray[key] = previousPath+""+path;
            }
            previousPath = pathStringArray[key];

            return null;
        })

        return pathStringArray;
    }
    render() {
        const { classes, saving, loadingMessage, successMessage, path, files, errorMessage } = this.props;
        const { newPath, folders, loading } = this.state;
        
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="moveFilesModal-slide-title"
                aria-describedby="moveFilesModal-slide-description"
            >
                <DialogTitle
                    id="moveFilesModal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {
                            saving || loading ?
                                <></>
                            :
                                <Close className={classes.modalClose} />
                        }
                    </Button>
                    {
                        loading ?
                            <></>
                        :
                            errorMessage !== null ?
                                <h4 className={classes.modalTitle}>{errorMessage}</h4>
                            : saving ?
                                <h4 className={classes.modalTitle}>{loadingMessage}</h4> 
                            :
                                <h4 className={classes.modalTitle}>{successMessage}</h4> 
                    }
                </DialogTitle>
                <DialogContent
                    id="moveFilesModal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            saving || loading ?
                                <LoaderComponent color="custom" align="center" />
                            : errorMessage !== null ?
                                <></>
                            :
                                <>
                                    <p>File(s) Path:</p>
                                    <ul className={classes.filesList}>
                                        {
                                            files.map((file) => {
                                                return (
                                                    <li key={file.id}>
                                                        {file.path}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    {
                                        folders ?
                                            <div className={classes.treeView}>
                                                <TreeView
                                                    className={classes.root}
                                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                                    defaultExpanded={this.getDefaultExpanded()}
                                                    defaultExpandIcon={<ChevronRightIcon />}
                                                    onNodeSelect={(event, value) => this.onNodeSelect(event, value)}
                                                    defaultSelected={path}
                                                >
                                                    {this.renderTree(folders)}
                                                </TreeView>
                                            </div>
                                        :
                                            <></>
                                    }
                                </>
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    {
                        loading ?
                            <></>
                        :
                            errorMessage !== null ?
                                <Button color="custom" onClick={() => this.props.onClose()}>Close</Button>
                            : saving === false ?
                                <>
                                    <Button color="custom" onClick={() => this.props.onSuccess(newPath)} disabled={(path === newPath)}>Move File(s)</Button>
                                    <Button color="white" onClick={() => this.props.onClose()}>Cancel</Button>
                                </>
                            :
                                <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

MoveFilesModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    saving: false
}
MoveFilesModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    saving: PropTypes.bool
}
export default withStyles(copyFilesModalStyle)(MoveFilesModal);