import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader.js";
import axios from "axios";
import Api from "../../../assets/js/utils/Api.js";
// import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { /*ExpandMore,*/ ChevronRight, CheckCircle, ArrowBack, CreateNewFolder, Folder, Close }  from '@material-ui/icons';
import { helper } from "../../../assets/js/utils/Element.js";
import ApiOneDrive from "../../../assets/js/utils/oneDrive/Api.js";
import ApiSharepoint from "../../../assets/js/utils/Sharepoint/Api.js";
import sendlinxIcon from "../../../assets/img/sendlinx-icon.png";
import onedriveIcon from "../../../assets/img/cloudicon.png";
import copyFilesModalStyle from "../../../assets/jss/user/copyFilesModalStyle.jsx";
import NewFolderModal from "./NewFolderModal.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const CopyMyDocModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }
        this.state = {
            newPath: this.props.path,
            path: decodeURIComponent(path.trim()),
            folders: null,
            loading: false,
            countingFiles: false,
            files: this.props.files,
            showLists: true,
            totalSize: this.getTotalSize(this.props.files),
            startTime: null,
            totalSent: 0,
            openFolders: null,
            openFolderPath: null,
            pathExist: true,
            saving: false,
            canceled: false,
            cancelToken: null,
            processedFileIds:[],
            filesCopiedSize: 0,
            newFolderModalOneDrive: false,
            creatingFolder: false,
            errorMessage: null,
            actionFlag: "CopyToMyDocs",
        }
    }
    componentDidMount(){
        this.loadFolders();
        this.countFoldersChilds();
    }
    componentWillReceiveProps(props){
        this.setState({
            files: props.files
        });
    }
    componentDidUpdate(prevPros, prevState){
        if(this.state.saving === true){
            const notCopiedObjects = this.state.files.filter(obj => !obj.copied);

            if(notCopiedObjects.length <= 0){
                setTimeout(() => {
                    this.props.onClose({openMyDocs: true});
                }, 1000);
            }
        }
        if(!prevState.saving && this.state.saving){
            this.setState({
                startTime: new Date()
            })
        }

        if(!prevState.canceled && this.state.canceled){
            setTimeout(() => {
                this.props.onClose({openMyDocs: false});
            }, 1000);
        }
    }
    loadFolders(){
        const source = axios.CancelToken.source();

        let requestData = {
            page: 1,
            limit: 200,
            path: '/My Docs',
            nested_files: 1,
            includeSub: true
        };
        this.setState({
            loading: true
        })
        Api.getFolders(requestData, source).then(data => {
            this.getFolders(data);
            this.setState({ loading: false })
        }).catch(err => {
            this.setState({ loading: false })
        }); 
    }
    countFoldersChilds = async () => {
        const { files } = this.state;
        this.setState({
            countingFiles: true
        })
        try{
            await Promise.all(files.map(async (folder, index) => {
                const data = await this.getAllDataFolderOneDrive(folder.parent_id, folder.id);
                
                if(data === undefined || data === null){
                    folder.countFiles = 0;
                }else{
                    folder.countFiles = data.length;
                }
            }));
        
            this.setState({
                countingFiles: false
            });
        } catch (err) {
            console.log(err);
        }           
    }
    async getAllDataFolderOneDrive(parentId, id){
        const { path } = this.state;
        let apiUrl = ApiOneDrive;
        if(path.includes("/Sharepoint")){
            apiUrl = ApiSharepoint;
        }

        const response = await apiUrl.getDataAllFolderOneDrive(parentId, id).then(data => {
            return data;
        }).catch(err => {
            console.log(err);
        });
        const folderData = response.value;

        if(folderData && folderData.length>0){            
            const childFolders = folderData.filter(item => item.folder);
            const childDataPromises = childFolders.map(folder => {
                return this.getAllDataFolderOneDrive(parentId, folder.id);
            });
            if (childDataPromises.length > 0) {
                const childData = await Promise.all(childDataPromises);
                folderData.push(...childData.flat());
            }
        }
        
        return folderData;
    }
    onNodeSelect(e, value){
        this.setState({
            newPath: value
        });
    }
    getNodeName(nodeName){
        const {  classes } = this.props;
        return (
            <div className={classes.nodeLabel}>
                <Button justIcon color="transparent" className={classes.fileIcon}>
                    { helper.getFolderIcon() }
                </Button>
                {nodeName}
            </div>
        )
    }
    renderTree(nodes){
        return(
            <TreeItem key={nodes.id} nodeId={nodes.id} label={this.getNodeName(nodes.name)}>
                {Array.isArray(nodes.children) ? nodes.children.map((node) => this.renderTree(node)) : null}
            </TreeItem>
        )
    }
    getFolderItems(data){
        let array = [];
        data.map((folder) => {
            if(folder.type === 'file'){
                return null;
            }
            let obj = {}
            obj.id = folder.path;
            obj.name = folder.name;
            array.push(obj);

            obj['children'] = this.getFolderItems(folder.files);

            return null
        });
        return array;
    }
    getFoldersObj(data){
        let { path } = this.state;
        let obj;
        if(path.includes("/OneDrive") || path.includes("/Sharepoint")){
            // obj = {
            //     'id': '/',
            //     'name': 'My Docs'
            // }
            // obj['children'] = this.getFolderItems(data);
            obj = this.getFolderItems(data);
            if(obj.length > 0){
                obj = obj[0];
            }
        }else{
            obj = {
                'id': '/',
                'name': 'root'
            }
            
            obj['children'] = this.getFolderItems(data);
        }
        // console.log(obj);
        return obj;
    }
    getFolders(data){
        let { path } = this.state;
        const userData = JSON.parse(localStorage.getItem("sendlinx_userinfo"));

        if(!data.response || data.response.length === 0){
            let obj;
            if(path.includes("/OneDrive") || path.includes("/Sharepoint")){
                obj = {
                    'id': '/My Docs',
                    'name': 'My Docs',
                    'children': []
                }
            }else{
                obj = {
                    'id': '/',
                    'name': 'root',
                    'children': []
                }
            }
            this.setState({
                folders: obj,
                loading: false
            });
            return obj;
        }
        const folders = this.getFoldersObj(data.response);
        this.setState({
            folders: folders,
            loading: false
        },() => {
            let isSharePoint = false;
            if(path.includes("/Sharepoint")){
                isSharePoint = true;
            }
            
            if(this.state.openFolderPath !== null){
                this.toggleFolder(this.state.openFolderPath);
            }else if(!isSharePoint && userData.extensions.one_drive.path !== null){
                let openFolderPath = userData.extensions.one_drive.path;
                openFolderPath = '/My Docs/'+openFolderPath;
                
                let isFound = this.toggleFolder(openFolderPath, true);
                if(!isFound){
                    this.toggleFolder('/', false, false);
                }
            }else if(isSharePoint && userData.extensions.sharepoint.path !== null){
                let openFolderPath = userData.extensions.sharepoint.path;
                openFolderPath = '/My Docs/'+openFolderPath;
                
                let isFound = this.toggleFolder(openFolderPath, true);
                if(!isFound){
                    this.toggleFolder('/', false, false);
                }
            }else{
                this.toggleFolder('/', false, false);
            }
        });
    }
    getDefaultExpanded(){
        var pathArray = this.props.path.split("/");
        pathArray = pathArray.map(i => '/' + i);

        let pathStringArray = [];
        let previousPath = "";
        pathArray.map((path, key) => {
            if(key <= 1){
                pathStringArray[key] = path;
            }else{
                pathStringArray[key] = previousPath+""+path;
            }
            previousPath = pathStringArray[key];

            return null;
        })

        return pathStringArray;
    }
    handleToggleFiles(){
        this.setState({
            showLists: !this.state.showLists
        })
    }
    getTotalSize(files){
        if(files === null){
            files = this.state.files;
        }
        let totalSize = 0;
        totalSize = files.reduce((accumulator, obj) => accumulator + obj.orignalSize, 0);
        
        return totalSize;
    }
    formatEstimatedTime(expectedTime){
        if(expectedTime <= 2){
            return "Few Seconds";
        }
        if(expectedTime < 60){
            expectedTime = Math.trunc(expectedTime);
            return expectedTime+" Seconds";
        }
        let minutes = expectedTime/60;
        if(minutes < 60){
            const seconds = Math.round(expectedTime%60);
            minutes = Math.trunc(minutes);
            return (minutes+" Minutes"+(seconds ? (" "+seconds+" Seconds") : ""));
        }
        expectedTime = expectedTime/60;
        let hours = expectedTime/60;
        minutes = Math.round(expectedTime%60);
        hours = Math.trunc(hours);
        return (hours+" Hours"+(minutes ? (" "+minutes+" Minutes") : ""));
    }
    getEstimatedTimeLeft(){
        const { startTime, totalSize, filesCopiedSize } = this.state;

        const currentTime = new Date();
        const timeElapsed = (currentTime - startTime)/1000;
        const totalUploaded = filesCopiedSize;
        if(totalUploaded <= 0){
            return 'Estimating...';
        }
        let uploadRate = (totalUploaded/timeElapsed);
        const expectedTime = (totalSize - totalUploaded)/uploadRate;
        const timeEstimate = this.formatEstimatedTime(expectedTime);
        
        return timeEstimate+' left...';
    }
    getPathStructure(){
        const { newPath } = this.state;
        if(this.props.path === newPath){
            return 'Select folder to show path';
        }
        if(newPath === '/'){
            return <span onClick={() => this.toggleFolder('/')}>
                My Docs
            </span>
        }
        
        const parts = newPath.split('/').filter(part => part.trim() !== '');
        const pathStructure = parts.map((part, index) => {
            let currentPath = '/'+parts.slice(0, index + 1).join('/');
            currentPath = currentPath === '/My Docs' ? '/' : currentPath;
            return (
                <span key={index} onClick={() => this.toggleFolder(currentPath)}>
                    {part}
                </span>
            );
        });

        return pathStructure;
    }
    getChildrenById(obj, targetId) {
        if(targetId === '/'){
            targetId = '/My Docs'
        }

        if (obj.id === targetId) {
          return obj.children;
        } else if (obj.children) {
          for (const child of obj.children) {
            const result = this.getChildrenById(child, targetId);
            if (result) {
              return result;
            }
          }
        }
        return null;
    }
    togglePath(folderId){
        if(folderId === this.state.newPath){
            const pathArray = folderId.split('/');
            pathArray.pop();
            let updatedPath = pathArray.join("/");
            if(updatedPath === ''){
                updatedPath = this.props.path;
            }
            folderId = updatedPath;
        }
        this.setState({
            newPath: folderId,
        });
    }
    toggleFolder(folderId, checkPathExist = false, updatePathExistState = true) {   
        const { folders } = this.state;
        let openFolders = this.getChildrenById(folders, folderId);
        let openFolderPath = folderId;
        if(this.props.path === folderId){
            openFolderPath = null;
        }

        let pathExist = true;
        if(checkPathExist && openFolders === null){
            pathExist = false;
        }

        let state = {
            openFolders: openFolders,
            newPath: folderId,
            openFolderPath: openFolderPath,
        };
        if(updatePathExistState){
            state['pathExist'] = pathExist;
        }
        this.setState(state);

        if(checkPathExist){
            return pathExist;
        }
    }
    renderFolder() {
        let { openFolders, newPath } = this.state;

        return (
            <>
                {
                    openFolders === null ?
                        <div className={'sd-folder-directory '+('/' === newPath ? 'selected' : '')}>
                            <div className='folder-name' onClick={() => this.togglePath('/')} onDoubleClick={() => this.toggleFolder('/')}>
                                <Folder />
                                <span>My Docs</span>
                            </div>
                            <div className='folder-action'>
                                <span onClick={() => this.onSuccess('/')} className='copy'>Copy to</span>
                                <span onClick={() => this.toggleFolder('/')}><ChevronRight /></span>
                            </div>
                        </div>
                    : openFolders.length > 0 ?
                        openFolders.map((folder, key) => {
                            return(
                                <div className={'sd-folder-directory '+(folder.id === newPath ? 'selected' : '')} key={key}>
                                    <div className='folder-name' onClick={() => this.togglePath(folder.id)} onDoubleClick={() => this.toggleFolder(folder.id)}>
                                        <Folder />
                                        <span>{folder.name}</span>
                                    </div>
                                    <div className='folder-action'>
                                        <span onClick={() => this.onSuccess(folder.id)} className='copy'>Copy to</span>
                                        <span onClick={() => this.toggleFolder(folder.id)}><ChevronRight /></span>
                                    </div>
                                </div>
                            )
                        })
                    :
                        <div className='empty-folder'><p>This folder is empty</p></div>
                }
            </>
        );
    }
    getBackPath(){
        const { openFolders, openFolderPath } = this.state;

        if(openFolders === null){
            return <></>;
        }else if(openFolderPath === '/'){
            return (
                <>
                    <Button color="transparent" className="sd-back-icon 1" onClick={() => this.toggleFolder(this.props.path)} justIcon round><ArrowBack /></Button>My Docs
                </>
            )
        }else{
            const pathArray = openFolderPath.split('/');
            const lastPath = pathArray[pathArray.length - 1];
            pathArray.pop();
            let updatedPath = pathArray.join("/");
            if(updatedPath === '/My Docs'){
                updatedPath = '/';
            }
            return (
                <>
                    <Button color="transparent" className="sd-back-icon 2" onClick={() => this.toggleFolder(updatedPath)} justIcon round><ArrowBack /></Button>{lastPath}
                </>
            )
        }
    }
    getCopiesCountTitle(){
        const { files } = this.state;

        let countTitle = '';
        if(files.length > 1){
            countTitle = files.length+' items';
        }else if(files.length === 1){
            files.map(file => {
                countTitle = file.name;

                return null;
            })
        }
        return 'Copy '+countTitle;
    }
    copyFilesProgress(){
        const { classes } = this.props;
        const { showLists, files, canceled } = this.state;
        let timeLeft = this.getEstimatedTimeLeft();

        return (
            <div className={classes.copyFilesProgress}>
                <p className='copied-files-header' onClick={() => this.handleToggleFiles()}>Copying {files.length}{files.length > 1 ? ' Items' : ' Item'} to My Docs...</p>
                {
                    !canceled ?
                        <div className={'copied-files-detail '+(showLists ? 'show' : 'hide')}>
                            <p>{timeLeft}</p>
                            <p className='cancel' onClick={() => this.onCancel()}>Cancel</p>
                        </div>
                    :
                        <></>
                }
                <div className={'files-listings '+(showLists ? 'show' : 'hide')}>
                    <ul>
                        {
                            files.map((file, key) => {
                                return (
                                    <li key={key}>
                                        <div className='file-info'>
                                            {
                                                file.type === 'folder' ?
                                                    <Button justIcon color="transparent" className={classes.fileIcon}>
                                                        { helper.getFolderIcon() }
                                                    </Button>
                                                :
                                                    helper.getFileIcon(file, classes)
                                            }
                                            <span className='name' title={file.name}>{file.name}</span>
                                            {
                                                file.countFiles > 0 || file.countFiles > '0' ?
                                                    <span className='count'>{file.hasOwnProperty('countCopiedFiles') ? file.countCopiedFiles : '0'} of {file.countFiles}</span>
                                                :
                                                    <></>
                                            }
                                        </div>
                                        {
                                            file.hasOwnProperty('copied') && file.copied ?
                                                <CheckCircle className={classes.icons}/>
                                            : !canceled ?
                                                <LoaderComponent color="custom" align="center" />
                                            :
                                                <></>
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                
            </div>
        );
    }
    onCancel(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }

        this.setState({
            cancelToken: null,
            showError: false,
            errorMessage: null,
            canceled: true
        });
    }
    onSuccess(newPath){
        if(newPath === "/"){
            newPath = "/My Docs"
        }
        const source = axios.CancelToken.source();
        this.setState({
            cancelToken: source
        })

        const { actionFlag } = this.state;
        this.moveData1(actionFlag, newPath, source);
    }
    moveData1(flag, destinationPath, source){ 
        const getAllNewFolder = this.getNewFolderOrFile('folder',destinationPath);
        if(getAllNewFolder.length>0){
            this.inserFolderOneDrive(flag, getAllNewFolder, destinationPath, false, source);
        }
        const getAllNewFile = this.getNewFolderOrFile('file',destinationPath);
        if(getAllNewFile.length>0){
            this.moveFileOneDrive(flag, getAllNewFile, null, destinationPath, false, source);
        }
    }
    getNewFolderOrFile(type,destinationPath = null){
        const { files } = this.state;
        let oldCheckedFilePath = [];

        const uniqueValues = files.filter(value => !oldCheckedFilePath.some(obj => obj=== destinationPath+value.name ));
        const uniqueFilesFolder = uniqueValues.filter(value => value.type === type)
       
        return uniqueFilesFolder;
    }
    inserFolderOneDrive(flag = null, data, destinationPath = null, closeCopyModal = true, sourceParam = null){
        let flageS = false;
        if (flag === "SyncToMyDocs") {
            flageS = true;
        }

        let source = sourceParam;
        if(sourceParam === null){
            source = axios.CancelToken.source();
        }

        for (let index = 0; index < data.length; index++) {
            let requestData;
            if (destinationPath) {
                requestData={
                    "folderName":data[index].name,
                    "folderUrl":'',
                    "folderParentId":4,
                    "flagStatus":flageS,
                    "itemId":data[index].id,
                    "driveId":data[index].parent_id,
                    "destinationPath":decodeURIComponent(destinationPath),
                    "created_at":data[index].created_at,
                    "updated_at":data[index].updated_at
                }
            }else{
                requestData={
                    "folderName":data[index].name,
                    "folderUrl":'',
                    "folderParentId":4,
                    "flagStatus":flageS,
                    "itemId":data[index].id,
                    "driveId":data[index].parent_id,
                    "created_at":data[index].created_at,
                    "updated_at":data[index].updated_at
                }
            }
            let stateObj = {
                // loading: true,
                saving: true,
                folderParentIdOneDrive:data[index].id,
                folderParentPathOneDrive:data[index].name
            };
            if(sourceParam === null){
                stateObj['cancelToken'] = source;
            }

            this.setState(stateObj);
            Api.insertFolerEntryToLocal(requestData, source).then(data1 => {
                const { files } = this.state;

                let mainFolder = files.find(obj => obj.id === data[index].id);
                
                mainFolder.countCopiedFiles = 0;
                if(mainFolder.countFiles <= 0 || mainFolder.countFiles === mainFolder.countCopiedFiles){
                    mainFolder.copied = true;
                }
                
                if (destinationPath) {
                    this.getAllDataFolderCopy(data[index].parent_id, data[index].id, data[index].name, data1.parent_path, destinationPath, mainFolder, closeCopyModal, sourceParam);
                }else{
                    this.getAllDataFolderCopy(data[index].parent_id, data[index].id, data[index].name, data1.parent_path, null, mainFolder, closeCopyModal, sourceParam);
                }
                if(closeCopyModal){
                    this.setState({
                        copyMyDocModal:false,
                        saving: false,
                    });
                }else{
                    this.setState({
                        loading: false
                    })
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
    moveFileOneDrive(flag, filesOne=null, isSync = null, destinationPath = null, closeCopyModal = true, sourceParam = null){
        const { path, files } = this.state;

        this.setState({
            // loading: true,
            saving: true,
        });
        let file ;
        if (isSync) {
            file = filesOne;
        }else if(filesOne){
            file = filesOne;
        }else{
            file = files;
        }
        let requestData = [];
        if (file.length <= 0) {
            return false;
        }
        let source = sourceParam;
        if(sourceParam === null){
            source = axios.CancelToken.source();
            this.setState({
                cancelToken: source,
            });
        }

        let filesSize = 0;
        if(isSync){
            for (let index = 0; index < file.length; index++) {
                filesSize = filesSize + file[index].file_size;

                requestData.push({
                    'url':file[index].url,
                    'file_name':file[index].file_name,
                    'file_size':file[index].file_size,
                    'file_mime':file[index].file_mime,
                    'created_at' : file[index].created_at,
                    'updated_at' : file[index].updated_at,
                    'flag' : flag,
                    'driveId' : file[index].driveId ,
                    'destinationPath':destinationPath,
                    'itemId' : file[index].itemId,
                    'flagStatus':file[index].flagStatus,
                    'extension':file[index].extension
                })
            }
        }else{
            for (let index = 0; index < file.length; index++) {
                filesSize = filesSize + file[index].orignalSize;

                requestData.push({
                    'url':file[index].downloadLink,
                    'file_name':file[index].name,
                    'file_size':file[index].orignalSize,
                    'file_mime':file[index].mime_major,
                    'created_at' : file[index].date,
                    'updated_at' : file[index].modified_date,
                    'flag' : flag,
                    'destinationPath':destinationPath,
                    'driveId' : file[index].parent_id ,
                    'itemId' : file[index].id,
                    'flagStatus':true,
                    'extension':file[index].extension
                })
            }
        }

        let apiUrl = ApiOneDrive;
        if(path.includes("/Sharepoint")){
            apiUrl = ApiSharepoint;
        }
        apiUrl.moveFileOneDriveToLocal(requestData, source).then(data => {            
            file.map((fileData) => {
                let matchingObj = files.find(obj => obj.id === fileData.id);
                matchingObj.copied = true;
                
                return null;
            });

            this.setState({
                loading: false,
                filesCopiedSize: this.state.filesCopiedSize + filesSize
            })

            if(closeCopyModal){
                this.setState({
                    saving: false,
                },() => {
                    if (isSync === null) {
                        this.setState({
                            activeTab:0,
                            copyMyDocModal: false,
                        });
                        this.history.push("/user/home?dir=/My Docs");
                    }
                });
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
        });
    }
    async getAllDataFolderCopy(pId, folderId, pName, pPath, destinationPath = null, mainFolder = null, closeCopyModal = true, sourceParam = null){
        const { path, folderParentIdOneDrive } = this.state;
        if(closeCopyModal){
            this.setState({
                loading: true,
            });
        }
        const getFolderPathOneDrive = (fullPath) => {
            const path = fullPath;
            const dataAfterRoot = path.split("root:")[1];
            return dataAfterRoot;
        };
        const getFileExtension = (filename) => {
            const parts = filename.split('.');
            return parts[parts.length - 1];
        };

        let apiUrl = ApiOneDrive;
        if(path.includes("/Sharepoint")){
            apiUrl = ApiSharepoint;
        }

        const response = await apiUrl.getDataAllFolderOneDrive(pId, folderId).then(data => {
            return data;
        }).catch(err => {
            console.log(err);
        });
        const { processedFileIds } = this.state;
        const folderData = response.value;

        let source = sourceParam;
        if(sourceParam === null){
            source = axios.CancelToken.source();
            this.setState({
                cancelToken: source,
            })
        }
        const processedFileIds1 = [...processedFileIds];
        if(folderData.length>0){
            const newDataPush1 = [];
            let filesSize = 0;
            for (let index = 0; index < folderData.length; index++) {
                if (!folderData[index]?.folder) {
                    const fileId = folderData[index]?.id;
                    if (processedFileIds.includes(fileId)) {
                        continue;
                    }
                    filesSize = filesSize + folderData[index]?.size;

                    if (destinationPath) {
                        newDataPush1.push({
                            'url': folderData[index]['@microsoft.graph.downloadUrl'],
                            'file_name':folderData[index]?.name,
                            'file_size':folderData[index]?.size,
                            'file_mime':folderData[index]?.file?.mimeType,
                            'parentId':pId,
                            'filePrentPath':decodeURIComponent(this.getActualPath(getFolderPathOneDrive(folderData[index]?.parentReference?.path),pPath)),
                            'created_at' : folderData[index]?.createdDateTime,
                            'updated_at' : folderData[index]?.lastModifiedDateTime,
                            'flag' : "flag",
                            'driveId' : folderData[index]?.parentReference?.driveId ,
                            'itemId' : folderData[index]?.id,
                            'flagStatus':true,
                            'extension':getFileExtension(folderData[index]?.name),
                            "folderParentIdOneDrive":folderParentIdOneDrive,
                            "parent_path":pName,
                            "destinationPath":destinationPath
                        });
                    }else{
                        newDataPush1.push({
                            'url': folderData[index]['@microsoft.graph.downloadUrl'],
                            'file_name':folderData[index]?.name,
                            'file_size':folderData[index]?.size,
                            'file_mime':folderData[index]?.file?.mimeType,
                            'parentId':pId,
                            'filePrentPath':decodeURIComponent(this.getActualPath(getFolderPathOneDrive(folderData[index]?.parentReference?.path),pPath)),
                            'created_at' : folderData[index]?.createdDateTime,
                            'updated_at' : folderData[index]?.lastModifiedDateTime,
                            'flag' : "flag",
                            'driveId' : folderData[index]?.parentReference?.driveId ,
                            'itemId' : folderData[index]?.id,
                            'flagStatus':true,
                            'extension':getFileExtension(folderData[index]?.name),
                            "folderParentIdOneDrive":folderParentIdOneDrive,
                            "parent_path":pName
                        });
                    }
                    processedFileIds1.push(fileId); 
                    this.setState({processedFileIds:processedFileIds1});
                }
            }  
            const childFolders = folderData.filter((item) => item.folder);
            const childDataPromises = childFolders.map((folder) => {
                return folder;
            });
            if (newDataPush1.length > 0 ) {
                apiUrl.moveFileOneDriveToLocal(newDataPush1, source).then(data => {
                    if(mainFolder !== null){
                        mainFolder.countCopiedFiles = mainFolder.countCopiedFiles + newDataPush1.length;
                    }
                    if(mainFolder.countFiles === mainFolder.countCopiedFiles){
                        mainFolder.copied = true;
                    }

                    this.setState({
                        loading: false,
                        filesCopiedSize: this.state.filesCopiedSize + filesSize
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
            if (childDataPromises.length > 0) {
                for (let j = 0; j < childDataPromises.length; j++) {
                    let requestData;
                    if (destinationPath) {
                        requestData ={
                            "folderName":childDataPromises[j].name,
                            "folderUrl":decodeURIComponent(this.getActualPath(getFolderPathOneDrive(childDataPromises[j]?.parentReference?.path),pPath)),
                            "folderParentId":pId,
                            "flagStatus":false,
                            "itemId":childDataPromises[j].id,
                            "driveId":childDataPromises[j]?.parentReference?.driveId,
                            "created_at":childDataPromises[j].createdDateTime,
                            "updated_at":childDataPromises[j].lastModifiedDateTime,
                            "folderParentIdOneDrive":folderParentIdOneDrive,
                            "parent_path":pName,
                            "destinationPath":destinationPath
                          }
                    }else{
                        requestData ={
                            "folderName":childDataPromises[j].name,
                            "folderUrl":decodeURIComponent(this.getActualPath(getFolderPathOneDrive(childDataPromises[j]?.parentReference?.path),pPath)),
                            "folderParentId":pId,
                            "flagStatus":false,
                            "itemId":childDataPromises[j].id,
                            "driveId":childDataPromises[j]?.parentReference?.driveId,
                            "created_at":childDataPromises[j].createdDateTime,
                            "updated_at":childDataPromises[j].lastModifiedDateTime,
                            "folderParentIdOneDrive":folderParentIdOneDrive,
                            "parent_path":pName
                        }
                    }
                    Api.insertFolerEntryToLocal(requestData, source).then(data => {
                        if(mainFolder !== null){
                            mainFolder.countCopiedFiles = mainFolder.countCopiedFiles + 1;
                        }
                        if(mainFolder.countFiles === mainFolder.countCopiedFiles){
                            mainFolder.copied = true;
                        }
                        if (destinationPath) {
                            this.getAllDataFolderCopy(childDataPromises[j].parentReference.driveId, childDataPromises[j].id, childDataPromises[j].name, data.parent_path, destinationPath, mainFolder, closeCopyModal);
                        }else{
                            this.getAllDataFolderCopy(childDataPromises[j].parentReference.driveId, childDataPromises[j].id, childDataPromises[j].name, data.parent_path, null, mainFolder, closeCopyModal);
                        }

                        this.setState({
                            loading: false,
                        });
                    }).catch(err => {
                            console.log(err);
                       });
                    }
            }
        }
    }
    getActualPath(pathNew,nameNew){
        pathNew = decodeURIComponent(pathNew);
        nameNew = decodeURIComponent(nameNew);
        const position = pathNew.indexOf(nameNew);
        let orPath;
        if (position !== -1) {
            orPath = "/"+pathNew.substring(position);
        } else {
            orPath = pathNew;
        }
       
        return orPath;
    }
    onNewFolderModalOneDrive(status = false){
        this.setState({
            newFolderModalOneDrive: status,
        });
    }
    onNewFolderModalSuccess(folderName){
        const { openFolderPath } = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            creatingFolder: true,
            cancelToken: source,
        });
        let NewP = openFolderPath;
        if (NewP === "/"){
            NewP = "/My Docs"
        }
        const requestData = {
            folderName: folderName,
            path: NewP
        }
        Api.addNewFolder(requestData, source).then(data => {
            this.setState({
                newFolderModalOneDrive:false,
                creatingFolder: false
            });
            this.loadFolders();
            this.countFoldersChilds();
        }).catch(err => {
            console.log(err);
        });
    }
    render() {
        const { classes, path, loadingMessage } = this.props;
        const { newPath, folders, loading, files, countingFiles, pathExist, saving, newFolderModalOneDrive,
            openFolderPath, creatingFolder, errorMessage } = this.state;
        const { user } = this.store.getState();
        let isSharePoint = false;
        if(path.includes("/Sharepoint")){
            isSharePoint = true;
        }
        const leftMemorySize = (user.quota - user.usage);
        
        // const totalFileSize = files.reduce((total, file) => total + file.size, 0);
        const totalFileSizeInMB = files.reduce((total, file) => {
            const matches = file.size.match(/([\d.]+)\s*(mb|kb)/i);
            if (matches) {
              const value = parseFloat(matches[1]);
              const unit = matches[2].toLowerCase();
              if (unit === 'mb') {
                return total + value;
              } else if (unit === 'kb') {
                return total + (value / 1024);
              }
            }
            return total;
        }, 0);
        
        if(saving){
            return this.copyFilesProgress()
        }

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="copyFilesModal-slide-title"
                aria-describedby="copyFilesModal-slide-description"
            >
                <DialogTitle
                    id="copyFilesModal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose({})}
                    >
                        {
                            saving || loading ?
                                <></>
                            :
                                <Close className={classes.modalClose} />
                        }
                    </Button>
                    {
                        loading || countingFiles ?
                            <></>
                        :
                            errorMessage !== null ?
                                <h4 className={classes.modalTitle+" "+classes.textCenter}>{errorMessage}</h4>
                            : saving ?
                                <h4 className={classes.modalTitle+" "+classes.textCenter}>{loadingMessage}</h4> 
                            :
                                <h4 className={classes.modalTitle+" "+classes.filesCountTitle}>{this.getCopiesCountTitle()}</h4> 
                    }
                </DialogTitle>
                <DialogContent
                    id="copyFilesModal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            saving || loading || countingFiles  ?
                                <LoaderComponent color="custom" align="center" />
                            : errorMessage !== null ?
                                <></>
                            :
                                <>
                                    <div className='from-content'>
                                        <p className='label'>From</p>
                                        <div className='from-name'>
                                            <img src={onedriveIcon} alt="onedrive-icon" />
                                            {
                                                isSharePoint ?
                                                    <>Sharepoint</>
                                                :
                                                    <>OneDrive</>
                                            }
                                        </div>
                                        <p className='email'>{localStorage.getItem("one_drive_email")}</p>
                                    </div> 
                                    <div className='from-content'>
                                        <p className='label'>To</p>
                                        <div className='from-name'>
                                            <img src={sendlinxIcon} alt="sendlinx-icon" />
                                            Sendlinx
                                        </div>
                                        <p className='path'>{this.getPathStructure()}</p>
                                    </div> 
                                    {
                                        !pathExist ? 
                                            <div className='folder-exist-note'>
                                                <p>The default destination path is no longer available. The new default path will be "My Docs".</p>
                                            </div>
                                        :
                                            <></>
                                    }
                                    {
                                        totalFileSizeInMB > leftMemorySize && (
                                            <>
                                                <p>Total File Size: {totalFileSizeInMB}MB</p>
                                                <p>Available Space: {leftMemorySize}MB</p>
                                                <p style={{ color: 'red' }}>Not enough space in memory</p>
                                            </>
                                        )
                                    }
                                    <div className='sd-back-newfolder'>
                                        <div className='sd-back-path'>{this.getBackPath()}</div>
                                        <Button color="transparent" onClick={() => this.onNewFolderModalOneDrive(true)} disabled={(path === newPath || openFolderPath === null)} justIcon round><CreateNewFolder /></Button>
                                    </div>
                                    {
                                        folders ?
                                            <div className={classes.foldersDirectory}>{this.renderFolder()}</div>
                                        :
                                            <></>
                                    }
                                </>
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    {
                        loading || countingFiles ?
                            <></>
                        :
                            errorMessage !== null ?
                                <Button color="custom" onClick={() => this.props.onClose({})}>Close</Button>
                            : saving === false ?
                                <>
                                    {
                                        totalFileSizeInMB < leftMemorySize && (
                                            <>
                                                <Button color="custom" onClick={() => this.onSuccess(newPath)} disabled={(path === newPath)}>Copy File(s)</Button>
                                            </>
                                        )
                                    }
                                    <Button color="white" onClick={() => this.props.onClose({})}>Cancel</Button>
                                </>
                            :
                                <></>
                    }
                </DialogActions>
                {
                    newFolderModalOneDrive ?
                        <NewFolderModal
                            open={newFolderModalOneDrive}
                            saving={creatingFolder}
                            store={this.store} 
                            path={openFolderPath}
                            onClose={() => this.onNewFolderModalOneDrive(false)}
                            onSuccess={(folderName) => this.onNewFolderModalSuccess(folderName)}
                            confirmationMessage="Create a Folder"
                        />
                    :
                        <></>
                }
            </Dialog>
        )
    }
}

CopyMyDocModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
}
CopyMyDocModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
}
export default withStyles(copyFilesModalStyle)(CopyMyDocModal);