import React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from "../../../assets/js/utils/Element";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import RefreshIcon from "@material-ui/icons/Refresh";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteFileModal from "../../components/User/DeleteFileModal";
import RestoreFileModal from "../../components/User/RestoreFileModal";

import MaterialIcon from "@mdi/react";
import { mdiArrowExpandUp } from '@mdi/js';

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
const history = createBrowserHistory();

const UserTrashFiles = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }
        const backPath = this.getBackPath(path);

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            path: decodeURIComponent(path.trim()),
            backPath: backPath,
            deleteFileModal: false,
            restoreFileModal: false,
            trash: [],
            shareFileModal: false,
            sharingFiles: false,
            downloadModal: false,
            downloadingFiles: false,
            downloadUrl: "",
            checkedAll: false,
            saving: false,
            errorMessage: null,
        };

        this.loadingFolder = false;
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            this.loadFiles();
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    getBackPath(path){
        if(typeof(path) !== "string" || path.trim().length <= 0){
            return "";
        }
        if(path.length <= 1 || path.trim() === "/"){
            return "";
        }
        if(path.substr(0, 1) !== "/"){
            path += "/";
        }
        path = decodeURIComponent(path.trim()); //Decode path
        path = path.replace(/^\/+|\/+$/g, ''); //Remove trailing slash
        const pathArray = path.split("/");
        pathArray.pop();
        let backPath = pathArray.join("/");
        if(backPath.length <= 0){
            backPath = "/";
        }
        return backPath;
    }
    loadFiles(viewMore = false, newPath = null, backPath = ""){
        const source = axios.CancelToken.source();
        let { page, path, trash } = this.state;
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        let requestData = {
            page: page,
            limit: 200,
            trash: 1
        };
        if(path === "/" && newPath !== null){
            path = "";
        }
        requestData['path'] = path;
        if(newPath !== null){
            path += "/"+newPath;
            requestData['path'] = path;
        }
        if(backPath !== ""){
            requestData['path'] = path = backPath;
        }
        backPath = this.getBackPath(path);
        history.push("/user/trash/files?dir="+encodeURIComponent(path));
        Api.getDeletedFiles(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            data.response.map(file => {
                if(file.trash){
                    trash.push(file.id);
                }
                return null;
            });
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                trash: trash
            });
            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            path: path,
            backPath: backPath,
            checked: viewMore ? this.state.checked : [],
            uploadFileModal: false
        });
        this.loadingFolder = true;
    }
    handleToggle(file) {
        if(this.loadingFolder){
            return;
        }
        // if(file.default){
        //     return;
        // }
        const { checked } = this.state;
        const currentIndex = checked.indexOf(file.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(file.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    onToggleAllFiles(e){
        if(this.loadingFolder){
            return;
        }

        const checkedFiles = [];
        if(e.target.checked){
            const { response } = this.state;

            response.map((file, key) => {
                checkedFiles.push(file.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedFiles
        });
    }
    getColumns(){
        const { classes } = this.props;

        let header = (
            <Checkbox
                tabIndex={-1}
                checked={this.state.checkedAll}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                onChange={(e) => this.onToggleAllFiles(e)}
                classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                }}
            />
        );

        let columns = [
            {
                Header: header,
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                resizable: false
            },
            {
                Header: "Name",
                accessor: "orignalName",
                headerClassName: "hd_name hd_show_head",
                className: "hd_name td_name td_hide",
                resizable: false
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_name hd_hide",
                className: "hd_name td_name",
            },
            {
                Header: "Size",
                accessor: "orignalSize",
                headerClassName: "hd_size hd_show_head",
                className: "hd_size td_size td_hide",
                resizable: false
            },
            {
                Header: "Size",
                accessor: "size",
                headerClassName: "hd_size hd_hide",
                className: "hd_size td_size",
            },
            {
                Header: "Uploaded",
                accessor: "date",
                headerClassName: "hd_creation_date",
                className: "hd_creation_date td_creation_date",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Creator",
                accessor: "owner",
                headerClassName: "hd_owner",
                className: "hd_owner td_owner",
            },   
        ]

        return columns;
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked, backPath } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        if(backPath.length > 0){
            let fileArray = {
                id: 0,
                check: "",
                orignalName: "",
                name: (
                    <Button color="transparent" justIcon className={classes.fileIcon}>
                        { helper.getFolderIcon() }
                    </Button>
                ),
                orignalSize: "",
                size: "",
                date: "",
                owner: "",
            };
            tableData.push(fileArray);
        }
        response.map(file => {
            let fileArray = {
                id: file.id,
                default: file.default,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(file.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(file)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        disabled={(file.default === 1)}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                orignalName: file.name,
                name: this.getFileName(file),
                orignalSize: file.orignalSize,
                size: file.size,
                date: file.modified_date,
                owner: file.owner,
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    getFileName(file){
        const { classes } = this.props;
        return (
            <div>
                {
                    file.type === "folder" ?
                        <>
                            <Button justIcon color="transparent" className={classes.fileIcon}>
                                {
                                    file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0 ?
                                        helper.getFolderIcon(true)                               
                                    :
                                        helper.getFolderIcon()                                                                
                                }
                            </Button>
                            {file.name}
                        </>
                    :
                        <>
                            { 
                                helper.getFileIcon(file, classes, true)
                            }
                            { file.name }
                        </>
                }
            </div>
        )
    }
    loadFolder(e, parent){
        e.preventDefault();
        this.loadFiles(false, parent.name);
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
            }
        }else{
            return {}
        }
    }
    getTrGroupProps(state, rowInfo){
        const { checked } = this.state;
        
        if (rowInfo && rowInfo.row) {
            if(checked.indexOf(rowInfo.original.id) !== -1){
                return {
                    className: 'selected_row'
                }
            }
            return {
                className: (rowInfo.original.id === 0 ? 'frozen_row' : '')
            }
        }else{
            return {}
        }
    }
    deleteSelected(){
        const { checked } = this.state;

        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    onDeleteModalClose(deleteFileModal = true){
        let checked = this.state.checked;
        if(deleteFileModal === false){
            checked = [];
        }
        this.setState({
            deleteFileModal: deleteFileModal,
            checked: checked,
            checkedAll: false,
        });
    }
    onDeleteModalSuccess(){
        const { checked, response } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            files: checked,
        };
        Api.deleteFilesPermanently(requestData, source).then(data => {
            let newResponse = [];
            response.map(file => {
                if(!checked.includes(file.id)){
                    newResponse.push(file);
                }
                return null;
            });
            this.setState({
                response: newResponse,
                checked: [],
                deleteFileModal: false,
                checkedAll: false,
                saving: false,
                errorMessage: null
            });
        }).catch(err => {
            this.setState({
                saving: false,
                errorMessage: err.message
            });
        });
        this.setState({
            saving: true
        });
    }
    restoreSelected(){
        const { checked } = this.state;

        if(checked.length <= 0){
            return;
        }
        this.setState({restoreFileModal: true});
    }
    onRestoreModalClose(restoreFileModal = true){
        let checked = this.state.checked;
        if(restoreFileModal === false){
            checked = [];
        }
        this.setState({
            restoreFileModal: restoreFileModal,
            checked: checked,
            checkedAll: false,
        });
    }
    onRestoreModalSuccess(){
        const { checked, response } = this.state;
        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            restoreFileModal: false,
            checkedAll: false,
        });
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getBreadCrumbs(){
        let { path } = this.state;
        let breadcrumbs = [{
            value: "/",
            label: "Home"
        }];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        let newPath = "";
        pathArray.map(path => {
            newPath += "/"+path;
            breadcrumbs.push({
                value: newPath,
                label: path
            });
            return null;
        });
        return breadcrumbs;
    }
    getCheckedFiles(){
        const { response, checked } = this.state;
        if(checked.length <= 0){
            return [];
        }
        let files = [];
        response.map((file) => {
            if(checked.indexOf(file.id) !== -1){
                files.push(file);
            }
            return null;
        });
        return files;
    }
    render() {
        const { classes } = this.props;
        const { limit, loading, loadingMore, page, totalPages, deleteFileModal, restoreFileModal, saving, errorMessage } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        const checkedFiles = this.getCheckedFiles();
        const tableData = this.getTableData();
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content}>
                        <GridItem>
                            <div className={classes.controls}>
                                    <h4>Trash Files</h4>
                                    <div className={classes.controlButtons+" "+(user.theme === 'standard' ? '': 'light-theme-buttons')}>
                                        <Button color="transparent" onClick={() => this.deleteSelected()}>
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.74308 1.125H10.2531C10.4618 1.125 10.6554 1.22344 10.7692 1.38867L11.3498 2.25H5.65022L6.2308 1.38867C6.34085 1.22344 6.53817 1.125 6.74688 1.125H6.74308ZM12.7842 2.25L11.8013 0.794531C11.4674 0.298828 10.883 0 10.2569 0H6.74308C6.11696 0 5.53259 0.298828 5.19866 0.794531L4.21585 2.25H2.43237H1.21429H0.607143C0.273214 2.25 0 2.50312 0 2.8125C0 3.12188 0.273214 3.375 0.607143 3.375H1.30156L2.2692 15.9117C2.35647 17.0895 3.41518 18 4.69018 18H12.3098C13.5848 18 14.6397 17.0895 14.7308 15.9117L15.6984 3.375H16.3929C16.7268 3.375 17 3.12188 17 2.8125C17 2.50312 16.7268 2.25 16.3929 2.25H15.7857H14.5676H12.7842ZM14.4842 3.375L13.5203 15.8309C13.4748 16.418 12.9473 16.875 12.3098 16.875H4.69018C4.05268 16.875 3.52522 16.418 3.47969 15.8309L2.51964 3.375H14.4804H14.4842Z" fill="#556DC2"/>
                                            </svg>
                                            Delete
                                        </Button>
                                        <Button color="transparent" onClick={() => this.restoreSelected(true)}>
                                            <MaterialIcon path={mdiArrowExpandUp} className="MuiSvgIcon-root" />
                                            Restore
                                        </Button>
                                    </div>
                            </div>
                            <div className={classes.filesContainer+" trash"}>
                                <div className={classes.files}>
                                    {
                                        loading ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                        null
                                    }
                                    <ReactTable
                                        columns={this.getColumns()}
                                        data={tableData}
                                        pageSizeOptions={[500]}
                                        defaultPageSize={limit}
                                        showPaginationTop={false}
                                        minRows={0}
                                        showPaginationBottom={false}
                                        className={"-striped -highlight "+ (loading ? classes.loading : "")+" "+classes.filesTable}
                                        getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                        getTrGroupProps={(state, rowInfo) => this.getTrGroupProps(state, rowInfo)}
                                        getNoDataProps={() => this.getNoDataProps()}
                                    />
                                    <div className={classes.loadMore}>
                                        {
                                            tableData.length > 0 && loadingMore === false && page < totalPages ?
                                                <Button color="custom" id="contentViewMore" onClick={(e) => this.loadFiles(true)}>
                                                    <RefreshIcon className={classes.icons} />View More
                                                </Button>
                                                
                                            :
                                                loadingMore === true ?
                                                    <LoaderComponent color="custom" align="center" />
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </GridItem>
                    </div>
                </div>
                {
                    deleteFileModal ?
                        <DeleteFileModal
                            deletedFiles={checkedFiles}
                            open={deleteFileModal}
                            onClose={() => this.onDeleteModalClose(false)}
                            onSuccess={() => this.onDeleteModalSuccess()}
                            loading={saving}
                            errorMessage={errorMessage}
                        />
                    :
                        <></>
                }
                {
                    restoreFileModal ?
                        <RestoreFileModal store={this.store} restoreFiles={checkedFiles} open={restoreFileModal} onClose={() => this.onRestoreModalClose(false)} onSuccess={() => this.onRestoreModalSuccess()} />
                    :
                        <></>
                }
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserTrashFiles.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(UserTrashFiles);
