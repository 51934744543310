import React from 'react';
import PropTypes from "prop-types";
import Carousel from 'react-slick';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";

import withStyles from "@material-ui/core/styles/withStyles";
import backgroundSliderStyle from "../../../assets/jss/material-kit-pro-react/views/backgroundSliderStyle.jsx";

import image1 from "../../../assets/img/blue.jpg";

const BackgroundSlider = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        let imagesArr = [];
        const images = localStorage.getItem("sendlinx_bg_images");
        if(images !== null){
            imagesArr = JSON.parse(images);
        }
        let backgroundInterval = localStorage.getItem("sendlinx_bg_interval");
        if(backgroundInterval !== null){
            backgroundInterval = parseInt(backgroundInterval);
        }

        this.requestSent = false;
        this.backgroundTheme = 1;
        this.allowSliderUpdate = false;

        this.state = {
            imagesArr: imagesArr,
            image: (imagesArr.length > 0 ? null : this.getRandomImage(imagesArr)),
            cancelToken: null,
            backgroundInterval: backgroundInterval
        };
    }
    componentWillReceiveProps(props){
        const { authorized } = this.store.getState();
        if(Auth.hasAccessToken()){
            if(authorized){
                this.loadImages();
            }
        }else{
            this.loadImages();
        }
    }
    componentDidMount() {
        const { authorized } = this.store.getState();
        if(Auth.hasAccessToken()){
            if(authorized){
                this.loadImages();
            }
        }else{
            this.loadImages();
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    loadImages(){
        // const { authorized, user } = this.store.getState();
        // if(authorized){
        //     if(this.backgroundTheme !== user.background_theme){
        //         this.requestSent = false;
        //         this.allowSliderUpdate = true;
        //     }
        //     this.backgroundTheme = user.background_theme;
        // }
        // if(authorized && user.businessUser && user.businessUser.internal_background_photo && user.businessUser.internal_background_photo.length > 0){
        //     const photo = user.businessUser.internal_background_photo;
        //     this.setState({
        //         imagesArr: [photo],
        //         image: photo,
        //         cancelToken: null,
        //         backgroundInterval: 60
        //     });
        //     return;
        // }
        // if(this.requestSent){
        //     return;
        // }
        // this.requestSent = true;
        // const source = axios.CancelToken.source();
        // const requestData = {
        //     page: 1,
        //     limit: 200,
        //     settings: 1
        // };
        // Api.getBackgroundImages(requestData, source).then(data => {
        //     const imagesArr = data.response;
        //     const settings = data.settings;
        //     const images = localStorage.getItem("sendlinx_bg_images");
        //     const backgroundInterval = this.getInterval(settings);
        //     if(images === null || this.allowSliderUpdate){
        //         this.allowSliderUpdate = false;
        //         this.setState({
        //             imagesArr: imagesArr,
        //             image: (imagesArr.length > 0 ? null : this.getRandomImage(imagesArr)),
        //             cancelToken: null,
        //             backgroundInterval: backgroundInterval
        //         });
        //     }
        //     localStorage.setItem("sendlinx_bg_images", JSON.stringify(imagesArr));
        //     if(backgroundInterval !== null){
        //         localStorage.setItem("sendlinx_bg_interval", backgroundInterval);
        //     }
        // }).catch(err => {
        //     console.log(err);
        // });
        // this.setState({
        //     cancelToken: source,
        // });
    }
    prepareImages(images){
        let imagesArr = [];
        images.map((image) => {
            imagesArr.push(image.url);
            return null;
        });
        return imagesArr;
    }
    getRandomImage(imagesArr = null){
        if(imagesArr === null){
            imagesArr = this.state.imagesArr;
        }
        let min = 1;
        let max = 6;
        let randNumber = Math.floor(min + Math.random() * (max - min));
        let image = image1;
        if(imagesArr.length > 0){
            let min = 1;
            let max = imagesArr.length - 1;
            let randNumber = Math.floor(min + Math.random() * (max - min));
            image = imagesArr[randNumber];
            return image;
        }
        
        if(randNumber === 2){
            image = image1
        }else if(randNumber === 3){
            image = image1
        }else if(randNumber === 4){
            image = image1
        }else if(randNumber === 5){
            image = image1
        }
        return image;
    }
    getInterval(settings){
        if(typeof(settings) !== "object" || typeof(settings.backgroundInterval) !== "string"){
            return null;
        }
        let backgroundInterval = parseInt(settings.backgroundInterval);
        if(backgroundInterval <= 0){
            return null;
        }
        backgroundInterval = backgroundInterval*60*1000;
        return backgroundInterval;
    }
    renderSlide(image, key = 1){
        let imageUrl = image;
        if(typeof(image) === "object"){
            imageUrl = image.url;
        }
        return (
            <div key={key}>
                <div className="background-image" style={{backgroundImage: 'url('+imageUrl+')'}}></div>
                <div className="banner-text-content">
                    <div className="banner-text">
                        <p>Large file transfers made simple & secure.</p>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { classes } = this.props;
        const { image, backgroundInterval, imagesArr } = this.state;
        var settings = {
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: (backgroundInterval !== null),
            draggable: false,
            autoplaySpeed: (backgroundInterval !== null ? backgroundInterval : 500)
        };
        return (
            <div className={classes.BackgroundSlider+" background-slider"}>
                <Carousel {...settings} >
                    {
                        image === null ?
                            imagesArr.map((imageObj, key) => {
                                return this.renderSlide(imageObj, key)
                            })
                        :
                        this.renderSlide(image)
                    }
                </Carousel>
            </div>
        )
    }
}

BackgroundSlider.propTypes = {
    classes: PropTypes.object
};

export default withStyles(backgroundSliderStyle)(BackgroundSlider);
