import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api.js";
import ApiDrive from "../../../assets/js/utils/oneDrive/Api";
import ApiSharepoint from "../../../assets/js/utils/Sharepoint/Api";
import Auth from "../../../assets/js/utils/Auth";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { helper } from "../../../assets/js/utils/Element";
import withStyles from "@material-ui/core/styles/withStyles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import EditIcon from "@material-ui/icons/Edit";
import NewEditAccountModal from '../../components/User/oneDrive/NewEditAccountModal';
import SharepointAccountModal from "../../components/User/Sharepoint/SharepointAccountModal";
import ConfirmationModal from '../../components/User/ConfirmationModal.js';
import Config from '../../../../Config';
import MsOfficeIcon from "../../../assets/img/ms_office.png";
import DocusignIcon from "../../../assets/img/docusign.png";
import AdobesignIcon from "../../../assets/img/adobesign.png";
import OnedriveIcon from "../../../assets/img/onedrive.png";
import SharepointIcon from "../../../assets/img/sharepoint.png";
import MessageModal from "../../components/User/ContactConfirmationModal";

import userSettingsPageStyle from "../../../assets/jss/user/userSettingsPageStyle.jsx";

const UserExtensions = class extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();
        this.state = {
            isPopupOpen: false,
            edit: false,
            newEditAccountModal: false,
            user: user,
            extensions: user.extensions,
            saving: false,
            validation: {
                isValid: false,
            },
            cancelToken: null,
            sharepointAccountModal: false,
            messageModal: false,
            messageModalMessage: '',
            deleteAccountModal: false,
            deleteAccountType: '',
            deleteingAccount: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);

        this.scrollContainerRef = React.createRef();
        this.scrollPosition = 0;
    }
    componentDidMount() {
        const { authorized, user } = this.store.getState();
        if (!authorized && !Auth.hasAccessToken()) {
            const location = this.history.location;
            const loginRequired = "/auth/login?return=" + encodeURIComponent(location.pathname + location.search);
            this.history.push(loginRequired);
            return;
        }

        if (authorized && user.hasOwnProperty('businessUser') && user.businessUser !== null && user.member_type !== 'business') {
            let redirectUrl = "/user/home";
            if (user.level_id === 5) {
                redirectUrl = "/user/shared";
            }
            this.history.push(redirectUrl);
        }

        if(authorized){
            let showAccessPopup = helper.getParam("showAccessPopup");
            let type = helper.getParam("type");
            let showSharepointAccountModal = helper.getParam("showSharepointAccountModal");
            let showOneDriveAccountModal = helper.getParam("showOneDriveAccountModal");
            
            if(showAccessPopup !== 'null' && type !== 'null'){
                let message = '';
                if(type === 'onedrive'){                
                    message = localStorage.getItem("one_drive_email")+' does not have a OneDrive account that is accessible.';
                }else if(type === 'sharepoint'){
                    message = localStorage.getItem("sharepoint_email")+' does not have a Sharepoint account that is accessible.';
                }

                this.messageModal(true, message);
            }else if(showOneDriveAccountModal !== 'null'){
                this.setState({
                    newEditAccountModal: true
                })
            }else if(showSharepointAccountModal !== 'null'){
                this.setState({
                    sharepointAccountModal: true
                })
            }else{
                // if(localStorage.getItem("one_drive_token") == null){
                const currentURL = window.location.href;
                const updatedURL = currentURL.replace(/#/g, '?');
                window.history.replaceState(null, '', updatedURL);
                let msAccessToken = helper.getParam("access_token");
                const sharepointAuth = localStorage.getItem("sharepoint_auth_flow");
                if (msAccessToken !== 'null') {
                    if(sharepointAuth === "1"){
                        localStorage.setItem("sharepoint_token", msAccessToken);
                        this.getEmailId(true);
                    }else{
                        localStorage.setItem("one_drive_token", msAccessToken);
                        this.getEmailId();
                    }
                }
            }

            // if(extensionsPageScrollPosition > 0){
            //     this.scrollContainerRef.current.scrollTop = extensionsPageScrollPosition;
            // }
        }
    }
    componentWillUnmount() {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    saveScrollPosition = () => {
        const scrollContainer = this.scrollContainerRef.current;
        if (scrollContainer) {
            this.scrollPosition = scrollContainer.scrollTop;
        }
    };
    messageModal(status = false, message = ''){
        this.setState({
            messageModal: status,
            messageModalMessage: message
        })

        if(status === false){
            this.props.history.replace({
                showAccessPopup: '',
                type: ''
            })
        }
    }
    loginWith(sharepointAuth = false){
        let clientId = Config.getClientIdOneDrive();
        if(sharepointAuth){
            clientId = Config.getClientIdSharepoint();
            localStorage.setItem("sharepoint_auth_flow", 1);
        }else{
            localStorage.removeItem("sharepoint_auth_flow");
        }
        let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
        const scope = Config.getOnedriveScope();
        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientId+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
        window.location.replace(URL);
    }
    getEmailId(sharepoint = false){
        if(sharepoint){
            ApiSharepoint.getEmail().then(data => {
                const email = data.mail;
                localStorage.setItem("sharepoint_email", email);
                localStorage.setItem("sharepoint_connected", true);
                localStorage.removeItem("sharepoint_auth_flow");     
                
                const site = localStorage.getItem("sharepoint_site");
                
                if(!site || site.length <= 0){
                    this.history.push('/user/extensions?showSharepointAccountModal=true');
                }else{
                    this.history.push('/user/extensions');
                }
            }).catch(err => {
                console.log('error',err);
            });
        }else{
            ApiDrive.getOneDriveEmail().then(data => {
                let oldEmail = localStorage.getItem("one_drive_email");
                const email = data.mail;
                localStorage.setItem("one_drive_email", email);
                localStorage.setItem("one_drive_ac_connect", true);

                if(oldEmail !== email){
                    this.history.push('/user/extensions?showOneDriveAccountModal=true');
                }else{
                    this.history.push('/user/extensions');
                }
            }).catch(err => {
                console.log('error',err);
            });

            // this.history.push('/user/home?dir=/OneDrive');
        }        
    }
    handleSubmit(e = null) {
        let { extensions } = this.state;
        const { user } = this.store.getState();
        if(e !== null){
            e.preventDefault();
        }else{
            extensions = user.extensions;
        }
        const isValid = this.validateForm();
        if (!isValid) {
            return;
        }

        const source = axios.CancelToken.source();
        this.setState({ cancelToken: source, saving: true });
        const requestData = {
            extensions: extensions,
        }

        Api.updateUserExtensions(requestData, source).then(data => {
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.body));
            let newUser = Api.prepareMemberObject(data.body);
            this.store.dispatch({ type: "UPDATE_STATE", state: { 
                user: newUser,
                // extensionsPageScrollPosition: this.scrollPosition
            } });
            this.history.push('/user/extensions');
        }).catch(err => {
            if (err && err.message !== "Request Cancelled") {
                this.setState({ cancelToken: null, saving: false });
            }
        });
    }
    onNewEditAccountModal(status = false, submit = false) {
        this.setState({
            newEditAccountModal: status,
        });

        if(status === false){
            if(submit === true){
                this.handleSubmit();
            }else{
                this.props.history.replace({
                    showOneDriveAccountModal: '',
                })
            }
        }
    }
    onSharepointAccountModal(status = false, submit = false){
        this.setState({
            sharepointAccountModal: status,
        });

        if(status === false){
            this.props.history.replace({
                showSharepointAccountModal: '',
            })

            if(submit === true){
                this.handleSubmit();
            }
        }
    }
    onDeleteAccountModal(status = false, deleteAccount = false, deleteAccountType = ''){
        if(status === false){
            this.setState({
                deleteAccountModal: false,
                deleteAccountType: ''
            })
        }else if(status === true){
            if(deleteAccount === true){
                if(this.state.deleteAccountType === 'oneDrive'){
                    this.onDeleteOneDrive();
                }else if(this.state.deleteAccountType === 'sharepoint'){
                    this.onDeleteSharepoint();
                }
            }else{
                this.setState({
                    deleteAccountModal: true,
                    deleteAccountType: deleteAccountType,
                })
            }
        }
    }
    onDeleteOneDrive = () => {
        const oneDriveEmail = localStorage.getItem("one_drive_email");

        localStorage.removeItem("one_drive_token");
        localStorage.removeItem("one_drive_email");
        localStorage.removeItem("one_drive_ac_connect");
        this.setState({ 
            deleteingAccount: true 
        });
        const { user } = this.state;
        const requestData = {
            "userId":user?.id
        }
        const source = axios.CancelToken.source();
        ApiDrive.updateSyncStatus(requestData, source).then(data => {
            this.setState({
                deleteAccountModal: false,
                deleteAccountType: '',
                deleteingAccount: false
            });

            const sharepointEmail = localStorage.getItem("sharepoint_email");
            if(oneDriveEmail !== sharepointEmail){
                const clientIdOneDrive = Config.getClientIdOneDrive();
                const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/logout?client_id='+clientIdOneDrive+'&post_logout_redirect_uri='+window.origin+'/user/home';
                window.location.href = URL;
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onDeleteSharepoint(){
        const sharepointEmail = localStorage.getItem("sharepoint_email");

        localStorage.removeItem("sharepoint_token");
        localStorage.removeItem("sharepoint_email");
        localStorage.removeItem("sharepoint_connected");
        localStorage.removeItem("sharepoint_auth_flow");
        localStorage.removeItem("sharepoint_site");
        localStorage.removeItem("sharepoint_connection");
        this.setState({ 
            deleteingAccount: true 
        });
        const { user } = this.state;
        const requestData = {
            "userId": user?.id,
            "driveName": "sharepoint"
        }
        const source = axios.CancelToken.source();
        ApiSharepoint.updateSyncStatus(requestData, source).then(data => {
            this.setState({
                deleteAccountModal: false,
                deleteAccountType: '',
                deleteingAccount: false
            });

            const oneDriveEmail = localStorage.getItem("one_drive_email");
            if(oneDriveEmail !== sharepointEmail){
                const clientId = Config.getClientIdOneDrive();
                const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/logout?client_id='+clientId+'&post_logout_redirect_uri='+window.origin+'/user/home';
                window.location.href = URL;
            }
        }).catch(err => {
            console.log(err);
        });
    }
    handleCheckbox(e, name) {
        this.setState({
            extensions: {
                ...this.state.extensions,
                [name]: {
                    'status': e.target.checked ? 1 : 0
                }
            }
        }, () => {
            this.handleSubmit(e);
        });
    }
    validateForm() {
        let validation = {
            isValid: true
        };

        return validation.isValid;
    }
    saveButtonDisabled() {
        const { user, extensions } = this.state;

        if (
            (user.extensions.ms_office.status !== extensions.ms_office.status) ||
            (user.extensions.google_workspace.status !== extensions.google_workspace.status) ||
            (user.extensions.docusign.status !== extensions.docusign.status) ||
            (user.extensions.adobesign.status !== extensions.adobesign.status) ||
            (user.extensions.autodesk.status !== extensions.autodesk.status)
        ) {
            return false;
        }

        return false;
    }
    render() {
        const { classes } = this.props;
        const { extensions, newEditAccountModal, sharepointAccountModal, messageModal, messageModalMessage,
            deleteAccountModal, deleteingAccount } = this.state;
        const { authorized, user } = this.store.getState();
        const is_connected = localStorage.getItem('one_drive_ac_connect');
        const isSharepointConnected = localStorage.getItem('sharepoint_connected');
        if (!authorized) {
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content} ref={this.scrollContainerRef} onScroll={this.saveScrollPosition}>
                        <GridItem>
                            <div className={classes.extensionsContainer}>
                                <h3 className={classes.title}>Extensions</h3>
                                <form onSubmit={this.handleSubmit} className={classes.formFields}>
                                    <div className={classes.extensionsLists}>
                                        {
                                            Config.isAdobesignAllowed() ?
                                                <div className={"extension"}>
                                                    <div className='icon-detail'>
                                                        <img src={AdobesignIcon} alt="Adobesign" />
                                                        <div>
                                                            <p className='title'>Adobesign</p>
                                                            <p className='description'>The Adobe Sign extension for Sendlinx enables users to sign, send, and autosave fully signed documents back into My Docs. It integrates electronic signature capabilities, streamlining the signing process. This enhances workflow efficiency by simplifying document handling and approval.</p>
                                                        </div>
                                                    </div>
                                                    <FormControlLabel
                                                        classes={{ label: classes.label }}
                                                        control={
                                                            <Switch
                                                                checked={(extensions.adobesign.status ? true : false)}
                                                                onChange={(e) => this.handleCheckbox(e, 'adobesign')}
                                                                value="adobesign"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        }
                                                        label={""}
                                                    />
                                                </div>
                                            :
                                                <></>
                                        } 
                                        {
                                            Config.isDocusignAllowed() ?
                                                <div className={"extension"}>
                                                    <div className='icon-detail'>
                                                        <img src={DocusignIcon} alt="Docusign" />
                                                        <div>
                                                            <p className='title'>Docusign</p>
                                                            <p className='description'>The DocuSign extension for cloud storage integrates electronic signature capabilities directly within the Sendlinx environment. It allows users to sign, send, and autosave fully signed documents back into My Docs without leaving the platform.</p>
                                                        </div>
                                                    </div>
                                                    <FormControlLabel
                                                        classes={{ label: classes.label }}
                                                        control={
                                                            <Switch
                                                                checked={(extensions.docusign.status ? true : false)}
                                                                onChange={(e) => this.handleCheckbox(e, 'docusign')}
                                                                value="docusign"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        }
                                                        label={""}
                                                    />
                                                </div>
                                            :
                                                <></>
                                        }   
                                        <div className={"extension"}>
                                            <div className='icon-detail'>
                                                <img src={MsOfficeIcon} alt="MS Office" />
                                                <div>
                                                    <p className='title'>Microsoft Office 365 Online(Word, Excel, PowerPoint)</p>
                                                    <p className='description'>The Office online extension enables you to  to directly open, edit, and collaborate on documents stored in Sendlinx My Docs. It ensures real-time updates and automatic saving, streamlining document management. Users benefit from a seamless, integrated editing experience without needing to download files.</p>
                                                </div>
                                            </div>
                                            <FormControlLabel
                                                classes={{ label: classes.label }}
                                                control={
                                                    <Switch
                                                        checked={(extensions.ms_office.status ? true : false)}
                                                        onChange={(e) => this.handleCheckbox(e, 'ms_office')}
                                                        value="ms_office"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                    />
                                                }
                                                label={""}
                                            />
                                        </div>
                                        <div className={"extension"}>
                                            <div className='icon-detail'>
                                                <img src={OnedriveIcon} alt="OneDrive" />
                                                <div>
                                                    <p className='title'>Microsoft OneDrive</p>
                                                    <p className='description'>The OneDrive extension for Sendlinx allows users to connect to OneDrive, copy files to their "My Docs" area, and manage secure links. This integration facilitates easy file access and secure sharing directly from OneDrive. It streamlines document handling and enhances link management efficiency.</p>
                                                </div>
                                            </div>
                                            {
                                                extensions.one_drive?.status && localStorage.getItem("one_drive_email") ?
                                                    <div className={classes.tableSection}>
                                                        <TableContainer className='TabContainer'>
                                                            <Table >
                                                                <TableHead>
                                                                    <TableRow >
                                                                        <TableCell>Account</TableCell>
                                                                        <TableCell>Status</TableCell>
                                                                        <TableCell>Permissions</TableCell>
                                                                        <TableCell className='path'>Path</TableCell>
                                                                        <TableCell></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                
                                                                    <TableRow
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row" className=''>
                                                                            {localStorage.getItem("one_drive_email")}
                                                                        </TableCell>
                                                                        <TableCell className=''>Connected</TableCell>
                                                                        <TableCell className=''>Copy To My Docs</TableCell>
                                                                        <TableCell  className='path' title={"/My Docs/"+user.extensions.one_drive.path}>
                                                                            <div>{"/My Docs/"+user.extensions.one_drive.path}</div>
                                                                        </TableCell>
                                                                        <TableCell className=''>
                                                                            <Button justIcon onClick={() => this.onNewEditAccountModal(true)} color="transparent" title="edit">
                                                                                <EditIcon />
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                : 
                                                    null 
                                            }
                                            <div className="extension-actions">
                                                <div className={"buttons"}>
                                                    {
                                                        extensions.one_drive?.status ? 
                                                            <>
                                                                <Button size="sm" onClick={() => this.loginWith()} color="custom" disabled={is_connected ? true : false} title="Add Account">
                                                                    Add Account
                                                                </Button>
                                                                <Button  size="sm" onClick={() => this.onDeleteAccountModal(true, false, 'oneDrive')} color="custom" disabled={is_connected ? false : true} ttitle="Delete Account">
                                                                    Delete Account
                                                                </Button>
                                                            </>
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                                <FormControlLabel
                                                    classes={{ label: classes.label }}
                                                    control={
                                                        <Switch
                                                            checked={(extensions.hasOwnProperty("one_drive") && extensions.one_drive.status ? true : false)}
                                                            onChange={(e) => this.handleCheckbox(e, 'one_drive')}
                                                            value="one_drive"
                                                            classes={{
                                                                switchBase: classes.switchBase,
                                                                checked: classes.switchChecked,
                                                                thumb: classes.switchIcon,
                                                                track: classes.switchBar
                                                            }}
                                                        />
                                                    }
                                                    label={""}
                                                />
                                            </div>
                                        </div>       
                                        <div className={"extension"}>
                                            <div className='icon-detail'>
                                                <img src={SharepointIcon} alt="Sharepoint" />
                                                <div>
                                                    <p className='title'>Microsoft SharePoint</p>
                                                    <p className='description'>The SharePoint extension for Sendlinx enables users to connect to SharePoint sites, copy files to their "My Docs" area, and manage secure links. This integration simplifies file handling and secure sharing directly from SharePoint. It enhances workflow efficiency by streamlining document management and link security.</p>
                                                </div>
                                            </div>
                                            {
                                                extensions.sharepoint?.status && localStorage.getItem("sharepoint_email") ?
                                                    <div className={classes.tableSection}>
                                                        <TableContainer className='TabContainer'>
                                                            <Table >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Account</TableCell>
                                                                        <TableCell>Status</TableCell>
                                                                        <TableCell>Permissions</TableCell>
                                                                        <TableCell className='path'>Path</TableCell>
                                                                        <TableCell></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row" className=''>
                                                                            {localStorage.getItem("sharepoint_email")}
                                                                        </TableCell>
                                                                        <TableCell className=''>Connected</TableCell>
                                                                        <TableCell className=''>Copy To My Docs</TableCell>
                                                                        <TableCell  className='path' title={"/My Docs/"+user.extensions.sharepoint.path}>
                                                                            <div>{"/My Docs/"+user.extensions.sharepoint.path}</div>                                                              
                                                                        </TableCell>
                                                                        <TableCell className=''>
                                                                            <Button justIcon onClick={() => this.onSharepointAccountModal(true)} color="transparent" title="edit">
                                                                                <EditIcon />
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>      
                                                    </div>
                                                : 
                                                    null
                                            }
                                            <div className="extension-actions">
                                                <div className={"buttons"}>
                                                    {
                                                        extensions.sharepoint?.status ? 
                                                            <>
                                                                <Button size="sm" onClick={() => this.loginWith(true)} color="custom" disabled={isSharepointConnected ? true :  false} title="Add Account">
                                                                    Add Account
                                                                </Button>
                                                                <Button size="sm" onClick={() => this.onDeleteAccountModal(true, false, 'sharepoint')} color="custom" disabled={isSharepointConnected ? false :  true} title="Delete Account">
                                                                    Delete Account
                                                                </Button>
                                                            </>
                                                            
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                                <FormControlLabel
                                                    classes={{ label: classes.label }}
                                                    control={
                                                        <Switch
                                                            checked={(extensions.hasOwnProperty("sharepoint") && extensions.sharepoint.status ? true : false)}
                                                            onChange={(e) => this.handleCheckbox(e, 'sharepoint')}
                                                            value="sharepoint"
                                                            classes={{
                                                                switchBase: classes.switchBase,
                                                                checked: classes.switchChecked,
                                                                thumb: classes.switchIcon,
                                                                track: classes.switchBar
                                                            }}
                                                        />
                                                    }
                                                    label={""}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className={"extension"}>
                                            <p className='title'>Google Workspace</p>
                                            <FormControlLabel
                                                classes={{ label: classes.label }}
                                                control={
                                                    <Switch
                                                        checked={(extensions.google_workspace.status ? true : false)}
                                                        onChange={(e) => this.handleCheckbox(e, 'google_workspace')}
                                                        value="google_workspace"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                    />
                                                }
                                                label={""}
                                            />
                                        </div> */}                                
                                        {/* <div className={"extension"}>
                                            <p className='title'>Autodesk</p>
                                            <FormControlLabel
                                                classes={{ label: classes.label }}
                                                control={
                                                    <Switch
                                                        checked={(extensions.autodesk.status ? true : false)}
                                                        onChange={(e) => this.handleCheckbox(e, 'autodesk')}
                                                        value="autodesk"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                    />
                                                }
                                                label={""}
                                            />
                                        </div> */}
                                        {
                                            newEditAccountModal && (
                                                <NewEditAccountModal
                                                    open={newEditAccountModal}
                                                    user={this.state.user}
                                                    onClose={(status, submit) => this.onNewEditAccountModal(false, submit)}
                                                    onSuccess={(folderName) => this.onNewFolderModalSuccess(folderName)}
                                                    confirmationMessage="Confirm Default Destination Folder"
                                                    store={this.store}
                                                    history={this.history}
                                                />
                                            )
                                        }
                                        {
                                            sharepointAccountModal && (
                                                <SharepointAccountModal
                                                    open={sharepointAccountModal}
                                                    user={this.state.user}
                                                    onClose={(status, submit) => this.onSharepointAccountModal(false, submit)}
                                                    store={this.store}
                                                    history={this.history}
                                                />
                                            )
                                        }
                                    </div>
                                    {/* <div className={classes.formFooter}>
                                        <Button color="custom" type="submit" disabled={this.saveButtonDisabled()}>
                                            {
                                                saving ?
                                                    <LoaderComponent color="white" align="center" saving={true} />
                                                :
                                                "Save"
                                            }
                                        </Button>
                                    </div> */}
                                </form>
                            </div>
                        </GridItem>
                    </div>
                    {
                        messageModal ?
                            <MessageModal 
                                onClose={() => this.messageModal()} 
                                open={messageModal} 
                                stor={this.store} 
                                history={this.history}
                                title={messageModalMessage}
                            />
                        :
                            <></>
                    }
                    {
                        deleteAccountModal ?
                            <ConfirmationModal 
                                open={deleteAccountModal} 
                                onClose={() => this.onDeleteAccountModal(false)} 
                                onSuccess={() => this.onDeleteAccountModal(true, true)}
                                confirmationMessage="Are you sure you want to delete account?"
                                cancelButtonText={"Cancel"}
                                successButtonText={"Yes"}
                                saving={deleteingAccount}
                            />
                        :
                            <></>
                    }
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserExtensions.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userSettingsPageStyle)(UserExtensions);
