import {
    whiteColor,
    grayColor,
    controls,
    separator,
    sidebar,
    content
} from "../material-kit-pro-react.jsx";

import userHomePageStyle from "./userHomePageStyle.jsx";
const transactionsPageStyle = theme => ({
    ...userHomePageStyle(theme),
    main: {
        marginTop: "0px"
    },
    container: {
        width: "100%",
        zIndex: "2",
        top: "62px",
        position: "relative",
        paddingTop: "20px",
        height: "calc(100vh - 62px)"
    },
    sidebar: {
        ...sidebar(theme)
    },
    content: {
        ...content(theme)
    },
    controls: {
        ...controls(theme)
    },
    separator: {
        ...separator(theme)
    },
    filesTable: {
        "& .rt-thead .rt-th": {
            fontSize: "12px",
            color: grayColor[7],
            fontWeight: "bold"
        },
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
            color: whiteColor,
        },
        "& .rt-tbody .rt-td": {
            padding: "0px 5px",
            margin: "5px 0px",
            "& svg": {
                fill: theme.customize.tableRowActionsIconsColor,
                color: theme.customize.tableRowActionsIconsColor
            },
            "& button": {
                padding: "0px",
                height: "17px",
                width: "30px",
                minWidth: "0px",
                margin: "0px"
            }
        },
        "& .hd_id": {
            width: "6% !important",
            textAlign: "center !important",
        },
        "& .hd_plan": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_gateway_transaction": {
            width: "20% !important",
            textAlign: "left !important",
        },
        "& .hd_amount": {
            width: "10% !important",
            textAlign: "left !important",
        },
        "& .hd_currency": {
            width: "5% !important",
            textAlign: "left !important",
        },
        "& .hd_payment_method": {
            width: "13% !important",
            textAlign: "left !important",
        },
        "& .hd_card_number": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            color: grayColor[17],
            opacity: "1 !important"
        },
        "& .rt-td": {
            fontSize: "12px"
        },
        "& .rt-tr": {
            cursor: "pointer"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "10px"
        }
    },
    loadMore: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
});

export default transactionsPageStyle;
