import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const disconnectAccountModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center",
        marginBottom: "10px"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "400px !important"
    },
    modalBody: {
        paddingTop: "24px",
        paddingRight: "24px",
        paddingBottom: "16px",
        paddingLeft: "24px",
        position: "relative",
        overflow: "visible",
        textAlign: "center"
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        textAlign: "center",
        marginTop: theme.spacing(2),
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
    },
    filesList: {
        listStyleType: "none",
        paddingLeft: "0px",
        "& li": {
            fontSize: "12px",
            fontWeight: "bold"
        }
    }
});
  
export default disconnectAccountModalStyle;
  