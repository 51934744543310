import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

import upgradeModalStyle from "../../../assets/jss/user/upgradeModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const UpgradeModal = class extends React.Component {
    render() {
        const { classes, message } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="messageModal-modal-slide-title"
                aria-describedby="messageModal-modal-slide-description"
            >
                <DialogTitle
                    id="messageModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <h4 className={classes.modalTitle+" "+classes.textCenter}>{message}</h4> 
                </DialogTitle>
                
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    <Button color="custom" onClick={() => this.props.onClose()}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

UpgradeModal.defaultProps = {
    open: false,
    message: "",
}
UpgradeModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    message: PropTypes.string,
}
export default withStyles(upgradeModalStyle)(UpgradeModal);