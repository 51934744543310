import {
    dangerColor,
    whiteColor,
    grayColor,
    editor,
    tableHeadColumns,
    tableBody,
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
const shareModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important"
    },
    justifyContentBetween: {
        WebkitBoxPack: "space-between !important",
        MsFlexPack: "space-between !important",
        justifyContent: "space-between !important",
        alignSelf: "space-between !important"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "1050px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "25px",
        paddingBottom: "20px",
        "&.pb-zero": {
            paddingBottom: "0px"
        },
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& li, & span": {
            opacity: "1 !important",
        },
    },
    transferForm: {
        position: "relative",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        zIndex: "2",
        borderRadius: "10px",
        height: "auto",
        marginRight: "auto",
        marginLeft: "auto",
        width: "100%",
        marginBottom: "0px",
        overflow: "hidden",
    },
    fileSendingForm: {
        borderRadius: "0px",
        backgroundColor: "transparent",
        textAlign: "center",
        "& >div": {
            width: "100%",
            overflow: "hidden",
            clear: "both"
        },
    },
    transferTypeLink: {
        marginBottom: "25px",
        "& button": {
            color: whiteColor+" !important",
            fontSize: "16px",
            textTransform: "none",
            padding: "0px",
            margin: "0px",
            textShadow: "2px 2px 2px #000",
            "& img": {
                maxWidth: "28px",
                marginRight: "8px"
            }
        }
    },
    formFields: {
        "& > div": {
            marginBottom: "10px",
            overflow: "hidden",
            clear: "both",
            "& .MuiInput-multiline": {
                "&:before, &:after": {
                    content: "none"
                }
            }
        },
        "& .formElement": {
            width: "100%",
            padding: "0",
            position: "relative",
            "& p":{
                textAlign: "left",
                fontSize: "14px",
                margin: "0px",
                clear: "both"
            }
        },
        "& input, textarea": {
            padding: "10px",
            boxSizing: "border-box",
            width: "100%",
            verticalAlign: "top",
            display: "inline-block",
            float: "right",
        },
        "& input": {
            paddingRight: "30px"
        },
        "& textarea": {
            minHeight: "200px"
        },
        "& label": {
            color: whiteColor,
            maxWidth: "150px",
            width: "100%",
            textAlign: "right",
            fontSize: "18px",
            marginTop: "7px",
            display: "inline-block",
            textShadow: "2px 2px 2px #000",
        },
        "& .claer-icon": {
            position: "absolute",
            top: "7px",
            right: "5px",
            fill: dangerColor[0]
        },
        [theme.breakpoints.down("sm")]: {
            "& label":{
                maxWidth: "100%",
                textAlign: "left",
                display: "block",
                padding: "0",
                fontSize: "16px",
                marginTop: "0px"
            },
            "& textarea": {
                minHeight: "135px",
            },
            "& .formElement": {
                width: "100%",
                float: "none"
            }
        },
    },
    transferFooter: {
        position: "relative",
        padding: "0",
        borderTop: "1px solid "+theme.customize.borderColorModal,
        height: "4.5em",
        "& Button": {
            "& svg":{
                cursor: "pointer",
                height: "1.5em",
                marginRight: "0.2em",
                width: "1.5em",
                fill: theme.customize.iconsColor,
                fontSize: "inherit",
                transform: "rotate(-45deg)",
                display: "inline-block",
                verticalAlign: 'top',
                marginTop: "-5px",
                "&:hover": {
                    fill: theme.customize.iconsColor,
                }
            },
            display: "block",
            fontSize: "1em",
            margin: "12px auto",
            color: theme.customize.linksColor+" !important",
            padding: "10px 30px",
            textTransform: "capitalize"
        }
    },
    fileSendingFormFooter: {
        textAlign: "left",
        width: "100% !Important",
        border: "none",
        height: "auto",
        "& img": {
            width: "1.5em",
            marginTop: "-5px",
            marginRight: "0.2em",
        },
        "& Button": {
            borderBottom: "1px solid "+grayColor[17],
            textAlign: "left",
            backgroundColor: "transparent !Important",
            width: "100%",
            borderRadius: "0px",
            padding: "10px 8px",
            boxShadow: "none !Important",
            margin: "0px"
        },
    },
    disableFooter: {
        borderRadius: "0px 0px 5px 5px"
    },
    editor: {
        ...editor(theme)
    },
    uploaderList: {
        position: "relative",
        width: "100% !Important",        
        borderRadius: "0px 0px 5px 5px",
        "& .uploader-add-files-count": {
            textAlign: "right",
            fontSize: "14px",
            lineHeight: "normal",
            marginBottom: "0px",
            paddingTop: "5px",
            paddingBottom: "5px",
            marginTop: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& .files-permission-header": {
                display: "flex",
                alignItems: "center",
                "& span": {
                    width: "20px",
                    textAlign: "center",
                    marginRight: "10px",
                    "& img": {
                        maxWidth: "14px",
                        "&.read-write-icon": {
                            maxWidth: "12px",
                        }
                    },
                    "& svg": {
                        color: theme.customize.iconsColor,
                        fill: theme.customize.iconsColor
                    }
                }
            },
            "& .files-detail": {
                width: "100%",
                color: theme.customize.color,
                "& span": {
                    color: theme.customize.linksColor,
                    borderLeft: "1px solid "+theme.customize.borderColorModal,
                    marginLeft: "6px",
                    paddingLeft: "5px",
                    verticalAlign: "top",
                    maxWidth: "110px",
                    width: "100%",
                    display: "inline-block",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }
            }
        },
    },
    filesList: {
        listStyle: "none",
        margin: "0",
        padding: "0",
        "& .file": {
            cursor: "default",
            margin: "0",
            outline: "0",
            padding: "10px 0px",
            position: "relative",
            textAlign: "left",
        },
        "& .file-header": {
            position: "relative"
        },
        "& .file-title": {
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "normal",
            margin: "0",
            overflow: "hidden",
            padding: "0",
            display: "flex",
            alignItems: "center",
            "& .file-name": {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "calc(100% - 220px)",
                display: "inline-block",
                verticalAlign: "middle",
                flex: "1"
            },
            "& .icon":{
                maxWidth: "18px",
                width: "100%",
                marginRight: "5px",
                textAlign: "center",
                display: "inline-block",
                "& img": {
                    verticalAlign: "bottom",
                    width: "100%",
                },
                "&.file-icon": {
                    "& img": {
                        maxWidth: "13px"
                    }
                },
                "& button": {
                    padding: "0px",
                    width: "auto",
                    height: "auto",
                    minWidth: "auto",
                }
            }
        },
        "& .file-detail":{
            float: "right",
            borderLeft: "1px solid "+theme.customize.borderColorModal,
            paddingLeft: "5px",
            maxWidth: "110px",
            width: "100%",
            textAlign: "right",
            "& svg": {
                width: "9px",
                height: "7px"
            },
            "& .folder-detail": {
                marginLeft: "0.25em"
            }
        },
        "& .filelist-action-delete": { 
            cursor: "pointer",
            position: "absolute",
            right: "-8px",
            top: "-4px",
            width: "auto",
            display: "none",
            "& img": {
                width: "28px",
            },
            [theme.breakpoints.down("xs")]: {
                display: "block"
            },
        },
        "& .file:hover": {
            "& .filelist-action-delete": { 
                display: "block"
            }
        },
        "& .file-permissions": {
            marginRight: "10px",
            "& .version-control": {
                marginRight: "10px"
            },
            "& .read-write": {
                marginRight: "10px"
            },
            "& .MuiCheckbox-root": {
                padding: "0px"
            }
        }
    },
    tagsInput: {
        textAlign: "left",
        marginBottom: "5px",
        width: "100%",
        padding: "5px",
        borderRadius: "5px",
        "& .react-tagsinput-tag a::before": {
            color: theme.customize.iconsColor,
        },
        "& .users-icon": {
            float: "left",
            "& img": {
                maxWidth: "20px",
                width: "100%"
            },
            "& svg": {
                width: "20px",
                display: "block",
                color: theme.customize.iconsColor,
                fill: theme.customize.iconsColor
            }
        },
        "& .users-lists": {
            paddingLeft: "20px"
        },
        "& span": {
            textTransform: "none",
            backgroundColor: "transparent !Important",
            borderRight: "1px solid "+theme.customize.borderColorModal,
            color: theme.customize.linksColor,
            borderRadius: "0px",
            lineHeight: "normal",
            paddingTop: "0px",
            paddingBottom: "0px",
            "& a": {
                top: "1px"
            }
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            float: "none"
        },
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        padding: "0px",
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
    },
    errorMessage: {
        color: dangerColor[0]
    },
    sharedWith: {
        paddingLeft: "0px",
        listStyleType: "none",
        "& li": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        }
    },
    customSelectControl: {
        minWidth: "100px",
        "& label": {
            display: "none !important"
        },
        "& .MuiFormControl-root": {
            marginTop: "0px !important",
            marginBottom: "0px !important"
        },
        "& .MuiInputBase-root": {
            marginTop: "0px !important",
        }
    },
    shareModalTransferFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid "+theme.customize.borderColorModal,
        textAlign: "left",
        "& img": {
            maxWidth: "18px",
            marginRight: "5px"
        }
    },
    usersContainer: {
        maxWidth: "100%",
        width: "100%",
        overflow: "auto",
    },
    usersTable: {
        ...tableHeadColumns(theme),
        ...tableBody(theme),
        marginTop: "20px",
        "& .rt-table": {
            "&:not(:hover)": {
                "&::-webkit-scrollbar": {
                    width: "15px",
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(0,0,0,0.3)",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                    
                }
            }
        },
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
            color: theme.customize.color,
        },
        "& .rt-tbody .rt-td": {
            padding: "0px 5px",
            margin: "5px 0px",
            display: "flex",
            alignItems: "center",
            "&.td_name": {
                margin: "0px",
                padding: "0px",
                "& .react-contextmenu-wrapper": {
                    padding: "5px 5px",
                    margin: "0px",
                }
            },
            "& .rt-expander": {
                "&::after": {
                    borderTopColor: theme.customize.iconsColor
                }
            }
        },
        "& .hd_check": {
            width: "30px !important",
            textAlign: "left !important",
            paddingLeft: "0px !important",
            "& .MuiCheckbox-root": {
                padding: "0px"
            },
        },
        "& .hd_email": {
            width: "22% !important",
            textAlign: "left !important",
        },
        "& .hd_owner_notification": {
            width: "11% !Important",
        },
        "& .hd_group_notification": {
            width: "11% !Important",
        },
        "& .hd_expires": {
            width: "15% !important",
            textAlign: "left !important",
            "& .MuiButton-root": {
                padding: "0px",
                height: "auto",
                minWidth: '30px',
                width: '30px'
            },
        },
        "& .hd_versionControl": {
            width: "130px !Important",
            textAlign: "left !important",
        },
        "& .td_check": {
            "& .MuiButtonBase-root": {
                padding: "0px",
            }            
        },
        "& .hd_status": {
            width: "6% !important",
            textAlign: "left !important",
            textTransform: "capitalize",
            "& div": {
                width: "100%"
            }
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            color: grayColor[17],
            opacity: "1 !important"
        },
        "& .rt-td": {
            fontSize: "12px"
        },
        "& .rt-resizer": {
            display: "none !important"
        },
        "& .rt-resizable-header-content:after": {
            display: "none !important"
        }
    },
    actionButtons: {
        "& button": {
            maxWidth: "210px",
            whiteSpace: "normal",
            textTransform: "capitalize",
            padding: "5px 8px",
        },
        "& img": {
            marginRight: "2px",
            height: "18px",
        }
    },
    csvFileIcon: {
        fontSize: "12px",
        color: theme.customize.linksColor,
        "& img": {
            height: "24px",
            marginRight: "2px"
        }
    },
    newUserActions: {
        "& > div": {
            display: "flex",
            fontSize: "14px",
            alignItems: "center",
            justifyContent: "space-between",
            color: theme.customize.color,
            "& > label": {
                width: "120px",
                color: theme.customize.color
            }
        }
    },
    permissionActions: {
        display: "none",
        "& > div": {
            flexDirection: "column",
            alignItems: "flex-start",
        }
    },
    notificationActions: {
        "& > div": {
            "& > label": {
                marginRight: "10px"
            }
        }
    },
    newExpiryDate: {
        "& button": {
            width: "24px",
            height: "24px",
            minWidth: "24px",
            margin: "0px 0px 0px 5px"
        }
    },
    newVersionControl: {
        
    },
    capitalize: {
        textTransform: "capitalize"
    }
});
  
export default shareModalStyle;
  