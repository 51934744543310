import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "../CustomInput/CustomInput";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import changeExpiryDateModalStyle from "../../../assets/jss/user/changeExpiryDateModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ChangeLinkPasswordModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            linkPassword: this.props.linkPassword,
            orgLinkPassword: this.props.linkPassword,
            viewPassword: false
        };

    }
    componentWillReceiveProps(props){
        this.setState({
            linkPassword: props.linkPassword,
        });
    }
    handleChange(e, name) {
        this.setState({
            [name]: e.target.value
        });
    }
    onViewPassword(){
        this.setState({
            viewPassword: !this.state.viewPassword
        })
    }
    render() {
        const { classes, saving, confirmationMessage } = this.props;
        const { linkPassword, viewPassword, orgLinkPassword } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        {
                            saving ?
                                <></>
                            :
                                <h4 className={classes.modalTitle}>{confirmationMessage}</h4> 
                        }
                </DialogTitle>
                <DialogContent
                    id="confirmationModal-modal-slide-description"
                    className={classes.modalBody}
                >
                    {
                        saving ?
                            <LoaderComponent color="custom" align="center" />
                        :
                            <CustomInput
                                labelText="Link Password (optional)"
                                id="input-password"
                                passwordInput={viewPassword ? false : true}
                                inputProps={{
                                    value: linkPassword,
                                    name: "linkPassword",
                                    type: "text",
                                    onChange: (e) => this.handleChange(e, 'linkPassword'),
                                    autoComplete: 'off',
                                    endAdornment: (
                                        <InputAdornment 
                                            className={classes.inputAdornment+' viewPasswordIcon'} 
                                            onClick={() => this.onViewPassword() }
                                            position='end'
                                        >
                                            {
                                                linkPassword ?
                                                    viewPassword ?
                                                        <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                                    :
                                                        <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                                :
                                                    <></>
                                            }
                                        </InputAdornment>
                                    ),
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: "custom-input m-zero"
                                }}
                            />
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    {
                        saving ?
                            <></>
                        :
                            <Button color="custom" disabled={((orgLinkPassword === '' || orgLinkPassword === null) && (linkPassword === '' || linkPassword === null)) || orgLinkPassword === linkPassword ? true : false} className={classes.changeButton} onClick={() => this.props.onSuccess(linkPassword)}>
                                Change
                            </Button>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

ChangeLinkPasswordModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false
}
ChangeLinkPasswordModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool
}
export default withStyles(changeExpiryDateModalStyle)(ChangeLinkPasswordModal);