import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import deleteFileModalStyle from "../../../assets/jss/user/deleteFileModalStyle";
import Close from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DeleteFileVersionModal = class extends React.Component {
  render() {
    const { classes, file } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="deleteFileVersion-slide-title"
        aria-describedby="deleteFileVersion-slide-description"
      >
        <DialogTitle
            id="deleteFileVersion-slide-title"
            disableTypography
            className={classes.modalHeader}
        >
            <Button
                simple
                className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                key="close"
                aria-label="Close"
                onClick={() => this.props.onClose()}
            >
                {" "}
                <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Are you sure you want to delete <b>Version {file.version}</b>?</h4> 
        </DialogTitle>
        <DialogContent
          id="deleteFileVersion-slide-description"
          className={classes.modalBody}
        >
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.justifyContentCenter}
        >
          <Button color="custom" onClick={() => this.props.onSuccess()}>Delete</Button>
          <Button color="white" onClick={() => this.props.onClose()}>Cancel</Button>
        </DialogActions>        
      </Dialog>
    );
  }
};

DeleteFileVersionModal.defaultProps = {
  open: false,
  deletingUser: false,
  deleteAll: false,
  confirm: false
};
DeleteFileVersionModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  deleteAll: PropTypes.bool,
  confirm: PropTypes.bool
};
export default withStyles(deleteFileModalStyle)(DeleteFileVersionModal);
