import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactTable from "react-table";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

import linkTableViewStyle from "../../../assets/jss/linkTableViewStyle";
import SummarySubView from './SummarySubView';

class SummaryView extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        
        this.state = {
            tableData: this.props.tableData,
            limit: this.props.limit
        }
    }
    componentWillReceiveProps(props){
        this.setState({
            tableData: props.tableData,
            limit: props.limit
        });
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    render(){
        const { classes } = this.props;
        const { tableData, limit } = this.state;

        return (
            <div>
                <ReactTable
                    columns={[
                        {
                            Header: (
                                <Checkbox
                                    tabIndex={-1}
                                    checked={this.props.checkedAll}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    onChange={(e) => this.props.onCheckedAll(e)}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                />
                            ),
                            accessor: "check",
                            sortable: false,
                            filterable: false,
                            resizable: false,
                            headerClassName: "hd_check",
                            className: "hd_check td_check",
                        },
                        {
                            Header: "Date",
                            accessor: "created_at",
                            headerClassName: "hd_created",
                            className: "hd_created td_created",
                            sortMethod: (a, b) => {
                                var a1 = new Date(a).getTime();
                                var b1 = new Date(b).getTime();
                              if(a1<b1)
                                return 1;
                              else if(a1>b1)
                                return -1;
                              else
                                return 0;
                            }
                        },
                        {
                            Header: "Destination",
                            accessor: "recipients",
                            headerClassName: "hd_recipients",
                            className: "hd_recipients td_recipients",
                        },
                        {
                            Header: "Attachment(s)",
                            accessor: "attachmentsPlainText",
                            headerClassName: "hd_attachments hd_show_head",
                            className: "hd_attachments td_attachments td_hide",
                            resizable: false,
                        },
                        {
                            Header: "Attachment(s)",
                            accessor: "attachments",
                            headerClassName: "hd_attachments hd_hide",
                            className: "hd_attachments td_attachments",
                        },
                        {
                            Header: "Link Password",
                            accessor: "link_password",
                            headerClassName: "hd_link_password",
                            className: "hd_link_password td_link_password",
                        },
                        {
                            Header: "Expiry Date",
                            accessor: "expire_at",
                            headerClassName: "hd_expire",
                            className: "hd_expire td_expire",
                            sortMethod: (a, b) => {
                                var a1 = new Date(a).getTime();
                                var b1 = new Date(b).getTime();
                              if(a1<b1)
                                return 1;
                              else if(a1>b1)
                                return -1;
                              else
                                return 0;
                            }
                        },
                        {
                            Header: "Status",
                            accessor: "status",
                            headerClassName: "hd_status",
                            className: "hd_status td_status",
                        },
                        {
                            Header: "Copy Link",
                            accessor: "webLink",
                            headerClassName: "hd_action",
                            className: "hd_action td_action",
                        },
                        {
                            Header: "Change Link",
                            accessor: "action",
                            headerClassName: "hd_action",
                            className: "hd_action td_action",
                        },
                        {
                            Header: "Delete Link",
                            accessor: "delete",
                            headerClassName: "hd_action",
                            className: "hd_action td_action",
                        },
                    ]}
                    data={tableData}
                    pageSizeOptions={[500]}
                    defaultPageSize={limit}
                    showPaginationTop={false}
                    minRows={0}
                    showPaginationBottom={false}
                    className={"-striped -highlight desktop-view-table "+classes.filesTable}
                    getNoDataProps={() => this.getNoDataProps()}
                    SubComponent={(row) => {
                        return (
                            <SummarySubView row={row} />
                        )
                    }}
                />
            </div>
        )
    }
}

SummaryView.propTypes = {
    classes: PropTypes.object,
    onCheckedAll: PropTypes.func,
};

export default (withStyles(linkTableViewStyle)(SummaryView));