import {
    whiteColor,
    dangerColor
  } from "./material-kit-pro-react.jsx";

const contactPageStyle = theme => ({
    panelContent: {
        paddingLeft: "5em",
        paddingRight: "5em",
        paddingTop: "5.5em",
        [theme.breakpoints.down("md")]: {
            paddingLeft: "3em",
            paddingRight: "3em"
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "2em",
            paddingRight: "2em",
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1em",
            paddingRight: "1em"
        }
    },
    heading: {
        marginBottom: "40px",
        [theme.breakpoints.down("xs")]: {
            "& >div": {
                textAlign: "center",
            }
        },
        "& h1": {
            fontSize: "2.5em",
            marginTop: "0px",
            marginBottom: "0px",
            fontWeight: "500",
            [theme.breakpoints.down("sm")]: {
                fontSize: "2em",
            },
        },
    },
    textLeft: {
        textAlign: "left"
    },
    textRight: {
        textAlign: "right",
    },
    alignCenter: {
        alignSelf: "center"
    },
    formFields: {
        "& > div": {
            marginBottom: "10px",
            overflow: "hidden",
            clear: "both",
            "& .MuiInput-multiline": {
                "&:before, &:after": {
                    content: "none"
                }
            }
        },
        "& .formElement": {
            width: "100%",
            padding: "0",
            position: "relative",
            "& p":{
                color: whiteColor,
                textAlign: "left",
                fontSize: "14px",
                margin: "0px",
                textShadow: "2px 2px 2px #000",
            }
        },
        "& input, textarea": {
            backgroundColor: whiteColor,
            padding: "10px",
            boxSizing: "border-box",
            borderRadius: "3px",
            width: "100%",
            verticalAlign: "top",
            display: "inline-block",
            border: "none",
            float: "right"
        },
        "& textarea": {
            minHeight: "200px"
        },
        "& label": {
            color: whiteColor,
            maxWidth: "150px",
            width: "100%",
            textAlign: "right",
            fontSize: "18px",
            marginTop: "7px",
            display: "inline-block",
            textShadow: "2px 2px 2px #000",
        },
        "& .claer-icon": {
            position: "absolute",
            top: "5px",
            right: "15px",
            fill: dangerColor[0]
        },
        [theme.breakpoints.down("sm")]: {
            "& label":{
                maxWidth: "100%",
                textAlign: "left",
                display: "block",
                padding: "0",
                fontSize: "16px",
                marginTop: "0px"
            },
            "& textarea": {
                minHeight: "135px",
            },
            "& .formElement": {
                width: "100%",
                float: "none"
            }
        },
    },
    formFooter: {
        position: "relative",
        padding: "0",
        borderRadius: "5px",
        textAlign: "left",
        width: "100% !Important",
        border: "none",
        height: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        "& img": {
            width: "1.5em",
            marginTop: "-5px",
            marginRight: "0.2em",
        },
        "& Button": {
            "& svg":{
                cursor: "pointer",
                height: "1.5em",
                marginRight: "0.2em",
                width: "1.5em",
                fill: theme.customize.iconsColor,
                fontSize: "inherit",
                transform: "rotate(-45deg)",
                display: "inline-block",
                verticalAlign: 'top',
                marginTop: "-2px",
                "&:hover": {
                    fill: theme.customize.iconsColor,
                },
                "&.sendicon": {
                    transform: "none",
                }
            },
            display: "block",
            fontSize: "1em",
            color: whiteColor,
            textTransform: "capitalize",
            textAlign: "left",
            backgroundColor: "transparent !Important",
            width: "100%",
            borderRadius: "0px",
            padding: "10px 8px",
            boxShadow: "none !Important",
            margin: "0px"
        },
    },
    contactMapContainer: {
        marginBottom: theme.spacing(6),
        "& iframe": {
            width: "100%",
            height: "450px",
        }
    },
    detailContainer: {
        "& h3": {
            marginTop: "0px",
            fontWeight: "bold"
        },
        "& h4": {
            fontWeight: "bold"
        },
        "& p": {
            marginBottom: "4px"
        },
        "& span": {
            fontWeight: "bold",
        },
        [theme.breakpoints.down("md")]: {
            marginBottom: theme.spacing(2)
        }
    }
});

export default contactPageStyle;
