import React from 'react';
import axios from "axios";
import { createBrowserHistory } from 'history';
import Api from "../../../assets/js/utils/Api";
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from "../../../assets/js/utils/Element";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import RefreshIcon from "@material-ui/icons/Refresh";
import CustomTabs from "../../components/CustomTabs/CustomTabs.jsx";
import DisconnectAccountModal from "../../components/User/DisconnectAccountModal";
import OtherAccounts from "./accounts/OtherAccounts";
import UserLogins from "./accounts/UserLogins";
import SiteLicense from "./accounts/SiteLicense";

import withStyles from "@material-ui/core/styles/withStyles";
import userAccountsPageStyle from "../../../assets/jss/user/userAccountsPageStyle.jsx";
const history = createBrowserHistory();

const Accounts = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            totalItemCount: 0,
            response: null,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            page: 1,
            disconnectAccountModal: false,
            disconnectAccountDetail: {},
            activeTab: this.props.tab
        }; 
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }        
        if(authorized){
            this.loadAccounts();
        }
    }
    loadAccounts(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 200,
        };
        Api.getAccounts(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            cancelToken: source,
            page: page,
        });
    }
    onDisconnectAccountModal(status = true, item){
        if(status === false){
            this.setState({
                disconnectAccountModal: status,
            })
            return;
        }
        this.setState({
            disconnectAccountModal: status,
            disconnectAccountDetail: {
                userId: item.id,
            }
        })
    }
    onDisconnectAccount(){
        this.onDisconnectAccountModal(false);
        this.loadAccounts();
    }
    getTableData(){
        const { response } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(item => {
            let newItem = Object.assign({}, item);
            newItem['name'] = item.account_name;
            newItem['usage'] = this.getUserUsage(item);
            newItem['actions'] = (
                <Button color="white" simple size="lg"  onClick={() => this.onDisconnectAccountModal(true, item)}>Disconnect</Button>
            )
            tableData.push(newItem);
            return null;
        });
        return tableData;
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getUserUsage(user){
        let usage = "";
        if(user.hasOwnProperty("usage")){
            const usagePercentage = (user.usage / user.quota)*100;
            usage += usagePercentage.toFixed(2)+"%";
        }
        if(user.hasOwnProperty("usage")){
            usage += " | "+helper.getFormatedSize(user.quota);
        }

        return usage;
    }
    onTabChange(tab){
        let url = "/user/accounts/license";
        switch(tab){
            case 0:
                url = "/user/accounts/license";
                break;
            case 1:
                url = "/user/accounts";
                break;
            case 2:
                url = "/user/accounts/other";
                break;
            case 3:
                url = "/user/accounts/install";
                break;
            default:
                break;
        }
        history.push(url);
        this.setState({activeTab: tab});
    }
    getTabContent(){
        const { loading, limit, loadingMore, page, totalPages } = this.state;
        const { classes } = this.props;
        const tableData = this.getTableData();
        
        return(
            <>
                <h4>
                    Your Added Account(s)
                    <Button color="custom" justIcon onClick={() => this.loadAccounts()}>
                        <RefreshIcon className={classes.icons} />
                    </Button>
                </h4>
                <div className="accounts-content">
                    <GridContainer>
                        <GridItem>
                            <div className={classes.accounts}>
                                {
                                    loading ?
                                        <LoaderComponent color="custom" align="center" />
                                    :
                                        <ReactTable
                                            columns={[
                                                {
                                                    Header: "Computer Name",
                                                    accessor: "computer",
                                                    headerClassName: "hd_computer",
                                                    className: "hd_computer td_computer",
                                                },
                                                {
                                                    Header: "IP",
                                                    accessor: "lastupdated_ip",
                                                    headerClassName: "hd_ip",
                                                    className: "hd_ip td_ip",
                                                },
                                                {
                                                    Header: "Application",
                                                    accessor: "application",
                                                    headerClassName: "hd_application",
                                                    className: "hd_application td_application",
                                                },
                                                {
                                                    Header: "Account Name",
                                                    accessor: "name",
                                                    headerClassName: "hd_account_name",
                                                    className: "hd_account_name td_account_name",
                                                },
                                                {
                                                    Header: "First Name",
                                                    accessor: "first_name",
                                                    headerClassName: "hd_first_name",
                                                    className: "hd_first_name td_first_name",
                                                },
                                                {
                                                    Header: "First Name",
                                                    accessor: "last_name",
                                                    headerClassName: "hd_last_name",
                                                    className: "hd_last_name td_last_name",
                                                },
                                                {
                                                    Header: "",
                                                    accessor: "actions",
                                                    headerClassName: "hd_actions",
                                                    className: "hd_actions td_actions",
                                                    sortable: false,
                                                    filterable: false,
                                                },
                                            ]}
                                            data={tableData}
                                            pageSizeOptions={[500]}
                                            defaultPageSize={limit}
                                            showPaginationTop={false}
                                            minRows={0}
                                            showPaginationBottom={false}
                                            className={"-striped -highlight "+classes.filesTable}
                                            getNoDataProps={() => this.getNoDataProps()}
                                        />
                                }
                                
                            </div>
                            <div className={classes.loadMore}>
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="custom" id="contentViewMore" onClick={(e) => this.loadAccounts(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </>
        )
    }
    render() {
        const { classes } = this.props;
        const { disconnectAccountModal, disconnectAccountDetail, activeTab } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.accountMainContainer}>
                    <div className={classes.content}>
                        <GridItem>
                            <div className={classes.accountsContainer}>
                                <h3 className={classes.title}>Added Accounts</h3>
                                <div className="accounts-section">
                                    <h4>Registered User</h4>
                                    <div className="accounts-content">
                                        <GridContainer>
                                            <GridItem xs={12} sm={6}>
                                                <ul>
                                                    <li><span>First Name:</span>{user.first_name}</li>
                                                    <li><span>Last Name:</span>{user.last_name}</li>
                                                    <li><span>Email:</span>{user.email}</li>
                                                </ul>
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <ul>
                                                    <li><span>Account Type:</span>{user.plan}</li>
                                                    <li><span>Quota:</span>{helper.getFormatedSize(user.quota)}</li>
                                                </ul>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                    <div className={classes.customTabs}>
                                        <CustomTabs
                                            tabs={[
                                                {
                                                    tabName: "Site License(s)",
                                                    tabContent: <SiteLicense store={this.store} history={this.history} />
                                                },
                                                { 
                                                    tabName: "Your Added Account(s)", 
                                                    tabContent: (
                                                        this.getTabContent()
                                                    )
                                                },
                                                { 
                                                    tabName: "Other(s) Added You",
                                                    tabContent: <OtherAccounts store={this.store} history={this.history} />
                                                },
                                                { 
                                                    tabName: "Login Activity", 
                                                    tabContent: <UserLogins store={this.store} history={this.history} />
                                                }
                                            ]}
                                            headerColor="primary"
                                            activeTab={activeTab}
                                            onChange={(tab) => this.onTabChange(tab)}
                                        />

                                    </div>
                                </div>
                            </div>
                        </GridItem>
                    </div>
                    {
                        disconnectAccountModal ?
                            <DisconnectAccountModal open={disconnectAccountModal} disconnectAccountDetail={disconnectAccountDetail} onClose={() => this.onDisconnectAccountModal(false)} onSuccess={() => this.onDisconnectAccount()} />
                        :
                            <></>
                    }
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Accounts.defaultProps = {
    tab: 0
}
Accounts.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userAccountsPageStyle)(Accounts);
