import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ReactTable from "react-table";
import CsvFileIcon from "../../../assets/img/csv-file-icon.png";
import Api from "../../../assets/js/utils/Api";
import AttachmentsModal from  "./AttachmentsModal";
import activitiesModalStyle from "../../../assets/jss/user/activitiesModalStyle";
import Close from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ActivitiesModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            attachmentsModal: false,
            attachmentFiles: []
        }
    }
    onAttachmentsModal(status = false, files = []){
        this.setState({
            attachmentsModal: status,
            attachmentFiles: files
        })
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getTableData(recentActivities){
        if(recentActivities === null){
            return [];
        }
        let tableData = [];
        recentActivities.map(activity => {
            let activityArray = {
                owner: activity.owner_name,
                date_time: activity.created_at,
                name: (
                    <p title={activity.file_name}>
                        { activity.file_name }
                    </p>
                ),
            };
            if(activity.action === 'Move' || activity.action === 'Copy'){
                activityArray.action = (
                    <p title={activity.action+' to '+activity.path}>
                        { activity.action+' to '+activity.path }
                    </p>
                );
            }else if(activity.action === 'Updated Document(s)'){
                activityArray.action = (
                    <p title={activity.action}>
                        { activity.action } <Button color="custom" simple onClick={() => this.onAttachmentsModal(true, activity.files)}>view More</Button>
                    </p>
                );
            }else{
                activityArray.action = (
                    <p title={activity.action}>
                        { activity.action }
                    </p>
                );
            }
            
            tableData.push(activityArray);
            return null;
        });

        return tableData;
    }
    render() {
        const { classes, activities, activitiesFileId, attachmentsModal, attachmentFiles } = this.props;
        let tableData = this.getTableData(activities);
        const apiUrl = Api.getUsersActivityExportUrl('',activitiesFileId);

        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="deleteFile-slide-title"
                aria-describedby="deleteFile-slide-description"
            >
                <DialogTitle
                    id="attachments-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Activities</h4>
                </DialogTitle>
                <DialogContent
                    id="deleteFile-slide-description"
                    className={classes.modalBody}
                >   
                    <div className={classes.UserRecentActivitySection}>
                        <ReactTable
                            columns={[
                                {
                                    Header: "Date/Time",
                                    accessor: "date_time",
                                    headerClassName: "hd_date_time",
                                    className: "hd_date_time td_date_time",
                                },
                                {
                                    Header: "User",
                                    accessor: "owner",
                                    headerClassName: "hd_owner",
                                    className: "hd_owner td_owner",
                                },
                                {
                                    Header: "Action",
                                    accessor: "action",
                                    headerClassName: "hd_action",
                                    className: "hd_action td_action",
                                },
                                {
                                    Header: "Files Affected",
                                    accessor: "name",
                                    headerClassName: "hd_name",
                                    className: "hd_name td_name",
                                },
                            ]}
                            data={tableData}
                            pageSizeOptions={[500]}
                            showPaginationTop={false}
                            minRows={0}
                            showPaginationBottom={false}
                            className={"-striped -highlight "+classes.usersTable}
                            getNoDataProps={() => this.getNoDataProps()}
                            sortable={false}
                        />
                        {
                            tableData.length > 0 ?
                                <div className="csv-file-icon">
                                    <a target="_blank" rel="noopener noreferrer" href={apiUrl} className={classes.csvFileIcon}>
                                        <img src={CsvFileIcon} alt={CsvFileIcon} />
                                    </a>
                                </div>
                            :
                                <></>
                        }
                        {
                            attachmentsModal ?
                                <AttachmentsModal 
                                    open={attachmentsModal} 
                                    onClose={() => this.onAttachmentsModal(false)}
                                    attachmentFiles={attachmentFiles}
                                    title={"Folder Files"}
                                />
                            :
                                <></>
                        }
                    </div>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button onClick={() => this.props.onClose()} color="primary">Close</Button>
                </DialogActions>        
            </Dialog>
        );
    }
};

ActivitiesModal.defaultProps = {
  open: false,
};
ActivitiesModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
};
export default withStyles(activitiesModalStyle)(ActivitiesModal);
