import {
    whiteColor,
    grayColor,
    infoColor,
    dangerColor,
    blackColor,
    hexToRgb,
    controls,
    separator,
    tableHeadColumns,
    tableBody,
    sidebar,
    content
} from "../material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const userAccountsPageStyle = theme => ({
    ...customCheckboxRadioSwitchStyle(theme),
    main: {
        marginTop: "0px",
        "& .react-contextmenu": {
            zIndex: "3",
            backgroundColor: whiteColor,
            cursor: "pointer",
            width: "150px",
            textAlign: "left",
            boxShadow:
                "0 4px 20px 0 rgba(" +
                hexToRgb(blackColor) +
                ",.14), 0 7px 10px -5px rgba(" +
                hexToRgb(blackColor) +
                ",.4)",
            "& .react-contextmenu-item": {
                padding: "5px 10px",
                "&:hover": {
                    backgroundColor: grayColor[2]
                }
            },
            "& .react-contextmenu-item + .react-contextmenu-item": {
                borderTop: "1px solid "+grayColor[6]
            }
        }
    },
    container: {
        width: "100%",
        zIndex: "2",
        top: "62px",
        position: "relative",
        paddingTop: "20px",
        height: "calc(100vh - 62px)"
    },
    downloadsMainContainer: {
        height: "100%"
    },
    sidebar: {
        ...sidebar(theme)
    },
    content: {
        ...content(theme)
    },
    controls: {
        ...controls(theme)
    },
    controlButtons: {
        position: "relative"
    },
    separator: {
        ...separator(theme)
    },
    filesTable: {
        ...tableHeadColumns(theme),
        ...tableBody(theme),
        "& .rt-table": {
            "&:not(:hover)": {
                "&::-webkit-scrollbar": {
                    width: "15px",
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(0,0,0,0.3)",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                    
                }
            }
        },
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
            color: theme.customize.color,
        },
        "& .rt-tbody .rt-td": {
            padding: "0px 5px",
            margin: "5px 0px",
            "&.td_name": {
                margin: "0px",
                padding: "0px",
                "& .react-contextmenu-wrapper": {
                    padding: "5px 5px",
                    margin: "0px",
                }
            }
        },
        "& .hd_check": {
            width: "30px !important",
            textAlign: "center !important",
        },
        "& .hd_name": {
            width: "30% !important",
            textAlign: "left !important",
        },
        "& .hd_size": {
            // width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_creation_date": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_related_links": {
            // width: "15% !important",
            textAlign: "left !important",
        },
        "& .td_recipients": {
            width: "15% !important",
            textAlign: "left !important",
            overflow: "visible",
        },
        "& .hd_owner": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .td_check": {
            "& .MuiButtonBase-root": {
                padding: "0px",
            }            
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            color: grayColor[17],
            opacity: "1 !important"
        },
        "& .rt-td": {
            fontSize: "12px"
        },
    },
    fileIcon: {
        height: "auto",
        width: "auto",
        padding: "3px",
        minWidth: "0px",
        margin: "0px",
        marginRight: "5px",
        "& img": {
            width: "18px"
        },
        "& .star_filled": {
            color: theme.customize.sidebarMenuIconsColor
        },
        "& .privacy-icon": {
            marginLeft: "5px"
        }
    },
    loadMore: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        color: theme.customize.pageTitleColor,
        marginBottom: "20px",
        width: "100%",
        marginTop: "0px",
        textAlign: "left",
    },
    videoTitle: {
        color: whiteColor,
        textShadow: "2px 2px 2px #000",
        fontSize: "16px"
    },
    videoContainer: {
        textAlign: "left",
        "& iframe": {
            [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
            },
            [theme.breakpoints.down("sm")]: {
                maxHeight: "300px",
            },
            [theme.breakpoints.down("xs")]: {
                maxHeight: "250px",
            }
        },
        
    },
    supportContainer: {
        textAlign: "left",
        maxWidth: "500px",
        width: "100%",
        marginRight: "auto",
        marginLeft: "0px",
    },
    formFields: {
        "& > div": {
            marginBottom: "10px",
            overflow: "hidden",
            clear: "both",
            "& .MuiInput-multiline": {
                "&:before, &:after": {
                    content: "none"
                }
            }
        },
        "& .formElement": {
            width: "100%",
            padding: "0",
            position: "relative",
            "& p":{
                color: whiteColor,
                textAlign: "left",
                fontSize: "14px",
                margin: "0px",
                textShadow: "2px 2px 2px #000",
            }
        },
        "& input, textarea": {
            backgroundColor: whiteColor,
            padding: "10px",
            boxSizing: "border-box",
            borderRadius: "3px",
            width: "100%",
            verticalAlign: "top",
            display: "inline-block",
            border: "none",
            float: "right"
        },
        "& textarea": {
            minHeight: "200px"
        },
        "& label": {
            color: whiteColor,
            maxWidth: "150px",
            width: "100%",
            textAlign: "right",
            fontSize: "18px",
            marginTop: "7px",
            display: "inline-block",
            textShadow: "2px 2px 2px #000",
        },
        "& .claer-icon": {
            position: "absolute",
            top: "5px",
            right: "15px",
            fill: dangerColor[0]
        },
        [theme.breakpoints.down("sm")]: {
            "& label":{
                maxWidth: "100%",
                textAlign: "left",
                display: "block",
                padding: "0",
                fontSize: "16px",
                marginTop: "0px"
            },
            "& textarea": {
                minHeight: "135px",
            },
            "& .formElement": {
                width: "100%",
                float: "none"
            }
        },
    },
    formFooter: {
        position: "relative",
        padding: "0",
        borderRadius: "5px",
        textAlign: "left",
        width: "100% !Important",
        border: "none",
        height: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        "& img": {
            width: "1.5em",
            marginTop: "-5px",
            marginRight: "0.2em",
        },
        "& Button": {
            "& svg":{
                cursor: "pointer",
                height: "1.5em",
                marginRight: "0.2em",
                width: "1.5em",
                fill: infoColor[0],
                fontSize: "inherit",
                transform: "rotate(-45deg)",
                display: "inline-block",
                verticalAlign: 'top',
                marginTop: "-5px",
                "&:hover": {
                    fill: infoColor[1],
                }
            },
            display: "block",
            fontSize: "1em",
            color: whiteColor,
            textTransform: "capitalize",
            textAlign: "left",
            backgroundColor: "transparent !Important",
            width: "100%",
            borderRadius: "0px",
            padding: "10px 8px",
            boxShadow: "none !Important",
            margin: "0px"
        },
    },
    breadcrumbs: {
        padding: "5px 30px",
        textAlign: "left",
        "& a": {
            color: infoColor[0]+" !important",
            verticalAlign: "middle",
        },
        "& div": {
            display: "inline-block",
            verticalAlign: "middle",
        },
        "& span": {
            margin: "0px 5px",
            verticalAlign: "middle",
            display: "inline-block",
            height: "18px",
            "& svg": {
                width: "18px",
                height: "18px"
            }
        },
        "& img": {
            width: "18px",
            marginRight: "5px"
        }
    },
    downloadsContainer: {
        "& >div": {
            "&+div": {
            marginTop: theme.spacing(8),
            },
            "&:last-child": {
                marginBottom: theme.spacing(8)
            }
        }
    },
    imageColumn: {
        "& img": {
            width: "100%",
            maxHeight: "150px",
            objectFit: "cover",
            objectPosition: "center",
            [theme.breakpoints.down("md")]: {
                maxHeight: "180px"
            }
        },
    },
    buttonColumn: {
        textAlign: "center",
        "& img":{
            maxWidth: "120px",
            width: "100%"
        },
        "& button": {
            maxWidth: "120px",
            width: "100%",
            padding: "0px",
            "& img": {
                maxWidth: "18px",
                width: "100%",
                marginRight: "5px"
            },
            "&.window": {
                backgroundColor: "#20b5c9"
            },
            "&.mac": {
                backgroundColor: "#b98cd1"
            }
        }
    },
    tutorialsMainContainer: {
        paddingBottom: theme.spacing(8),
        height: "100%"
    },
    mainVideoColumn: {
        "& iframe": {
            width: "100%"
        },
        "& p": {
            lineHeight: "normal",
        },
        "& .video-title": {
            fontWeight: "bold",
            marginTop: "10px",
            marginBottom: "3px"
        },
    },
    sideVideosColumn: {
        "& >div+div": {
            marginTop: theme.spacing(1),
        },
        "& img": {
            width: "100%",
            maxHeight: "120px",
            height: "100%",
            objectFit: "cover",
            cursor: "pointer",
            [theme.breakpoints.down("sm")]: {
                height: "auto"
            }
        },
        "& .video-thumbnail": {
            paddingRight: "8px",
        },
        "& .video-detail": {
            paddingLeft: "0px"
        },
        "& p": {
            margin: "0px",
            lineHeight: "normal",
        },
        "& .video-title": {
            fontWeight: "bold",
            cursor: "pointer",
            marginBottom: "3px"
        },
        [theme.breakpoints.down("md")]: {
            marginTop: theme.spacing(4)
        }
    },
    filesContainer: {
        maxWidth: "99%",
        width: "100%",
        maxHeight: "460px",
        overflow: "auto",
        minHeight: "460px",
    },
    recipients: {
        "& div": {
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        "& ul": {
            display: "none",
            listStyleType: "none",
            paddingLeft: "0px",
            position: "absolute",
            zIndex: "1",
            background: infoColor[0],
            borderRadius: "3px",
            "& li": {
                padding: "3px 5px"
            }
        },
        "&:hover": {
            "& ul": {
                display: "block"
            }
        }
    },
    textColumn: {
        whiteSpace: "break-spaces"
    },
    accountContainer: {
        textAlign: "left",
        "& .section-title": {
            margin: "1rem 0 2rem",
            fontWeight: "500",
            "&::after": {
                content: '""',
                margin: ".75rem 0 0",
                border: "none",
                borderRadius: ".125rem",
                backgroundColor: whiteColor,
                display: "block",
                height: ".25rem",
            }
        },
        "& .section-content": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            borderRadius: "5px",
        },
        "& .profile-section-content": {
            padding: "2.5rem 1rem 1.25rem",
            textAlign: "center",
            "& .user-image": {
                display: "inline-block",
                position: "relative",
                marginBottom: "1.5rem",
                "& .user-icon": {
                    width: "8rem",
                    height: "8rem",
                    display: "inline-block",
                    backgroundColor: grayColor[7],
                    borderRadius: "100%",
                    lineHeight: "8rem",
                    fontSize: "40px",
                    fontWeight: "bold",
                    color: whiteColor,
                    "& img.profile-picture": {
                        width: "100%",
                        height: "100%",
                        borderRadius: "100%",
                        verticalAlign: "top",
                        objectFit: "cover"
                    }
                },
            },
            "& .user-name": {
                fontSize: "1.75rem",
                lineHeight: "normal",
                wordBreak: "break-all",
                marginTop: "0px",
                marginBottom: ".75rem",
            },
            "& .user-email": {
                marginBottom: "1.75rem",
            }
        },
        "& .plans-section-content": {
            "&>div":{
                padding: "1.25rem",
            },
            "& .plan-header": {
                minHeight: "5.5rem",
                "& img": {
                    height: "2rem"
                },
                "& h4": {
                    margin: ".5rem 0 0",
                    fontSize: "1.25rem",
                }
            },
            "& .plan-body": {
                minHeight: "7.9rem",
                "& p": {
                    marginBottom: "0px"
                }
            }
        },
        "& .resources-section-content": {
            "& .resources-content": {
                display: "flex",
                alignItems: "center",
                marginBottom: theme.spacing(3),
                "& svg": {
                    marginRight: "10px",
                    width: "1.6em",
                    height: "1.6em"
                },
                "& a": {
                    color: infoColor[0],
                    fontSize: "1.125rem",
                    lineHeight: "normal",
                    fontWeight: "500",
                    "&:hover": {
                        textDecoration: "none"
                    }
                },
                "& p":{
                    marginBottom: "0px",
                }
            }
        },
        "& .tasks-section-content": {
            "& ul":{
                listStyleType: "none",
                padding: "0px",
                "& li":{
                    marginBottom: ".5rem",
                    "& a": {
                        color: infoColor[0],
                        fontSize: "1.125rem",
                        lineHeight: "1.75rem",
                        fontWeight: "500"
                    }
                }
            }
        }
    },
    profileContainer: {
        "& .userInfo": {
            marginTop: theme.spacing(2),
            "& input, & label, & label.MuiFormLabel-root:not(.Mui-error), & .MuiSelect-select": {
                color: whiteColor+" !important",
                textAlign: "left",
                "&.MuiFormControlLabel-root": {
                    width: "100%",
                    "& a": {
                        color: infoColor[0]
                    }
                },
                "& span": {
                    color: whiteColor
                }
            },
            "& .MuiInputBase-root.Mui-disabled": {
                backgroundColor: "transparent"
            }
        },
        "& .saveButtons": {
            display: "flex",
            justifyContent: "space-between",
            "&.saveButtonsBottom": {
                marginBottom: theme.spacing(5)
            }
        },
        "& .editButton": {
            display: "flex",
            justifyContent: "flex-end",
            "&.editButtonBottom": {
                marginBottom: theme.spacing(5)
            }
        },
        "& .passwordCheck-notValid-customizable": {
            color: infoColor[0],
        },
        "& .form-error-message": {
            marginBottom: "10px",
        }
    },
    accountsContainer: {
        paddingBottom: "70px",
        "& .accounts-section": {
            border: "1px solid",
            "& h4": {
                backgroundColor: theme.customize.opacityColor,
                border: theme.customize.border,
                borderRight: "none",
                borderTop: "none",
                borderLeft: "none",
                padding: "10px",
                marginTop: "0px",
                textAlign: "left",
                fontWeight: "500",
                color: theme.customize.color,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            },
        },
        "& .accounts-content": {
            padding: "10px",
            background: theme.customize.opacityColor,
            "& ul": {
                listStyleType: "none",
                paddingLeft: "0px",
                textAlign: "left",
                "& li": {
                    wordBreak: "break-word"
                },
                "& span": {
                    display: "inline-block",
                    fontWeight: "500",
                    width: "30%",
                }
            },
            "& .rt-tbody .rt-td, .rt-thead .rt-th": {
                textAlign: "left"
            },
            "& .td_actions": {
                "& button": {
                    padding: "0px",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: theme.customize.color+" !Important",
                    border: "none !Important"
                }
            },
            "& .rt-tbody": {
                "& .td_state": {
                    textTransform: "capitalize"
                }
            }
        },
    },
    privacyContainer: {
        "& .privacy-section": {
            textAlign: "left",
            "& ul": {
                paddingLeft: "30px",
                "& a": {
                    color: infoColor[0],
                }
            },
            "& .delete-account": {
                backgroundColor: "rgb(0, 0, 0, 0.2)",
                padding: theme.spacing(3),
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& p": {
                    marginBottom: "0px",
                }
            }
        }
    },
    exportContainer: {
        "& .export-section": {
            textAlign: "left",
            "& .error": {
                "& svg": {
                    borderColor: dangerColor[0],
                },
                "& .MuiFormControlLabel-label": {
                    //color: dangerColor[0]
                }
            }
        },
        "& input, & label, & label.MuiFormLabel-root:not(.Mui-error), & .MuiSelect-select": {
            color: whiteColor+" !important",
            textAlign: "left",
            "&.MuiFormControlLabel-root": {
                width: "100%",
                "& a": {
                    color: infoColor[0]
                }
            },
            "& span": {
                color: whiteColor
            }
        },
        "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: "transparent"
        },
        "& .request-button": {
            padding: theme.spacing(0, 5, 5, 0),
            display: "flex",
            justifyContent: "flex-end",
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(0, 0),
            }
        },
        "& .custom-select": {
            maxWidth: "150px",
            width: "100%",
            marginTop: "0px !Important",
            "& svg": {
                color: whiteColor
            }
        },
        "& .size-section": {
            display: "flex",
            alignItems: "flex-start",
            "& ul": {
                listStyleType: "none",
                paddingLeft: "30px",
            },
            [theme.breakpoints.down("xs")]: {
                display: "block",
                "& ul": {
                    paddingLeft: "0px"
                }
            }
        },
        "& .date-range-section": {
            display: "flex",
            alignItems: "center",
            "& >*": {
                width: "auto !important",
                paddingTop: "0px !important",
                marginBottom: "0px !important",
                marginRight: "30px",
                "&:last-child": {
                    marginRight: "0px"
                }
            },
            [theme.breakpoints.down("xs")]: {
                display: "block",
                marginBottom: "10px",
                "& >*": {
                    width: "100% !Important",
                }
            }
        }
    },
    downloadContainer: {
        "& .download-section": {
            textAlign: "left",
        },
        "& .download-detail": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            "& ul": {
                listStyleType: "none",
                paddingLeft: "0px",
                "& span": {
                    fontWeight: "bold"
                }
            },
            "& button": {
                padding: "0px",
                margin: "0px",
                color: infoColor[0],
                textTransform: "none",
                fontSize: "16px",
                "& svg": {
                    marginLeft: "5px",
                    width: "22px",
                    height: "22px",
                }
            }
        },
        "& .download-files": {
            "& ul": {
                paddingLeft: "0px",
                listStyleType: "none",
                marginBottom: "0px",
                "&.download-files-lists>li": {
                    borderTop: "1px solid "+grayColor[0],
                    paddingTop: "10px",
                    paddingBottom: "10px"
                },
                "& ul": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "& Button": {
                        padding: "0px",
                        margin: "0px",
                        marginLeft: "5px",
                        marginTop: "-3px",
                        color: infoColor[0],
                        "& svg": {
                            width: "22px",
                            height: "22px"
                        }
                    },
                    "& img": {
                        width: "36px",
                        height: "36px",
                        marginRight: "10px"
                    }
                }
            }
        }
    },
    customTabs: {
        "& .card": {
            backgroundColor: "transparent",
            marginLeft: "0px",
            marginRight: "0px",
            boxShadow: "none",
        },
        "& .card-header": {
            marginLeft: "0px",
            marginRight: "0px",
            boxShadow: "none",
            background: theme.customize.hoverColor,
            padding: "10px 0px",
            border: theme.customize.border,
            borderLeft: "none",
            borderRight: "none",
            borderRadius: "0px",
            "& button": {
                color: theme.customize.color,
                "&.Mui-selected": {
                    border: theme.customize.border
                }
            }
        },
    }
});

export default userAccountsPageStyle;
