import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const copyFilesModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "71px",
        paddingTop: "51px",
        paddingRight: "0px",
        paddingLeft: "0px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& h4": {
            textAlign: "center",
            marginTop: "0px",
            marginBottom: '15px',
        },
        "& li": {
            color: theme.customize.whiteBlackColor,
            opacity: 1,
        },
        "& .from-content": {
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
            "& p": {
                marginBottom: "0px",
                fontWeight: "500",
                "&.label": {
                    minWidth: "32px",
                },
                "&.path": {
                    "& span": {
                        cursor: "pointer"
                    },
                    "& span+span": {
                        "&::before": {
                            content: "'>'",
                            padding: "0px 5px",
                        }
                    }
                }
            },
            "& .from-name": {
                fontWeight: "500",
                border: "1px solid "+theme.customize.borderColorModal,
                padding: "3px 5px",
                borderRadius: "6px",
                margin: "0px 10px",
                display: "flex",
                alignItems: "center",
                minWidth: "110px",
                color: theme.customize.whiteBlackColor,
                opacity: "0.8",
                "& img": {
                    maxWidth: "25px",
                    width: "100%",
                    marginRight: "5px",
                }
            }
        },
        "& .sd-back-newfolder": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid",
            borderColor: theme.customize.borderColorModal,
            marginBottom: "10px",
            "& .sd-back-path": {
                display: 'flex',
                alignItems: "center",
                color: theme.customize.whiteBlackColor,
                opacity: "0.8",
                "& svg": {
                    display: "block",
                    width: "24px",
                    height: "24px",
                    color: theme.customize.iconsColor,
                }
            },
            "& svg": {
                width: "28px",
                height: "28px",
                color: theme.customize.iconsColor
            }
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    filesList: {
        listStyleType: "none",
        paddingLeft: "0px",
        "& li": {
            fontSize: "12px",
            fontWeight: "bold"
        }
    },
    treeView: {
        height: "200px",
        overflow: "auto",
        backgroundColor: theme.customize.blackWhiteColor,
        padding: "2px",
        "& .MuiTreeItem-label": {
            paddingTop: "4px",
            paddingBottom: "4px"
        },
        "& .MuiTreeItem-group": {
            marginLeft: "10px"
        }
    },
    foldersDirectory: {
        height: "200px",
        overflowY: "auto",
        "& .empty-folder":{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
        },
        "& .sd-folder-directory": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "10px",
            cursor: "pointer",
            borderTopRightRadius: "30px",
            borderBottomRightRadius: "30px",
            marginBottom: "5px",
            "& .folder-name": {
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
                flex: "1",
                "& svg": {
                    fontSize: "20px",
                    color: theme.customize.whiteBlackColor,
                    fill: theme.customize.whiteBlackColor,
                }
            },
            "& .folder-action": {
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
                "& .copy": {
                    fontWeight: "500",
                    fontSize: "14px",
                    padding: "0px 10px",
                    borderRadius: "10px",
                    "&:hover": {    
                        backgroundColor: theme.customize.blackWhiteColor,
                    }
                },
                "& svg": {
                    display: "block",
                    borderRadius: "100%",
                    fontSize: "30px",
                    "&:hover": {
                        backgroundColor: theme.customize.blackWhiteColor,
                    }
                }
            },
            "& .copy": {
                display: "none"
            },
            "&.selected": {
                backgroundColor: theme.customize.tableActiveRowColor,
                "&:hover": {
                    backgroundColor: theme.customize.tableActiveRowColor,
                }
            },
            "&:hover": {
                backgroundColor: theme.customize.tableActiveRowColor,
                "& .copy": {
                    display: "block"
                }
            }
        }
    },
    nodeLabel: {
        display: "flex",
        alignItems: "center",
        "& img": {
            maxWidth: "18px",
            marginRight: "8px"
        }
    },
    copyFilesProgress: {
        position: "fixed",
        left: "20px",
        bottom: "0px",
        backgroundColor: theme.customize.blackWhiteColor,
        zIndex: 10000000000,
        borderRadius: "10px 10px 0px 0px",
        maxWidth: "350px",
        width: "100%",
        columnGap: "20px",
        alignItems: "center",
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        overflow: "hidden",
        "& p.copied-files-header": {
            fontWeight: "bold",
            marginBottom: "0px",
            color: theme.customize.color,
            padding: "10px",
            textAlign: "left",
            cursor: "pointer",
            background: theme.customize.backgroundColor,
        },
        "& span": {
            color:  theme.customize.color,
            fontWeight: "500",
            fontSize: "14px",
            textAlign: 'left'
        },
        "& .sd_loader": {
            width: "18px",
            height: "18px",
            margin: "0px",
            "& .MuiCircularProgress-root": {
                width: "18px !important",
                height: "18px !important",
                "& svg": {
                    width: "inherit",
                    height: "inherit"
                }
            }
        },
        "& .copied-files-detail": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderTop: "1px solid",
            borderColor: theme.customize.borderColor,
            padding: "5px 10px",
            "&.hide": {
                display: "none"
            },
            "& p": {
                lineHeight: "normal",
                color: theme.customize.color,
                marginBottom: "0px",
                "&.cancel": {
                    cursor: "pointer",
                    color: theme.customize.linksColor,
                }
            }
        },
        "& .files-listings": {
            maxHeight: "200px",
            overflowX: "hidden",
            overflowY: "auto",
            "&.hide": {
                display: "none"
            },
            "& ul": {
                marginBottom: "0px",
                paddingLeft: "0px",
            },
            "& li": {
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
                alignItems: "center",
                "& div": {
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    "& .name": {
                        whiteSpace: "nowrap",
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    },
                    "& .count": {
                        marginLeft: "15px",
                        whiteSpace: "nowrap"
                    },
                    "&.file-info": {
                        flex: "1",
                        maxWidth: "calc(100% - 30px)",
                    }
                }
            }
        }
    },
    fileIcon: {
        height: "auto",
        width: "auto",
        padding: "0px",
        minWidth: "0px",
        margin: "0px",
        marginRight: "10px",
        flexShrink: '0',
        cursor: "auto",
        "& img": {
            width: "24px"
        },
        "& .questionmark_icon": {
            color: theme.customize.tableRowActionsIconsColor,
            width: "24px",
            height: "24px"
        },
        color: theme.customize.color,
    },
    icons: {
        color: theme.customize.iconsColor
    }
});
  
export default copyFilesModalStyle;
  