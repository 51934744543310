import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import { Link as RouerLink } from "react-router-dom";
import Auth from "../../../assets/js/utils/Auth";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import LoaderComponent from '../../components/Loader';
import GridItem from "../../components/Grid/GridItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import Switch from "@material-ui/core/Switch";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactTable from "react-table";
import userSettingsPageStyle from "../../../assets/jss/user/userSettingsPageStyle.jsx";
import DeleteHouseKeepingConfirmationModal from "../../components/User/DeleteHouseKeepingConfirmationModal";
import DateTimePicker from "../../components/CustomInput/DateTimePicker";
import HousekeepingInfoModal from "../../components/User/HousekeepingInfoModal";

const UserSettings = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();
        this.state = {
            user: user,
            saving: false,
            houseKeepingSaving: false,
            validation: {
                isValid: false,
            },
            cancelToken: null,
            houseKeepingData: this.getHouseKeepingData(),
            orignalHouseKeepingData: this.getOrignalHouseKeepingData(),
            deleteHouseKeepingModal: false,
            deleteHouseKeepingSaving: false,
            infoModal: false,
            infoType: ''
        };

        this.remindersArray = [
            {
                key: 0,
                value: "Disabled"
            },
            {
                key: 10,
                value: '10 Minutes'
            },
            {
                key: 20,
                value: '20 Minutes'
            },
            {
                key: 30,
                value: '30 Minutes'
            },
            {
                key: 60,
                value: '1 Hour'
            },
            {
                key: 120,
                value: '2 Hours'
            },
            {
                key: 180,
                value: '3 Hours'
            },
            {
                key: 240,
                value: '4 Hours'
            },
            {
                key: 300,
                value: '5 Hours'
            },
            {
                key: 1440,
                value: '1 Day'
            },
            {
                key: 2880,
                value: '2 Days'
            },
            {
                key: 4320,
                value: '3 Days'
            },
            {
                key: 5760,
                value: '4 Days'
            },
            {
                key: 7200,
                value: '5 Days'
            },
            {
                key: 8640,
                value: '6 Days'
            },
            {
                key: 10080,
                value: '7 Days'
            }
        ]

        this.backgroundCategories = [
            {
                key: 1,
                value: "Random"
            },
            {
                key: 2,
                value: "Nature"
            },
            {
                key: 3,
                value: "Night Scene"
            },
            {
                key: 4,
                value: "Creative"
            },
        ];

        this.homePages = [
            {
                key: 'send_mail',
                value: "Send Mail"
            },
            {
                key: 'my_docs',
                value: "My Docs"
            },
            {
                key: 'link_manager',
                value: "Link Manager"
            },
            {
                key: 'external_links',
                value: "External Links"
            },
            {
                key: 'shared',
                value: "Shared"
            },
        ];

        this.durationArray = [
            {
                key: 0,
                value: "Select"
            },
            {
                key: 3,
                value: "3 Months"
            },
            {
                key: 6,
                value: "6 Months"
            },
            {
                key: 12,
                value: "12 Months"
            }
        ];

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.themeOptions = Api.getThemeOptions();
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleChange(event, name) {
        this.setState({
            user: {
                ...this.state.user,
                [name]: event.target.value
            }
        });
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});
        const { user } = this.state;
        const requestData = {
            notifyRecipientDownloaded: user.notify_recipient_downloaded,
            recipientDownloadReminder: user.recipient_download_reminder,
            fileNotDownloadedReminder: user.file_not_downloaded_reminder,
            backgroundTheme: user.background_theme,
            theme: user.theme,
            homePage: user.home_page,
        }
        
        Api.updateUserSettings(requestData, source).then(data => {
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.body));
            let newUser = Api.prepareMemberObject(data.body);
            this.store.dispatch({type: "UPDATE_STATE",state: {user: newUser}});
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }  
        });
    }
    handleCheckbox(e, name){
        this.setState({
            user: {
                ...this.state.user,
                [name]: e.target.checked
            }
        });
    }
    handleThemeToggle(e, name){
        this.setState({
            user: {
                ...this.state.user,
                [name]: e.target.checked ? 'standard' : 'light'
            }
        });
    }
    validateForm(){
        let validation = {
            isValid: true
        };

        return validation.isValid;
    }   
    handleHouseKeepingChange(e, name, houseKeeping) {
        const { houseKeepingData } = this.state;

        let objIndex = houseKeepingData.findIndex((obj => obj.id === houseKeeping.id));
        houseKeepingData[objIndex][name] = e.target.value;

        if((houseKeeping.criteria_no === 1 || houseKeeping.criteria_no === 3) && houseKeepingData[objIndex][name] < houseKeepingData[objIndex+1][name]){
            houseKeepingData[objIndex+1][name] = 0;
        }

        this.setState({
            houseKeepingData: houseKeepingData
        });
    }
    handleHouseKeepingCheckbox(e, name, houseKeeping){
        const { houseKeepingData } = this.state;

        let objIndex = houseKeepingData.findIndex((obj => obj.id === houseKeeping.id));
        houseKeepingData[objIndex][name] = e.target.checked

        if(name === 'status'){
            if((houseKeeping.criteria_no === 1 || houseKeeping.criteria_no === 3) && !houseKeepingData[objIndex][name]){
                houseKeepingData[objIndex+1][name] = false
            }
            if((houseKeeping.criteria_no === 2 || houseKeeping.criteria_no === 4) && !houseKeepingData[objIndex-1][name]){
                houseKeepingData[objIndex][name] = false;
                return true;
            }
        }
        if(name === 'files'){
            houseKeepingData[objIndex]['links'] = true
        }
        
        this.setState({
            houseKeepingData: houseKeepingData
        });
    }
    handleHouseKeepingDateChange(date, name, houseKeeping){
        const { houseKeepingData } = this.state;
        
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            const day = parsedDate.getDate();
            const month = parsedDate.getMonth() + 1;
            const year = parsedDate.getFullYear();
            const hours = parsedDate.getHours();
            const mins = parsedDate.getMinutes();
            const secs = parsedDate.getSeconds();
            let dateStr = year+"-"+month+"-"+day+" "+hours+":"+mins+":"+secs;

            let objIndex = houseKeepingData.findIndex((obj => obj.id === houseKeeping.id));
            houseKeepingData[objIndex][name] = dateStr;
            houseKeepingData[objIndex][name+'_utc'] = parsedDate.toISOString();

            this.setState({
                houseKeepingData: houseKeepingData
            });
        }catch(e){ console.log(e); }        
    }
    getHouseKeepingData(){
        const { user } = this.store.getState();
        let houseKeepingData = [];
        if(user.hasOwnProperty('houseKeeping')){
            houseKeepingData = Object.assign([], user.houseKeeping);
        }
        
        return houseKeepingData;
    }
    getOrignalHouseKeepingData(){
        const { user } = this.store.getState();
        
        let houseKeepingArray = [];
        if(user.hasOwnProperty('houseKeeping')){
            user.houseKeeping.map(houseKeeping => {
                let houseKeepingArrayData = {
                    id: houseKeeping.id,
                    criteria_no: houseKeeping.criteria_no,
                    duration: houseKeeping.duration,
                    duration_time: houseKeeping.duration_time,
                    files: houseKeeping.files,
                    links: houseKeeping.links,
                    status: houseKeeping.status,
                };
    
                houseKeepingArray.push(houseKeepingArrayData);
                return null;
            });
        }

        return houseKeepingArray;
    }
    getCriteriaName(houseKeeping){
        let name = "";
        if(houseKeeping.criteria_no === 1){
            name = "If files are older than";
        }else if(houseKeeping.criteria_no === 2){
            name = "If files have been downloaded and are older than";
        }else if(houseKeeping.criteria_no === 3){
            name = "If links are older than";
        }else if(houseKeeping.criteria_no === 4){
            name = "If links are expired and older than"
        }

        return name;
    }
    onInfoModal(status = false, type = ''){
        this.setState({
            infoModal: status,
            infoType: type
        });
    }
    isCheckboxDisabled(houseKeeping){
        let disabled = true;
        if(houseKeeping.status){
            disabled = false;
        }
        if(houseKeeping.criteria_no === 1 || houseKeeping.criteria_no === 2){
            disabled = true;
        }
        if((houseKeeping.criteria_no === 3 || houseKeeping.criteria_no === 4) && houseKeeping.files){
            disabled = true;
        }

        return disabled;
    }
    getDurationArray(houseKeeping){
        let durationArray = this.durationArray;

        const { houseKeepingData } = this.state;

        let objIndex = houseKeepingData.findIndex((obj => obj.id === houseKeeping.id));
        if(houseKeeping.criteria_no === 2 || houseKeeping.criteria_no === 4){
            let duration = houseKeepingData[objIndex-1]['duration'];
            
            durationArray = durationArray.filter(function( obj ) {
                return obj.key <= duration;
            });
        }

        return durationArray;
    }
    getTableData(){
        const { classes } = this.props;
        const { houseKeepingData } = this.state;

        let tableData = [];

        let houseKeepingArray = {
            id: "",
            status: "",
            criteria: "",
            duration: "",
            delete: (
                <>
                    <p>Links</p>
                    <p>Files</p>
                </>
            ),
            more_info: ""
        }
        tableData.push(houseKeepingArray);

        houseKeepingData.map(houseKeeping => {
            let houseKeepingArray = {
                id: houseKeeping.id,
                status: (
                    <Switch
                        checked={(houseKeeping.status ? true : false)}
                        onChange={(e) => this.handleHouseKeepingCheckbox(e, 'status', houseKeeping)}
                        value="status"
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                        }}
                    />
                ),
                criteria: this.getCriteriaName(houseKeeping),
                duration: (
                    <div className='duration_flex'>
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customSelectFormControl
                            }}
                            labelText=""
                            selectProps={{
                                onChange: (e) => this.handleHouseKeepingChange(e,"duration", houseKeeping),
                                value: houseKeeping.duration
                            }}
                            inputProps={{
                                name: "duration",
                                id: "input-duration",
                                disabled: houseKeeping.status ? false : true
                            }}
                            color="custom"
                            items={this.getDurationArray(houseKeeping)}
                            itemLabel="value"
                            itemValue="key"
                        />
                        <DateTimePicker
                            labelText={null}
                            id="input-startDate"
                            value={houseKeeping.duration_time}
                            onChange={(date) => this.handleHouseKeepingDateChange(date, 'duration_time', houseKeeping)}
                            disableFuture={true}
                            inputProps={{
                                placeholder:"Select Date",
                            }}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                        />
                    </div>
                ),
                delete: (
                    <>
                        <Checkbox                                                
                            tabIndex={-1}
                            onClick={(e) => this.handleHouseKeepingCheckbox(e, "links", houseKeeping)}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{checked: classes.checked, root: classes.checkRoot}}
                            checked={houseKeeping.links ? true : false}
                            disabled={this.isCheckboxDisabled(houseKeeping)}
                        />
                        <Checkbox                                                
                            tabIndex={-1}
                            onClick={(e) => this.handleHouseKeepingCheckbox(e, "files", houseKeeping)}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{checked: classes.checked, root: classes.checkRoot}}
                            checked={(houseKeeping.files ? true : false)}
                            disabled={houseKeeping.status ? false : true}
                        />
                    </>
                ),
                more_info: (
                    <Button color="transparent" simple onClick={() => this.onInfoModal(true, houseKeeping.criteria_no)}>
                        More Info...
                    </Button>
                )
            };
            tableData.push(houseKeepingArray);
            return null;
        });
        return tableData;
    }
    getTrProps(state, rowInfo){
        return {}
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getColumns(){
        let columns = [
            {
                Header: "ON/OFF",
                accessor: "status",
                sortable: false,
                filterable: false,
                headerClassName: "hd_status",
                className: "hd_status td_status",
            },
            {
                Header: "Criteria",
                accessor: "criteria",
                sortable: false,
                filterable: false,
                headerClassName: "hd_criteria",
                className: "hd_criteria td_criteria",
            },
            {
                Header: "Duration",
                accessor: "duration",
                sortable: false,
                filterable: false,
                headerClassName: "hd_duration",
                className: "hd_duration td_duration",
            },
            {
                Header: "Delete",
                accessor: "delete",
                sortable: false,
                filterable: false,
                headerClassName: "hd_delete",
                className: "hd_delete td_delete",
            },
            {
                Header: "",
                accessor: "more_info",
                sortable: false,
                filterable: false,
                headerClassName: "hd_more_info",
                className: "hd_more_info td_more_info",
            },
        ];

        return columns;
    }
    onSetDefault(){
        const { houseKeepingData } = this.state;

        houseKeepingData.map((houseKeeping, index) => {
            if(index === 0){
                houseKeeping.status = true;
                houseKeeping.duration = 6;
                houseKeeping.links = true;
                houseKeeping.files = true;
            }else if(index === 1 || index === 2 || index === 3){
                houseKeeping.status = true;
                houseKeeping.duration = 3;
                houseKeeping.links = true;
                houseKeeping.files = false;
            }
            return null;
        });

        this.setState({
            houseKeepingData: houseKeepingData
        }, () => {
            this.onSaveHouseKeeping()
        })
    }
    onSaveHouseKeeping(){
        const source = axios.CancelToken.source();
        this.setState({
            cancelToken: source, 
            houseKeepingSaving: true
        });
        const { houseKeepingData } = this.state;
        const requestData = {
            houseKeepings: houseKeepingData,
        }

        Api.updateHouseKeeping(requestData, source).then(data => {
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.body));
            let newUser = Api.prepareMemberObject(data.body);
            this.store.dispatch({type: "UPDATE_STATE",state: {user: newUser}});
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, houseKeepingSaving: false});
            }  
        });
    }
    onRunHouseKeepingModal(status = false){
        this.setState({
            deleteHouseKeepingModal: status
        })
    }
    onRunHouseKeeping(){
        const { user } = this.store.getState();
        const source = axios.CancelToken.source();
        this.setState({
            cancelToken: source, 
            deleteHouseKeepingSaving: true
        });
        const { houseKeepingData } = this.state;
        const requestData = {
            houseKeepings: houseKeepingData,
            userId: user.id
        }

        Api.runHouseKeeping(requestData, source).then(data => {
            this.setState({
                deleteHouseKeepingSaving: false,
                deleteHouseKeepingModal: false
            })
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, deleteHouseKeepingSaving: false});
            }  
        });
    }
    saveButtonDisabled(){
        const { user } = this.store.getState();
        let notify_recipient_downloaded = user.notify_recipient_downloaded ? true : false;
        let state_notify_recipient_downloaded = this.state.user.notify_recipient_downloaded ? true : false;
        let background_theme = user.background_theme ? true : false;
        let state_background_theme = this.state.user.background_theme ? true : false;

        if(
            (notify_recipient_downloaded !== state_notify_recipient_downloaded) ||
            (user.recipient_download_reminder !== this.state.user.recipient_download_reminder) ||
            (user.file_not_downloaded_reminder !== this.state.user.file_not_downloaded_reminder) ||
            (background_theme !== state_background_theme) ||
            (user.theme !== this.state.user.theme) ||
            (user.home_page !== this.state.user.home_page)
        ){
            return false;
        }

        return true;
    }
    houseKeepingSaveButtonDisabled(){
        const { houseKeepingData, orignalHouseKeepingData } = this.state;


        let disabled = true;
        houseKeepingData.map((object1, index) => {
            let object2 = orignalHouseKeepingData[index];

            let obj1Status = object1.status ? true : false;
            let obj2Status = object2.status ? true : false;
            let obj1Links = object1.links ? true : false;
            let obj2Links = object2.links ? true : false;
            let obj1Files = object1.files ? true : false;
            let obj2Files = object2.files ? true : false;

            if(obj1Status !== obj2Status || object1.duration !== object2.duration || object1.duration_time !== object2.duration_time || obj1Links !== obj2Links || obj1Files !== obj2Files){
                disabled  = false;
            }

            return null;
        });

        return disabled;
    }
    render() {
        const { classes } = this.props;
        const { user, saving, houseKeepingData, deleteHouseKeepingModal, houseKeepingSaving, deleteHouseKeepingSaving,
            infoModal, infoType } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content}>
                        <GridItem>
                            <div className={classes.settingsContainer}>
                                <h3 className={classes.title}>Settings</h3>
                                <form onSubmit={this.handleSubmit} className={classes.formFields}>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Checkbox                                                
                                                tabIndex={-1}
                                                onClick={(e) => this.handleCheckbox(e, "notify_recipient_downloaded")}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{checked: classes.checked, root: classes.checkRoot}}
                                                checked={(user.notify_recipient_downloaded ? true : false)}
                                            />
                                        }
                                        label="Notify you when recipient has downloaded the file(s)"
                                    />
                                    <CustomSelect 
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customSelectFormControl
                                        }}
                                        labelText="Reminders for recipients to download files for every"
                                        selectProps={{
                                            onChange: (e) => this.handleChange(e,"recipient_download_reminder"),
                                            value: user.recipient_download_reminder
                                        }}
                                        inputProps={{
                                            name: "recipient_download_reminder",
                                            id: "input-reminders",
                                            className: classes.alignLeft
                                        }}
                                        color="custom"
                                        items={this.remindersArray}
                                        itemLabel="value"
                                        itemValue="key"
                                    />
                                    <CustomSelect 
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customSelectFormControl
                                        }}
                                        labelText="Notification to remind you that recipient has not downloaded the files"
                                        selectProps={{
                                            onChange: (e) => this.handleChange(e,"file_not_downloaded_reminder"),
                                            value: user.file_not_downloaded_reminder
                                        }}
                                        inputProps={{
                                            name: "file_not_downloaded_reminder",
                                            id: "input-notDownloadedReminders",
                                            className: classes.alignLeft
                                        }}
                                        color="custom"
                                        items={this.remindersArray}
                                        itemLabel="value"
                                        itemValue="key"
                                    />
                                    <div className={classes.themeToggle}>
                                        <label>Theme Layout</label>
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Switch
                                                    checked={(user.theme === 'standard' ? true : false)}
                                                    onChange={(e) => this.handleThemeToggle(e, 'theme')}
                                                    value="theme"
                                                    classes={{
                                                        switchBase: classes.switchBase,
                                                        checked: classes.switchChecked,
                                                        thumb: classes.switchIcon,
                                                        track: classes.switchBar
                                                    }}
                                                />
                                            }
                                            label={user.theme === 'standard' ? 'Standard' : 'Light'}
                                        />
                                    </div>
                                    {/* {
                                        user.theme ==='standard' ?
                                            <CustomSelect 
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customSelectFormControl
                                                }}
                                                labelText="Background Theme"
                                                selectProps={{
                                                    onChange: (e) => this.handleChange(e,"background_theme"),
                                                    value: user.background_theme
                                                }}
                                                inputProps={{
                                                    name: "background_theme",
                                                    id: "input-backgroundCategories",
                                                    className: classes.alignLeft,
                                                    disabled: user.user_type  === "business" ? false : true
                                                }}
                                                color="custom"
                                                items={this.backgroundCategories}
                                                itemLabel="value"
                                                itemValue="key"
                                            />
                                        :
                                            <></>
                                    } */}
                                    <CustomSelect 
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customSelectFormControl
                                        }}
                                        labelText="Default Landing page"
                                        selectProps={{
                                            onChange: (e) => this.handleChange(e,"home_page"),
                                            value: user.home_page
                                        }}
                                        inputProps={{
                                            name: "home_page",
                                            id: "input-home_page",
                                            className: classes.alignLeft,
                                        }}
                                        color="custom"
                                        items={this.homePages}
                                        itemLabel="value"
                                        itemValue="key"
                                    />
                                    <div className={classes.formFooter}>
                                        <Button color="custom" type="submit" disabled={this.saveButtonDisabled()}>
                                            {
                                                saving ?
                                                    <LoaderComponent color="white" align="center" saving={true} />
                                                :
                                                "Save"
                                            }
                                        </Button>
                                    </div>
                                </form>
                            </div>
                            {
                                houseKeepingData.length > 0 ?
                                    <div className={classes.houseKeepingContainer}>
                                        <h3>Housekeeping (Auto checks daily)</h3>
                                        <ReactTable
                                            columns={this.getColumns()}
                                            data={this.getTableData()}
                                            pageSizeOptions={[500]}
                                            defaultPageSize={500}
                                            showPaginationTop={false}
                                            minRows={0}
                                            showPaginationBottom={false}
                                            className={"-striped -highlight "+classes.houseKeepingTable}
                                            getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                            getNoDataProps={() => this.getNoDataProps()}
                                        />
                                        <div className="note">
                                            <p>Housekeeping applies to all files and links including those in the <RouerLink to={"/user/archives-transfers"} onClick={this.handleClose}>Archives</RouerLink>.</p>
                                        </div>
                                        <div className={classes.houseKeepingFooter}>
                                            <div className="run-save">
                                                <Button disabled={this.houseKeepingSaveButtonDisabled()} color="custom" onClick={() => this.onSaveHouseKeeping()}>
                                                    {
                                                        houseKeepingSaving ?
                                                            <LoaderComponent color="white" align="center" saving={true} />
                                                        :
                                                            "Save"
                                                    }
                                                </Button>
                                                <Button color="custom" onClick={() => this.onRunHouseKeepingModal(true)}>
                                                    Run
                                                </Button>
                                            </div>
                                            <div className="set-default">
                                                <Button color="custom" onClick={() => this.onSetDefault()} >
                                                    Set Defaults
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <></>
                            }
                        </GridItem>
                    </div>
                </div>
                {
                    deleteHouseKeepingModal ?
                        <DeleteHouseKeepingConfirmationModal 
                            open={deleteHouseKeepingModal} 
                            saving={deleteHouseKeepingSaving}
                            onClose={() => this.onRunHouseKeepingModal(false)} 
                            onSuccess={() => this.onRunHouseKeeping()}
                            confirmationMessage="Are you sure you want to run?"
                        />
                    :
                    <></>
                }
                {
                    infoModal ?
                        <HousekeepingInfoModal
                            open={infoModal}
                            onClose={() => this.onInfoModal(false)}
                            type={infoType}
                        />
                    :
                        <></>
                }
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserSettings.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userSettingsPageStyle)(UserSettings);
