import {
    whiteColor,
    dangerColor
  } from "./material-kit-pro-react.jsx";

const editDocumentPageStyle = theme => ({
    main: {
        background: whiteColor,
        border: "none",
        height: "100%",
        width: "100%",
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        "& .sd_loader": {
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            margin: "auto",
            "& .MuiCircularProgress-root": {
                width: "100px !important",
                height: "100px !important",
                "& svg": {
                    width: "inherit",
                    height: "inherit"
                }
            }
        }
    },
    officeForm: {
        display: "none"
    },
    officeIframeContainer: {
        width: "inherit",
        height: "inherit",
        position: "relative",
        "& iframe": {
            border: "none",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px"
        }
    },
    errorMessage: {
        color: dangerColor[0],
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        margin: "auto",
        height: "23px"
    }
});

export default editDocumentPageStyle;
