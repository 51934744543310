import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import confirmationModalStyle from "../../../assets/jss/user/confirmationModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ContactConfirmationModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
            >
                <DialogTitle
                    id="contact-confirmation-modal"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>{this.props.title}</h4>                     
                </DialogTitle>
                <DialogContent
                    id="confirmationModal-modal-slide-description"
                    className={classes.modalBody}
                >
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button color="custom" onClick={() => this.props.onClose()} >
                        Ok
                    </Button>
                </DialogActions>
                
            </Dialog>
        )
    }
}

ContactConfirmationModal.defaultProps = {
    open: false,
    title: "Thank You - We will get back to you soon!",
}
ContactConfirmationModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string
}
export default withStyles(confirmationModalStyle)(ContactConfirmationModal);