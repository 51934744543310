import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import { helper } from "../../../assets/js/utils/Element";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import GridItem from "../../components/Grid/GridItem";
import { Link as RouterLink } from "react-router-dom";
import LoaderComponent from "../../components/Loader";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Button from "../../components/CustomButtons/Button";
import ArchivesInfoModal from "../../components/User/ArchivesInfoModal";
import ArchivesFilesModal from "../../components/User/ArchivesFilesModal";
import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

const UsersArchivesTransfers = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        let path = helper.getParam("dir");
        if(path === null || path === "null" || path === "/" || path === "%2F"){
            path = "/Archives";
        }

        this.state = {
            limit: 200,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            path: decodeURIComponent(path.trim()),
            newUser: null,
            infoModal: false,
            infoType: '',
            filesModal: false,
            falseType: ''
        };

        this.loadingFolder = false;
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            if(user.level_id === 5){
                let redirectUrl = "/user/shared";
                this.history.push(redirectUrl);
            }

            this.loadArchivesStats();
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    loadArchivesStats(){
        const source = axios.CancelToken.source();
        
        let requestData = {
            archives: 1
        };

        requestData['includeSub'] = true;
        requestData['filesOnly'] = true;
        requestData['stats'] = true;

        this.setState({
            loading: true
        });
        
        Api.getArchiveFiles(requestData, source).then(data => {
            const response = data.response;

            this.setState({
                response: response,
                loading: false,
            });

            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
        });
        
        this.loadingFolder = true;
    }
    getBreadCrumbs(){
        let { path } = this.state;
        let breadcrumbs = [{
            value: "/",
            label: "Archives"
        }];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        if(pathArray.length > 0){
            breadcrumbs = [];
        }
        let newPath = "";
        pathArray.map(path => {
            newPath += "/"+path;
            breadcrumbs.push({
                value: newPath,
                label: path
            });
            return null;
        });
        return breadcrumbs;
    }
    onInfoModal(status = false, type = ''){
        this.setState({
            infoModal: status,
            infoType: type
        });
    }
    onFilesModal(status = false, type = ''){
        this.setState({
            filesModal: status,
            filesType: type
        });

        if(status === false){
            this.loadArchivesStats();
        }
    }
    getFilesContent(){
        const { classes } = this.props;
        const { loading, response } = this.state;
        const breadcrumbs = this.getBreadCrumbs();

        let content = (
            <div className={classes.content}>
                <GridItem>
                    <div className={classes.controls}>
                        <h4>Archives</h4>
                    </div>
                    <div className={classes.breadcrumbs}>
                        <div>
                            {
                                breadcrumbs.map((breadCrumb, key) => {
                                    return (
                                        <div key={key}>
                                            {
                                                key !== 0 ?
                                                    <span><ChevronRight /></span>
                                                :
                                                <></>
                                            }
                                            <RouterLink to="/user/archives">
                                                {breadCrumb.label}
                                            </RouterLink>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={classes.filesContainer+" "+classes.archivesContainer}>
                        <div className={classes.archivesFiles}>
                            <div className='files-table'>
                                <h5>My Docs & Web Transfers</h5>
                                {
                                    loading === true || response === null ?
                                        <LoaderComponent color="custom" align="center" />
                                    :
                                        <ul className="files-list">
                                            <li>
                                                <ul className="file-list">
                                                    <li>
                                                        <Button color="transparent" simple onClick={() => this.onFilesModal(true, 'link')}>
                                                            Link Downloads
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        { helper.getFormatedSize(response.link) }
                                                    </li>
                                                    <li>
                                                        <Button color="transparent" simple onClick={() => this.onInfoModal(true, 'link')}>
                                                            More Info...
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <ul className="file-list">
                                                    <li>
                                                        <Button color="transparent" simple onClick={() => this.onFilesModal(true, 'direct')}>
                                                            Direct Downloads
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        { helper.getFormatedSize(response.direct) }
                                                    </li>
                                                    <li>
                                                        <Button color="transparent" simple onClick={() => this.onInfoModal(true, 'direct')}>
                                                            More Info...
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                }
                            </div>
                            <div className='files-table'>
                                <h5>Previous Versions</h5>
                                {
                                    loading === true || response === null ?
                                        <LoaderComponent color="custom" align="center" />
                                    :
                                        <ul className="files-list">
                                            <li>
                                                <ul className="file-list">
                                                    <li>
                                                        <Button color="transparent" simple onClick={() => this.onFilesModal(true, 'office')}>
                                                            MS Office Documents
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        { helper.getFormatedSize(response.office) }
                                                    </li>
                                                    <li>
                                                        <Button color="transparent" simple onClick={() => this.onInfoModal(true, 'office')}>
                                                            More Info...
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <ul className="file-list">
                                                    <li>
                                                        <Button color="transparent" simple onClick={() => this.onFilesModal(true, 'shared')}>
                                                            Shared Documents
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        { helper.getFormatedSize(response.shared) }
                                                    </li>
                                                    <li>
                                                        <Button color="transparent" simple onClick={() => this.onInfoModal(true, 'shared')}>
                                                            More Info...
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <ul className="file-list">
                                                    <li>
                                                        <Button color="transparent" simple onClick={() => this.onFilesModal(true, 'keep')}>
                                                            Keep Forever
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        { helper.getFormatedSize(response.keep) }
                                                    </li>
                                                    <li>
                                                        <Button color="transparent" simple onClick={() => this.onInfoModal(true, 'keep')}>
                                                            More Info...
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                }
                            </div>
                        </div>
                    </div>
                </GridItem>
            </div>
        );
        
        return (
            content 
        );
    }
    render() {
        const { classes } = this.props;
        const { infoModal, infoType, filesModal, filesType } = this.state;
        const { authorized, user } = this.store.getState();

        if(!authorized || user.level_id === 5){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    {
                        this.getFilesContent()
                    }
                    {
                        infoModal ?
                            <ArchivesInfoModal
                                open={infoModal}
                                onClose={() => this.onInfoModal(false)}
                                type={infoType}
                            />
                        :
                            <></>
                    }
                    {
                        filesModal ?
                            <ArchivesFilesModal
                                open={filesModal}
                                onClose={() => this.onFilesModal(false)}
                                type={filesType}
                                store={this.store} 
                            />
                        :
                            <></>
                    }
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UsersArchivesTransfers.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(UsersArchivesTransfers);
