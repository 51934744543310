import { blackColor } from "../material-dashboard-pro-react.jsx";
import { grayColor,  infoColor, successColor, dangerColor } from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";

const restoreFileModalStyle = theme => ({
    ...modalStyle(theme),
    modalRoot: {
        overflow: "auto",
        display: "block"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "25px",
        paddingBottom: "20px",
        textAlign: "left",
        "& p": {
            color: theme.customize.whiteBlackColor,
            opacity: "0.8",
            "&$danger": {
                color: dangerColor[0],
                opacity: "1",
            },
            marginBottom: "18px",
            "& span": {
                fontWeight: "700"
            },            
            "&.delete-note-1": {
                marginBottom: "0px",
                marginTop: "20px"
            }
        },
        "& .items-lists": {
            paddingLeft: "23px",
            "& ul": {
                marginBottom: "0px"
            },
            "& p": {
                marginBottom: "0px",
                lineHeight: "normal"
            }
        },
        "& h6": {
            textTransform: "none",
            marginBottom: "3px"
        },
        "& .custom-input": {
            marginTop: "20px"
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    modalDuplicates: {
        maxWidth: "600px",
        width: "100%",
    },
    duplicates: {
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
            textAlign: "left",
            "& li": {
                cursor: "pointer",
                padding: "8px 10px",
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s ease",
                "& svg": {
                    marginRight: "5px"
                },
                "&.active": {
                    background: infoColor[0]
                },
                "&:hover:not(.active)": {
                    background: infoColor[8]
                }
            }
        }
    },
    checkIcon: {
        color: successColor[0]
    },
    skipIcon: {
        color: infoColor[2]
    },
    duplicateSelection: {
        color: blackColor,
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
            textAlign: "left",
            "& li": {
                cursor: "pointer",
                padding: "8px 0px",
                marginTop: "8px",
                borderTop: "1px solid "+grayColor[4]
            }
        }
    },
    selectiveCheckboxes: {
        display: "flex",
        "& $label": {
            whiteSpace: "nowrap",
            paddingLeft: "0px",
            color: blackColor
        },
        "& $checkRoot": {
            padding: "5px"
        },
        "& .MuiFormControlLabel-root": {
            marginLeft: "0px",
            width: "50%"
        }
    },
    duplicateFile: {
        display: "flex",
        "& $label": {
            paddingLeft: "0px",
            color: blackColor
        },
        "& $checkRoot": {
            padding: "5px"
        },
        "& .MuiFormControlLabel-root": {
            marginLeft: "0px",
            width: "50%"
        }
    },
    duplicateFileName: {
        fontSize: "14px",
        paddingLeft: "5px"
    },
    selectionButtons: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "0px",
        "& button": {
            marginLeft: "10px"
        }
    },
    checkboxSection: {
        "& p": {
            marginTop: "10px",
            marginBottom: "0px"
        },
        "& label": {
            display: "block",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "5px"
        }
    }
});

export default restoreFileModalStyle;
