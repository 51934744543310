import { whiteColor, grayColor, infoColor, successColor, blackColor } from "../material-kit-pro-react.jsx";

import homePageStyle from "../material-kit-pro-react/views/homePageStyle.jsx";
const groupMembersModalStyle = theme => ({
    ...homePageStyle(theme),
    modalRoot: {
        overflow: "auto",
        display: "block"
    },
    modal: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: "550px",
            margin: "auto"
        },
        borderRadius: "6px",
        overflow: "visible",
        maxHeight: "unset",
        width: "60%",
        marginTop: "130px !important",
        boxShadow: "0px 11px 15px -7px rgba(255, 255, 255, 0.2), "+
                "0px 24px 38px 3px rgba(255, 255, 255, 0.14), "+
                "0px 9px 46px 8px rgba(255, 255, 255, 0.12)",
        [theme.breakpoints.down("sm")]: {
            marginTop: "50px !important",
            margin: theme.spacing(3)
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "30px !important",
            margin: theme.spacing(2),
            width: "400px",
            maxWidth: "100%"
        }
    },
    modalHeader: {
        borderBottom: "none",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        minHeight: "0px"
    },
    modalTitle: {
        margin: "0",
        lineHeight: "1.5",
        textAlign: "center",
    },
    modalCloseButton: {
        "&, &:hover": {
            color: blackColor
        },
        "&:hover": {
            opacity: "1"
        },
        cursor: "pointer",
        padding: "1rem",
        margin: "-1rem -1rem -1rem auto",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5",
        color: blackColor
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalBody: {
        paddingTop: "24px",
        paddingRight: "24px",
        paddingBottom: "16px",
        paddingLeft: "24px",
        position: "relative",
        overflow: "visible",
        textAlign: "center"
    },
    modalFooter: {
        padding: "15px",
        paddingTop: "0",
        margin: "0 auto"
    },
    modalCloseButtonCustom: {
        top: "16px !important",
        right: "16px !important",
        position: "absolute !important"
    },
    transferFooter: {
        ...homePageStyle(theme).transferFooter,
        "& svg": {
            transform: "none !important",
            marginTop: "0px !important"
        },
        "& .MuiFormControlLabel-label.Mui-disabled": {
            color: grayColor[12]
        }
    },
    filesTable: {
        "& .rt-thead .rt-th": {
            fontSize: "12px",
            color: theme.customize.tableHeadTextColorModal,
            fontWeight: "bold",
            "&.hd_hide": {
                display: 'none'
            },
            "& .rt-resizable-header-content": {
                "&:after": {
                    color: theme.customize.tableHeadTextColorModal+" !important",
                }
            }
        },
        marginBottom: "10px",
        "& .rt-table": {
            "&:not(:hover)": {
                "&::-webkit-scrollbar": {
                    width: "15px",
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(0,0,0,0.3)",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                    
                }
            }
        },
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
        },
        "& .rt-tbody .rt-td": {
            padding: "0px",
            margin: "5px 0px",
            "&.td_name": {
                "& .react-contextmenu-wrapper": {
                    padding: "5px 5px",
                    margin: "0px",
                    "&>div": {
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        display: "block",
                        width: "100%",
                    }
                }
            }
        },
        "& .hd_check": {
            width: "20px !important",
            flex: "20 0 auto !important",
            textAlign: "center !important",
            "& .MuiCheckbox-root": {
                padding: "0px"
            },
        },
        "& .hd_name": {
            textAlign: "left !important",
            width: "80px !Important",
            flex: "80 0 auto !important",
        },
        "& .hd_email": {
            textAlign: "left !important",
            width: "120px !Important",
            flex: "120 0 auto !important",
        },
        "& .td_check": {
            "& .MuiButtonBase-root": {
                padding: "0px",
            }            
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            color: grayColor[17],
            opacity: "1 !important"
        },
        "& .rt-td": {
            fontSize: "12px"
        },
        "& .rt-tr": {
            cursor: "pointer"
        },
        "& .rt-tbody": {
            overflow: "unset",
            marginBottom: "5px",
            maxHeight: "200px",
            "& .rt-tr-group": {
                flexGrow: "0",
            }
        },
        [theme.breakpoints.down("xs")]: {
            padding: "10px"
        },
        "&.usersTable": {
            "& .left": {
                textAlign: "left"
            }
        },
        "& .rt-resizer": {
            display: "none !important"
        },
        "& .rt-resizable-header-content:after": {
            display: "none !important"
        }
    },
    zipName: {
        "& .MuiInput-underline": {
            border: "1px solid "+grayColor[2],
            paddingLeft: "10px",
            borderRadius: "3px",
            "&:before": {
                display: "none !important"
            },
            "&:after": {
                bottom: "-1px",
                borderBottomWidth: "1px"
            }
        }
    },
    zipPassword: {
        "& .MuiFormControl-root": {
            paddingTop: "0px"
        }
    },
    removeButtonBorder: {
        "& button": {
            border: "0px !important"
        }
    },
    inlineFields: {
        "& .MuiFormControl-root": {
            width: "calc(50% - 10px)",
            "&:first-child": {
                marginRight: "5px",
            },
            "&:first-child + div": {
                marginLeft: "5px"
            },
            "& .MuiTextField-root": {
                width: "100%"
            }
        }
    },
    modalDuplicates: {
        maxWidth: "600px",
        width: "100%"
    },
    duplicates: {
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
            textAlign: "left",
            "& li": {
                cursor: "pointer",
                padding: "8px 10px",
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s ease",
                "& svg": {
                    marginRight: "5px"
                },
                "&.active": {
                    background: infoColor[0]
                },
                "&:hover:not(.active)": {
                    background: infoColor[8]
                }
            }
        }
    },
    checkIcon: {
        color: successColor[0]
    },
    skipIcon: {
        color: infoColor[2]
    },
    duplicateSelection: {
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
            textAlign: "left",
            "& li": {
                cursor: "pointer",
                padding: "8px 0px",
                marginTop: "8px",
                borderTop: "1px solid "+grayColor[4]
            }
        }
    },
    selectiveCheckboxes: {
        display: "flex",
        "& $label": {
            whiteSpace: "nowrap",
            paddingLeft: "0px",
        },
        "& $checkRoot": {
            padding: "5px"
        },
        "& .MuiFormControlLabel-root": {
            marginLeft: "0px",
            width: "50%"
        }
    },
    duplicateFile: {
        display: "flex",
        "& $label": {
            paddingLeft: "0px",
        },
        "& $checkRoot": {
            padding: "5px"
        },
        "& .MuiFormControlLabel-root": {
            marginLeft: "0px",
            width: "50%"
        }
    },
    duplicateFileName: {
        fontSize: "14px",
        paddingLeft: "5px"
    },
    selectionButtons: {
        display: "flex",
        justifyContent: "flex-end",
        "& button": {
            marginLeft: "10px"
        }
    },
    addBtn: {
        "& span": {
            fontSize: "14px"
        }
    },
    customTabs: {
        "& .MuiTabs-scrollButtons": {
            width: "max-content"
        },
        "& .card": {
            backgroundColor: "transparent",
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            boxShadow: "none",
        },
        "& .card-header": {
            marginLeft: "0px",
            marginRight: "0px",
            boxShadow: "none",
            background: whiteColor,
            padding: "0px 0px 10px",
            border: "1px solid "+grayColor[11],
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderRadius: "0px",
            "& button": {
                color: blackColor,
                border: "1px solid transparent",
                "&.Mui-selected": {
                    border: "1px solid "+grayColor[11]
                }
            }
        },
        "& .card-body": {
            padding: "5px 0px"
        }
    }
});

export default groupMembersModalStyle;
