import Config from '../../../../Config';
import Cookie from './Cookie';
import { helper } from './Element'
import axios from 'axios';

const ApiHelper = {
    countries: null,
    getDefaultMemberObject(){
        return  {
            first_name: "",
            last_name: "",
            email: "",
            created_at: "",
            level_id: 5,
            verified: 0,
            theme: "light",
            enable_2fa: false,
            cell_verified: 0,
            home_page: 'send_mail',
            themeSetting: {
                backgroundColor: '#F7F7F8',
                textColor: '#030229',
                pageTitleColor: '#212121',
                borderColor: '#00000040',
                loaderColor: '#4e6ec8',
                iconsColor: '#4e6ec8',
                sidebarMenuLinkColor: '#030229',
                sidebarSubmenuLinkColor: '#5b616b',
                sidebarMenuLinkShadow: '#212121',
                sidebarMenuIconsColor: '#556DC2',
                horizontalMenuTextColor: '#000',
                horizontalMenuIconsColor: '#4e6ec8',
                tableHeadTextColor: '#030229',
                tableRowActionsIconsColor: '#000',
                checkboxColor: '#B3B3BF',
                breadcrumbsColor: '#B3B3BF',
                linksColor: '#4e6ec8',
                headingsColor: '#4e6ec8',
                buttonsColor: '#4e6ec8'
            }
        };
    },
    prepareMemberObject(member){
        const defaultObject = this.getDefaultMemberObject();
        let memberObject = Object.assign({}, defaultObject); //Create copy to avoid reference copy
        memberObject = Object.assign(memberObject, member);
        for(var attrib in memberObject){
            try{
                if(typeof(memberObject[attrib]) === "string"){
                    memberObject[attrib] = memberObject[attrib].trim();
                }                
            }catch(e){ }
        }
        return memberObject;
    },
    getPlans(){
        const plans = [
            {
                id: 1,
                label: "Promotion",
            },
            {
                id: 2,
                label: "Standard",
            },
            {
                id: 3,
                label: "Pro",
            },
            {
                id: 4,
                label: "Business",
            },
            {
                id: 5,
                label: "Lite",
            },
            {
                id: 6,
                label: "Pro Lite",
            },
        ];
        return plans;
    },
    getBusinessTypes(){
        const businessTypes = [
            {
                id: 1,
                label: "Individual/Sole Proprietorship",
            },
            {
                id: 2,
                label: "Partnership",
            },
            {
                id: 3,
                label: "Corporation",
            },
            {
                id: 4,
                label: "Nonprofit Organization",
            },
            {
                id: 5,
                label: "Government Entity",
            },
        ];
        return businessTypes;
    },
    getSignupControls(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/signupControls?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    canSignup(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/canSignup";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    signUp(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/signup";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    confirmSignUp(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/verify";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    resendCode(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/resend";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    forgot(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/forgot";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    reset(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/reset";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    login(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/login";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    signupRequest(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/signupRequest";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getRadiusArray(){
        const radiusArray = [1,2,3,4,5,10,15,20,25,35,50,75,100,250];
        return radiusArray;
    },
    getCookies(){
        const cookies = Cookie.getAll();
        return encodeURIComponent(JSON.stringify(cookies));
    },
    getTopics(){
        const topics = ['Food', 'Travel', 'Parenting', 'Fitness'];
        return topics;
    },
    getSettings(){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"public/settings";
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    return resolve(res.data.response);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    sendActivity(requestData){
        //Handle 0 - 10;
        if(requestData.length <= 10){
            return this.sendActivityPartial(requestData);
        }

        //Handle 0 - 20;
        this.sendActivityPartial(requestData.splice(0,10));
        if(requestData.length <= 10){
            return this.sendActivityPartial(requestData);
        }

        //Handle 0 - 30;
        this.sendActivityPartial(requestData.splice(0,10));
        if(requestData.length <= 10){
            return this.sendActivityPartial(requestData);
        }

        //Handle 0 - 40;
        this.sendActivityPartial(requestData.splice(0,10));
        if(requestData.length <= 10){
            return this.sendActivityPartial(requestData);
        }

        //Handle 0 - 50;
        this.sendActivityPartial(requestData.splice(0,10));
        if(requestData.length <= 10){
            return this.sendActivityPartial(requestData);
        }
    },
    sendActivityPartial(requestData){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/activity";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateUser(userId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/profile/"+userId; 
            if(requestData.accessCode){
                ApiUrl += "?access-code="+requestData.accessCode;
            }           
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateLoginStatus(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/updateloginstatus";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateNewUpdateStatus(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/updatenewupdatestatus";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updatePassword(userId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/password/"+userId;            
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteUser(userId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/delete/"+userId;            
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    createContent(requestData){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/content";
            let headers = Config.getApiHeaders();
            let data = Object.assign({}, requestData);
            data.isDraft = !data.isDraft;
            for(var attrib in data.googlePlacesResponse){
                if(typeof(data.googlePlacesResponse[attrib]) === "string" && data.googlePlacesResponse[attrib].length <= 0){
                    data.googlePlacesResponse[attrib] = " ";
                }
            }
            axios.post(ApiUrl, JSON.stringify(data), {
                headers: headers,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateContent(contentId, requestData){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/content/"+contentId;
            let headers = Config.getApiHeaders();
            let data = Object.assign({}, requestData);
            data.isDraft = !data.isDraft;
            if(data.isDraft === false && (data.coverMediaId.length <= 0 || data.keywords.length <= 0)){
                data.isDraft = true;
            }
            for(var attrib in data.googlePlacesResponse){
                if(typeof(data.googlePlacesResponse[attrib]) === "string" && data.googlePlacesResponse[attrib].length <= 0){
                    data.googlePlacesResponse[attrib] = " ";
                }
            }
            axios.put(ApiUrl, JSON.stringify(data), {
                headers: headers,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteContent(contentId){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/content/"+contentId;
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    getMedia(mediaId, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/media/"+mediaId;
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    return resolve(res.data.response[0]);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    createPaymentMethod(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/paymentmethod";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    deletePaymentMethod(cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/paymentmethod";
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject("Invalid response from api.");
            });
        });
    },
    checkUsername(username, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/user/username/"+username;
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    return resolve(res.data.response);
                }else{
                    reject({message: "Username is available."});
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    createPlatform(requestData, cancelTokenSource, userId){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/user/platform/"+userId;
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    getPlatforms(requestData, cancelTokenSource, userId){
        return new Promise(function(resolve, reject){
            let ApiUrl = Config.getApiUrl()+"data/user/platform/"+userId;
            axios({
                url: ApiUrl+"?"+helper.serialize(requestData),
                method: "GET",
                headers: Config.getApiHeaders(),
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    getContent(requestData, cancelTokenSource, userId = null){
        return new Promise(function(resolve, reject){
            let ApiUrl = Config.getApiUrl()+"data/content/";
            if(userId !== null){
                ApiUrl += "user/"+userId;
            }
            axios({
                url: ApiUrl+"?"+helper.serialize(requestData),
                method: "GET",
                headers: Config.getApiHeaders(),
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });        
    },
    sendContactRequest(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/support";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    pronouns() {
        let array = ["he/him/his","she/her/hers","they/them/theirs","other/ask me"];
        return array;
    },
    getFonts(){
        return [
            {
                fontName: "Default",
                fontFamily: "Roboto"
            },
            {
                fontName: "Lato",
                fontFamily: "Lato"
            },
            {
                fontName: "Open Sans",
                fontFamily: "Open Sans"
            },
            {
                fontName: "Josefin Sans",
                fontFamily: "Josefin Sans"
            },
            {
                fontName: "Sniglet",
                fontFamily: "Sniglet"
            },
            {
                fontName: "Courgette",
                fontFamily: "Courgette"
            },
            {
                fontName: "Athiti",
                fontFamily: "Athiti"
            },
        ];
    },
    prepareFiles(files){
        const filesArray = [];
        files.map(item => {
            if(item.type === "folder"){
                let folderFiles = [];
                let totalSize = 0;
                item.files.map(subItem => {
                    let file = subItem;
                    if(item.hasOwnProperty("checkRelativePath")){
                        file = subItem.file;
                    }
                    let path = file.webkitRelativePath;
                    if(item.hasOwnProperty("checkRelativePath")){
                        path = subItem.webkitRelativePath;
                    }
                    path = path.substring(0, path.lastIndexOf("/"));
                    folderFiles.push({
                        name: path+"/"+file.name,
                        item_type: "file",
                        size: file.size,
                        type: file.type,
                        duplicate: (item.hasOwnProperty("duplicate") ? 1 : 0)
                    });
                    totalSize += file.size;
                    return null;
                });
                filesArray.push({
                    name: item.name,
                    item_type: item.type,
                    size: totalSize,
                    type: item.type,
                    files: folderFiles,
                    duplicate: (item.hasOwnProperty("duplicate") ? 1 : 0)
                });
            }else{
                filesArray.push({
                    name: item.file.name,
                    item_type: "file",
                    size: item.file.size,
                    type: item.file.type,
                    duplicate: (item.hasOwnProperty("duplicate") ? 1 : 0)
                });
            }
            return null;
        });
        return filesArray;
    },
    sendFiles(requestData, files, cancelTokenSource, manager = false){
        const that = this;
        return new Promise(function(resolve, reject){
            requestData['files'] = that.prepareFiles(files);
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"transfers/email";
            if(manager){
                ApiUrl = Config.getApiUrl()+"user/transfers/email";
            }
            axios.post(ApiUrl, requestData, {
                headers: headers,
                cancelToken: cancelTokenSource.token,
                timeout: 0
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
            
        });
    },
    createVersion(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/version";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    createMedia(file, requestData, cancelTokenSource, onUploadProgress, manager = false){
        const that = this;
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"transfers/"+requestData.id+"/files";
            if(manager){
                ApiUrl = Config.getApiUrl()+"user/transfers/"+requestData.id+"/files";
            }
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    that.uploadMedia(file, res.data.body, cancelTokenSource, onUploadProgress).then((data) => {
                        resolve(data);
                    }).catch(err => {
                        if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                            return reject({
                                message: "The File or Folder is no longer available. Please remove and Re-add the file."
                            });
                        }
                        reject(err);
                    });
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getPartUrl(file, blob, requestData, cancelTokenSource, onUploadProgress, manager = false){
        const that = this;
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"transfers/"+requestData.id+"/files/part-put-url";
            if(manager){
                ApiUrl = Config.getApiUrl()+"user/transfers/"+requestData.id+"/files/part-put-url";
            }
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    that.uploadBlob(file, blob, res.data.body, cancelTokenSource, onUploadProgress).then((data) => {
                        resolve(data);
                    }).catch(err => {
                        reject(err);
                    });
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    uploadMedia(file, data, cancelTokenSource, onUploadProgress){
        return new Promise(function(resolve, reject){
            let headers = {};
            headers['Content-Type'] = file.type;
            axios.put(data.uploadUrl, file, {
                headers: headers,
                cancelToken: cancelTokenSource.token,
                onUploadProgress: function(e){
                    onUploadProgress(e);
                }
            }).then(res => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    uploadBlob(file, blob, data, cancelTokenSource, onUploadProgress){
        return new Promise(function(resolve, reject){
            let headers = {};
            headers['Content-Type'] = file.type;
            axios.put(data.uploadUrl, blob, {
                headers: headers,
                cancelToken: cancelTokenSource.token,
                onUploadProgress: function(e){
                    onUploadProgress(e);
                }
            }).then(res => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    finalizeMultiPart(requestData, cancelTokenSource, manager = false){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"transfers/"+requestData.id+"/files/finalize-mpp";
            if(manager){
                ApiUrl = Config.getApiUrl()+"user/transfers/"+requestData.id+"/files/finalize-mpp";
            }
            axios({
                url: ApiUrl,
                headers: headers,
                method: "GET",
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    processMedia(id, cancelTokenSource, manager = false, requestData = null){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"transfers/"+id+"/finalize";
            if(manager){
                ApiUrl = Config.getApiUrl()+"user/transfers/"+id+"/finalize";
            }
            if(requestData && typeof(requestData) === "object"){
                let key = 0;
                for(var attrib in requestData){
                    if(key === 0){
                        ApiUrl += "?"+attrib+"="+encodeURIComponent(requestData[attrib]);
                    }else{
                        ApiUrl += "&"+attrib+"="+encodeURIComponent(requestData[attrib]);
                    }
                    key++;
                }
            }
            axios({
                url: ApiUrl,
                headers: headers,
                method: "GET",
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getTransfer(id, recipient, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"transfers/"+id+"/preview?recipient="+recipient;
            axios({
                url: ApiUrl,
                headers: headers,
                method: "GET",
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code === 403){
                    return reject({
                        message: "The file(s) you are trying to download is no longer available"
                    });
                }
                reject(err);
            });
        });
    },
    browserExit(cancelTokenSource, requestData = null){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"transfers/"+requestData.id+"/browser_exit";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    previewPassword(id, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"transfers/"+id+"/previewPassword?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                headers: headers,
                method: "GET",
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code === 403){
                    return reject({
                        message: "The file(s) you are trying to download is no longer available"
                    });
                }
                reject(err);
            });
        });
    },
    getCountries(cancelTokenSource){
        const that = this;
        return new Promise(function(resolve, reject){
            if(that.countries !== null){
                return resolve(that.countries);
            }
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"countries";
            axios({
                url: ApiUrl,
                headers: headers,
                method: "GET",
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    that.countries = res.data.body;
                    resolve(that.countries);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getStates(country, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"states/"+country;
            axios({
                url: ApiUrl,
                headers: headers,
                method: "GET",
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getCities(country, state, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"cities/"+country+"/"+state;
            axios({
                url: ApiUrl,
                headers: headers,
                method: "GET",
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getFolders(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/folders/manage?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                  
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getFiles(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/files/manage?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                  
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getSingleFile(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"getsinglefile?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteSingleFile(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"delete-single-file?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    
    insertFolerEntryToLocal(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"folder-entry";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
  
    getArchiveFiles(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/archiveFiles?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteFiles(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/delete";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteFilesPermanently(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/deletePermanently";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteLinksPermanently(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/links/deletePermanently";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    restoreFilesPermanently(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/restore";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    restoreLinksPermanently(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/links/restore";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    favouriteFile(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/favourites";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    shareFiles(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/share";
            axios.defaults.timeout = 18000000;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
                timeout: 18000000
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    downloadFiles(requestData, cancelTokenSource){
        let url = "user/files/download";
        if(requestData.accessCode){
            url += "?access-code="+requestData.accessCode;
        }
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+url;
            axios.defaults.timeout = 18000000;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
                timeout: 18000000
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getLinks(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/links";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getDeletedLinks(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/links/deleted?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    changeLink(transferId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/transfer/rename";
            if(transferId !== null){
                ApiUrl += "/"+transferId;
            }
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteLink(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/links/delete";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    changeExpiryDate(transferId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/transfer/expire";
            if(transferId !== null){
                ApiUrl += "/"+transferId;
            }
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    changeLinkPassword(transferId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/transfer/linkPassword";
            if(transferId !== null){
                ApiUrl += "/"+transferId;
            }
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    checkSubscription(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"subscription/return";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    changeSubscription(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"subscription/change";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getTransactions(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/transactions?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getFavouriteFiles(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/files/favourites?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getDeletedFiles(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/files/deleted?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getTimezoneOptions(){
        const timezones = [
            {
                key: "US/Pacific",
                value: "(UTC-8) Pacific Time (US & Canada)"
            },
            {
                key: "US/Mountain",
                value: "(UTC-7) Mountain Time (US & Canada)"
            },
            {
                key: "US/Central",
                value: "(UTC-6) Central Time (US & Canada)"
            },
            {
                key: "US/Eastern",
                value: "(UTC-5) Eastern Time (US & Canada)"
            },
            {
                key: "America/Halifax",
                value: "(UTC-4)  Atlantic Time (Canada)"
            },
            {
                key: "America/Anchorage",
                value: "(UTC-9)  Alaska (US & Canada)"
            },
            {
                key: "Pacific/Honolulu",
                value: "(UTC-10) Hawaii (US)"
            },
            {
                key: "Pacific/Samoa",
                value: "(UTC-11) Midway Island, Samoa"
            },
            {
                key: "Etc/GMT-12",
                value: "(UTC-12) Eniwetok, Kwajalein"
            },
            {
                key: "Canada/Newfoundland",
                value: "(UTC-3:30) Canada/Newfoundland"
            },
            {
                key: "America/Buenos_Aires",
                value: "(UTC-3) Brasilia, Buenos Aires, Georgetown"
            },
            {
                key: "Atlantic/South_Georgia",
                value: "(UTC-2) Mid-Atlantic"
            },
            {
                key: "Atlantic/Azores",
                value: "(UTC-1) Azores, Cape Verde Is."
            },
            {
                key: "Europe/London",
                value: "Greenwich Mean Time (Lisbon, London)"
            },
            {
                key: "Europe/Berlin",
                value: "(UTC+1) Amsterdam, Berlin, Paris, Rome, Madrid"
            },
            {
                key: "Europe/Athens",
                value: "(UTC+2) Athens, Helsinki, Istanbul, Cairo, E. Europe"
            },
            {
                key: "Europe/Moscow",
                value: "(UTC+3) Baghdad, Kuwait, Nairobi, Moscow"
            },
            {
                key: "Iran",
                value: "(UTC+3:30) Tehran"
            },
            {
                key: "Asia/Dubai",
                value: "(UTC+4) Abu Dhabi, Kazan, Muscat"
            },
            {
                key: "Asia/Kabul",
                value: "(UTC+4:30) Kabul"
            },
            {
                key: "Asia/Yekaterinburg",
                value: "(UTC+5) Islamabad, Karachi, Tashkent"
            },
            {
                key: "Asia/Calcutta",
                value: "(UTC+5:30) Bombay, Calcutta, New Delhi"
            },
            {
                key: "Asia/Katmandu",
                value: "(UTC+5:45) Nepal"
            },
            {
                key: "Asia/Omsk",
                value: "(UTC+6) Almaty, Dhaka"
            },
            {
                key: "Indian/Cocos",
                value: "(UTC+6:30) Cocos Islands, Yangon"
            },
            {
                key: "Asia/Krasnoyarsk",
                value: "(UTC+7) Bangkok, Jakarta, Hanoi"
            },
            {
                key: "Asia/Hong_Kong",
                value: "(UTC+8) Beijing, Hong Kong, Singapore, Taipei"
            },
            {
                key: "Asia/Tokyo",
                value: "(UTC+9) Tokyo, Osaka, Sapporto, Seoul, Yakutsk"
            },
            {
                key: "Australia/Adelaide",
                value: "(UTC+9:30) Adelaide, Darwin"
            },
            {
                key: "Australia/Sydney",
                value: "(UTC+10) Brisbane, Melbourne, Sydney, Guam"
            },
            {
                key: "Asia/Magadan",
                value: "(UTC+11) Magadan, Solomon Is., New Caledonia"
            },
            {
                key: "Pacific/Auckland",
                value: "(UTC+12) Fiji, Kamchatka, Marshall Is., Wellington"
            },
        ];
        return timezones;
    },
    getBackgroundImages(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"images?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateUserSettings(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/settings";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateUserExtensions(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let ApiUrl = Config.getApiUrl()+"user/extensions";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    addNewFolder(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/folder";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    addOneDrivePath(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"update-extension";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    addNewFile(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/file";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getDownloads(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/downloads?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getAccounts(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/accounts?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getUserSubscriptions(){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/subscriptions";
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getUserAddresses(){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/addresses";
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getUserPaymentMethods(){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/payment/sources";
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getUserInvoices(requestData){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/invoices?"+helper.serialize(requestData);
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getInvoice(invoiceId){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/invoice/pdf/"+invoiceId;
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateUserAddresses(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/addresses";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    cancelSubscription(subscriptionId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/subscription/cancel/"+subscriptionId;
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    resumeSubscription(subscriptionId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/subscription/resume/"+subscriptionId;
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    createPaymentSource(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/payment/sources/create";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    removePaymentSource(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/payment/sources/delete";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    paymentSourceRole(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/payment/sources/role";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateSubscriptionPlan(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/subscription/plan/change";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateSubscriptionPaymentMethod(subscriptionItemId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/subscription/billing/"+subscriptionItemId;
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    copyFiles(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/copy";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    moveFiles(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/move";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    renameFile(id, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/file/rename/"+id;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    noteFile(id, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/file/note/"+id;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    uploadUserPhoto(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/photo";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }
            }).catch(function (err) {
                reject(err);
            });
            
        });
    },
    removeUserPhoto(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/removePhoto";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }
            }).catch(function (err) {
                reject(err);
            });
            
        });
    },
    uploadOtherUserPhoto(requestData, cancelTokenSource, userId){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            headers['oauth-identity'] = userId;
            const ApiUrl = Config.getApiUrl()+"user/photo";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }
            }).catch(function (err) {
                reject(err);
            });
            
        });
    },
    filesExport(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/export/request";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    cancelFiles(requestData){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/transfers/cancel";
            axios.post(ApiUrl, requestData, {
                headers: headers,
                timeout: 0
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
            
        });
    },
    disconnectAccount(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/account/disconnect";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getLoginHistory(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/logins?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getSharedFiles(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/files/shared?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    sharePermissions(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/shared/permission";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    sendStatusNotification(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/shared/notification";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getOccupationOptions(){
        const occupations = [
            'Accounting',
            'Administration',
            'Arts, Culture',
            'Business',
            'Communications',
            'Customer Service',
            'Education',
            'Energy Utilities',
            'Engineering',
            'Finance',
            'Financial Services',
            'Government',
            'Health',
            'Hospitality',
            'Human Resources',
            'Internet',
            'Information Technology',
            'Legal',
            'Manufacturing',
            'Marketing',
            'Non-profit',
            'Recreation',
            'Religion',
            'Research',
            'Sales',
            'Sports, Fitness',
            'Student'
        ];
        return occupations;
    },
    businessInfo(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/businessInfo";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getBusinessInfo(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/businessInfo";
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getBusinessAds(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"business/businessAds";
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateBusinessInfo(userId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/businessInfo?id="+userId;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateBusinessAccountAllowance(userId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/businessAccountAllowance?id="+userId;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateBusinessAds(userId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"business/businessAds?id="+userId;
            
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }
            }).catch(function (err) {
                reject(err);
            });
            
        });
    },
    uploadBusinessPhoto(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"business/photo";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }
            }).catch(function (err) {
                reject(err);
            });
            
        });
    },
    uploadBusinessBackgroundPhoto(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"business/backgroundPhoto";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }
            }).catch(function (err) {
                reject(err);
            });
            
        });
    },
    uploadInternalBackgroundPhoto(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"business/internalBackground";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }
            }).catch(function (err) {
                reject(err);
            });
            
        });
    },
    removeBusinessPhoto(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"business/removePhoto";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }
            }).catch(function (err) {
                reject(err);
            });
            
        });
    },
    removeBusinessAds(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"business/removeAds";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }
            }).catch(function (err) {
                reject(err);
            });
            
        });
    },
    addUser(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/addUser";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    editUser(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/editUser";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    addMultiUser(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/addMultiUser";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    changeStorageSize(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/changeStorageSize";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    changeAccountType(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/changeAccountType";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    change2FA(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/change2fa";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    changeExtensions(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/changeExtensions";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteBusinessEmployee(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/deleteUser";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getUsers(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/getUsers?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    downloadCsv(fileName){
        let url = Config.getApiUrl()+"user/downloadCsv/"+fileName;
        
        window.location.href = url;
    },
    addRule(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"file/attachment/rule";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    editRule(ruleId, requestData){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"file/attachment/rule/"+ruleId;
            let headers = Config.getApiHeaders();
            let data = Object.assign({}, requestData);
            axios.put(ApiUrl, JSON.stringify(data), {
                headers: headers,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    getRules(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"file/attachment/rule";
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteRule(ruleId){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"file/attachment/rule/"+ruleId;
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    clearPermissionUsers(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/shared/clearusers";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getUsersActivityExportUrl(userId, fileIds){
        let apiUrl = Config.getApiUrl()+"user/exportActivity?export=1&file_id="+fileIds+"&user_id="+userId+"&oauth-consumer-key="+Config.getConsumerKey();
        const token = Cookie.read("oauth_token");
        const secret = Cookie.read("oauth_secret");
        if(token){
            apiUrl += "&oauth-token="+token;
        }
        if(secret){
            apiUrl += "&oauth-secret="+secret;
        }
        return apiUrl;
    },
    checkCancelledFiles(){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"user/files/cancelled";
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getFileVersions(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/files/versions?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    keepFileVersion(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/version/keep/"+requestData.id;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    deleteFileVersion(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/version/delete/"+requestData.id;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    deleteArchiveVersion(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/archiveFiles/version/delete";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    setCurrentFileVersion(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/version/current/"+requestData.id;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getThemeOptions(){
        const themeTypes = [
            {
                key: "standard",
                value: "Standard"
            },
            {
                key: "light",
                value: "Light"
            },
        ];
        return themeTypes;
    },
    subscribeAddon(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"subscription/addon";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    verifyCode(requestData, cancelTokenSource, extraHeaders = {}){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            for(var header in extraHeaders){
                try{
                    headers[header] = extraHeaders[header]
                }catch(e){ }
            }
            let ApiUrl = Config.getApiUrl()+"user/verify/code";         
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    confirmCell(requestData, cancelTokenSource, extraHeaders = {}){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            for(var header in extraHeaders){
                try{
                    headers[header] = extraHeaders[header]
                }catch(e){ }
            }
            let ApiUrl = Config.getApiUrl()+"user/confirm/cell";         
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    resendSmsCode(requestData, cancelTokenSource, extraHeaders = {}){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            for(var header in extraHeaders){
                try{
                    headers[header] = extraHeaders[header]
                }catch(e){ }
            }
            let ApiUrl = Config.getApiUrl()+"user/verify/resend";         
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    trackFileDownload(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"download/track/files";
            axios.defaults.timeout = 18000000;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    sendSignupInvitation(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/shared/signupEmail";
            axios.defaults.timeout = 18000000;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    checkPromoCode(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/promo/verify";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    discoverFileLink(id, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getWopiApiUrl()+"wopi/files/"+id+"/link?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getExternalLinks(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/external-links?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    createExternalLink(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/external-links";
            axios.defaults.timeout = 18000000;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
                timeout: 18000000
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    editExternalLink(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/external-links/edit";
            axios.defaults.timeout = 18000000;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
                timeout: 18000000
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    deleteExternalLinks(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/external-links";
            axios.delete(ApiUrl, {
                headers: headers,
                cancelToken: cancelTokenSource.token,
                data: JSON.stringify(requestData)
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getExternalLink(id, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/external-links/"+id;
            axios({
                url: ApiUrl,
                headers: headers,
                method: "GET",
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code === 404){
                    return reject({
                        message: "The link(s) you are trying to view is no longer available"
                    });
                }
                reject(err);
            });
        });
    },
    previewLinkPassword(id, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/external-links/"+id+"/previewPassword?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                headers: headers,
                method: "GET",
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code === 403){
                    return reject({
                        message: "The link(s) you are trying to view is no longer available"
                    });
                }
                reject(err);
            });
        });
    },
    restoreDeletedUser(userId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/restore/delete/"+userId;            
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getAddons(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/addons?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    inviteUsers(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/referral";            
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getReferrals(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/referrals?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateHouseKeeping(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let ApiUrl = Config.getApiUrl()+"user/houseKeeping";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    runHouseKeeping(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let ApiUrl = Config.getApiUrl()+"user/runHouseKeeping";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    shareLink(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/link/share";
            axios.defaults.timeout = 18000000;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
                timeout: 18000000
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getLoginVideos(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/getLoginVideos?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateLoginVideo(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/updateLoginVideo";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteLoginVideo(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/deleteLoginVideo";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    authorizeDocusign(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"docusign/callback?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getDocusignUserInfo(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"docusign/userinfo?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    signDocusign(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"docusign/sign-document";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    authorizeAdobesign(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"adobesign/callback?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getAdobesignUserInfo(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"adobesign/userinfo?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    signAdobesign(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"adobesign/sign-document";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    addActivity(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/addActivity";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
}

export default ApiHelper;
export { ApiHelper };