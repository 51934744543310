import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader";

import confirmationModalStyle from "../../../assets/jss/user/confirmationModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const CancelSubscriptionModal = class extends React.Component {
    render() {
        const { classes, saving, confirmationMessage } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        {
                            saving ?
                                <></>
                            :
                            <h4 className={classes.modalTitle+" "+classes.textCenter}>{confirmationMessage}</h4> 
                        }
                </DialogTitle>
                {
                    saving ?
                        <DialogContent
                            id="confirmationModal-modal-slide-description"
                            className={classes.modalBody}
                            >
                                <LoaderComponent color="custom" align="center" />
                        </DialogContent>
                    :
                    <></>
                }
                
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        saving === false ?
                            <>
                                <Button color="white" onClick={() => this.props.onClose()}>
                                    Cancel
                                </Button>
                                <Button color="custom" className={classes.rightButton} onClick={() => this.props.onSuccess(false)}>
                                    Yes
                                </Button>
                            </>
                        :
                        <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

CancelSubscriptionModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false
}
CancelSubscriptionModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool
}
export default withStyles(confirmationModalStyle)(CancelSubscriptionModal);