import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from '../../../assets/js/utils/Element';
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import ProcessingModal from "../../components/User/ProcessingModal";

import withStyles from "@material-ui/core/styles/withStyles";
import userSubscriptionPageStyle from "../../../assets/jss/user/userSubscriptionPageStyle.jsx";

const UserSubscription = class extends React.Component {
    constructor(props){
        super(props);

        const { classes } = this.props;
        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();

        let status = helper.getParam("state");
        if(status === "null" || !status){
            status = "cancelled";
        }
        let id = helper.getParam("id");
        if(id === "null" || !id){
            id = null;
        }
        let loadingModal = false;
        let successMessage = "Welcome and thank you for registering for Sendlinx "+user.plan+" Account. Your account has now been created and you can log in anytime by using your email address and password";
        if(status === "cancelled"){
            loadingModal = true;
            successMessage = <span className={classes.danger}>Your subscription failed.</span>;
        }
        this.state = {
            status: status,
            id: id,
            loadingModal: loadingModal,
            loading: false,
            successMessage: successMessage,
            user: user
        };

        this.onModalClose = this.onModalClose.bind(this);
    }
    componentDidMount(){
        const { status, id } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized && status !== "cancelled" && id){
            this.checkSubscriptionStatus();
        }
    }
    checkSubscriptionStatus(){
        const { classes } = this.props;
        const source = axios.CancelToken.source();
        const requestData = {
            id: this.state.id
        };
        Api.checkSubscription(requestData, source).then(data => {
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
            const successMessage = "Welcome and thank you for registering for Sendlinx "+data.user.plan+" Account. Your account has now been created and you can log in anytime by using your email address and password";
            this.setState({loading: false, user: data.user, successMessage: successMessage});
        }).catch(err => {
            this.setState({
                loading: false, 
                successMessage: <span className={classes.danger}>Your subscription failed.</span>
            });
        });
        this.setState({
            loading: true,
            loadingModal: true
        });
    }
    onModalClose(){
        const { user } = this.state;
        this.setState({
            loadingModal: false,
            loading: false
        });

        this.store.dispatch({
            type: "UPDATE_STATE",
            state: {
                user: user
            }
        });

        if(user.user_type === "business" && user.businessInfo === null){
            this.history.push("/user/business-info");
        }else{
            this.history.push(helper.getHomePageUrl(user));
        }
    }
    render() {
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        const { loadingModal, loading, successMessage } = this.state;
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    
                </div>
                {
                    loadingModal ?
                        <ProcessingModal 
                            open={loadingModal} 
                            saving={loading} 
                            onClose={() => this.onModalClose()} 
                            onSuccess={() => this.onModalClose()}
                            loadingMessage="Please wait while we check your subscription status."
                            successMessage={<span className={classes.center}>{successMessage}</span>}
                            store={this.store}
                        />
                    :
                    <></>
                }
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserSubscription.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userSubscriptionPageStyle)(UserSubscription);
