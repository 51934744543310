import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader";
import CustomInput from "../CustomInput/CustomInput.jsx";
import Close from "@material-ui/icons/Close";
import renameFileModalStyle from "../../../assets/jss/user/renameFileModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const SharedViewNameModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
        }
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    render() {
        const { classes, saving, errorMessage } = this.props;
        const { firstName, lastName } = this.state;
        
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="renameFileModal-slide-title"
                aria-describedby="renameFileModal-slide-description"
            >
                <DialogTitle
                    id="renameFileModal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    {
                        errorMessage !== null ?
                            <></>
                        : saving ?
                            <h4 className={classes.modalTitle}>Please wait.</h4> 
                        :
                            <h4 className={classes.modalTitle}>Insert your information.</h4> 
                    }
                </DialogTitle>
                <DialogContent id="renameFileModal-slide-description" className={classes.modalBody}>
                    {
                        errorMessage !== null ?
                            <p className={classes.errorMessage}>{errorMessage}</p>
                        : saving ?
                            <LoaderComponent color="custom" align="center" />
                        :
                            <>
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input m-zero"
                                    }}
                                    labelText= {"First Name"}
                                    id="input-firstName"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e, 'firstName'),
                                        name: "firstName",
                                        value: firstName,
                                    }}
                                />
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input"
                                    }}
                                    labelText= {"Last Name"}
                                    id="input-lastName"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e, 'lastName'),
                                        name: "lastName",
                                        value: lastName,
                                    }}
                                />
                            </>
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    {
                        saving === false ?
                            <Button color="custom" onClick={() => this.props.onSuccess(firstName, lastName)} disabled={(firstName.length < 1 || lastName.length < 1)}>Submit</Button>
                        :
                            <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

SharedViewNameModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    saving: false,
    errorMessage: null
}
SharedViewNameModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    saving: PropTypes.bool,
    errorMessage: PropTypes.string,
}
export default withStyles(renameFileModalStyle)(SharedViewNameModal);