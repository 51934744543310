import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import deleteFileModalStyle from "../../../assets/jss/user/deleteFileModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DeleteRuleModal = class extends React.Component {
    render() {
        const { classes } = this.props;
        return (
        <Dialog
            classes={{
                root: classes.modalRoot,
                paper: classes.modal
            }}
            open={this.props.open}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="deleteFile-slide-title"
            aria-describedby="deleteFile-slide-description"
        >
            <DialogTitle
                id="contact-confirmation-modal"
                disableTypography
                className={classes.modalHeader}
            >
                <Button
                    simple
                    className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.props.onClose()}
                >
                    <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>Are you sure you want to delete selected rule(s)?</h4>
            </DialogTitle>
            <DialogContent id="deleteFile-slide-description" className={classes.modalBody}>
            </DialogContent>
            <DialogActions className={classes.modalFooter}>
                <Button color="custom" onClick={() => this.props.onSuccess()}>Delete</Button>
                <Button color="white" onClick={() => this.props.onClose()}>Cancel</Button>
            </DialogActions>        
        </Dialog>
        );
    }
};

DeleteRuleModal.defaultProps = {
  open: false,
  deletingUser: false,
  deleteAll: false
};
DeleteRuleModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  deleteAll: PropTypes.bool
};
export default withStyles(deleteFileModalStyle)(DeleteRuleModal);
