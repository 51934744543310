import React from 'react'
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import LoaderComponent from "../Loader";
import Close from "@material-ui/icons/Close";
import ReactTable from "react-table";
import moment from 'moment';
import DeleteFileModal from "../../components/User/DeleteFileModal";

import archivesFilesModalStyle from "../../../assets/jss/user/archivesFilesModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ArchivesFilesModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
          
        this.state = {
            showError: false,
            errorMessage: "",
            response: null,
            saving: false,
            removedNotice: false,
            files: [],
            page: 1,
            loading: false,
            checkedAll: false,
            deleteFileModal: false,
            checked: []
        };

        this.loadingFolder = false;
    }
    componentDidMount(){
        this.loadFiles();
    }
    loadFiles(viewMore = false){
        const source = axios.CancelToken.source();
        let { page } = this.state;
        let { type } = this.props;
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        let requestData = {
            page: page,
            limit: 200,
            archives: 1
        };

        requestData['includeSub'] = true;
        requestData['filesOnly'] = true;
        requestData['transferType'] = type;

        this.setState({
            loading: true
        });
        
        Api.getArchiveFiles(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
            });
            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
        });
        let newState = {
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            checked: viewMore ? this.state.checked : [],
            files: [],
            errorMessage: null
        };
        if(!viewMore){
            newState['totalPages'] = 0;
        }
        this.setState(newState);
        this.loadingFolder = true;
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
            }
        }else{
            return {}
        }
    }
    getTrGroupProps(state, rowInfo){
        const { checked } = this.state;
        
        if (rowInfo && rowInfo.row) {
            if(checked.indexOf(rowInfo.original.id) !== -1){
                return {
                    className: 'selected_row'
                }
            }
            return {
                className: (rowInfo.original.id === 0 ? 'frozen_row' : '')
            }
        }else{
            return {}
        }
    }
    getColumns(){
        const { classes, type } = this.props;
        let columns = [
            {
                Header: (
                    <Checkbox
                        tabIndex={-1}
                        checked={this.state.checkedAll}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        onChange={(e) => this.onToggleAllFiles(e)}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                accessor: "check",
                sortable: false,
                filterable: false,
                resizable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                width: 30
            },
            {
                Header: "File Name",
                accessor: "name",
                headerClassName: "hd_name",
                className: "hd_name td_name",
                width: 180
            },
            {
                Header: "Path",
                accessor: "path",
                headerClassName: "hd_path",
                className: "hd_path td_path",
            },
            {
                Header: "Size",
                accessor: "orignalSize",
                headerClassName: "hd_size hd_show_head",
                className: "hd_size td_size td_hide",
                resizable: false
            },
            {
                Header: "Size",
                accessor: "size",
                headerClassName: "hd_size hd_hide",
                className: "hd_size td_size",
            },
            {
                Header: "Date Created",
                accessor: "date_created",
                headerClassName: "hd_date_created",
                className: "hd_date_created td_date_created",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Destination",
                accessor: "recipients",
                headerClassName: "hd_destination",
                className: "hd_destination td_destination",
            },
            {
                Header: "Last downloaded",
                accessor: "last_downloaded",
                headerClassName: "hd_last_downloaded",
                className: "hd_last_downloaded td_last_downloaded",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            }
        ];

        if(type === 'office' || type === 'keep'){
            let versionColumn = {
                Header: "Version Number",
                accessor: "version",
                headerClassName: "hd_version",
                className: "hd_version td_version",
                width: 120
            };

            columns.push(versionColumn);
        }
        return columns;
    }
    getTableData(){
        const { classes, type } = this.props;
        const { response, checked } = this.state;
        if(response ===  null){
            return;
        }
        let tableData = [];
        response.map(file => {
            const fileArray = {
                id: file.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(file.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(file)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                name: file.name,
                path: file.path,
                size: file.size,
                orignalSize: file.orignalSize,
                date_created: file.date,
                recipients: (
                    <>
                        {
                            file.title !== '' && file.title ?
                                file.title
                            :
                                file.recipients.join(",")
                        }
                    </>
                ),
                last_downloaded: this.getFormatedDownloadDate(file.download_date),
            };
            if(type === 'office' || type === 'keep'){
                fileArray.version = file.file_version
            }
            
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    getFormatedDownloadDate(date){
        if(!date || date.length <= 0){
            return "";
        }
        return moment(date).format("MM/DD/YYYY h:mm A");
    }
    getFileName(file){
        return (
            <div title={file.name}>
                {
                    file.name
                }
            </div>
        )
    }
    handleToggle(file) {
        if(this.loadingFolder){
            return;
        }
        // if(file.default){
        //     return;
        // }
        
        const { checked } = this.state;
        const currentIndex = checked.indexOf(file.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(file.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked,
        });
    }
    onToggleAllFiles(e){
        if(this.loadingFolder){
            return;
        }

        const checkedFiles = [];
        if(e.target.checked){
            const { response } = this.state;

            response.map((file, key) => {
                checkedFiles.push(file.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedFiles
        });
    }
    getModalTitle(type){
        let title = "";
        switch(type){
            case 'link':
                title = "Link Downloads";
                break;
            case 'direct':
                title = "Direct Downloads";
                break;
            case 'office':
                title = "Previous Versions: Office Documents";
                break;
            case 'shared':
                title = "Shared Documents";
                break;
            case 'keep':
                title = "Keep Forever";
                break;
            default:
                break;
        }

        return title;
    }
    deleteSelected(){
        const { checked } = this.state;

        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    onModalClose(deleteFileModal = true){
        let checked = this.state.checked;
        if(deleteFileModal === false){
            checked = [];
        }
        this.setState({
            deleteFileModal: deleteFileModal,
            checked: checked,
            checkedAll: false,
        });
    }
    onModalSuccess(){
        const { type } = this.props;
        const { checked } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            files: checked,
        };
        
        if(type === 'office' || type === 'keep'){
            Api.deleteArchiveVersion(requestData, source).then(data => {
                this.onDeleteApiSuccess();
            }).catch(err => {
                this.setState({
                    saving: false,
                    errorMessage: err.message
                });
            });
        }else{
            Api.deleteFiles(requestData, source).then(data => {
                this.onDeleteApiSuccess();
            }).catch(err => {
                this.setState({
                    saving: false,
                    errorMessage: err.message
                });
            });
        }
        this.setState({
            saving: true
        });        
    }
    onDeleteApiSuccess(){
        const { checked, response } = this.state;
        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteFileModal: false,
            checkedAll: false,
            saving: false,
            errorMessage: null
        });
    }
    getCheckedFiles(){
        const { response, checked } = this.state;
        if(checked.length <= 0){
            return [];
        }
        let files = [];
        response.map((file) => {
            if(checked.indexOf(file.id) !== -1){
                files.push(file);
            }
            return null;
        });
        return files;
    }
    render() {
        const { classes, type } = this.props;
        const { saving, errorMessage, showError, loading, deleteFileModal } = this.state;
        const tableData = this.getTableData();
        const checkedFiles = this.getCheckedFiles();
        
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="shareModal-modal-slide-title"
                aria-describedby="shareModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    {
                        saving === false ?
                            <Button
                                simple
                                className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.onClose()}
                            >
                                <Close className={classes.modalClose} />
                            </Button>
                        :
                            <></>
                    }
                    <h4 className={classes.modalTitle}>{this.getModalTitle(type)}</h4> 
                </DialogTitle>
                <DialogContent
                    id="shareModal-modal-slide-description"
                    className={classes.modalBody}
                >
                    {
                        showError ?
                            <p className={classes.errorMessage}>{errorMessage}</p>
                        : saving ?
                            <LoaderComponent color="custom" align="center" />
                        :
                            <></>
                    }
                    {
                        loading ?
                            <LoaderComponent color="custom" align="center" />
                        :
                            <div className={classes.filesContainer}>
                                <ReactTable
                                    columns={this.getColumns()}
                                    data={tableData}
                                    pageSizeOptions={[500]}
                                    defaultPageSize={50000000000}
                                    showPaginationTop={false}
                                    minRows={0}
                                    showPaginationBottom={false}
                                    className={"-striped -highlight "+classes.filesTable}
                                    getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                    getTrGroupProps={(state, rowInfo) => this.getTrGroupProps(state, rowInfo)}
                                    getNoDataProps={() => this.getNoDataProps()}
                                />
                            </div>
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button color="custom" onClick={() => this.deleteSelected()}>
                        Delete
                    </Button>
                </DialogActions>

                <DeleteFileModal
                    deleteNote={true}
                    deletedFiles={checkedFiles}
                    open={deleteFileModal}
                    onClose={() => this.onModalClose(false)}
                    onSuccess={() => this.onModalSuccess()}
                    loading={saving}
                    errorMessage={errorMessage}
                />
            </Dialog>
        )
    }
}

ArchivesFilesModal.defaultProps = {
    open: false,
    saving: false,
}
ArchivesFilesModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    saving: PropTypes.bool,
}
export default withStyles(archivesFilesModalStyle)(ArchivesFilesModal);