import {
    grayColor,
  } from "./material-kit-pro-react.jsx";

const privacyPageStyle = theme => ({
    panelContent: {
        paddingLeft: "5em",
        paddingRight: "5em",
        paddingTop: "5.5em",
        paddingBottom: "4em",
        [theme.breakpoints.down("md")]: {
            paddingLeft: "3em",
            paddingRight: "3em"
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "2em",
            paddingRight: "2em",
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1em",
            paddingRight: "1em"
        },
        "& h1": {
            fontSize: "2.5em",
            marginTop: "0px",
            marginBottom: "0.25em",
            fontWeight: "500",
            [theme.breakpoints.down("sm")]: {
                fontSize: "2em",
            },
        },
        "& p": {
            fontSize: "1em",
            lineHeight: "1.3125em",
            marginTop: "0",
            marginBottom: "1em",
            display: "block",
        },
        "& ul": {
            fontSize: "0.875em",
            lineHeight: "1.5714285714em",
            margin: "0 0 2em",
            padding: "0 2.8571428571em 0 2em",
            "& li": {
                paddingLeft: "0.5714285714em",
                paddingRight: "0.5714285714em",
            },
            [theme.breakpoints.down("sm")]: {
                paddingRight: "0px",
                "& li": {
                    paddingRight: "0px"
                }
            },
        },
        "& h3": {
            fontSize: "1.3em",
            margin: "10px 0px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "1em",
            },
        },
        "& a": {
            color: grayColor[1],
            textDecoration: "underline"
        },
    },
    textLeft: {
        textAlign: "left"
    },
});

export default privacyPageStyle;
