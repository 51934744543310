import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
const changeExpiryDateModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important",
        marginTop: "0px"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "65px",
        paddingBottom: "50px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& .viewPasswordIcon": {
            cursor: "pointer",
            marginRight: "10px",
            "& svg": {
                color: "#556DC2BF",
                fill: "#556DC2BF",
            }
        },
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    dateTimePicker: {
        position: "relative",
        "& .clear-icon": {
            position: "absolute",
            margin: "0px",
            padding: "0px",
            width: "24px",
            height: "24px",
            minWidth: "auto",
            right: "10px",
            bottom: "14px",
        }
    }
});
  
export default changeExpiryDateModalStyle;
  