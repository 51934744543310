import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import deleteFileModalStyle from "../../../assets/jss/user/deleteFileModalStyle";
import Close from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const FileVersionTypeModal = class extends React.Component {
  render() {
    const { classes, file, uploadedFile } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="fileVersionType-slide-title"
        aria-describedby="fileVersionType-slide-description"
      >
        <DialogTitle
            id="fileVersionType-slide-title"
            disableTypography
            className={classes.modalHeader}
            >
                <Button
                    simple
                    className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.props.onClose()}
                >
                    {" "}
                    <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>
                    Replacing {uploadedFile.name} with new {file.name} will update your link (if any). Continue?
                </h4> 
        </DialogTitle>
        <DialogContent
          id="fileVersionType-slide-description"
          className={classes.modalBody}
        >
        </DialogContent>
        <DialogActions
          className={classes.modalFooter}
        >
          <Button color="custom" onClick={() => this.props.onSuccess()}>Yes</Button>
          <Button color="white" onClick={() => this.props.onClose()}>No</Button>
        </DialogActions>        
      </Dialog>
    );
  }
};

FileVersionTypeModal.defaultProps = {
  open: false,
  deletingUser: false,
  deleteAll: false,
  confirm: false
};
FileVersionTypeModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  deleteAll: PropTypes.bool,
  confirm: PropTypes.bool
};
export default withStyles(deleteFileModalStyle)(FileVersionTypeModal);
