import {
    whiteColor,
    grayColor,
    blackColor,
    hexToRgb,
    controls,
    controlButtons,
    sidebar,
    separator,
    content,
    dangerColor
} from "../material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
import userHomePageStyle from "../user/userHomePageStyle.jsx";
const userReferralsPageStyle = theme => ({
    ...userHomePageStyle(theme),
    ...customCheckboxRadioSwitchStyle(theme),
    controls: {
        ...controls(theme),
    },
    controlButtons: {
        ...controlButtons(theme),
    },
    sidebar: {
        ...sidebar(theme)
    },
    main: {
        marginTop: "0px",
        "& .react-contextmenu": {
            zIndex: "3",
            backgroundColor: whiteColor,
            cursor: "pointer",
            width: "275px",
            textAlign: "left",
            boxShadow:
                "0 4px 20px 0 rgba(" +
                hexToRgb(blackColor) +
                ",.14), 0 7px 10px -5px rgba(" +
                hexToRgb(blackColor) +
                ",.4)",
            "& .react-contextmenu-item": {
                padding: "5px 10px",
                fontSize: "14px",
                "&:hover": {
                    backgroundColor: grayColor[2]
                },
                "& $fileIcon": {
                    padding: "0px"
                }
            },
            "& .react-contextmenu-item + .react-contextmenu-item": {
                borderTop: "1px solid "+grayColor[6]
            }
        }
    },
    container: {
        width: "100%",
        zIndex: "2",
        top: "62px",
        position: "relative",
        paddingTop: "20px",
        height: "calc(100vh - 62px)"
    },
    content: {
        ...content(theme),
        textAlign: "left"
    },
    separator: {
        ...separator(theme)
    },
    title: {
        color: theme.customize.pageTitleColor,
        marginBottom: "20px",
        width: "100%",
        marginTop: "0px",
        textAlign: "left",
    },
    profileContainer: {
        "& .userInfo": {
            "& input, & label, & label.MuiFormLabel-root:not(.Mui-error), & .MuiSelect-select": {
                color: theme.customize.color+" !important",
                textAlign: "left",
                "&.MuiFormControlLabel-root": {
                    width: "100%",
                    "& a": {
                        color: theme.customize.linksColor
                    }
                },
                "& span": {
                    color: theme.customize.color
                },
                "&[class*='labelRootError']": {
                    color: "#f44336 !important",
                }
            },
            "& .MuiInputBase-root.Mui-disabled": {
                backgroundColor: "transparent"
            },
            "& label.Mui-disabled span": {
                color: grayColor[12]
            }
        },
        "& .form-error-message": {
            marginBottom: "10px",
        }
    },
    inputButtons: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: "17px",
        "& button": {
            marginLeft: "10px",
            marginBottom: "0px",
            marginTop: "8px",
            padding: "0.55rem 1.25rem"
        },
        "& .MuiFormControl-root": {
            marginBottom: "0px"
        },
        "& .sd_loader": {
            width: "17px",
            height: "17px",
            marginRight: "4px"
        }
    },
    sendInviteButtons: {
        marginBottom: "5px"
    },
    errorMessages: {
        color: dangerColor[0],
        marginBottom: "0px"
    },
    referralsTable: {
        "& .hd_email": {
            width: "20% !important",
            textAlign: "left !important",
        },
        "& .hd_updated_at": {
            width: "20% !important",
            textAlign: "left !important"
        },
        "& .hd_status": {
            width: "20% !important",
            textAlign: "left !important",
            "& svg": {
                verticalAlign: "middle",
                marginRight: "4px",
                height: "18px",
                width: "18px",
                fill: theme.customize.color+" !important"
            }
        },
    },
    referrals: {
        marginTop: "20px"
    },
    spaceEarned: {
        "& p": {
            marginBottom: "0px",
            fontSize: "16px",
            fontWeight: "bold",
            lineHeight: "30px"
        }
    },
    referralsHeading: {
        fontSize: "16px",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "space-between",
        "& svg": {
            width: "22px",
            height: "22px",
            verticalAlign: "middle",
            marginRight: "5px"
        }
    },
    loadMore: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "10px"
    },
});

export default userReferralsPageStyle;
