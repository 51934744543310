import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../assets/js/utils/Api";
import PropTypes from "prop-types";
import Button from "../components/CustomButtons/Button";
import Clear from "@material-ui/icons/Clear";
import ContactConfirmationModal from "../components/User/ContactConfirmationModal";
import GridItem from "../components/Grid/GridItem";
import GridContainer from "../components/Grid/GridContainer.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import contactPageStyle from "../../assets/jss/contactPageStyle.jsx";

const Contact = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();
        this.state = {
            open: false,
            name: "",
            email: user.email,
            subject: "",
            message: "",
            validation: {
                name: "",
                email: "",
                subject: "",
                message: "",
                isValid: false,
            },
            cancelToken: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.onOkClick =this.onOkClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleChange(event, name) {
        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    }
    onOkClick(){
        this.resetForm(true);
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, open: true});
        const requestData = {
            name: this.state.name,
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message,
        };
        
        Api.sendContactRequest(requestData, source).then(data => {
            //Do nothing
            this.resetForm();
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.resetForm();
            }  
        });
    }
    validateForm(){
        let validation = {
            email: 'success',
            subject: 'success',
            message: 'success',
            isValid: true
        };

        const values = this.state;
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(values.email.length <= 0 || emailRex.test(values.email) === false){
            validation.email = "error";
            validation.isValid = false;
        }
        if(values.subject.length <= 0){
            validation.subject = "error";
            validation.isValid = false;
        }
        if(values.message.length <= 0){
            validation.message = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    resetForm(hideModal = false){
        let newState = {
            subject: "",
            message: "",
            validation: {
                email: "",
                subject: "",
                message: "",
                isValid: false,
            },
        };
        if(hideModal === true){
            newState['open'] = false;
        }
        this.setState(newState);
    }
    
    render() {
        const { classes } = this.props;
        const { name, subject, message, open, validation } = this.state;
        return (
            <div>
                <div className={classes.panelContent}>
                    <GridContainer className={classes.heading}>
                        <GridItem xs={12} sm={6} className={classes.textLeft}>
                            <h1 className={classes.marginZero}>Contact Us</h1>
                        </GridItem>
                    </GridContainer>
                        <GridContainer  className={classes.contactMapContainer}>
                            <GridItem xs={12}>
                                <iframe src="https://www.mapquest.ca/embed/canada/on/burlington/l7s-2k9/1235-fairview-st-43.330334,-79.818157?center=43.33079696281896,-79.8189353942871&zoom=15&maptype=undefined" title="Map"></iframe>
                            </GridItem>
                        </GridContainer>
                        <GridContainer className={classes.contactContainer}>
                            <GridItem xs={12} md={4} className={classes.contactDetailColumn}>
                                <div className={classes.detailContainer+" "+classes.textLeft}>
                                    <h3>Contact Info</h3>
                                    <div className={classes.address}>
                                        <p>Sendlinx - Head office</p>
                                        <p>1235 Fairview St #381, Burlington, ON L7S 2K9</p>
                                        <p>P. 1-866-647-4874</p>
                                    </div>
                                    <div className={classes.contactInfo}>
                                        <h4>Emails</h4>
                                        <p><span>Sales:</span> sales@sendlinx.com</p>
                                        <p><span>Support:</span> support@sendlinx.com</p>
                                        <p><span>Billing:</span> billing@sendlinx.com</p>
                                        <p><span>General Inquiries:</span> info@sendlinx.com</p>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} md={8} className={classes.ContactFormColumn}>
                                <div className={classes.supportContainer}>
                                    <form onSubmit={this.handleSubmit} className={classes.formFields}>
                                        <div className="formElement">
                                            <input 
                                                type="text" 
                                                value={name} 
                                                placeholder="Name"
                                                id="outlined-subject"
                                                name= "name"
                                                onChange= {(e) => this.handleChange(e, 'name')}
                                            >
                                            </input>
                                            {
                                                validation.name === 'error' ?
                                                    <Clear className="claer-icon" />
                                                :
                                                    <></>
                                            }
                                        </div>
                                        <div className="formElement">
                                            <input 
                                                type="email" 
                                                value={this.state.email} 
                                                placeholder="From: user@example.com"
                                                id="outlined-yourEmail"
                                                name= "yourEmail"
                                                onChange= {(e) => this.handleChange(e, 'email')}
                                            >
                                            </input>
                                            {
                                                validation.email === 'error' ?
                                                    <Clear className="claer-icon" />
                                                :
                                                    <></>
                                            }
                                        </div>
                                        <div className="formElement">
                                            <input 
                                                type="text" 
                                                value={subject} 
                                                placeholder="Subject"
                                                id="outlined-subject"
                                                name= "subject"
                                                onChange= {(e) => this.handleChange(e, 'subject')}
                                            >
                                            </input>
                                            {
                                                validation.subject === 'error' ?
                                                    <Clear className="claer-icon" />
                                                :
                                                    <></>
                                            }
                                        </div>

                                        <div className="formElement">
                                            <textarea 
                                                type="text" 
                                                value={message} 
                                                placeholder="Question"
                                                id="outlined-message"
                                                name= "message"
                                                onChange= {(e) => this.handleChange(e, 'message')}
                                            >
                                            </textarea>
                                            {
                                                validation.message === 'error' ?
                                                    <Clear className="claer-icon" />
                                                :
                                                    <></>
                                            }
                                        </div>
                                        <div className={classes.formFooter}>
                                            <Button color="custom" type="submit">
                                                <svg className="sendicon" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 3760 3500">
                                                    <path d="M970 3489c-52-16-86-45-108-92-19-39-178-874-169-883 8-8 888 629 884 640-3 12-423 297-472 320-43 21-95 26-135 15zm1322-152c-40-15-1104-787-1100-798 2-5 379-378 838-830C3215 542 3575 185 3559 195c-8 5-614 481-1348 1059-734 577-1339 1049-1345 1049-13 0-743-529-790-572-67-61-89-186-47-268 45-90-23-61 1743-738C2675 379 3472 74 3542 48c138-53 167-54 195-11 9 13 16 36 15 51-1 19-1046 2900-1130 3113-18 45-74 101-127 125-56 27-146 31-203 11z"/>
                                                </svg> 
                                                Send
                                            </Button>
                                        </div>
                                        <ContactConfirmationModal onClose={this.onOkClick} open={open} stor={this.store} history={this.history}/>
                                    </form>
                                </div>
                            </GridItem>
                        </GridContainer>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Contact.propTypes = {
    classes: PropTypes.object
};

export default withStyles(contactPageStyle)(Contact);
