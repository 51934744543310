import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomSelect from "../CustomInput/CustomSelect";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader";

import changeExpiryDateModalStyle from "../../../assets/jss/user/changeExpiryDateModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ChangeAccountTypeModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            accountType: 2
        };

        this.accountTypes = [
            {
                id: 2,
                label: "Standard",
            },
            {
                id: 3,
                label: "Pro",
            }
        ];
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    renderErrorMessages(){
        const { errorMessage } = this.props;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    render() {
        const { classes, saving, confirmationMessage, errorMessage } = this.props;
        const { accountType } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    {
                        saving ?
                            <></>
                        :
                            <h4 className={classes.modalTitle}>{confirmationMessage}</h4> 
                    }
                </DialogTitle>
                <DialogContent
                    id="confirmationModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            saving === false && errorMessage !== null ?
                                this.renderErrorMessages()
                            :
                                <></>
                        }
                        {
                            saving ?
                                <LoaderComponent color="custom" align="center" />
                            :
                                <CustomSelect 
                                    formControlProps={{
                                        fullWidth: true,
                                        className: 'custom-input m-zero'
                                    }}
                                    labelText="Accont Type"
                                    selectProps={{
                                        onChange: (e) => this.handleChange(e,"accountType"),
                                        value: accountType
                                    }}
                                    inputProps={{
                                        name: "accountType",
                                        id: "input-accountType",
                                        className: classes.alignLeft
                                    }}
                                    items={this.accountTypes}
                                    itemLabel="label"
                                    itemValue="id"
                                />
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    {
                        saving ?
                            <></>
                        :
                            <Button color="custom" className={classes.changeButton} onClick={() => this.props.onSuccess(accountType)}>
                                Change
                            </Button>   
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

ChangeAccountTypeModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false
}
ChangeAccountTypeModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool
}
export default withStyles(changeExpiryDateModalStyle)(ChangeAccountTypeModal);