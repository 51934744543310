import React from "react";
import Api from "../../../assets/js/utils/Api";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader';
import GridItem from "../../components/Grid/GridItem.jsx";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import axios from "axios";
import CustomInput from "../CustomInput/CustomInput.jsx";

import addRuleModelStyle from "../../../assets/jss/user/addRuleModelStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const AddRuleModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { ruleDetails, editMode } = this.props;

        let ruleEmails = ruleDetails.emails;
        if(editMode === true){
            ruleEmails = ruleDetails.emails.join('\n');
        }
        
        this.state = {
            ruleDetails: ruleDetails,
            prefix: ruleDetails.prefix,
            prefixOp: ruleDetails.prefixOp,
            suffix: ruleDetails.suffix,
            suffixOp: ruleDetails.suffixOp,
            directory: ruleDetails.directory,
            emails: ruleEmails,
            to: ruleDetails.to,
            cc: ruleDetails.cc,
            bcc: ruleDetails.bcc,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                prefix: "",
                suffix: "",
                directory: "",
                emails: "",
                isValid: false
            },
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            editMode: editMode
        };

        this.updateRuleDetails = this.updateRuleDetails.bind(this);
        this.opTypes = this.opTypes();
    }
    opTypes(){
        return [
            {
                'id': 0,
                'label': 'None'
            },
            {
                'id': 1,
                'label': 'And'
            },
            {
                'id': 2,
                'label': 'Or'
            },
        ]
    }
    updateRuleDetails(){
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }

        const { ruleDetails, prefix, prefixOp, suffix, suffixOp, directory, to, cc, bcc, emails, editMode } = this.state;

        let emailsArray = emails.split(/[\s,;]+/);
        emailsArray = emailsArray.map(i=>i.trim());

        const requestData = {
            prefix: prefix,
            prefixOp: prefixOp,
            suffix: suffix,
            suffixOp: suffixOp,
            directory: directory,
            to: to,
            cc: cc,
            bcc: bcc,
            emails: emailsArray,
            case: 0
        };
        
        if(ruleDetails.hasOwnProperty("id")){
            requestData['id'] = ruleDetails.id;
        }

        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        if(editMode === true) {
            Api.editRule(requestData.id, requestData).then(data => {
                that.setState({
                    success: true,
                    saving: false
                });
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                    this.setState({
                        saving: false,
                        showError: true,
                        errorMessage: err.message
                    });
                }
            });
        }else{
            Api.addRule(requestData, source).then(data => {
                that.setState({
                    success: true,
                    saving: false
                });
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                    this.setState({
                        saving: false,
                        showError: true,
                        errorMessage: err.message
                    });
                }
            });
        }
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    validateForm(){
        let validation = {
            prefix: "success",
            suffix: "success",
            directory: "success",
            emails: "success",
            isValid: true
        };
        const { prefix, suffix, directory, emails } = this.state;
        if(prefix.length <= 0){
            validation.prefix = "error";
            validation.isValid = false;
        }
        if(suffix.length <= 0){
            validation.suffix = "error";
            validation.isValid = false;
        }
        if(directory.length <= 0){
            validation.directory = "error";
            validation.isValid = false;
        }
        if(emails.length <= 0){
            validation.emails = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    renderForm(){
        const { prefix, prefixOp, suffix, suffixOp, directory, emails, validation, to, cc, bcc } = this.state;
        const { classes } = this.props;

        return(
            <div className={classes.addUserContainer}>
                <div className={classes.addUserForm}>
                    <div>
                        <CustomInput
                            success={validation.prefix === "success"}
                            error={validation.prefix === "error"}
                            id="input-prefix"
                            labelText="Prefix"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'prefix'),
                                value: prefix,
                                name: "prefix",
                                type: "text",
                            }}
                            color="custom"
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input m-zero"
                            }}
                        />
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                            labelText="PrefixOp"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"prefixOp"),
                                value: prefixOp
                            }}
                            inputProps={{
                                name: "prefixOp",
                                id: "input-prefixop",
                                className: classes.alignLeft
                            }}
                            items={this.opTypes}
                            itemLabel="label"
                            itemValue="id"
                        />
                        <CustomInput
                            success={validation.suffix === "success"}
                            error={validation.suffix === "error"}
                            id="input-suffix"
                            labelText="Suffix"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'suffix'),
                                value: suffix,
                                name: "suffix",
                                type: "text",
                            }}
                            color="custom"
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                        />
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                            labelText="SuffixOp"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"suffixOp"),
                                value: suffixOp
                            }}
                            inputProps={{
                                name: "suffixOp",
                                id: "input-suffixop",
                                className: classes.alignLeft
                            }}
                            items={this.opTypes}
                            itemLabel="label"
                            itemValue="id"
                        />
                        <CustomInput
                            success={validation.directory === "success"}
                            error={validation.directory === "error"}
                            id="input-directory"
                            labelText="Directory"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'directory'),
                                value: directory,
                                name: "directory",
                                type: "text",
                            }}
                            color="custom"
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                        />
                        <div className={classes.inspectSection}>
                            <label className={classes.label}>Inspect</label>
                            <FormControlLabel
                                classes={{label: classes.label}}
                                control={
                                    <Checkbox                                                
                                        tabIndex={-1}
                                        onClick={(e) => this.handleCheckbox(e, "to")}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                        checked={to}
                                    />
                                }
                                label={"To"}
                            />
                            <FormControlLabel
                                classes={{label: classes.label}}
                                control={
                                    <Checkbox                                                
                                        tabIndex={-1}
                                        onClick={(e) => this.handleCheckbox(e, "cc")}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                        checked={cc}
                                    />
                                }
                                label={"CC"}
                            />
                            <FormControlLabel
                                classes={{label: classes.label}}
                                control={
                                    <Checkbox                                                
                                        tabIndex={-1}
                                        onClick={(e) => this.handleCheckbox(e, "bcc")}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                        checked={bcc}
                                    />
                                }
                                label={"BCC"}
                            />
                        </div>
                        <CustomInput
                            success={validation.emails === "success"}
                            error={validation.emails === "error"}
                            id="input-emails"
                            labelText="E-mail(s)"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'emails'),
                                value: emails,
                                name: "emails",
                                type: "text",
                                multiline: true,
                                rows: 3,
                            }}
                            color="custom"
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                        />
                        <div className={classes.emailNote}>
                            <i className="red">Email must be a valid email address</i>
                            <i className="gray">* Return or semicolon after each email address</i>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { saving, success, showError, editMode } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="userDetails-slide-title"
                aria-describedby="userDetails-slide-description"
            >
                <DialogTitle
                    id="userDetails-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>
                        {
                            editMode ?
                                "Edit Rule"
                            :
                                "Add Rule"
                        }
                    </h4>
                </DialogTitle>
                <DialogContent id="userDetails-slide-description" className={classes.modalBody+" "+(success ? 'success' : '')}>
                    {
                        showError ?
                            this.renderErrorMessages()
                        :
                            <></>
                    }
                    {
                        success ?
                            editMode ?
                                <p>Rule updated successfully.</p>
                            :
                                <p>Rule added successfully.</p>
                        :
                            this.renderForm()
                    }
                </DialogContent>      
                <DialogActions className={classes.modalFooter}>
                    {
                        success ?
                            <Button color="custom" onClick={() => this.props.onClose()}>
                                Close
                            </Button>
                        :
                            <Button color="custom" onClick={this.updateRuleDetails} type="button">
                                {
                                    saving ?
                                        <LoaderComponent color="white" align="left" saving={true} />
                                    :
                                        "Save Changes"
                                }
                            </Button>
                    }
                </DialogActions>
            </Dialog>
        );
    }
};

AddRuleModal.defaultProps = {
    open: false,
    editMode: false,
};
AddRuleModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    ruleDetails: PropTypes.object.isRequired,
    editMode: PropTypes.bool
};
export default withStyles(addRuleModelStyle)(AddRuleModal);
