import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DateTimePicker from "../CustomInput/DateTimePicker";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader";

import changeExpiryDateModalStyle from "../../../assets/jss/user/changeExpiryDateModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ChangeExpiryDateModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            expire: this.props.expire,
            orgExpireDate: this.props.expire
        };

        this.handleDateChange = this.handleDateChange.bind(this);
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    onClearDate(){
        this.setState({
            expire: ''
        })
    }
    render() {
        const { classes, saving, confirmationMessage } = this.props;
        const { expire, orgExpireDate } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        {
                            saving ?
                                <></>
                            :
                            <h4 className={classes.modalTitle}>{confirmationMessage}</h4> 
                        }
                </DialogTitle>
                <DialogContent
                    id="confirmationModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            saving ?
                                <LoaderComponent color="custom" align="center" />
                            :
                                <div className={classes.dateTimePicker+" datetime-element"}>
                                    <DateTimePicker
                                        labelText={"Expiry Date"}
                                        id="input-expire"
                                        value={expire}
                                        onChange={(date) => this.handleDateChange(date, 'expire')}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: "custom-input m-zero"
                                        }}
                                        disablePast={true}
                                    />
                                    {
                                        expire && expire !== '' ?
                                            <Button className="clear-icon" justIcon color="custom" onClick={() => this.onClearDate()}>
                                                <Close className={classes.inputAdornmentIcon}/>
                                            </Button>
                                        :
                                            <></>
                                    }
                                </div>
                        }
                </DialogContent>
                {
                    saving ?
                        <></>
                    :
                    <DialogActions className={classes.modalFooter}>
                        <Button color="custom" disabled={(orgExpireDate === '' && expire === '') || orgExpireDate === expire ? true : false} className={classes.changeButton} onClick={() => this.props.onSuccess(expire)}>
                            Change
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        )
    }
}

ChangeExpiryDateModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false
}
ChangeExpiryDateModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool
}
export default withStyles(changeExpiryDateModalStyle)(ChangeExpiryDateModal);