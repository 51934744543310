import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "../CustomInput/CustomInput.jsx";
import deleteFileModalStyle from "../../../assets/jss/user/deleteFileModalStyle";
import Close from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DeleteLinkModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            deleteText: ''
        }
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    render() {
        const { classes, deletedLinks, confirm } = this.props;
        const { deleteText } = this.state;
        return (
        <Dialog
            classes={{
            root: classes.modalRoot,
            paper: classes.modal
            }}
            open={this.props.open}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="deleteFile-slide-title"
            aria-describedby="deleteFile-slide-description"
        >
            <DialogTitle
                id="attachments-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
                <Button
                    simple
                    className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.props.onClose()}
                >
                    {" "}
                    <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>Are you sure you want to delete?</h4> 
            </DialogTitle>
            <DialogContent
                id="deleteFile-slide-description"
                className={classes.modalBody}
            >
                {
                    confirm && deletedLinks.length > 5 ?
                        <CustomInput
                            id="input-deleteText"
                            labelText="Type delete for confirm"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'deleteText'),
                                value: deleteText,
                                name: "deleteText",
                                type: "text",
                            }}
                            color="custom"
                            formControlProps={{
                                fullWidth: true,
                                className: 'custom-input'
                            }}
                        />
                    :
                    <></>
                }
            </DialogContent>
            <DialogActions
                className={classes.modalFooter}
            >
                <Button color="custom" disabled={ confirm && deletedLinks.length > 5 && deleteText.toLowerCase() !== 'delete' ? true : false } onClick={() => this.props.onSuccess()}>Delete</Button>
                <Button color="white" onClick={() => this.props.onClose()}>Cancel</Button>
            </DialogActions>        
        </Dialog>
        );
    }
};

DeleteLinkModal.defaultProps = {
  open: false,
  deletingUser: false,
  deleteAll: false,
  confirm: false
};
DeleteLinkModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  deleteAll: PropTypes.bool,
  confirm: PropTypes.bool
};
export default withStyles(deleteFileModalStyle)(DeleteLinkModal);
