import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader";
import confirmationModalStyle from "../../../assets/jss/user/confirmationModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const CancelDeleteRequestModal = class extends React.Component {
    render() {
        const { classes, saving } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="deleteRequestModal-slide-title"
                aria-describedby="deleteRequestModal-slide-description"
            >
                <DialogTitle
                    id="contact-confirmation-modal"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Are you sure you want to cancel your delete account request?</h4>
                </DialogTitle>
                <DialogContent id="deleteRequestModal-slide-description" className={classes.modalBody}>
                </DialogContent>
                <DialogActions className={classes.modalFooter} >
                    <Button color="custom" onClick={() => this.props.onSuccess()}>
                        {
                            saving ?
                                <LoaderComponent color="white" saving={true} />
                            :
                                "Yes"
                        }
                    </Button>
                    <Button color="white" onClick={() => this.props.onClose()}>No</Button>
                </DialogActions>        
            </Dialog>
        );
    }
};

CancelDeleteRequestModal.defaultProps = {
  open: false,
  deletingUser: false,
  deleteAll: false,
  saving: false
};
CancelDeleteRequestModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  deleteAll: PropTypes.bool,
  saving: PropTypes.bool
};
export default withStyles(confirmationModalStyle)(CancelDeleteRequestModal);
