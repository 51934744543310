import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactTable from "react-table";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

import linkTableViewStyle from "../../../assets/jss/linkTableViewStyle";

class MobileView extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        
        this.state = {
            tableData: this.props.tableData,
            limit: this.props.limit
        }
    }
    componentWillReceiveProps(props){
        this.setState({
            tableData: props.tableData,
            limit: props.limit
        });
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    render(){
        const { classes } = this.props;
        const { tableData, limit } = this.state;

        return (
            <div>
                <ReactTable
                    columns={[
                        {
                            Header: (
                                <Checkbox
                                    tabIndex={-1}
                                    checked={this.props.checkedAll}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    onChange={(e) => this.props.onCheckedAll(e)}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                />
                            ),
                            accessor: "check",
                            sortable: false,
                            filterable: false,
                            resizable: false,
                            headerClassName: "hd_check hd_mobile_view",
                            className: "hd_check td_check hd_mobile_view",
                        },
                        {
                            Header: "Date",
                            accessor: "created_at",
                            headerClassName: "hd_created hd_mobile_view",
                            className: "hd_created td_created hd_mobile_view",
                            sortMethod: (a, b) => {
                                var a1 = new Date(a).getTime();
                                var b1 = new Date(b).getTime();
                              if(a1<b1)
                                return 1;
                              else if(a1>b1)
                                return -1;
                              else
                                return 0;
                            }
                        },
                        {
                            Header: "",
                            accessor: "attachmentsMobile",
                            headerClassName: "hd_mobile_icon",
                            className: "hd_mobile_icon",
                            sortable: false,
                            filterable: false,
                            resizable: false,
                        },
                        {
                            Header: "Destination",
                            accessor: "recipients",
                            headerClassName: "hd_recipients hd_mobile_view",
                            className: "hd_recipients td_recipients hd_mobile_view",
                        },
                        {
                            Header: "",
                            accessor: "webLink",
                            headerClassName: "hd_mobile_icon",
                            className: "hd_mobile_icon",
                            sortable: false,
                            filterable: false,
                            resizable: false,
                        },
                        {
                            Header: "",
                            accessor: "action",
                            headerClassName: "hd_mobile_icon",
                            className: "hd_mobile_icon",
                            sortable: false,
                            filterable: false,
                            resizable: false,
                        },
                        {
                            Header: "",
                            accessor: "delete",
                            headerClassName: "hd_mobile_icon",
                            className: "hd_mobile_icon ",
                            sortable: false,
                            filterable: false,
                            resizable: false,
                        },
                        {
                            Header: "",
                            accessor: "three_dot",
                            headerClassName: "hd_mobile_icon",
                            className: "hd_mobile_icon ",
                            sortable: false,
                            filterable: false,
                            resizable: false,
                        },
                    ]}
                    data={tableData}
                    pageSizeOptions={[500]}
                    defaultPageSize={limit}
                    showPaginationTop={false}
                    minRows={0}
                    showPaginationBottom={false}
                    className={"-striped -highlight mobile-view-table "+classes.filesTable}
                    getNoDataProps={() => this.getNoDataProps()}
                />
            </div>
        )
    }
}

MobileView.propTypes = {
    classes: PropTypes.object,
    onCheckedAll: PropTypes.func,
};

export default (withStyles(linkTableViewStyle)(MobileView));