import React from 'react';
import axios from "axios";
import Api from "../../assets/js/utils/Api";
import PropTypes from "prop-types";
import Cookie from "../../assets/js/utils/Cookie";
import LoaderComponent from "../components/Loader";
import { helper } from "../../assets/js/utils/Element";
import { Helmet } from 'react-helmet';

import withStyles from "@material-ui/core/styles/withStyles";
import editDocumentPageStyle from "../../assets/jss/editDocumentPageStyle.jsx";

const EditDocument = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        this.modelBodyRef = React.createRef();
        this.formRef = React.createRef();
        this.iframeRef = React.createRef();
        let id = "";
        if(this.props.match.params.hasOwnProperty("id")){
            id = this.props.match.params.id;
        }
        let sharedFile = helper.getParam("sharedFile");
        if(!sharedFile || sharedFile === "null"){
            sharedFile = null;
        }


        this.state = {
            action: this.props.action,
            file: id,
            sharedFileId: sharedFile,
            appUrl: null,
            favIconUrl: null,
            fileData: null,
            loading: false,
        };
    }
    componentDidMount(){
        this.discoverLink();
    }
    discoverLink(){
        const { action, file, sharedFileId } = this.state;
        const requestData = {
            access_token: Cookie.read("oauth_token")+":"+Cookie.read("oauth_secret"),
            action: action
        };
        if(sharedFileId !== null){
            requestData['sharedFileId'] = sharedFileId;
        }
        const source = axios.CancelToken.source();
        Api.discoverFileLink(file, requestData, source).then((data) => {
            this.setState({
                appUrl: data.appUrl,
                favIconUrl: data.favIconUrl,
                fileData: data,
                loading: true,
            }, () => {
                this.submitAppForm();
            });
        }).catch(err => {
            this.setState({
                loading: false,
                showError: true, 
                errorMessage: err.message
            });
        });
        this.setState({
            loading: true,
            cancelToken: source,
        });
    }
    submitAppForm(){
        this.formRef.current.submit();
    }
    render() {
        const { classes } = this.props;
        const { appUrl, loading, showError, errorMessage, favIconUrl, fileData } = this.state;
        let expiresAt = Cookie.read("expires_at");
        return (
            <div className={classes.main}>
                {
                    appUrl !== null ?
                        <>
                            <form className={classes.officeForm} ref={this.formRef} id="office_form" name="office_form" target="office_frame" action={appUrl} method="post">
                                <input name="access_token" value={Cookie.read("oauth_token")+":"+Cookie.read("oauth_secret")} type="hidden" />
                                <input name="access_token_ttl" value={expiresAt} type="hidden" />
                            </form>
                            <div className={classes.officeIframeContainer} id="frameholder">
                                <iframe ref={this.iframeRef} id="office_frame" name="office_frame" title="Office Frame" sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox allow-downloads" allowFullScreen>

                                </iframe>
                            </div>
                        </>
                    :
                        loading ?
                            <LoaderComponent color="custom" className={classes.loading} align="center" />
                        :
                            null
                }
                {
                    showError ?
                        <p className={classes.errorMessage}>{errorMessage}</p>
                    :
                        null
                }
                <Helmet
                    title={fileData ? fileData.name+" - Sendlinx" : process.env.REACT_APP_TITLE}
                >
                    {
                        favIconUrl !== null ?
                            <link rel="shortcut icon" href={favIconUrl} />
                        :
                        null
                    }
                </Helmet>
            </div>
        )
    }
}

EditDocument.defaultProps = {
    action: "edit"
}

EditDocument.propTypes = {
    classes: PropTypes.object,
    action: PropTypes.string
};

export default withStyles(editDocumentPageStyle)(EditDocument);
